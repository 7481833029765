import React from 'react';
import PropTypes from 'prop-types';
import Classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFaIcon } from '../gears/helpers/fa';

/**
 * Small badge for use in ribbons
 */
class Badge extends React.Component {
    render() {
        const disabled = !this.props.count;
        return (
            <div className="badge" style={{ background: disabled ? 'gray' : 'red' }}>
                {disabled ? '0' : this.props.count}
            </div>
        );
    }
}

/**
 * Ribbon item for RibbonBar
 */
class Ribbon extends React.Component {
    get isDisabled() {
        return !this.props.widget.count;
    }
    render() {
        const { widget } = this.props;
        const ribbonClass = Classnames('ribbon-box', { 'ribbon-box-active': !this.isDisabled });
        const bgColor = Gears.bgFromClass(widget.options.color);
        return (
            <div className={ribbonClass} style={{ backgroundColor: bgColor }} onMouseDown={this.props.onClick}>
                <div className="ribbon-detail">
                    <Badge count={widget.count} />
                    <FontAwesomeIcon size="2x" icon={getFaIcon(widget.icon)} />
                    <br />
                    <span>{widget.text}</span>
                </div>
                <svg className="arrow" viewBox="0 0 10 10" preserveAspectRatio="none">
                    <polygon fill={bgColor} strokeWidth="0" points="0,0 5,5 0,10" />
                </svg>
            </div>
        );
    }
}

/**
 * A widget to provide a 'ribbon' style list of objects
 */
export default class RibbonBar extends React.Component {
    handleRibbonClick(widget, event) {
        const { onRibbonClick } = this.props;
        if (onRibbonClick) {
            onRibbonClick(widget, event);
        }
    }
    render() {
        return (
            <div className="ribbon-bar">
                {this.props.widgets.map((widget, index) => (
                    <Ribbon key={widget.text} widget={widget} onClick={event => this.handleRibbonClick(widget, event)} />
                ))}
            </div>
        );
    }
    static defaultProps = {
        onRibbonClick: null,
        widgets: null,
    };

    static propTypes = {
        onRibbonClick: PropTypes.func.isRequired,
        widgets: PropTypes.object.isRequired,
    };
}

window.RibbonBar = RibbonBar;
