import { titleize } from 'inflection';
import * as _ from 'lodash';
import { ReactNode } from 'react';
// import registry from "../../react-components/WebfrontRegistry";
import { IEditor } from '../Forms/Editors/Editor';
import { FieldTypes, IFieldType, toFieldType } from '../Forms/FieldType';
import { ILookupSpec, LookupConfig } from '../LookupConfig';

interface IModel {
    [key: string]: any;
}

type IMarkdownString = string;

export interface IFieldHelpSpec {
    title?: string | ReactNode;
    content: IMarkdownString;
    icon?: string;
}

export interface IFieldSpec<T, M extends IModel> {
    editable?: boolean;
    enabled?: boolean;
    filterable?: boolean;
    icon?: string;
    label?: ReactNode;
    mobileTemplate?: null;
    defaultValue?: any;
    onChange?: (this: M, e: { field: string }) => any;
    parse?: (rawValue: any) => T | null;
    help?: string | IFieldHelpSpec;
    type?: IFieldType | keyof typeof FieldTypes;
    when_null?: (this: M, e: { field: string }) => any;
    validation?: (value: any) => boolean;
    lookup?: ILookupSpec;
    gearsInit?: any;
    mobile?: any;
    form?: {
        visible?: (this: M) => any;
        tag?: string;
    };
    required?: boolean;
    attributes?: object;
}

function labelFromFieldName(field: string): string {
    const m = field.match(/(\w+)\W*$/);
    const lastSegment = m && m[1];
    return lastSegment ? titleize(lastSegment) : 'Unknown';
}

function rejectNulls<T extends object>(obj: T): Partial<T> {
    return _.pickBy(obj, i => i != null);
}

export default class FieldConfig<T, M extends IModel> implements IFieldSpec<T, M> {
    public fieldName: string;
    public editable: boolean;
    public enabled: boolean;
    public filterable: boolean;
    public icon?: string;
    public label: ReactNode;
    public mobileTemplate?: null;
    public defaultValue?: any;
    public onChange?: (this: M, e: { field: string }) => any;
    public parse?: (rawValue: any) => T | null;
    public help?: IFieldHelpSpec;
    public type: IFieldType;
    public when_null?: (this: M, e: { field: string }) => any;
    public validation?: Constraints;
    public lookup?: LookupConfig;
    public gearsInit?: any;
    public required?: boolean;
    public attributes?: object;
    public mobile?: any;
    public form: {
        visible?: (this: M) => any;
        tag?: string;
    };

    constructor(spec: IFieldSpec<T, M>, fieldName: string) {
        _.defaults(this, rejectNulls(spec), {
            editable: true,
            enabled: true,
            fieldName,
            filterable: true,
            form: {},
            icon: null,
            label: labelFromFieldName(fieldName),
            required: false,
        });
        if (spec.lookup) {
            this.lookup = new LookupConfig(spec.lookup, fieldName);
        }
        if (typeof spec.help === 'string') {
            this.help = { content: spec.help, title: this.label };
        }
        this.type = toFieldType(spec.type || 'string');
    }

    public get Editor(): IEditor<T> {
        const { tag } = this.form;
        return (global as any).registry.getComponent(tag || (this.lookup ? 'LookupEditor' : this.type.editor) || 'InputEditor') as IEditor<T>;
    }
}
