import Class from 'classnames';
import * as React from 'react';
import styles from './Theme.scss';

interface IThemeProps {
  theme: string;
}

export default class Theme extends React.Component<IThemeProps, never> {
  public render(): JSX.Element {
    const { theme, children, ...other } = this.props;
    return (
      <div className={Class(styles[theme], theme)} {...other}>
        {children}
      </div>
    );
  }
}
