import { faStar as faStarO } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import { bind } from 'decko';
import { action, IObservable } from 'mobx';
import * as React from 'react';
import styles from './StarRating.scss';

const defaultProps = {
  editable: false,
  emptyIcon: faStarO,
  filledIcon: faStar,
  rating: 0,
};

interface IStarRatingProps {
  observable?: IObservable;
  value?: string;
  filledIcon?: string;
  emptyIcon?: string;
  editable?: boolean;
  disabled?: boolean;
  rating?: number;
  onChange?: (rating: number) => void;
  className?: string;
}
interface IStarRatingState {
  defaultRating: number;
  rating: number;
}

/**
 * A 5-star rating display
 */
export default class StarRating extends React.Component<IStarRatingProps, IStarRatingState> {
  public static defaultProps = defaultProps;

  constructor(props: IStarRatingProps) {
    super(props);

    if (!props.observable) {
      this.state = { defaultRating: props.rating || defaultProps.rating, rating: props.rating || defaultProps.rating };
    } else {
      this.state = { defaultRating: props.observable[props.value] || defaultProps.rating, rating: props.observable[props.value] };
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IStarRatingProps): void {
    if (!nextProps.observable) {
      this.setState({
        defaultRating: nextProps.rating || defaultProps.rating,
        rating: nextProps.rating || defaultProps.rating,
      });
    } else {
      this.setState({
        defaultRating: nextProps.rating || defaultProps.rating,
        rating: nextProps.observable[nextProps.value],
      });
    }
  }

  @bind
  private onMouseMove(e: React.MouseEvent<HTMLUListElement>): void {
    const left = e.clientX - e.currentTarget.getBoundingClientRect().left;
    const width = e.currentTarget.getBoundingClientRect().width;
    const percent = left / width;
    let rating = Math.ceil(percent * 5);
    // Special case, set it to zero if too far left
    if (percent * 5 < 0.5) {
      rating = 0;
    }
    this.setState({ rating });
  }

  @bind
  @action
  private onMouseDown(e: React.MouseEvent<HTMLUListElement>): void {
    this.setState({ defaultRating: this.state.rating });
    if (this.props.observable && this.props.value !== undefined) {
      this.props.observable[this.props.value] = this.state.rating;
    }
    if (this.props.onChange) {
      this.props.onChange(this.state.rating);
    }
  }

  @bind
  private onMouseLeave(e: React.MouseEvent<HTMLUListElement>): void {
    this.setState({ rating: this.state.defaultRating });
  }

  public render(): JSX.Element {
    const { observable, disabled, rating, value, filledIcon, emptyIcon, className, onChange, editable, ...other } = this.props;
    const stars = [];
    const activeClass = Class(styles['rating-full'], 'rating-full');
    const inactiveClass = Class(styles['rating-empty'], 'rating-empty');
    const classNameMod = Class(styles.rating, styles.form, 'rating', 'form', className);
    for (let i = 0; i < 5; i++) {
      const active = i < this.state.rating;
      stars.push(
        <li key={i} className={active ? activeClass : inactiveClass}>
          <FontAwesomeIcon icon={active ? filledIcon || defaultProps.filledIcon : emptyIcon || defaultProps.emptyIcon} />
        </li>,
      );
    }
    return (
      <ul
        className={classNameMod}
        onMouseDown={editable || !disabled ? this.onMouseDown : undefined}
        onMouseMove={editable || !disabled ? this.onMouseMove : undefined}
        onMouseLeave={editable || !disabled ? this.onMouseLeave : undefined}
        {...other}
      >
        {stars}
      </ul>
    );
  }
}
