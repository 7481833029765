// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3iuZjqn18rhTrgGcSI5Uj5{padding-bottom:64px;z-index:0}._3iuZjqn18rhTrgGcSI5Uj5>.N-HefOX1b7mh3CmL0h1dt{width:1024px;margin:auto auto;margin-top:220px;box-shadow:2px 4px 4px rgba(0,0,0,0.2),-2px 2px 2px rgba(0,0,0,0.1),0px 0px 4px rgba(0,0,0,0.2);border-top:8px #444444 solid;background:white;position:relative;z-index:0}._3iuZjqn18rhTrgGcSI5Uj5>._1Gw9MaFd3wniEy6YwYyZfl{position:fixed;height:300px;top:0px;left:0px;right:0px;box-shadow:0px 2px 2px rgba(0,0,0,0.2);background:#AAAAAA;z-index:0}@media print{._3iuZjqn18rhTrgGcSI5Uj5{padding:0px}._3iuZjqn18rhTrgGcSI5Uj5>.N-HefOX1b7mh3CmL0h1dt{margin:0px;border:none}}@media screen and (max-width: 600px){._3iuZjqn18rhTrgGcSI5Uj5{padding:0px}._3iuZjqn18rhTrgGcSI5Uj5>._1Gw9MaFd3wniEy6YwYyZfl{display:none}._3iuZjqn18rhTrgGcSI5Uj5>.N-HefOX1b7mh3CmL0h1dt{width:100%;margin-top:0px;border:none}}\n", ""]);
// Exports
exports.locals = {
	"webform": "_3iuZjqn18rhTrgGcSI5Uj5",
	"content": "N-HefOX1b7mh3CmL0h1dt",
	"backdrop": "_1Gw9MaFd3wniEy6YwYyZfl"
};
module.exports = exports;
