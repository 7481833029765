import classNameType from 'classnames';

const dedupeClassNames: typeof classNameType = require('cndedupe');
import { find } from 'lodash';
import { IComputedValue, reaction } from 'mobx';
import { useDisposable, useObserver } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useContext } from 'react';
import { Registry } from 'react-registry';
import Select from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/lib/Select';
import { ConfigurationError } from '../../Errors';
import gearsState from '../../GearsState';
import { ILookup } from '../../GearsState/Lookups';
import { formatLookup } from '../../helpers';
import { ILookupSpec, LookupConfig } from '../../LookupConfig';
import { FormContext } from '../FormState';
import { IValueChangeHandlerProps, useFocusWithProps, useLookup, useValueChangeHandlerWithProps } from '../hooks';
import { IEditorProps } from './Editor';

export interface ILookupProps<T extends string | number = string> extends IEditorProps<T>, IValueChangeHandlerProps<T> {
    options: any[];
    idField?: 'id' | 'identifier';
    data?: IComputedValue<ILookup>;
}

function markMatchExceptHtml(raw: string, term: string): string {
    if (!term) {
        return raw;
    }
    const matcher = new RegExp(`((?:^|[>])[^<]*)(${term})([^<]*)`, 'ig');

    return raw.replace(matcher, (all: any, before: string, match: string, after: string) => `${before}<b class='select-match'>${match}</b>${after}`);
}

function formatOptionLabel(option: ILookup, labelMeta: FormatOptionLabelMeta<ILookup>): ReactNode {
    // console.log("Formatting option", option, labelMeta);
    return <span dangerouslySetInnerHTML={{ __html: markMatchExceptHtml(formatLookup(option), labelMeta.inputValue) }} />;
}

function getOptionLabel(option: ILookup): string {
    const { text, search, initials } = option;
    return search ? search : initials ? `${initials} ${text}` : text;
}

const styles = {
    menu: (provided: any, state: any) => ({ ...provided, zIndex: 3 }),
};

function Lookup<T extends string | number>(props: ILookupProps<T>): ReactNode {
    const { onValueChange, enabled = true, form, className, idField = 'id', value, lookup, data, options, ...extra } = props;
    const idParam: 'id' | 'identifier' = idField || (lookup && lookup.id) || 'id';

    // const [focus, focusHandlers] = useFocusWithProps(props, false);

    function itemForId(id: string | number | null): ILookup {
        /* tslint:disable-next-line:triple-equals */
        return find(options, it => id == it[idParam]) || { id: null, text: ' - ' };
    }

    function getOptionValue(option: ILookup): string {
        return (option[idParam] as any) as string;
    }

    const onChange = useCallback(
        (selected: any) => {
            const v = selected[idParam];
            // console.log("Lookup onChange", selected);
            onValueChange && onValueChange(v);
            value.set(v);
            data && data.set(selected);
        },
        [onValueChange, value, data],
    );

    const safeClassName = dedupeClassNames(className, { 'form-control': false, lookup: true });

    return useObserver(() => {
        const current = data ? data.get() : itemForId(value.get());
        // console.log("Rendering lookup", props, options, options.map, current);
        return (
            <Select
                {...extra}
                options={options}
                className={safeClassName}
                isDisabled={!enabled}
                styles={styles}
                onChange={onChange}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                formatOptionLabel={formatOptionLabel}
                value={current}
            />
        );
    });
}

Lookup.defaultProps = {
    enabled: true,
};

Lookup.displayName = 'Lookup';
Registry.register(Lookup, { id: 'Lookup' });

export interface ILookupEditorProps<T extends string | number = string> extends IEditorProps<T>, IValueChangeHandlerProps<T> {
    options?: any[];
    field: string;
    lookup?: LookupConfig;
    data?: IComputedValue<ILookup>;
}

function LookupEditor<T extends string | number>(props: ILookupEditorProps<T>): ReactNode {
    const form = props.form || useContext(FormContext);
    const fieldConfig = form.getFieldConfig(props.field);
    const lookup = props.lookup || fieldConfig.lookup;

    if (!lookup) {
        throw new ConfigurationError(`Lookup config not defined for field '${props.field}'`);
    }

    switch (lookup && lookup.type) {
        case 'values':
            return ValuesLookup({ ...props, lookup });
        case 'inline':
            return ValuesLookup({ ...props, lookup });
        // return InlineLookup(props);
        default:
            throw new ConfigurationError("Lookup type must be either 'values' or 'inline'");
    }
}

LookupEditor.displayName = 'LookupEditor';
Registry.register(LookupEditor, { id: 'LookupEditor' });

export interface IValuesLookupProps<T extends string | number = string> extends ILookupEditorProps<T> {
    lookup: LookupConfig;
}

export interface IValuesLookupProps extends ILookupEditorProps {
    options?: any[];
    lookup: LookupConfig;
    data: IComputedValue<ILookup>;
}

function ValuesLookup<T extends string | number>(props: IValuesLookupProps<T>): ReactNode {
    const { form, className, value, lookup, data, options, ...extra } = props;

    const source = options || useLookup(lookup.source);

    // console.log("Rendering values lookup", props, source);
    if (!source) {
        throw new ConfigurationError(`Lookup '${lookup.source}' is not found`);
    }

    return Lookup({
        ...props,
        className: dedupeClassNames(className, 'values-lookup'),
        idField: lookup.id,
        options: source,
    });
}

ValuesLookup.displayName = 'ValuesLookup';
Registry.register(ValuesLookup, { id: 'ValuesLookup' });

export default LookupEditor;
