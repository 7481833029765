import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import Portal from '../Portal';
import './Menu.scss';

const defaultProps = {
  mount: 'right-top',
};

type Mount =
  | 'right-top'
  | 'right-bottom'
  | 'right-center'
  | 'left-top'
  | 'left-bottom'
  | 'left-center'
  | 'above-left'
  | 'above-center'
  | 'above-right'
  | 'below-left'
  | 'below-center'
  | 'below-right';

interface IMenuProps extends React.HTMLProps<HTMLElement> {
  target: any;
  mount?: Mount;
  className?: string;
  onOpen?: () => any;
  onClose?: () => any;
  noPadding?: boolean;
  preventClose?: boolean;
}

interface IMenuState {
  visible: boolean;
}

const MenuContext: React.Context<null | Menu> = React.createContext<null | Menu>(null);

export { MenuContext };

/**
 * A very simple React menu that can position itself and supports
 * many sub menu's
 */
export default class Menu extends React.Component<IMenuProps, IMenuState> {
  public static defaultProps = defaultProps;
  private menuRef: HTMLDivElement | null;
  private menuContainerRef: HTMLDivElement | null;
  private ignore = false;
  private currentTarget: any;
  public stayOpen?: boolean;

  constructor(props: IMenuProps) {
    super(props);

    this.state = { visible: false };
  }

  private computePosition(override?: string, target?: any): void {
    if (target && target.getBoundingClientRect && this.menuContainerRef && this.menuRef) {
      let mount = override || this.props.mount || defaultProps.mount;
      const targetBounds = target.getBoundingClientRect();
      const targetWidth = targetBounds.width;
      const targetHeight = targetBounds.height;
      const menuWidth = Math.max(180, this.menuRef.clientWidth);
      const menuHeight = this.menuContainerRef.clientHeight;
      const currentTarget = typeof target === 'string' ? (document.querySelector(target) as HTMLDivElement) : (target as HTMLDivElement);
      const position = currentTarget.getBoundingClientRect();
      // Fix for IE11/Edge not having x/y properties
      if (position.x === undefined) {
        position.x = position.left;
        position.y = position.top;
      }
      let requiresOverride = false;

      let left = 0;
      let top = 0;
      if (mount.indexOf('right-') >= 0) {
        left = position.x + targetWidth - 8;
      }
      if (mount.indexOf('left-') >= 0) {
        left = position.x - menuWidth - 8;
      }
      if (mount.indexOf('above-') >= 0) {
        top = position.y - menuHeight - 8;
      }
      if (mount.indexOf('below-') >= 0) {
        top = position.y + targetHeight - 8;
      }

      if (mount.indexOf('-top') >= 0) {
        top = position.y - 8;
      }
      if (mount.indexOf('-bottom') >= 0) {
        top = position.y + targetHeight - menuHeight;
      }
      if (mount.indexOf('left-') >= 0 || mount.indexOf('right-') >= 0) {
        if (mount.indexOf('-center') >= 0) {
          top = position.y + targetHeight / 2 - (menuHeight + 16) / 2;
        }
      }
      if (mount.indexOf('-right') >= 0) {
        left = position.x - 8;
      }
      if (mount.indexOf('-left') >= 0) {
        left = position.x - menuWidth - 8 + targetWidth;
      }
      if (mount.indexOf('above-') >= 0 || mount.indexOf('below-') >= 0) {
        if (mount.indexOf('-center') >= 0) {
          left = position.x + targetWidth / 2 - (menuWidth + 16) / 2;
        }
      }
      // Override and reverse mounting if we hit edges of screen
      if (left < 0) {
        mount = mount.replace('left-', 'right-');
        requiresOverride = true;
      }
      if (top < 0) {
        mount = mount.replace('above-', 'below-');
        requiresOverride = true;
      }
      if (left + menuWidth > window.innerWidth) {
        mount = mount.replace('right-', 'left-');
        requiresOverride = true;
      }
      if (top + menuHeight > window.innerHeight) {
        mount = mount.replace('below-', 'above-');
        mount = mount.replace('-top', '-bottom');
        requiresOverride = true;
      }

      if (!requiresOverride || override) {
        if (left + menuWidth > window.innerWidth) {
          left = window.innerWidth - menuWidth - 16;
        }
        if (mount.indexOf('left') >= 0) {
          this.menuContainerRef.style.left = -menuWidth + 'px';
          this.menuRef.style.left = left + menuWidth + 'px';
          this.menuRef.style.top = top + 'px';
        } else {
          this.menuRef.style.left = left + 'px';
          this.menuRef.style.top = top + 'px';
        }
      } else {
        this.computePosition(mount, target);
      }
    }
  }

  private attach(props: IMenuProps): void {
    if (props.target) {
      const target = typeof props.target === 'string' ? document.querySelector(props.target) : props.target;
      if (target) {
        target.addEventListener('click', this.toggle);
      }
    }
  }

  @bind
  private onWindowResize(): void {
    if (!this.menuRef || !this.menuContainerRef) {
      return;
    }
    this.computePosition(undefined, this.currentTarget);

    const mount = this.props.mount || defaultProps.mount;
    const width = Math.max(this.menuRef.clientWidth || 0, 180);

    this.menuRef.style.setProperty('transition', 'none');
    if (mount.indexOf('left') >= 0) {
      this.menuContainerRef.style.left = '0';
      this.menuRef.style.left = parseInt(this.menuRef.style.left || '0', 10) - width + 'px';
    }
  }

  public componentDidMount(): void {
    this.attach(this.props);
  }

  public componentWillUnmount(): void {
    if (this.props.target) {
      if (this.props.target.removeEventListener) {
        this.props.target.removeEventListener('click', this.toggle);
      }
      if (this.menuRef) {
        this.menuRef.removeEventListener('click', this.onClick);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: IMenuProps): void {
    if (this.props.target && this.props.target.removeEventListener) {
      this.props.target.removeEventListener('click', this.toggle);
    }
    this.attach(newProps);
  }

  @bind
  public show(e: MouseEvent): void {
    this.computePosition(undefined, e.currentTarget);
    this.currentTarget = e.currentTarget;
    this.setState({ visible: true });
    setTimeout(() => {
      document.addEventListener('touchend', this.hide);
      document.addEventListener('click', this.hide);
    }, 20);
    window.addEventListener('resize', this.onWindowResize);
    this.menuContainerRef &&
      new ResizeObserver(() => {
        console.log('Menu contents resized', this.menuContainerRef);
        this.onWindowResize();
      }).observe(this.menuContainerRef);
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  @bind
  public hide(): void {
    this.setState({ visible: false });
    document.removeEventListener('touchend', this.hide);
    document.removeEventListener('click', this.hide);
    window.removeEventListener('resize', this.onWindowResize);
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  @bind
  public toggle(e?: MouseEvent | TouchEvent): void {
    if (!this.ignore && this.menuRef) {
      this.ignore = true;
      setTimeout(() => (this.ignore = false), 20);
      if (this.state.visible) {
        window.removeEventListener('resize', this.onWindowResize);
        if (this.props.onClose) {
          this.props.onClose();
        }
      } else {
        window.addEventListener('resize', this.onWindowResize);
        if (this.props.onOpen) {
          this.props.onOpen();
        }
      }
      this.setState({ visible: !this.state.visible });
      if (this.state.visible) {
        document.addEventListener('touchend', this.toggle);
        document.addEventListener('click', this.toggle);
        this.menuRef.addEventListener('click', this.onClick);
      } else {
        document.removeEventListener('touchend', this.toggle);
        document.removeEventListener('click', this.toggle);
        this.menuRef.removeEventListener('click', this.onClick);
      }
      if (e && this.state.visible) {
        this.computePosition(undefined, e.currentTarget);
        this.currentTarget = e.currentTarget;
      }
    }
  }

  @bind
  private onClick(e: MouseEvent | React.MouseEvent<HTMLElement>): void {
    if (!this.props.preventClose) {
      e.stopPropagation();
      if (!this.stayOpen) {
        this.toggle();
        this.stayOpen = false;
      }
    }
  }

  public componentDidUpdate(prevProps: IMenuProps, prevState: IMenuState): void {
    if (!this.menuContainerRef || !this.menuRef) {
      return;
    }
    const mount = this.props.mount || defaultProps.mount;
    this.menuRef.style.display = 'block';
    const width = Math.max(this.menuRef.clientWidth || 0, 180);
    this.menuRef.style.display = '';

    if (!prevState.visible && this.state.visible) {
      this.menuRef.style.setProperty('transition', 'height 500ms, left 300ms');
      this.menuRef.style.setProperty('width', `0px`, 'important');
      setTimeout(() => {
        if (!this.menuContainerRef || !this.menuRef) {
          return;
        }
        if (mount.indexOf('left') >= 0) {
          this.menuContainerRef.style.left = '0';
          this.menuRef.style.left = parseInt(this.menuRef.style.left || '0', 10) - width + 'px';
        }
        this.menuRef.style.height = this.menuContainerRef.clientHeight + (this.props.noPadding ? 0 : 16) + 'px';
        this.menuRef.style.setProperty('transition', '');
        this.menuRef.style.setProperty('width', `${width}px`, 'important');
      }, 1);
    } else if (!this.state.visible && prevState.visible) {
      const containerWidth = this.menuContainerRef.clientWidth;
      this.menuRef.style.height = '';
      this.menuRef.style.width = '';
      this.menuContainerRef.style.left = '';
      if (mount.indexOf('left') >= 0) {
        this.menuRef.style.left = parseInt(this.menuRef.style.left || '0', 10) + containerWidth + 'px';
      }
    }
  }

  public render(): JSX.Element {
    let display = true;
    if (this.props.target && !this.state.visible) {
      display = false;
    }
    const mount = this.props.mount || '';
    const mounts = mount.split('-');
    mounts[1] = '-' + mounts[1];
    const renderedMenu = (
      <div onClick={this.onClick} className={Class('react-menu', mount, mounts, this.props.className, { showMenu: display })} ref={ref => (this.menuRef = ref)}>
        <div ref={ref => (this.menuContainerRef = ref)}>
          <MenuContext.Provider value={this}>{this.props.children}</MenuContext.Provider>
        </div>
      </div>
    );
    if (this.props.target) {
      return (
        <Portal>
          <div style={{ bottom: 0, display: display ? 'block' : 'none', left: 0, position: 'fixed', right: 0, top: 0, zIndex: 9001 }} />
          {renderedMenu}
        </Portal>
      );
    }
    return renderedMenu;
  }
}
