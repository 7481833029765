import * as _ from 'lodash';
import * as numeral from 'numeral';
import { ICalculationInputs, NUMBER_FORMATS, registerCalculationType } from './Calculator';
import { ITier, ITiersOptions, ITiersResult, Tiers } from './Tiers';

interface ITotals {
    total?: number;
    totalCalculation?: string;
}

interface ICompoundPercentageTiersOptions extends ITiersOptions {
    computation_type: 'compound_percentage_tiers';
}

export default class CompoundPercentageTiers extends Tiers {
    readonly tiers: Array<ITier & ITotals>;
    constructor(options: ICompoundPercentageTiersOptions) {
        super(options);
        this.calculateTierTotals();
    }

    calculationFunction(inputs: ICalculationInputs): ITiersResult {
        const value = inputs.baseValue;
        const tierIndex = this.findTierIndex(value);

        const lowerTiers = _.take(this.tiers, tierIndex);
        const tierTotals: number[] = _.map(lowerTiers, 'total') as number[];
        const tierCalculations: string[] = _.map(lowerTiers, 'totalCalculation') as string[];

        const currentTier = this.tiers[tierIndex];
        const percentage = numeral(currentTier.percentage + '%');
        const currentTierCount = value - (currentTier.lower_value || 0);

        const currentTierResult = percentage.value() * currentTierCount;
        const currentTierCalculation = `Tier ${currentTier.tier}: ${numeral(currentTierCount).format(NUMBER_FORMATS.numberFormat)} ${
            NUMBER_FORMATS.multiplication
        } ${percentage.format(NUMBER_FORMATS.percentFormat)} = ${numeral(currentTierResult).format(NUMBER_FORMATS.currencyFormat)}`;

        tierTotals.push(currentTierResult);
        tierCalculations.push(currentTierCalculation);

        const rawResult = _.sum(tierTotals);
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const resultUnit = '$';
        const rounding = this.roundFormula('' + rawResult);

        const finalCalculation = `Total: ${_.join(
            _.map(tierTotals, t => numeral(t).format(NUMBER_FORMATS.currencyFormat)),
            ' + ',
        )} = ${formattedResult}`;
        const calculation = _.join(tierCalculations.concat([finalCalculation]), '\n');
        return {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            inputs,
            percentage: percentage.format('%'),
            rawResult,
            result: result.value(),
            resultUnit,
            rounding,
            tier: currentTier,
        };
    }

    private calculateTierTotals() {
        this.tiers.forEach(this.calculateTierTotal);
    }

    private calculateTierTotal(tier: ITier & ITotals) {
        if (tier.upper_value == null || tier.lower_value == null) {
            return;
        }
        const count = tier.upper_value - tier.lower_value;
        const percentage = numeral(tier.percentage + '%');
        const total = numeral(count * percentage.value());
        tier.totalCalculation = `Tier ${tier.tier}: ${numeral(count).format(NUMBER_FORMATS.numberFormat)} ${NUMBER_FORMATS.multiplication} ${percentage.format(
            NUMBER_FORMATS.percentFormat,
        )} = ${total.format(NUMBER_FORMATS.currencyFormat)}`;
        tier.total = total.value();
    }
}

registerCalculationType('compound_percentage_tiers', CompoundPercentageTiers);
