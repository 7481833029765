import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Class from 'classnames';
import { IWithFlexibleEnabled, withFlexibleEnabled } from 'commonui/util/withFlexibleEnabled';
import React, { useContext, useMemo } from 'react';
import Icon from '../Icon';

import Menu, { MenuContext } from './Menu';

export interface IMenuItemPropsBase extends React.HTMLProps<HTMLDivElement> {
  icon?: IconProp | string;
  iconColour?: string;
  iconClassName?: string;
  iconSize?: string;
  title: string;
  shortcut?: any;
  enabled?: boolean;
  className?: string;
  danger?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => boolean | unknown;
  menu?: Menu;
}

export type IMenuItemProps = IWithFlexibleEnabled<IMenuItemPropsBase>;

/**
 * A single item in a Menu
 */
const MenuItem: React.FunctionComponent<IMenuItemPropsBase> = ({
  icon,
  title,
  menu,
  shortcut,
  enabled,
  disabled,
  children,
  className,
  iconClassName,
  iconSize,
  iconColour,
  danger,
  onClick,
  ...other
}: IMenuItemPropsBase): React.ReactElement => {
  const menuContext = menu || useContext(MenuContext);
  const realDisabled = disabled || !enabled;

  const clickCallback = useMemo(
    () =>
      realDisabled
        ? undefined
        : (e: React.MouseEvent<HTMLDivElement>): void => {
            if (e.button === 0) {
              const res = onClick ? onClick(e) : true;
              if (menuContext) {
                menuContext.stayOpen = res === true;
              }
            }
          },
    [menuContext, onClick, realDisabled],
  );

  return (
    <div className={Class({ danger, disabled: realDisabled }, className)} onMouseDown={clickCallback} {...other}>
      <span>
        <span>
          {icon ? <Icon icon={icon} fixedWidth iconSize={iconSize} className={iconClassName} colour={iconColour} /> : undefined}
          {title}
        </span>
        <span className="shortcut">{children ? <Icon icon="fa-chevron-right" /> : shortcut}</span>
      </span>
      {children}
    </div>
  );
};

MenuItem.defaultProps = { enabled: true };

export default withFlexibleEnabled(MenuItem) as React.FunctionComponent<IMenuItemProps>;
