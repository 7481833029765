import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import Icon from './Icon';
import './SubmitLoader.scss';

const defaultProps = {
  enabled: true,
  title: 'Submit',
};

interface ISubmitLoaderProps {
  className?: string;
  disabled?: boolean;
  enabled?: boolean;
  onSubmit: (loader: SubmitLoader) => void;
  title?: string;
}

interface ISubmitLoaderState {
  complete?: boolean;
  loading?: boolean;
}

/**
 * A button that morphs into a loading spinner
 */
export default class SubmitLoader extends React.Component<ISubmitLoaderProps, ISubmitLoaderState> {
  public static defaultProps = defaultProps;

  constructor(props: ISubmitLoaderProps) {
    super(props);

    this.state = { complete: false, loading: false };
  }

  @bind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    this.setState({ loading: true });
    if (this.props.onSubmit) {
      this.props.onSubmit(this);
    }
  }

  /** Call this when submit loader should finish animating */
  @bind
  public finish(): void {
    this.setState({ complete: true });
  }

  /** Cancel the loading animation */
  @bind
  public cancel(): void {
    this.setState({ loading: false });
  }

  @bind
  public reset(): void {
    this.setState({ complete: false, loading: false });
  }

  public render(): JSX.Element {
    const { enabled, disabled, title, className, onSubmit, ...other } = this.props;
    const realDisabled = disabled || !enabled;
    return (
      <div
        className={Class('submit-loader-container', className, { complete: this.state.complete, disabled: realDisabled, loading: this.state.loading })}
        onClick={realDisabled ? undefined : this.onClick}
        tabIndex={0}
        role="button"
        {...other}
      >
        <div className="submit-loader">
          <span className="submit-title">{title}</span>
          <Icon icon="fa-check" />
        </div>
        <div className="submit-overlay" />
      </div>
    );
  }
}
