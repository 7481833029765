import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Class from 'classnames';
import { toJS } from 'mobx';
import * as React from 'react';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { IColumn, IColumnTooltip } from './SmartGrid';

export interface IHeaderProps {
  columns: IColumn[];
  sort?: (e: React.MouseEvent<HTMLElement>) => void;
  group?: (e: React.MouseEvent<HTMLElement>) => void;
  sortedField?: string;
  sortOrder?: 'asc' | 'desc';
  groupBy?: string;
  inlineGroups?: boolean;
  lockGrouping?: boolean;
  lockSorting?: boolean;
}

export interface IHeaderColumnProps extends IHeaderProps {
  column: IColumn;
  index: number;
}

function idString(str: string): string {
  return (str || '')
    .toString()
    .replace(new RegExp(/[ .+-/!/@/#/$/%/^/&/*/;/:/?/|<>/\\(\)\'\"]/, 'g'), '_')
    .toLowerCase();
}

function isTooltipArray(arg: any): arg is IColumnTooltip[] {
  return Array.isArray(toJS(arg));
}

export default React.memo(function Header(props: IHeaderProps): JSX.Element {
  const { columns, groupBy } = props;
  return (
    <tr>
      {columns.map((column, index) => {
        if (groupBy && column.field === groupBy) {
          return;
        }
        if (column.spacer) {
          return <th key={index} className="spacer" />;
        } else {
          return <HeaderColumn key={index} {...props} column={column} index={index} />;
        }
      })}
    </tr>
  );
});

const HeaderColumn = React.memo(function HeaderColumn(props: IHeaderColumnProps): JSX.Element {
  const { column, index, sort, group, sortedField, sortOrder, lockGrouping, lockSorting } = props;
  const tooltipArray = column.tooltip ? (isTooltipArray(column.tooltip) ? column.tooltip : [column.tooltip]) : undefined;
  const groupClass = column.groupable && !lockGrouping ? 'groupable' : undefined;
  const sortClass =
    column.sortable && !lockSorting ? (column.field === sortedField ? (sortOrder === 'asc' || sortOrder === undefined ? 'sort-up' : 'sort-down') : 'sort') : undefined;
  return (
    <th
      className={Class('column-title', column.className, {
        groupable: groupClass !== undefined,
        hasTooltips: tooltipArray !== undefined,
        ['hidden-cell']: column.name === '' || column.name === undefined,
        sortable: sortClass !== undefined,
      })}
      style={{ textAlign: column.alignment }}
    >
      {column.customHeader
        ? typeof column.customHeader === "function"
          ? React.createElement(column.customHeader, { ...props })
          : typeof column.customHeader === "string"
            ? column.customHeader
            : React.cloneElement(column.customHeader, { ...props })
        : <>
          {column.icon ? <Icon small icon={column.icon} /> : null}
          <span style={{ width: column.width }}>{column.iconOnly ? null : column.name}</span>
          {(groupClass || sortClass) && (
            <span className="control-container">
              {groupClass && [
                <span key={'smartgrid_g_' + idString(column.field)} id={'smartgrid_g_' + idString(column.field)} onClick={group} data-group={column.field}>
                  <FontAwesomeIcon icon="object-group" />
                </span>,
                <Tooltip key={'smartgrid_gt_' + idString(column.field)} event="hover" target={'#smartgrid_g_' + idString(column.field)} mount="below-center">
                  Group by column
            </Tooltip>,
              ]}
              {sortClass && [
                <span key={'smartgrid_s_' + idString(column.field)} id={'smartgrid_s_' + idString(column.field)} onClick={sort} data-column={index}>
                  <FontAwesomeIcon icon={sortClass} />
                </span>,
                <Tooltip key={'smartgrid_st_' + idString(column.field)} event="hover" target={'#smartgrid_s_' + idString(column.field)} mount="below-center">
                  Sort by column
            </Tooltip>,
              ]}
            </span>
          )}
          {tooltipArray && (
            <span className="tooltip-container">
              {tooltipArray.map((tooltip, tooltipIndex: number) => (
                <Icon
                  key={tooltip.icon + '_' + tooltipIndex}
                  icon={tooltip.icon}
                  className={tooltip.className}
                  small
                  fixedWidth
                  data-tooltip={tooltip.value}
                  data-tooltip-mount="below-center"
                />
              ))}
            </span>
          )}
        </>
      }
    </th>
  );
});
