import * as Promise from "bluebird";
import { action, computed, observable, ObservableMap, when } from "mobx";
import * as moment from "moment-timezone";
import { handleError } from "../helpers/HandleError";
import GearsUser from "./GearsUser";
import Lookups, { ILookupsMap } from "./Lookups";
import MenuState from "./MenuState";
import NotificationState from "./NotificationState";

interface IHomeLookups {
    user: string;
}

interface IBuildInfo {
    gears_core_ref: string;
    gears_online_ref: string;
    branch: string;
    build_time: string;
    environment: "development" | "production";
    reports_host: string;
    app_name: string;
    app_id: string;
    database_name: string;

    [key: string]: string;
}

export default class GearsState {
    public readonly user: GearsUser = new GearsUser();
    public readonly lookups: Lookups = new Lookups();
    public readonly notifications: NotificationState = new NotificationState();
    @observable private _timezone: string;
    @observable public utcOffset: number = 0;
    @observable public loaded: boolean = false;
    @observable public protocol: string = "https://";
    @observable public host: string;
    @observable public asset_host: string;
    @observable public auth: ObservableMap<string, boolean> = new ObservableMap<string, boolean>();
    @observable public menuState: MenuState = new MenuState(this, "");
    @observable public buildInfo: IBuildInfo;

    public constructor() {
        when(() => !!this.user.roles.notifications, () => {
            this.notifications.initialize();
        });
    }

    @action
    public loadState(url?: string): Promise<GearsState> {
        return Promise
            .resolve($.get(url || "/homelookups.js"))
            .then((data: any) => {
                if (data.error) {

                }
                this.setState(data);
                return this;
            });
    }

    @action
    public setTimezone(newTimezone: string) {
        this._timezone = newTimezone;
        moment.tz.setDefault(newTimezone);
    }

    @computed
    get timezone(): string {
        return this._timezone;
    }

    @computed
    get origin(): string {
        return `${this.protocol}${this.host}`;
    }

    @computed
    get asset_origin(): string {
        return `${this.protocol}${this.asset_host}`;
    }

    set timezone(newTimezone: string) {
        this.setTimezone(newTimezone);
    }

    @action
    public setState(data: any) {
        if (data.user) { this.user.fromJS(data.user); }
        this.lookups.setLookups(data as ILookupsMap);
        if (data.timezone) { this.setTimezone(data.timezone); }
        if (data.utcOffset) { this.utcOffset = data.utcOffset; }
        if (data.host) { this.host = data.host; }
        if (data.asset_host) { this.asset_host = data.asset_host; }
        if (data.protocol) { this.protocol = data.protocol; }
        if (data.auth) { this.auth = observable.map(data.auth); }
        if (data.nav) { this.menuState.navHtml = data.nav; }
        if (data.build) { this.buildInfo = data.build; }
        this.loaded = true;
    }
}

