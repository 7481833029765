import * as _ from 'lodash';
import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, registerCalculationType } from './Calculator';

export interface IEvalWithOptions extends ICalculationOptions {
    calculation_type: 'eval_with';
    code: string;
}

export default class EvalWith extends Calculator {
    private code: string;
    constructor(options: IEvalWithOptions) {
        super(options);
        this.code = options.code;
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> {
        // const rawResult = eval(`with(inputs) {return ${this.code};}`);
        const evalCode = _.reduce(
            inputs,
            (result: string, value: any, key: string) => {
                const formatted = typeof value === 'number' ? numeral(value).format('0,0[.]00') : value;
                return result.replace(new RegExp(`\\b${key}\\b`, 'g'), formatted);
            },
            this.code,
        );
        const argNames: any[] = _.keys(inputs);
        const argValues: any[] = _.map(_.values(inputs), i => (i == null ? 0 : i));
        argNames.unshift(null);
        // argValues.unshift(null);
        argNames.push(`return ${this.code}`);

        const func = new (Function.prototype.bind.apply(Function, argNames))();
        const rawResult = func.apply(null, argValues);
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = `${evalCode} = ${formattedResult}`;

        return {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            rawResult,
            result: result.value(),
            rounding: this.roundFormula('' + rawResult),
        };
    }
}

registerCalculationType('evalWith', EvalWith);
