import './Icon.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {cnb} from 'cnbuilder';
import * as React from 'react';
import { getFaIcon } from '../../gears/helpers/fa';

type Flips = 'horizontal' | 'vertical' | 'both';
type Rotation = 0 | 90 | 180 | 270;

enum IconTypes {
  Unknown = 0,
  FontAwesome = 1,
  MaterialDesign = 2,
}

interface IIconProps extends React.HTMLProps<HTMLElement> {
  background?: string;
  className?: string;
  colour?: string;
  fixedWidth?: boolean;
  flip?: Flips;
  icon: string | object;
  large?: boolean;
  rotate?: Rotation;
  small?: boolean;
  style?: any;
  x2?: boolean;
  x3?: boolean;
  x4?: boolean;
  x5?: boolean;
  iconSize?: string;
}

/**
 * A react based icon loader that supports dynamically switching between multiple icon sources
 */
export default React.memo(function Icon(props: IIconProps): JSX.Element | null {
  const { small, large, x2, x3, x4, x5, fixedWidth, icon, className, rotate, flip, colour, background, style, children, iconSize, ...other } = props;
  if (!icon) {
    return null;
  }
  let newStyle = style;
  let size = iconSize;
  if (small) {
    size = 'xs';
  }
  if (large) {
    size = 'lg';
  }
  if (x2) {
    size = '2x';
  }
  if (x3) {
    size = '3x';
  }
  if (x4) {
    size = '4x';
  }
  if (x5) {
    size = '5x';
  }
  if (!newStyle && (colour || background)) {
    newStyle = {};
  }
  if (colour) {
    newStyle.color = colour;
  }
  if (background) {
    newStyle.backgroundColor = background;
  }
  if (typeof icon === 'object') {
    return <FontAwesomeIcon className={cnb('gear-icon', className)} icon={icon} size={size} flip={flip} rotation={rotate} style={newStyle} {...other} />;
  }
  const type = icon.substring(0, 2);
  let iconType: IconTypes = IconTypes.Unknown;
  if (type === 'fa') {
    iconType = IconTypes.FontAwesome;
  }
  if (type === 'md') {
    iconType = IconTypes.MaterialDesign;
  }
  if (iconType === IconTypes.FontAwesome) {
    return <FontAwesomeIcon className={cnb('gear-icon', className)} icon={getFaIcon(icon)} style={newStyle} size={size} flip={flip} rotation={rotate} {...other} />;
  }
  // For legacy this is kept here, to show pages that haven't been updated
  if (iconType === IconTypes.MaterialDesign) {
    console.warn('MaterialDesign icons is deprecated');
    return (
      <i className={cnb('gear-icon', 'material-icons', className)} style={newStyle} {...other}>
        {icon.substring(3, icon.length)}
      </i>
    );
  }
  return null;
});
