export function roundTo(fn: 'round' | 'ceil' | 'floor' | 'up' | 'down', val: number, precision: number) {
    if (typeof val !== 'number') {
        throw new TypeError('Expected value to be a number');
    }

    if (!Number.isInteger(precision)) {
        throw new TypeError('Expected precision to be an integer');
    }

    const exponent = precision > 0 ? 'e' : 'e-';
    const exponentNeg = precision > 0 ? 'e-' : 'e';
    precision = Math.abs(precision);

    if (fn === 'round') {
        return Number(Math.sign(val) * (Math.round(Math.abs(val) + exponent + precision) + exponentNeg + precision));
    }
    if (fn === 'up') {
        fn = 'ceil';
    }
    if (fn === 'down') {
        fn = 'floor';
    }

    return Number(Math[fn](val + exponent + precision) + exponentNeg + precision);
}

export const round = roundTo.bind(null, 'round');
export const up = roundTo.bind(null, 'ceil');
export const down = roundTo.bind(null, 'floor');
