import ExtendableError from 'es6-error';
import _ from 'lodash';

declare function require(path: string): any;

// const ExtendableError: typeof Error = require("es6-error");

interface ErrorOptions {
    message?: string;
    title?: string;
    error?: Error | null;
}

export class WebfrontError extends ExtendableError implements ErrorOptions {
    error: Error | null;
    readonly name: string;
    message: string;
    title: string;
    // constructor is optional; you should omit it if you just want a custom error
    // type for inheritance and type checking
    constructor(message: string | ErrorOptions, extra?: any) {
        let options: ErrorOptions;
        if (typeof message === 'string') {
            options = extra || { message };
            options.message = message;
        } else {
            options = message || extra || {};
        }
        console.log('Initialise error', options, message, extra);
        // super(options.message);
        super();
        _.merge(this, options);
        this.title || (this.title = this.defaultTitle);
        this.message || (this.message = this.defaultMessage);
        // this.message || (this.message = constructor.defaultMessage);
    }

    get defaultMessage(): string {
        return (this.error && this.error.message) || 'Unknown Webfront Error';
    }

    get defaultTitle(): string {
        return _(this.name)
            .words()
            .join(' ');
    }
}

export class ServerError extends WebfrontError {}
export class DeleteRecordError extends ServerError {
    get defaultTitle(): string {
        return 'Error Deleting Record';
    }
}

export class DataFetchError extends ServerError {
    get defaultTitle(): string {
        return 'Error Loading Records';
    }
}

export class ConnectionError extends ServerError {
    get defaultMessage(): string {
        return 'Could not reach server, test network connection.';
    }
}

export class AuthenticationError extends ServerError {
    get defaultMessage(): string {
        return 'Problem authenticating user.';
    }
}

export class AccessDenied extends ServerError {
    get defaultMessage(): string {
        return 'Access to this object not permitted for this user.';
    }
}

/**
 * Not necessarily an error, just an unusual valid case that requires aborting.
 */
export class WebfrontException extends WebfrontError {}

export class UserCancelled extends WebfrontException {}
export class ValidationError extends WebfrontException {}
export class ParseError extends WebfrontException {}

/**
 * Need to throw an error to work around a kendo issue. Not a real error.
 */
export class SubvertKendo extends WebfrontException {}

export class WebClientError extends WebfrontError {}
export class ConfigurationError extends WebClientError {}
export class InvalidDataError extends WebClientError {}

export class InvalidActionEventError extends WebClientError {}
