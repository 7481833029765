import * as styles from './DatePicker.scss';

import Class from 'classnames';
import * as $ from 'jquery';
import * as Moment from 'moment-timezone';
import * as React from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { bindAll } from 'lodash-decorators';
import { isMoment } from 'moment';
import Button from '../Button';
import Icon from '../Icon';
import BlankView from './BlankView';
import { monthColoursDark } from './Constants';
import MonthView from './MonthView';
import SideBar from './SideBar';
import TimeView from './TimeView';

const timeFormatReg = /[HhAamSsZ]/;
const datFormatReg = /[Dd]/;

function formatHasTime(format: string): boolean {
  return timeFormatReg.test(format);
}

function formatHasDay(format: string): boolean {
  return datFormatReg.test(format);
}

const defaultProps = {
  canClear: true,
  displayFormat: 'Do MMMM YYYY h:mmA',
  editFormat: 'DD/MM/YYYY h:mma',
  enabled: true,
  showInput: true,
};

export interface IDatePickerProps {
  /** Date format when datepicker is unfocused */
  displayFormat: string;
  /** Date format when datepicker is focused */
  editFormat: string;
  /** Amount minutes change by per click, defaults to 5 */
  minuteStep?: number;
  value?: number | string | Moment.Moment | null;
  /** Callback when user changes date */
  onChange?: (date?: Moment.Moment | null) => void;
  /** Callback when user cancels changing date */
  onCancel?: () => void;
  inputClassName?: string;
  enabled?: boolean;
  disabled?: boolean;
  showInput?: boolean;
  open?: boolean;
  fixed?: boolean;
  canClear?: boolean;
  className?: any;
}

interface IDatePickerState {
  stringValue?: string;
  showDatePicker?: boolean;
  inputting?: boolean;
}

/**
 * A react based datepicker with date and datetime options
 */
@bindAll()
export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  public static defaultProps = defaultProps;
  private containerRef: HTMLElement;
  private currentDate: Moment.Moment;
  private value: Moment.Moment | string;
  private mode: 'date' | 'datetime' | 'month';
  private ignoreEvent: boolean;

  constructor(props: IDatePickerProps) {
    super(props);

    const isClear = !props.value;
    this.currentDate = isClear ? Moment() : Moment(props.value);
    this.value = isClear ? '' : this.currentDate.clone();
    const stringValue = isClear ? '' : this.currentDate.format(props.displayFormat);

    this.state = {
      inputting: false,
      showDatePicker: props.open,
      stringValue,
    };
    // Checks if editFormat contains any time
    this.mode = formatHasTime(props.editFormat) ? 'datetime' : 'date';
    this.mode = formatHasDay(props.editFormat) ? this.mode : 'month';

    this.ignoreEvent = props.open ? true : false;
  }

  public UNSAFE_componentWillReceiveProps(newProps: IDatePickerProps): void {
    // tslint:disable-next-line:triple-equals
    if (newProps.value != this.props.value) {
      const isClear = !newProps.value || (isMoment(newProps.value) && !newProps.value.isValid());
      this.currentDate = isClear ? Moment() : Moment(newProps.value);
      this.value = isClear ? '' : this.currentDate.clone();
      const stringValue = isClear ? '' : this.currentDate.format(newProps.displayFormat);

      this.setState({
        inputting: false,
        showDatePicker: newProps.open,
        stringValue,
      });
    }
    this.mode = formatHasTime(newProps.editFormat) ? 'datetime' : 'date';
    this.mode = formatHasDay(newProps.editFormat) ? this.mode : 'month';
  }

  private onChange(date: Moment.Moment): void {
    this.currentDate = date;
    this.setState({ stringValue: date.format(this.props.displayFormat) });
  }

  private onSetNow(): void {
    this.currentDate = Moment();
    this.setState({ stringValue: this.currentDate.format(this.props.displayFormat) });
  }

  private onInputFocus(e: React.FocusEvent<HTMLInputElement>): void {
    if (e.target.value !== '' && e.target.value !== undefined) {
      this.setState({ inputting: true, stringValue: this.currentDate.format(this.props.editFormat) });
    } else {
      this.setState({ inputting: true, stringValue: '' });
    }
  }

  private onInputBlur(e: React.FocusEvent<HTMLInputElement>): void {
    if (e.target.value !== '') {
      this.setState({ inputting: false, stringValue: this.currentDate ? this.currentDate.format(this.props.displayFormat) : '' });
    } else {
      this.setState({ inputting: false, stringValue: '' });
    }
    if (this.props.onChange && !this.state.showDatePicker) {
      if (e.target.value) {
        const newdate = Moment(e.target.value, this.props.editFormat, false);
        if (newdate.isValid()) {
          this.props.onChange(newdate);
        }
      } else {
        this.props.onChange(null);
      }
    }
  }

  private onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const newdate = Moment(e.target.value, this.props.editFormat, false);
    if (newdate.isValid()) {
      this.currentDate = newdate;
    }
    this.setState({ stringValue: e.target.value });
  }

  private onCancel(): void {
    if (this.value !== '') {
      this.currentDate = Moment(this.value);
      this.setState({ showDatePicker: false, stringValue: typeof this.value === 'string' ? this.value : this.value.format(this.props.displayFormat) });
    } else {
      this.setState({ showDatePicker: false, stringValue: '' });
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  private onOk(): void {
    if (this.mode === 'date') {
      this.currentDate = this.currentDate.startOf('day');
    }
    if (!this.currentDate) {
      this.currentDate = Moment();
    }
    this.value = this.currentDate;
    this.setState({ showDatePicker: false });
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  private onClear(): void {
    this.value = '';
    this.setState({ showDatePicker: false, stringValue: '' });
    if (this.props.onChange) {
      this.props.onChange();
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  private toggleDatePicker(): void {
    this.setState({ showDatePicker: !this.state.showDatePicker });
    this.ignoreEvent = true;
  }

  private closeDatePicker(): void {
    if (!this.ignoreEvent) {
      if (this.state.showDatePicker) {
        this.setState({ showDatePicker: false, stringValue: this.value ? (this.value as Moment).format(this.props.displayFormat) : '' });
        if (this.props.onCancel) {
          this.props.onCancel();
        }
      }
    }
    this.ignoreEvent = false;
  }

  /**
   * _dirtyHax stops event propogation outside the datepicker
   */
  private _dirtyHax(event: React.MouseEvent<HTMLDivElement>): void {
    if (this.state.showDatePicker) {
      event.stopPropagation();
    }
  }

  public componentDidMount(): void {
    if (this.props.fixed) {
      const offset = $(this.containerRef).offset();
      const width = $(this.refs.datepicker).width();
      const height = $(this.refs.datepicker).height();
      let left = offset.left - width / 2;
      let top = offset.top;
      if ($(this.containerRef).offset().top > $(document).height() / 2) {
        top = top - height + 24;
      }
      left = Math.max(0, left);
      left = Math.min($('body').width() - width, left);
      $(this.refs.datepicker).css('left', left);
      $(this.refs.datepicker).css('top', top);
      /**
       * This was causing problems with double clicking on the grid
       */
      // $(this.refs.datepicker).on("dblclick", (e) => {
      //    e.stopPropagation();
      // });
    }

    $(window).on('click', this.closeDatePicker);
  }

  public componentWillUnmount(): void {
    $(window).off('click', this.closeDatePicker);
  }

  public render(): JSX.Element {
    let renderAbove = false;
    if (this.containerRef) {
      if ($(this.containerRef).offset().top > $(document).height() / 2) {
        renderAbove = true;
      }
    }
    const realDisabled = this.props.disabled || !this.props.enabled;
    return (
      <div className={Class(styles['datepicker-container'], 'datepicker-container', this.props.className)} ref={ref => (this.containerRef = ref)} onClick={this._dirtyHax}>
        {this.props.showInput ? (
          <div className={Class(styles['datepicker-input-container'], 'datepicker-input-container', { [styles.disabled]: realDisabled, disabled: realDisabled })}>
            <input
              className={Class(styles['datepicker-input'], this.props.inputClassName)}
              type="datetime"
              ref={ref => (this.inputRef = ref)}
              onFocus={realDisabled ? undefined : this.onInputFocus}
              onBlur={this.onInputBlur}
              onChange={this.onInputChange}
              value={this.state.stringValue}
              readOnly={realDisabled}
            />
            <span
              ref={ref => (this.toggleRef = ref)}
              className={Class(styles['datepicker-toggle'], { [styles.open]: this.state.showDatePicker })}
              onClick={realDisabled ? null : this.toggleDatePicker}
            >
              <Icon icon="fa-calendar-alt" />
            </span>
          </div>
        ) : null}
        <TransitionGroup>
          {this.state.showDatePicker ? (
            <CSSTransition classNames={renderAbove ? 'datepicker-ani-above' : 'datepicker-ani'} timeout={200}>
              <div className={Class(styles.datepicker, { above: renderAbove, fixed: this.props.fixed })} ref="datepicker">
                {this.mode === 'datetime' ? (
                  <div className={styles['component-container']}>
                    <SideBar date={this.currentDate} />
                    <MonthView date={this.currentDate} onChange={this.onChange} showYear showMonth showDate />
                    <TimeView date={this.currentDate} onChange={this.onChange} minuteStep={this.props.minuteStep} />
                  </div>
                ) : (
                  <div className={styles['component-container']}>
                    <SideBar monthOnly={this.mode === 'month'} date={this.currentDate} />
                    <MonthView date={this.currentDate} onChange={this.onChange} showYear showMonth />
                    {this.mode === 'month' ? <BlankView /> : <MonthView date={this.currentDate} onChange={this.onChange} showDate />}
                  </div>
                )}
                <div className={styles['confirm-view']}>
                  <div>
                    <Button
                      className={styles['now-button']}
                      flat
                      onClick={this.onSetNow}
                      style={{ color: monthColoursDark[this.currentDate.month()] }}
                      icon="fa-clock"
                      title="Now"
                    />
                    {this.props.canClear && (
                      <Button className="clear-button" flat onClick={this.onClear} style={{ color: monthColoursDark[this.currentDate.month()] }} icon="fa-ban" title="Clear" />
                    )}
                  </div>
                  <div className={styles['button-container']}>
                    <Button onClick={this.onCancel} flat className={styles.cancel} title="Cancel" style={{ color: monthColoursDark[this.currentDate.month()] }} />
                    <Button onClick={this.onOk} flat className={styles.ok} title="Ok" style={{ color: monthColoursDark[this.currentDate.month()] }} />
                  </div>
                </div>
              </div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
    );
  }
}

export default DatePicker;

(global as any).DatePicker = DatePicker;
