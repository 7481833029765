import classNames from 'classnames';
import Modal from 'commonui/Modal';
import { bind } from 'decko';
import { Provider } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface ICustomDialogContext {
  resolve: (result: any) => void;
}

const CustomDialogContext = React.createContext<ICustomDialogContext>({ resolve: () => {} });
export { CustomDialogContext };

/**
 *  Creates a gearsDialog with no additional formatting or controls.
 * Closing this dialog needs to be implemented by calling props.onClose with any result
 */
export default function createCustomDialog(render: JSX.Element): Promise<any> {
  return new Promise((resolve, reject) => {
    let modalElement = document.getElementById('modalContainer');
    if (!modalElement) {
      const div = document.createElement('div');
      div.id = 'modalContainer';
      document.body.appendChild(div);
      modalElement = document.getElementById('modalContainer') as HTMLElement;
    }
    ReactDOM.unmountComponentAtNode(modalElement);
    ReactDOM.render(React.createElement(CustomDialog, { children: render, onClose: resolve }), modalElement);
  });
}

interface ICustomDialogProps {
  onClose: (result: any) => void;
  children: JSX.Element;
}

class CustomDialog extends React.Component<ICustomDialogProps, never> {
  @bind
  private onClose(result: any): void {
    const modalElement = document.getElementById('modalContainer');
    if (modalElement) {
      ReactDOM.unmountComponentAtNode(modalElement);
    }
    this.props.onClose(result);
  }

  public render(): JSX.Element {
    return (
      <Modal className={classNames('modal-dialog2', 'custom')} show={true}>
        <CustomDialogContext.Provider value={{ resolve: this.onClose }}>
          <Provider resolve={this.onClose}>{this.props.children}</Provider>
        </CustomDialogContext.Provider>
      </Modal>
    );
  }
}
