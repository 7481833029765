import * as React from 'react';
import Select2 from 'react-select';
import Async from 'react-select/async';
import './Select.scss';

export interface ISelectOptions {
	value: string | number;
	label: string;
}

export default function Select(props): JSX.Element {
	const { onChange, simpleValue, value, options, multi, ...other } = props;

	const onSimpleChange = React.useCallback(
		v => {
			if (!multi) {
				onChange(v ? v.value : null);
			} else {
				if (v) {
					onChange(v.map(e => e.value));
				} else {
					onChange([]);
				}
			}
		},
		[multi, onChange],
	);

	const isAsync = props.loadOptions;

	return (
		<span className="custom-select">
			{(!props.async && !isAsync)
				? <Select2
					onChange={simpleValue ? onSimpleChange : onChange}
					value={simpleValue ? options.find(opt => opt.value === value) : value}
					options={options}
					isMulti={multi}
					{...other}
				/>
				: <Async
					onChange={simpleValue ? onSimpleChange : onChange}
					value={simpleValue ? options.find(opt => opt.value === value) : value}
					options={options}
					isMulti={multi}
					{...other}
				/>
			}
		</span>
	);
}
