import * as React from 'react';

interface ICardTabProps extends React.HTMLProps<HTMLDivElement> {
  icon?: string;
  iconColour?: string;
  title: string;
  subtitle: string;
}

/**
 * A single tab inside a CardTabs group
 */
export default class CardTab extends React.Component<ICardTabProps, never> {
  public render() {
    return <div>{this.props.children}</div>;
  }
}
