/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import Class from 'classnames';
import { bind } from 'decko';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { monaco as MonacoInstance } from "@monaco-editor/react"
const monacoLoaded = MonacoInstance.init();

export type Language = 'typescript' | 'javascript' | 'lua' | 'sql' | 'json' | 'json5' | 'css' | 'scss' | 'pgsql' | 'xml' | 'html';
export type FileType = 'jsx' | 'tsx' | 'scss';

const fileTypes = {
	css: 'css',
	html: 'html',
	javascript: 'tsx',
	json: 'json',
	json5: 'json',
	pgsql: 'sql',
	scss: 'scss',
	sql: 'sql',
	typescript: 'tsx',
};

export interface IBaseMonacoEditorProps {
	lineNumbers?: 'on' | 'off';
	theme?: 'vs' | 'vs-dark';
	language?: Language;
	className?: string;
	style?: object;
	readOnly?: boolean;
	minimap?: boolean | monaco.editor.IEditorMinimapOptions;
	smoothScrolling?: boolean;
	cursorBlinking?: monaco.editor.IEditorOptions.cursorBlinking;
	cursorSmoothCaretAnimation?: boolean;
	autoClosingBrackets?: monaco.editor.IEditorOptions.autoClosingBrackets;
	autoClosingQuotes?: monaco.editor.IEditorOptions.autoClosingQuotes;
}

export interface ISimpleMonacoEditorProps extends IBaseMonacoEditorProps {
	type: 'simple';
	observable?: any;
	value: string;
	fileType?: FileType;
	onChange?: (value: string, event: monaco.editor.IModelContentChangedEvent) => void;
	onChangeSelection?: (value: string) => void;
}

export interface IMultiMonacoEditorProps extends IBaseMonacoEditorProps {
	type: 'multi';
	models: {
		[key: string]: IModelProps;
	};
	activeModel: string;
	onChange?: (model: string, value: string, event: monaco.editor.IModelContentChangedEvent) => void;
	onChangeSelection?: (model: string, value: string) => void;
}

export interface IModelProps {
	observable?: any;
	value: string;
	readOnly?: boolean;
	language?: Language;
	filetype?: FileType;
}

export interface IDiffMonacoEditorProps extends IBaseMonacoEditorProps {
	type: 'diff';
	original: string;
	modified: string;
	renderSideBySide?: boolean;
}

export type IMonacoEditorProps = ISimpleMonacoEditorProps | IDiffMonacoEditorProps | IMultiMonacoEditorProps;
type IUnionMonacoEditorProps = ISimpleMonacoEditorProps & IDiffMonacoEditorProps & IMultiMonacoEditorProps;

@observer
export default class MonacoEditor extends React.Component<IMonacoEditorProps> {
	public static defaultProps = { type: 'simple' };
	private codeRef: HTMLElement | null;
	private editor: monaco.editor.IStandaloneCodeEditor;
	private diffEditor: monaco.editor.IStandaloneDiffEditor;
	private models: { [key: string]: monaco.editor.IModel } = {};
	private defaultTheme = 'vs';
	private defaultLanguage = 'javascript';
	private editorPromise: Promise<monaco.editor.IStandaloneCodeEditor | monaco.editor.IStandaloneDiffEditor>;
	private editorResolve?: (value: monaco.editor.IStandaloneCodeEditor | monaco.editor.IStandaloneDiffEditor) => void;

	constructor(props: IMonacoEditorProps) {
		super(props);
		this.editorPromise = new Promise(resolve => {
			this.editorResolve = resolve;
		});
	}

	public componentDidMount(): void {
		monacoLoaded.then(() => this.initialize());
		window.addEventListener('resize', this.resizeLayout);
	}

	public componentWillUnmount(): void {
		if (this.editor) {
			if (this.props.type === 'simple') {
				const model = this.editor.getModel();
				if (model) {
					model.dispose();
				}
			} else if (this.props.type === 'multi') {
				Object.entries(this.models).map(([key, model]) => {
					if (model.dispose) {
						model.dispose();
					}
				});
			}
			this.editor.dispose();
		}
		if (this.diffEditor) {
			this.diffEditor.dispose();
		}
		for (const key of Object.keys(this.models)) {
			this.models[key].dispose();
		}
		window.removeEventListener('resize', this.resizeLayout);
	}

	public componentDidUpdate(): void {
		this.initialize();
	}

	public getEditorPromise(): Promise<monaco.editor.IStandaloneCodeEditor | monaco.editor.IStandaloneDiffEditor> {
		return this.editorPromise;
	}

	public getModels() {
		return this.models;
	}

	@bind
	private resizeLayout(): void {
		if (this.editor && this.codeRef) {
			const bounds = this.codeRef.getBoundingClientRect();
			this.editor.layout({
				height: bounds.height,
				width: 100,
			});
			this.editor.layout();
		}
	}

	private realValue(): string {
		const { observable, value } = this.props as ISimpleMonacoEditorProps;
		if (observable) {
			return observable[value] || '';
		}
		return value || '';
	}

	private realValueMulti(model: IModelProps): string {
		const { observable, value } = model;
		if (observable) {
			return observable[value] || '';
		}
		return value || '';
	}

	private initialize(): void {
		if (this.codeRef) {
			const minimapSettings = this.props.minimap
				? typeof this.props.minimap === 'boolean'
					? ({
						enabled: this.props.minimap,
						maxColumn: 160,
						renderCharacters: false,
						showSlider: 'always',
					} as monaco.editor.IEditorMinimapOptions)
					: this.props.minimap
				: { enabled: false };
			if (this.props.type === 'simple' || this.props.type === 'multi') {
				/**
				 * Simple editor with basic configuration
				 */
				const realValue = this.realValue();
				if (!this.editor) {
					let model;
					if (this.props.type === 'simple') {
						const URI = monaco.Uri.parse(`scheme://file.${this.props.fileType || fileTypes[this.props.language || this.defaultLanguage]}`);
						model = monaco.editor.createModel(realValue, this.props.language || this.defaultLanguage, URI);
					} else if (this.props.type === 'multi') {
						const modelConfig = this.props.models[this.props.activeModel];
						this.models[this.props.activeModel] = monaco.editor.createModel(
							this.realValueMulti(modelConfig),
							modelConfig.language,
							modelConfig.filetype && monaco.Uri.parse('scheme://' + this.props.activeModel + '.' + modelConfig.filetype),
						);
						this.models[this.props.activeModel].updateOptions({
							tabSize: 4,
						});
						model = this.models[this.props.activeModel];
						this.delayModelLoading();
					}

					this.editor = monaco.editor.create(this.codeRef, {
						acceptSuggestionOnEnter: 'off',
						autoClosingBrackets: this.props.autoClosingBrackets,
						autoClosingQuotes: this.props.autoClosingQuotes,
						cursorBlinking: this.props.cursorBlinking !== undefined ? this.props.cursorBlinking : 'phase',
						cursorSmoothCaretAnimation: this.props.cursorSmoothCaretAnimation !== undefined ? this.props.cursorSmoothCaretAnimation : true,
						language: this.props.language || this.defaultLanguage,
						lineNumbers: this.props.lineNumbers || 'on',
						minimap: minimapSettings,
						model,
						readOnly: this.props.readOnly,
						smoothScrolling: this.props.smoothScrolling !== undefined ? this.props.smoothScrolling : true,
						theme: this.props.theme || this.defaultTheme,
					});
					this.editor.onDidChangeModelContent(this.props.type === 'simple' ? this.onChange : this.onChangeMulti);
					this.editor.onDidChangeCursorSelection(this.props.type === 'simple' ? this.onChangeSelection : this.onChangeSelectionMulti);
					if (this.editorResolve) {
						this.editorResolve(this.editor);
					} else {
						this.editorPromise = Promise.resolve(this.editor);
					}
				} else {
					this.editor.updateOptions({
						lineNumbers: this.props.lineNumbers || 'on',
						minimap: minimapSettings,
					});
					if (this.props.type === 'simple') {
						if (realValue !== this.editor.getValue()) {
							this.editor.setValue(realValue);
						}
					} else if (this.props.type === 'multi') {
						if (!this.models.hasOwnProperty(this.props.activeModel)) {
							const model = this.props.models[this.props.activeModel];
							this.models[this.props.activeModel] = monaco.editor.createModel(
								this.realValueMulti(model),
								model.language,
								model.filetype && monaco.Uri.parse('scheme://' + this.props.activeModel + '.' + model.filetype),
							);
							this.models[this.props.activeModel].updateOptions({
								tabSize: 4,
							});
						}
						this.delayModelLoading();
						// Remove models
						for (const key of Object.keys(this.models)) {
							if (!this.props.models.hasOwnProperty(key)) {
								this.models[key].dispose();
								delete this.models[key];
							}
						}
						this.editor.setModel(this.models[this.props.activeModel]);
					}
					if (this.editorResolve) {
						this.editorResolve(this.editor);
					} else {
						this.editorPromise = Promise.resolve(this.editor);
					}
				}
			} else if (this.props.type === 'diff') {
				/**
				 * Diff editor showing differences between 2 files
				 */
				if (!this.diffEditor) {
					this.diffEditor = monaco.editor.createDiffEditor(this.codeRef, {
						cursorBlinking: 'phase',
						cursorSmoothCaretAnimation: true,
						enableSplitViewResizing: false,
						lineNumbers: this.props.lineNumbers || 'on',
						minimap: minimapSettings,
						readOnly: true,
						renderSideBySide: this.props.renderSideBySide || false,
						smoothScrolling: true,
						theme: this.props.theme || this.defaultTheme,
					});
					const original = typeof this.props.original === 'object' ? JSON.stringify(this.props.original, null, 4) : this.props.original;
					const modified = typeof this.props.modified === 'object' ? JSON.stringify(this.props.modified, null, 4) : this.props.modified;
					const type = typeof this.props.original === 'object' ? 'json' : this.props.language || 'text/plain';
					this.diffEditor.setModel({
						modified: monaco.editor.createModel(modified, type),
						original: monaco.editor.createModel(original, type),
					});
				} else {
					this.diffEditor.updateOptions({
						lineNumbers: this.props.lineNumbers || 'on',
						minimap: minimapSettings,
					});
				}
			}
		}
	}

	/**
	 * Model loading can have a noticeably delay depending on number of models and size
	 * So we'll load the active one straight away, then load the rest when browser is idle
	 */
	@bind
	private delayModelLoading(): void {
		const callback = window.requestIdleCallback || window.setTimeout;
		const { models } = this.props as IMultiMonacoEditorProps;
		for (const key of Object.keys(models)) {
			callback(() => {
				const model = models[key];
				if (!this.models.hasOwnProperty(key)) {
					this.models[key] = monaco.editor.createModel(this.realValueMulti(model), model.language, model.filetype && monaco.Uri.parse('scheme://' + key + '.' + model.filetype));
					this.models[key].updateOptions({
						tabSize: 4,
					});
				}
			});
		}
	}

	@bind
	private onChangeSelectionMulti(): void {
		const { activeModel, onChangeSelection } = this.props as IMultiMonacoEditorProps;
		if (onChangeSelection) {
			onChangeSelection(activeModel, this.editor.getModel().getValueInRange(this.editor.getSelection()));
		}
	}

	@bind
	@action
	private onChangeMulti(e: monaco.editor.IModelContentChangedEvent): void {
		const { activeModel, onChange, models } = this.props as IMultiMonacoEditorProps;
		const { observable, value } = models[activeModel];
		if (observable) {
			observable[value] = this.editor.getValue();
		}
		if (onChange) {
			onChange(activeModel, this.editor.getValue(), e);
		}
	}

	@bind
	private onChangeSelection(): void {
		const { onChangeSelection } = this.props as ISimpleMonacoEditorProps;
		if (onChangeSelection) {
			onChangeSelection(this.editor.getModel().getValueInRange(this.editor.getSelection()));
		}
	}

	@bind
	@action
	private onChange(e: monaco.editor.IModelContentChangedEvent): void {
		const { observable, value, onChange } = this.props as ISimpleMonacoEditorProps;
		if (observable) {
			observable[value] = this.editor.getValue();
		}
		if (onChange) {
			onChange(this.editor.getValue(), e);
		}
	}

	public render(): JSX.Element {
		const { className, style, value, renderSideBySide, lineNumbers, theme, onChange, onChangeSelection, minimap, models, activeModel, ...other } = this
			.props as IUnionMonacoEditorProps;
		return <div className={Class('monaco-editor', className)} style={{ height: '100%', ...style }} ref={ref => (this.codeRef = ref)} {...other} />;
	}
}
