import {useMemo} from "react";
import {compact, noop, over} from "lodash";

export function eachCallback<T>(callbacks: Array<null | undefined | ((event: T) => void)>, empty?: () => void ): (event: T) => void {
    const justCallbacks = compact(callbacks);
    const [first, second, third] = justCallbacks;
    switch (justCallbacks.length) {
        case 0:
            return empty || noop;
        case 1:
            return justCallbacks[0];
        case 2:
            return (event: T): void => {
                first(event);
                second(event);
            };
        case 3:
            return (event: T): void => {
                first(event);
                second(event);
                third(event);
            };
        default:
            return over(justCallbacks);
    }
}

export function useEachCallback<T>(callbacks: Array<null | undefined | ((event: T) => void)>): (event: T) => void {
    return useMemo(() => eachCallback(callbacks), callbacks);
}

export default useEachCallback;
