import { Icon, IconParams } from '@fortawesome/fontawesome';
import { IconLookup, IconName } from '@fortawesome/fontawesome-common-types';
import { getFaIconProps } from '../gears/helpers/fa';

declare const FontAwesome: { icon(icon: IconName | IconLookup, params?: IconParams): Icon };

export default function setFAFavicon(icon: string, color: string | null, bg = 'transparent'): void {
    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 64;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const iconProps = getFaIconProps(icon);

    let styles;
    if (color) {
        styles = {
            'background-color': bg,
            color,
        };
    }

    // console.log("fa", icon, color, styles);
    const font = FontAwesome.icon(iconProps.icon as IconLookup, styles && { styles });
    if (!font) {
        return;
    }
    const data = font.html;
    // console.log("Data", data);

    const DOMURL = window.URL || window.webkitURL || window;

    const url = 'data:image/svg+xml,' + encodeURIComponent(data);
    // const url = DOMURL.createObjectURL(svg); // Breaks on switching tabs with firefox workaround.

    const link = document.createElement('link');
    link.setAttribute('rel', 'icon');
    for (let icons = document.querySelectorAll('link[rel*=icon]') as any, i = 0, l = icons.length; i < l; i++) {
        icons[i].parentNode.removeChild(icons[i]);
    }
    if (navigator.userAgent.match(/Firefox/)) {
        link.setAttribute('href', url);
        link.setAttribute('type', 'image/svg+xml');
        document.getElementsByTagName('head')[0].appendChild(link);
    } else {
        const img = new Image();
        img.onload = function() {
            // console.log("Image loaded");
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            // console.log("Canvas drawn", canvas.toDataURL("image/png"));
            link.setAttribute('href', canvas.toDataURL('image/png'));
            link.setAttribute('type', 'image/png');
            document.getElementsByTagName('head')[0].appendChild(link);
            // DOMURL.revokeObjectURL(url);
        };
        img.src = url;
    }
}
