import * as _ from 'lodash';
import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, NUMBER_FORMATS, registerCalculationType } from './Calculator';

export interface IAssignedPercentageTiersOptions extends ICalculationOptions {
    calculation_type: 'assigned_percentage_tiers';
    member_tiers: Array<{ tier: number; member_id: number }>;
    tiers: Array<{ tier: number | string; percentage: number | string }>;
}

export interface IAssignedPercentageTiersInputs extends ICalculationInputs {
    member_id: number;
}

export default class AssignedPercentageTiers extends Calculator {
    readonly tiersMap: { [tierId: number]: number };
    readonly memberMap: { [memberId: number]: number };

    constructor(options: IAssignedPercentageTiersOptions) {
        super(options);
        this.memberMap = this.calculateMemberMap(options.member_tiers);
        this.tiersMap = this.calculateTiersMap(options.tiers);
    }

    private calculateMemberMap(member_tiers: any[]) {
        return _.reduce(
            member_tiers,
            (agg: { [memberId: number]: number }, mt: { tier: number; member_id: number }) => {
                agg[mt.member_id] = mt.tier;
                return agg;
            },
            {},
        );
    }

    private calculateTiersMap(tiers: any[]) {
        return _.reduce(
            tiers,
            (agg: { [tierId: number]: number }, t: { tier: number | string; percentage: number | string }) => {
                agg[parseFloat(t.tier as string)] = parseFloat(t.percentage as string);
                return agg;
            },
            {},
        );
    }

    protected calculationFunction(inputs: IAssignedPercentageTiersInputs): Partial<ICalculationResult> {
        const value = inputs.baseValue;

        const percentage = numeral(this.tiersMap[this.memberMap[inputs.member_id]] + '%');
        const rawResult = percentage.value() * value;
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = `${value} ${NUMBER_FORMATS.multiplication} ${percentage.format(NUMBER_FORMATS.percentFormat)} = ${formattedResult}`;
        return {
            calculation,
            formattedResult,
            percentage: percentage.format(NUMBER_FORMATS.percentFormat),
            rawResult,
            result: result.value(),
        };
    }
}

registerCalculationType('assigned_percentage_tiers', AssignedPercentageTiers);
registerCalculationType('assigned_percentage_tier', AssignedPercentageTiers);
