import React from 'react';
import { withProps } from 'recompose';

interface IFlexibleEnabledProps {
  disabled?: boolean;
  enabled?: boolean;
}

export type IWithFlexibleEnabled<T> = Omit<T, 'enabled'> & {
  disabled?: boolean;
  enabled?: boolean;
};

export const withFlexibleEnabled = (withProps((props: IFlexibleEnabledProps): { enabled: boolean } => ({
  enabled: !props.disabled && (props.enabled == null || !!props.enabled),
})) as any) as <T extends IFlexibleEnabledProps>(Component: React.FunctionComponent<T>) => React.FunctionComponent<IWithFlexibleEnabled<T>>;
