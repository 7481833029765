import { action, autorun } from 'mobx';
import React, { useContext } from 'react';
import { Registry } from 'react-registry';
import { ConfigurationError } from '../Errors';
import FieldConfig from '../GearsModel/FieldConfig';
import { IEditor } from './Editors';
import FormState, { FormContext } from './FormState';
import { usePathComputed } from './hooks';

require('./Editors');
import classNameType from 'classnames';
const dedupeClassNames: typeof classNameType = require('cndedupe');

interface IFieldEditorProps {
    field: string;
    form?: FormState<any>;
    className?: any;
    editor?: IEditor;
    editorProps?: any;
}

const FieldEditor: React.FunctionComponent<IFieldEditorProps> = ({ field, form, className, editor, editorProps }: IFieldEditorProps) => {
    form = form || useContext(FormContext);
    const fieldConfig = form.getFieldConfig(field);

    const valueComputed = usePathComputed(form, `record.${field}`);
    const Editor = editor || fieldConfig.Editor;
    if (!Editor) {
        throw new ConfigurationError(`No editor specified for field "${field}" on form for model "${form.model.modelName}".`);
    }

    return <Editor {...editorProps} className={dedupeClassNames('form-control', field, className)} value={valueComputed} field={field} form={form} />;
};

FieldEditor.displayName = 'FieldEditor';
Registry.register(FieldEditor, { id: 'FieldEditor' });

export default FieldEditor;
