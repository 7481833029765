import * as _ from 'lodash';
import { toJS } from 'mobx';
import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, registerCalculationType } from './Calculator';

interface IInputSpec {
    input: string;
    calculation: any;
}
export interface IComposeInputsCalculationOptions extends ICalculationOptions {
    calculation_type: 'compose';
    inputs: any;
    baseCalculator?: Calculator;
}

export default class ComposeInputs extends Calculator {
    private inputCalculators: any;
    private baseCalculator: Calculator;
    constructor(options: IComposeInputsCalculationOptions) {
        super(options);
        const baseOptions = _.cloneDeep(toJS(options));
        baseOptions.baseCalculator = undefined;
        baseOptions.modify = undefined;
        baseOptions.compose = undefined;
        this.baseCalculator = options.baseCalculator || Calculator.createCalculator(baseOptions);
        // console.log("Composer constructor", options);
        this.inputCalculators = this.prepareInputCalculators(options.inputs || options.compose);
    }

    protected prepareInputCalculators(inputs: any): any {
        return _.mapValues(inputs, (input: any) => this.prepareInputCalculator(input));
    }

    protected prepareInputCalculator(input: any): Calculator {
        return Calculator.createCalculator(input);
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> {
        const inputOverrideCalcs = _.mapValues(this.inputCalculators, (ic: Calculator) => ic.calculate(inputs));
        const inputOverrideCalculations = _.join(
            _.map(inputOverrideCalcs, (io: any, fieldName: string) => `**${fieldName}**: ${io.calculation}\n`),
            '',
        );
        const finalInputs: ICalculationInputs = _.merge({}, inputs, _.mapValues(inputOverrideCalcs, 'rawResult'));
        // console.log("inputCalculators", this.inputCalculators);
        // console.log("inputOverrideCalcs", inputOverrideCalcs);
        // console.log("inputOverrideCalculations", inputOverrideCalculations);
        // console.log("finalInputs", finalInputs);

        const baseCalc = this.baseCalculator.calculate(finalInputs);
        baseCalc.inputOverrideCalculations = inputOverrideCalculations;
        baseCalc.calculation = `${inputOverrideCalculations}\n-------\n\n${baseCalc.calculation}`;
        return baseCalc;
    }
}

registerCalculationType('compose', ComposeInputs);
