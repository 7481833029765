import Class from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import Icon from './Icon';
import styles from './TextArea.scss';

interface ITextAreaProps extends React.HTMLProps<HTMLElement> {
  requireConfirm?: boolean;
  onChange?: (value: string) => void;
  onCancel?: () => void;
  disabled?: boolean;
  label?: string;
  observable: any;
  value: string;
  hideLabel?: boolean;
}

interface ITextAreaState {
  value: string;
}

/**
 * A react TextArea
 */
@observer
export default class TextArea extends React.Component<ITextAreaProps, ITextAreaState> {
  constructor(props: ITextAreaProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);

    if (props.requireConfirm) {
      if (props.observable) {
        this.state = { value: props.observable[props.value] };
      } else {
        this.state = { value: props.value };
      }
    }
  }

  private onChange(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    const value = e.currentTarget.value;
    if (!this.props.requireConfirm) {
      this.changeValue(value);
    } else {
      this.setState({ value });
    }
  }

  private changeValue(newValue: string) {
    if (this.props.observable) {
      this.props.observable[this.props.value] = newValue;
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
      return;
    }
  }

  private onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    if (this.props.requireConfirm) {
      if (this.props.observable) {
        this.setState({ value: this.props.observable[this.props.value] });
      } else {
        this.setState({ value: this.props.value });
      }
    }
  }

  private onSave() {
    this.changeValue(this.state.value);
  }

  public render() {
    const { value, observable, disabled, label, requireConfirm, onFocus, onBlur, onChange, children, hideLabel, ...other } = this.props;

    let realValue = value;
    if (this.props.observable) {
      realValue = this.props.observable[this.props.value];
    }
    const classNames = Class(styles.textarea, {
      [styles.unsaved]: this.state && this.state.value !== realValue,
      requireConfirm,
      unsaved: this.state && this.state.value !== realValue,
    });

    return (
      <div className={classNames} onFocus={onFocus} onBlur={onBlur}>
        <textarea
          className={Class({
            [styles['has-value']]: label !== undefined && (requireConfirm ? this.state.value : realValue),
            [styles['without-label']]: hideLabel,
          })}
          disabled={disabled}
          onChange={this.onChange}
          value={requireConfirm ? this.state.value : realValue}
          aria-label={label}
          {...other}
        />
        {!hideLabel && <label>{label}</label>}
        {requireConfirm && (
          <div className={styles['button-container']}>
            <Icon icon="md-close" colour="#f44336" onClick={this.onCancel} />
            <Icon icon="md-check" colour="#4CAF50" onClick={this.onSave} />
          </div>
        )}
      </div>
    );
  }
}

export { TextArea as MobXTextArea };
