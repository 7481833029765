import Class from 'classnames';
import Button from 'commonui/Button';
import Dialog from 'commonui/Dialog';
import TextArea from 'commonui/TextArea';
import { bind } from 'decko';
import * as $ from 'jquery';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { NoteManager } from '.';
import Markdown from '../public/Markdown';
import ChildNote from './ChildNote';
import styles from './Note.scss';
import INoteType from './NoteType';
import relativeTime from './relativeTime';

interface IParentNoteProps {
    note: INoteType;
    childNotes: INoteType[];
    user: number;
    channel?: string;
    filter?: object;
    canResolve: boolean;
    onAddReply: (note: INoteType) => void;
    onFailedReply: () => void;
    showHeader?: boolean;
    removeControls?: boolean;
    name?: string;
}

@inject('noteStore')
@observer
export default class ParentNote extends React.Component<IParentNoteProps, never> {
    @observable private collapsed: boolean = true;
    @observable private addingReply: boolean = false;
    @observable private replyText: string = '';
    @observable private hasNewMessage: boolean = false;

    @bind
    @action
    private toggleCollapsed(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        this.collapsed = !this.collapsed;
        this.hasNewMessage = false;
    }

    @bind
    @action
    private startCreateReply(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        this.collapsed = false;
        this.addingReply = true;
        this.hasNewMessage = false;
    }

    @bind
    @action
    private onCreateReply(): void {
        this.collapsed = false;
        const noteStore = this.props.noteStore as NoteManager;
        $.ajax({
            data: {
                note: {
                    channel: this.props.channel,
                    created_by_email: noteStore.email,
                    created_by_id: this.props.user,
                    note_text: this.replyText,
                    parent_note_id: this.props.note.id,
                    webfront_metadata: {
                        ...this.props.filter,
                    },
                },
                ...noteStore.data,
            },
            dataType: 'json',
            method: 'POST',
            url: noteStore.submitRoute || '/notes/',
        })
            .then(t => {
                this.addingReply = false;
                this.replyText = '';
                return t;
            })
            .then(this.props.onAddReply)
            .catch(this.props.onFailedReply);
    }

    @bind
    @action
    private onCancelReply(): void {
        this.addingReply = false;
        this.replyText = '';
    }

    @bind
    private onResolve(e: React.MouseEvent<HTMLElement>): void {
        e.preventDefault();
        const id = this.props.note.id;
        if (id > 0) {
            Dialog({
                content: 'This will close the note and prevent further replies',
                title: 'Resolve Note?',
            }).then(
                action(() => {
                    const now = moment().format();
                    this.props.note.resolved_at = now;
                    $.ajax({
                        data: {
                            note: {
                                resolved_at: now,
                            },
                        },
                        method: 'PUT',
                        url: '/notes/' + id,
                    });
                }),
            );
        }
    }

    public render(): JSX.Element {
        const { note, name, childNotes, showHeader, removeControls } = this.props;

        const realShowHeader = showHeader === undefined ? true : showHeader;
        const collapsable = childNotes.length || note.note_text.length > 100;
        const realRemoveControls = removeControls === undefined ? false : removeControls;
        const user = note.webfront_relations.user;

        return (
            <div className={Class(styles.note, 'note')}>
                {realShowHeader && (
                    <div className={Class(styles['note-group-header'], 'note-group-header')}>
                        {(note.webfront_metadata && note.webfront_metadata._name) || name}
                        {note.resolved_at && <div className={Class(styles.resolved, 'resolved')}>Resolved {moment(note.resolved_at).format('hh:mma Do MMM YYYY')}</div>}
                    </div>
                )}
                <div className={Class(styles['user-area'], 'user-area')}>
                    {user && <span className={Class(styles.initials, user.initials_class, 'initials')}>{user.initials}</span>}
                    {user && <span className={Class(styles['user-name'], 'user-name')}>{user.text}</span>}
                    {!user && <span className={Class(styles['user-name'], 'user-name')}>Unknown User</span>}
                    <span className={Class(styles.timestamp, 'timestamp')}>posted {relativeTime(note.created_at)} ago</span>
                </div>
                <Markdown className={Class(styles['note-message'], 'note-message', { [styles.collapsed]: this.collapsed, collapsed: this.collapsed })}>{note.note_text}</Markdown>
                {!this.collapsed && childNotes.length > 0 && (
                    <div className={Class(styles['child-note-area'], 'child-note-area')}>
                        {childNotes.map(childNote => <ChildNote key={childNote.id} note={childNote} />)}
                    </div>
                )}
                {collapsable && (
                    <div className={Class(styles.collapser, 'collapser')}>
                        {this.collapsed ? (
                            <a href="#" data-id={note.id} onClick={this.toggleCollapsed}>
                                Show More {childNotes.length > 0 && `(${childNotes.length} replies)`}
                            </a>
                        ) : (
                            <a href="#" data-id={note.id} onClick={this.toggleCollapsed}>
                                Show Less
                            </a>
                        )}
                    </div>
                )}
                {note.resolved_at && !realShowHeader && <div className={Class(styles.resolved, 'resolved')}>Resolved {moment(note.resolved_at).format('hh:mma Do MMM YYYY')}</div>}
                {!this.addingReply && !note.resolved_at && !realRemoveControls && (
                    <div className={Class(styles['action-container'], 'action-container')}>
                        <a href="#" onClick={this.startCreateReply}>
                            Reply
                        </a>
                        <a href="#" onClick={this.onResolve}>
                            Resolve
                        </a>
                    </div>
                )}
                {this.addingReply && (
                    <div className={Class(styles['create-note'], 'create-note')}>
                        <TextArea hideLabel placeholder="Reply: " observable={this} value="replyText" label="Reply: " />
                        <div className={Class(styles['action-container'], 'action-container')}>
                            <Button title="Create" flat icon="fa-check" onClick={this.onCreateReply} />
                            <Button title="Cancel" flat icon="fa-times" className={Class(styles.cancel, 'cancel')} onClick={this.onCancelReply} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
