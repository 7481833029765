import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import styles from './Button.scss';
import Icon from './Icon';

export interface IButtonProps extends React.HTMLProps<HTMLElement> {
  className?: string;
  coloured?: boolean;
  disabled?: boolean;
  enabled?: boolean;
  fill?: boolean;
  flat?: boolean;
  icon?: string;
  onClick?: any;
  title?: string;
}

const isMobile = global.orientation !== undefined;

function isTouchEvent(arg: any): arg is React.TouchEvent<HTMLButtonElement> {
  return arg.clientX === undefined;
}

/**
 * A customizable button
 */
export default class Button extends React.PureComponent<IButtonProps, never> {
  public static defaultProps: IButtonProps = { enabled: true };
  private buttonRef: HTMLButtonElement | null;
  private rippleRef: HTMLElement | null;
  private rippleExpansionTimer?: NodeJS.Timer;

  @bind
  private onMouseDown(e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>): void {
    if (!this.buttonRef || !this.rippleRef) {
      return;
    }
    const position = this.buttonRef.getBoundingClientRect();
    let x: number;
    let y: number;
    if (!isTouchEvent(e)) {
      x = e.clientX - position.left;
      y = e.clientY - position.top;
    } else {
      x = e.touches[0].clientX - position.left;
      y = e.touches[0].clientY - position.top;
    }
    const initialSize = 48; // About size of a finger press
    const expandSpeed = 128;
    const maxSize = Math.max(this.buttonRef.clientWidth * 2, this.buttonRef.clientHeight * 2);
    const expansionTime = Math.floor((maxSize / expandSpeed) * 300);
    Object.assign(this.rippleRef.style, {
      display: 'block',
      height: `${initialSize}px`,
      left: x - initialSize / 2 + 'px',
      opacity: '1',
      top: y - initialSize / 2 + 'px',
      transition: '',
      width: `${initialSize}px`,
    });
    setTimeout(() => {
      if (this.buttonRef && this.rippleRef) {
        Object.assign(this.rippleRef.style, {
          height: maxSize + 'px',
          left: x - maxSize / 2 + 'px',
          opacity: '0',
          top: y - maxSize / 2 + 'px',
          transition: `width, height, opacity, background-color, left, top`,
          transitionDuration: `${expansionTime}ms`,
          transitionTimingFunction: 'ease-out',
          width: maxSize + 'px',
        });
        if (this.rippleExpansionTimer) {
          clearTimeout(this.rippleExpansionTimer);
          this.rippleExpansionTimer = undefined;
        }
        this.rippleExpansionTimer = setTimeout(() => {
          if (this.buttonRef && this.rippleRef) {
            Object.assign(this.rippleRef.style, {
              display: 'none',
            });
          }
        }, expansionTime);
      }
    }, 1);
  }

  public render(): JSX.Element {
    const { icon, title, flat, className, disabled, enabled, coloured, fill, onClick, children, ...other } = this.props;
    const realTitle = title || children;
    const realDisabled = disabled || !enabled;
    const classNames = {
      [styles.disabled]: realDisabled,
      [styles['no-icon']]: !icon,
      [styles['no-title']]: !realTitle,
      [styles.coloured]: coloured,
      [styles.fill]: fill,
      [styles.flat]: flat,
      coloured,
      disabled: realDisabled,
      fill,
      flat,
      'no-icon': !icon,
      'no-title': !realTitle,
    };
    if (!realTitle && !other['aria-label']) {
      console.warn('Button without title requires an aria-label!');
    }
    return (
      <button
        ref={ref => (this.buttonRef = ref)}
        onClick={onClick}
        onMouseDown={!isMobile ? this.onMouseDown : undefined}
        onTouchStart={this.onMouseDown}
        disabled={realDisabled}
        className={Class(styles['simple-button'], 'simple-button', className, classNames)}
        {...other}
      >
        <div ref={ref => (this.rippleRef = ref)} className={styles.ripple} />
        {icon ? <Icon icon={icon} fixedWidth small /> : ''}
        {icon && realTitle ? ' ' : ''}
        {realTitle}
      </button>
    );
  }
}
