// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1lORRHhnQdOU7fvAx-4gjF{background:white;position:fixed;z-index:8;border-radius:50%;height:40px;width:40px;left:calc(50% - 20px);box-shadow:0 0 2px rgba(0,0,0,0.14),0px 2px 2px rgba(0,0,0,0.12),0 0 3px rgba(0,0,0,0.2)}._1lORRHhnQdOU7fvAx-4gjF svg{margin-left:8px;margin-top:8px;transition:color 200ms;font-size:24px}@keyframes pull-refresh-fade{from{opacity:1}to{opacity:0}}@keyframes pull-refresh-rotate{from{transform:rotate(-90deg)}to{transform:rotate(270deg)}}\n", ""]);
// Exports
exports.locals = {
	"container": "_1lORRHhnQdOU7fvAx-4gjF"
};
module.exports = exports;
