// import useFetch from "fetch-suspense";
import Promise from 'bluebird';
import { defaults, each, get, merge } from 'lodash';
import { bind } from 'lodash-decorators';
import React, { ComponentClass, FunctionComponent, Suspense, lazy } from 'react';
import ReactDom from 'react-dom';
import * as ReactRegistry from 'react-registry';
import { ConfigurationError, WebClientError } from '../gears/Errors';
import loader from '../modules/LiteLoader';
// const loader = {};
import { IPageJS } from './template_builder/PageConfig';

const { Registry } = ReactRegistry;

type AnyComponentType = ComponentClass | FunctionComponent | string;

interface IModelSource {
    model: string;
    component?: string;
}

interface IPageSource {
    page: string;
    component?: string;
}

interface ILoaderSource {
    loader: string;
    component?: string;
}

export type AnyLazySource = IModelSource | IPageSource | ILoaderSource;

interface IComponentProps {
    component: string;
    source?: AnyLazySource;
}

interface ILazyProps extends IComponentProps {
    fallback?: boolean | string | ((props: ILazyProps) => JSX.Element);
}

function toRegistryArguments(component: string, source?: AnyLazySource): { id: string; registry?: string } {
    if (!source) {
        return { id: component };
    } else {
        const page = (source as IPageSource).page,
            model = (source as IModelSource).model,
            loaderName = (source as ILoaderSource).loader;
        return {
            id: source.component || component,
            registry: page ? `Page#${page}` : model ? `Model#${model}` : loaderName ? `Loader#${loaderName}` : undefined,
        };
    }
}

function isModelSource(source: AnyLazySource): source is IModelSource {
    return !!(source && (source as IModelSource).model);
}

function isPageSource(source: AnyLazySource): source is IModelSource {
    return !!(source && (source as IPageSource).page);
}

function isLoaderSource(source: AnyLazySource): source is ILoaderSource {
    return !!(source && (source as ILoaderSource).loader);
}

function Lazy(this: WebfrontRegistry, { fallback, ...extra }: ILazyProps): React.ReactElement<any> {
    fallback = fallback || 'Loading component...';
    console.log('Rendering Lazy');
    return (
        <Suspense fallback={fallback}>
            <this.Component {...extra} />
        </Suspense>
    );
}

function Component(this: WebfrontRegistry, { component, source, ...extra }: IComponentProps): React.ReactElement<any> {
    if (!component) {
        throw new ConfigurationError('Lazy component must specify an id.');
    }
    return React.createElement(this.getComponent(component, source), extra);
}

// function TryComponent(this: WebfrontRegistry, { component, source, fallback, ...extra }: IComponentProps & {fallback: React.FunctionComponent}): React.ReactElement<any> {
//     if (!component) {
//         throw new ConfigurationError("Lazy component must specify an id.");
//     }
//     return React.createElement(this.tryComponent(component, source) || fallback, extra);
// }

class WebfrontRegistry {
    private readonly pageLoaders = new Map<string, Promise<IPageJS>>();
    private readonly loaderLoaders = new Map<string, Promise<any>>();

    loadComponent(component: string, source: AnyLazySource): Promise<AnyComponentType> {
        if (isModelSource(source)) {
            return this.loadModelComponent(component || source.component, source);
        } else if (isPageSource(source)) {
            return this.loadPageComponent(component || (source.component as string), source);
        } else if (isLoaderSource(source)) {
            return this.loadLoaderComponent(component || (source.component as string), source);
        } else {
            throw new ConfigurationError('Must specify either a valid source when loading Components from server.');
        }
    }

    loadPageComponent(component: string, source: IPageSource): Promise<AnyComponentType> {
        const error = () => new WebClientError('Component could not be loaded', { component, source }),
            page = loader.loadPage(source.page);

        function _get(): AnyComponentType {
            const C = Registry.get(toRegistryArguments(component as string, source)) as AnyComponentType | null;
            if (!C) {
                throw error();
            }
            return C;
        }

        if (page.isPending()) {
            return page.then(_get);
        } else if (page.isRejected()) {
            throw error();
        } else {
            return Promise.resolve(_get());
        }
    }

    loadLoaderComponent(component: string, source: ILoaderSource): Promise<AnyComponentType> {
        const error = () => new WebClientError('Component could not be loaded', { component, source }),
            loaderP = (loader as any)[source.loader];

        function _get(): AnyComponentType {
            const C = Registry.get(toRegistryArguments(component as string, source)) as AnyComponentType | null;
            if (!C) {
                throw error();
            }
            return C;
        }

        if (loaderP.isPending()) {
            return loaderP.then(_get);
        } else if (loaderP.isRejected()) {
            throw error();
        } else {
            return Promise.resolve(_get());
        }
    }

    registerModule(module: string, components: { [name: string]: React.FunctionComponent }): void {
        console.log('Registering module', module, components);
        const registryName = `Loader#${module}`;
        each(components, (component: React.FunctionComponent, key: string) => {
            // console.log("Registering component", component, key, registryName);
            Registry.register(component, { id: key, registry: registryName });
        });
    }

    registerPageComponents(page: IPageJS): void {
        const { id, identifier, components, scripts, template } = page,
            idRegistryName = `Page#${id}`,
            identifierRegistryName = `Page#${identifier}`;
        console.log('Registering template', template, { id: 'Main', registry: idRegistryName });
        Registry.register(template, { id: 'Main', registry: idRegistryName });
        Registry.register(template, { id: 'Main', registry: identifierRegistryName });
        [scripts, components].forEach(g =>
            g.forEach((component: any, name: string) => {
                // console.log("Registering component", component, name, idRegistryName);
                Registry.register(component, { id: name, registry: idRegistryName });
                Registry.register(component, { id: name, registry: identifierRegistryName });
            }),
        );
    }

    tryComponent(componentOrSource: string | AnyLazySource, source?: AnyLazySource): AnyComponentType | null {
        try {
            return this.getComponent(componentOrSource, source);
        } catch (e) {
            return null;
        }
    }

    @bind
    getComponent(componentOrSource: string | AnyLazySource, source?: AnyLazySource): AnyComponentType {
        let component: string;

        switch (typeof componentOrSource) {
            case 'string':
                component = componentOrSource;
                break;
            case 'object':
                component = (componentOrSource as AnyLazySource).component as string;
                source = componentOrSource;
                break;
            default:
                component = '';
        }

        if (!component) {
            throw new ConfigurationError('Component not specified in getComponent');
        }
        if (source) {
            const rArguments = toRegistryArguments(component, source),
                C = Registry.get(rArguments) as AnyComponentType;
            if (C) {
                return C;
            } else {
                const promise = this.loadComponent(component, source as AnyLazySource);
                if (promise.isRejected()) {
                    console.log(`Error loading component ${component}`, source, promise.reason());
                    throw promise.reason();
                } else if (promise.isFulfilled()) {
                    return promise.value();
                } else {
                    // console.log("Throw promise", promise, {
                    //     rejected: promise.isRejected(),
                    //     resolved: promise.isFulfilled(),
                    //     pending: promise.isPending()
                    // });
                    throw promise;
                }
            }
        } else {
            const C = (Registry.get(component) as AnyComponentType) || get(global as any, component);
            if (C) {
                return C;
            } else if (component[0] === component[0].toLowerCase()) {
                return component;
            } else {
                throw new ConfigurationError(`Component '${component}' not registered, not available in global and no dynamic source specified.`);
            }
        }
    }

    Lazy: FunctionComponent<ILazyProps> = Lazy.bind(this);
    Component: FunctionComponent<ILazyProps> = Component.bind(this);
    ReactRegistry: any = ReactRegistry;
}

const registry = new WebfrontRegistry();
ReactRegistry.Registry.register(registry.Lazy, { id: 'Lazy' });
ReactRegistry.Registry.register(registry.Component, { id: 'Component' });

merge(global, { Component: registry.Component, Lazy: registry.Lazy, getComponent: registry.getComponent, registry });

export default registry;

// export const {getComponent, registerComponent, registerComponents} = defaultRegistry;
// export default defaultRegistry;
//
// (global as any).componentRegistry = (global as any).componentRegistry  || defaultRegistry;
// (global as any).getComponent = (global as any).getComponent  || getComponent;

// (global as any).componentRegistry = (global as any).componentRegistry  || defaultRegistry;
// (global as any).getComponent = (global as any).getComponent  || getComponent;
