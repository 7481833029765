import Class from 'classnames';
import * as React from 'react';
import { monthColoursDark } from './Constants';
import styles from './DatePicker.scss';

interface ISidebarProps {
  date: any;
  monthOnly?: boolean;
}

/**
 * A sidebar of the datepicker that neatly displays information on the current date
 */
export default class SideBar extends React.Component<ISidebarProps, never> {
  public render(): JSX.Element {
    const { date, monthOnly } = this.props;
    return (
      <div className={Class(styles.component, styles.sidebar)} style={{ background: monthColoursDark[date.month()] }}>
        <div className={styles.year}>{date.format('YYYY')}</div>
        {!monthOnly && <div className={styles.day}>{date.format('ddd')},</div>}
        <div className={styles.date}>{date.format(monthOnly ? 'MMM' : 'MMM D')}</div>
        <div className={styles.timezone}>GMT {date.format('Z')}</div>
      </div>
    );
  }
}
