import React, { useMemo } from "react";
import {Registry} from "react-registry";
import { InvalidDataError } from "../../Errors";
import TextEditor, { ITextEditorProps } from "./TextEditor";

// var kendoBindingGearsObject, out$ = typeof exports != 'undefined' && exports || this;
import { parse, stringify } from "json5";
import { computed, IComputedValue } from "mobx";
import { notification } from "../../helpers/Notification";

function unknownToString(value: any, expanded: boolean = true): string {
    if ($.isEmptyObject(value)) {
        value = "";
    }
    if (typeof value === "string") {
        return value;
    } else {
        return stringify(value, undefined, expanded ? "  " : undefined);
    }
}

function stringToObject(value: string): object | null {
    if (!value) {
        return null;
    }
    try {
        return parse(value);
    } catch (e) {
        window.value = value;
        notification({
            title: "Invalid Input",
            content: "<i class='fa fa-warn' id='alertboxsmall'></i> <i>Could not parse input</i>",
            messageType: "warning",
            displayType: "bigBox",
            timeout: 4000
        });
        // throw new InvalidDataError({ message: "Invalid Input: \n" + e.message });
        return {};
    }
}

function mapComputed<T1, T2>(existing: IComputedValue<T1>, mapGet: (v: T1) => T2, mapSet?: (v: T2) => T1): IComputedValue<T2> {
    return computed(
        () => mapGet(existing.get()),
        {
            set: (mapSet ? (v: T2) => existing.set(mapSet(v)) : undefined)
        });
}


const JsonEditor: React.FunctionComponent<ITextEditorProps> = (props: ITextEditorProps) => {
    const { value } = props;
    const mappedValue = useMemo(() => mapComputed(value, unknownToString, stringToObject), [value]);
    return TextEditor({ ...props, value: mappedValue });
};
JsonEditor.displayName = "JsonEditor";


Registry.register(JsonEditor, {id: "JsonEditor"});
export default JsonEditor;
