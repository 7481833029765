import * as FileSaver from 'filesaver.js-npm/FileSaver';
import { extendObservable, observable } from 'mobx';
import formatter from '../util/formatter';
import { Alignment, IColumn, ISmartGridProps, ISpacer } from './SmartGrid';

/**
 * Builder that helps structure columns and data in a SmartGrid
 */
export default class SmartGridBuilder {
  private gridData: any[] = [];
  private columns: Array<IColumn | ISpacer> = [];

  private theme = 'theme-default';
  private rowTotal = false;
  private group?: string;
  private sort?: string;
  private sortOrder?: 'asc' | 'desc';

  /**
   * Adds a new column to the grid, defaults to number type if no options are added
   */
  public addColumn(name: IColumn['name'], field: IColumn['field'], options: Partial<IColumn> = {}) {
    this.columns.push({
      name,
      field,
      type: 'number',
      alignment: !options.type || options.type === 'currency' || options.type === 'number' ? 'right' : 'left',
      ...options,
    });
    return this;
  }

  /**
   * Adds an empty column spacer
   */
  public addSpacer() {
    this.columns.push({
      spacer: true,
    });
    return this;
  }

  /**
   * Adds a row total to the grid after it is created
   */
  public createRowTotal() {
    this.rowTotal = true;
    return this;
  }

  public setTheme(theme: string) {
    this.theme = theme;
    return this;
  }

  /**
   * Sets the initial sorting on the grid
   * @param sort Field to sort
   * @param sortOrder Sort order, valid orders are 'asc' and 'desc (asc is default if not provided)
   */
  public setSort(sort: string, sortOrder?: 'asc' | 'desc') {
    this.sort = sort;
    this.sortOrder = sortOrder;
    return this;
  }

  /**
   * Sets the initial grouping on the grid
   * @param group Field to group by
   */
  public setGroup(group: string) {
    this.group = group;
    return this;
  }

  private randomString() {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 ';
    const strLen = 4 + Math.random() * 10;
    let output = '';
    for (let i = 0; i < strLen; i++) {
      output += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return output;
  }

  /**
   * Builds the final boject for use with a SmartGrid. This object also gets CSV generating functions (see exportToCSV and downloadCSV)
   * @param generateRows - Number of 'dummy' test rows to generate, only usefull for debugging smart layout without requiring data.
   */
  public build(generateRows?: number): ISmartGridProps {
    if (this.columns.length === 0) {
      throw new Error('Unable to build smart grid, requries at least one column');
    }
    if (generateRows) {
      this.gridData = [];
      for (let i = 0; i < generateRows; i++) {
        const fields: any = {};
        for (const column of this.columns) {
          if (!column.spacer) {
            switch (column.type) {
              case 'string':
                fields[column.field] = this.randomString();
                break;
              default:
                fields[column.field] = Math.round(Math.random() * 1000);
            }
          }
        }
        this.gridData.push(fields);
      }
    }

    const grid = observable({
      columns: this.columns,
      data: this.gridData,
      grid: null,
      group: this.group,
      rowTotal: this.rowTotal,
      smartgrid: null,
      sort: this.sort,
      sortOrder: this.sortOrder,
      theme: this.theme,
    });
    extendObservable(grid, {
      downloadCSV: downloadCSV.bind(grid),
      exportToCSV: exportToCSV.bind(grid),
    });
    grid.grid = grid;
    return grid;
  }
}

function downloadCSV(filename: string): void {
  const csv = this.exportToCSV();
  const file = new Blob([csv], {
    type: 'data/text',
  });
  FileSaver.saveAs(file, `${filename}.csv`);
}

function exportToCSV(): string {
  let gridString = '';
  const height = this.data.length;
  for (const column of this.columns) {
    if (column.name) {
      gridString += column.name + ',';
    }
  }
  gridString += '\n';

  let rowNum = 0;
  for (const rowData of this.data) {
    for (const column of this.columns) {
      if (column.name) {
        let value = rowData[column.field];
        if (typeof value === 'object' && value !== null) {
          let canExport = false;
          if (value.export) {
            value = value.export(value);
            canExport = true;
          } else if (column.export) {
            value = column.export(value);
            canExport = true;
          }
          if (!canExport) {
            throw new Error(`Cannot convert object to CSV value (column: ${column.field}, row: ${rowNum})`);
          }
        }
        if (column.type === 'currency') {
          value = formatter(value, 'currency');
        }
        gridString += `"${value || ''}",`;
      }
    }
    rowNum++;
    gridString += '\n';
  }

  return gridString;
}
