import * as moment from "moment-timezone";
import { iconHtml } from "./fa";

export function datetimeTooltip(format?: string | any, options: {format?: string, tooltip_format?: string} = {}): (time: Date) => string {
    if (format != null) {
        if (format.format != null) {
            options = format;
        } else {
            options.format = format;
        }
    }
    options.format == null && (options.format = "h:mm A");
    options.tooltip_format == null && (options.tooltip_format = "DD MMMM YYYY h:mm:ss A");
    return function timeFormatter(time: Date): string {
        if (time == null) {
            return "";
        }
        return (
            "<span data-toggle=\"tooltip\" title=\"" +
            moment(time).format(options.tooltip_format) +
            "\">" +
            moment(time).format(options.format) +
            "</span>"
        );
    };
}

const timeWithTooltip = datetimeTooltip();
const shortDatetime = datetimeTooltip("D MMM h:mm A");
const shortDatePlus = datetimeTooltip("D MMM");
const shortDate = datetimeTooltip("D MMM", { tooltip_format: "D MMMM YYYY" });
const simpleDate = datetimeTooltip({
    format: "D MMM YYYY",
    tooltip_format: "D MMMM YYYY"
});
const shortMonth = datetimeTooltip({
    format: "MMM YYYY",
    tooltip_format: "MMMM YYYY"
});
export { timeWithTooltip, shortDatetime, shortDatePlus, shortDate, simpleDate, shortMonth };

const DateCategoryMatch = /^(Today|Yesterday|Last)/;
const DateCategoryColors: {[key: string]: string} = {
    Today: 'txt-color-red',
    Yesterday: 'txt-color-amber',
    Last: 'txt-color-blue',
    Month: 'txt-color-green',
    Old: 'txt-color-green txt-color-lighten-2',
};

const CalendarIcon = 'far fa-calendar-alt fa-fw fa-lg ';

export function recentAtIconClass(updatedAt: Date): string {
    const matchCategory = moment(updatedAt).calendar().match(DateCategoryMatch);
    let category: string = 'Old';
    if (matchCategory) {
        category = matchCategory[0];
    } else if  (moment.duration(moment().diff(moment(updatedAt).subtract(1, 'M'))).days() < 30) {
        category = 'Month';
    } else {
        category = 'Old';
    }
    return CalendarIcon + DateCategoryColors[category];
}

export function recentAtTooltipContent(updatedAt: Date): string {
    if(!updatedAt) {return '';}
    // const iconClass = recentAtIcon(updatedAt);

    return moment(updatedAt).format('[<b>]Mo MMMM YYYY[</b><br/>]h:mma');
}

export function recentAtIcon(updatedAt: Date): string {
    if(!updatedAt) {return '';}
    // const iconClass = recentAtIcon(updatedAt);
    const iconClass = recentAtIconClass(updatedAt);
    return iconHtml(iconClass);
}

export function recentAtIconTooltip(updatedAt?: Date): string {
   if(!updatedAt) {return '';}
   const iconClass = recentAtIcon(updatedAt);
   return `<span data-rel="popover" data-placement="auto-top" data-animation="pop" data-trigger="hover" data-content="${moment(updatedAt).format('[<b>]Mo MMMM YYYY[</b><br/>]h:mma')}">${iconHtml(iconClass)}</span>`;
}
