// import gearsState from "../GearsState/index";
// import {MultiSelect} from "../GridMultiSelect";
import _ from 'lodash';
// import { decorate, observable } from 'mobx';
import { ConfigurationError } from '../Errors';
import { ensureNestedObservable } from '../helpers/setNestedObservable';
import { toBooleanPropertyDescriptor } from '../helpers/convertProperties';
import { Transition } from '../transitions';
import { parseConstraints } from './Constraints';
import { IModelSpec, IModelType } from './ModelType';
import * as Transitions from '../transitions';
import validate from '../helpers/validate';

import inspect from 'inspect.macro';

// if (typeof kendo === 'undefined') {
//     if(!global.window) {
//         global.window = global;
//     }
//     if(!global.window.document) {
//         global.window.document = {};
//     }
//     if(!global.window.document.createElement) {
//         global.window.document.createElement = _.noop;
//     }
//     const kendo = require('@progress/kendo-ui/js/kendo.core');
//     global.kendo = kendo;
//     kendo.data = {};
//     const data = require('@progress/kendo-ui/js/kendo.data');
//     global.data = data;
// }

declare const Gears: any;
let observableObject;
let kendoModel: typeof kendo.data.Model;
let kendoModelInit: any = Object.assign;
let observableObjectInit;
let parseDate: (s: string) => Date;
let kendoDefined = false;

const gearsState: any = (global as any).gearsState;

if (typeof kendo === 'undefined') {
    observableObject = {
        extend(it: any): any {
            return it;
        },
    };
    kendoModel = class FakeKendo {
        constructor(d: any) {
            Object.assign(this, d);
        }
        static define(base: any, options: any = {}): any {
            // class FakeModel extends base {
            //     constructor(data: any) {
            //         super(data);
            //     }
            // }
            const f = function(data: any) {
                base.call(this, data);
            };
            f.prototype = Object.create(base.prototype);
            options.idField = options.id || 'id';
            options.id = undefined;
            Object.assign(f.prototype, options);
            // f.prototype.fields = options.fields;
            // f.prototype.idField = options.id || 'id';
            return f;
        }
        static extend(it: any): any {
            return it;
        }
        get(p: any, d: any): any {
            if (d === 'this' || !d) {
                return this;
            }
            return _.get(this, p, d);
        }
        set(p: any, v: any): any {
            return _.set(this, p, v);
        }
        bind(): any {
           return null;
        }
        on(): any {
            return null;
        }
        isNew() {
            return false;
        }
        init() {}
        toJSON() {
            const o = {};
            return _.forOwn(this, (v, k) => (o[k] = v));
        }
    } as any;
    parseDate = s => new Date(s);
} else {
    kendoDefined = true;
    kendoModel = kendo.data.Model;
    kendoModelInit = kendoModel.fn.init;
    observableObject = kendo.data.ObservableObject;
    observableObjectInit = observableObject.fn.init;
    parseDate = kendo.parseDate;
}

function parseGearsBoolean(it: string | boolean | null): boolean | null {
    switch (it) {
        case 'false':
            return false;
        case 'true':
            return true;
        case '':
        case null:
            return null;
        default:
            return !!it;
    }
}

// kendoModel.parseGearsBoolean = parseGearsBoolean;

const dateType = {
    defaultValue: null,
    parse(it: string): Date | string {
        let e;
        try {
            return parseDate(it);
        } catch (e$) {
            e = e$;
            return it;
        }
    },
    type: 'date',
};

const numberType = {
    defaultValue: null,
    parse: typeof kendo !== 'undefined' && kendo !== null ? kendo.parseFloat : parseFloat,
};

const stringType = {
    defaultValue: '',
    parse(value: string | number | null | undefined): string | null {
        if (value != null) {
            return value + '';
        } else {
            return null;
        }
    },
};

const arrayType = {
    defaultValue(): any[] {
        return [];
    },
    parse(it: any): any[] {
        if (typeof it === 'object') {
            return it;
        } else {
            return [];
        }
    },
};

const fieldTypes = {
    boolean: {
        defaultValue: null,
        parse: parseGearsBoolean,
    },
    date: dateType,
    date_month: dateType,
    date_recent: dateType,
    date_year: dateType,
    datetime: dateType,
    default: {
        defaultValue: null,
        parse(it: any): any {
            return it;
        },
    },
    interval: stringType,
    number: numberType,
    object: {
        defaultValue(): object {
            return {};
        },
        parse(it: any): object {
            if (typeof it === 'object') {
                return it;
            } else {
                return {};
            }
        },
    },
    rating: numberType,
    string: stringType,
    tags: arrayType,
    tags_string: stringType,
};

(kendoModel as any).fieldTypes = fieldTypes;

/**
 * TODO: Properly name these variables
 */
function setupGearsDefaults(model: any): void {
    if (!model.fields) {
        model.fields = {};
    }
    const { fields } = model;

    if (fields.webfront_relations == null) {
        fields.webfront_relations = { defaultValue: {} };
    }

    model._all_fields = _.clone(model.fields);
    model.nested_fields == null && (model.nested_fields = {});
    function setupFieldDefault(f, g: any): void {
        const type = f.type || 'default';
        const conf = (fieldTypes as any)[type];
        if (!conf) {
            throw new ConfigurationError(`${f.type} is not a valid field type. Model: ${model.gears_model_name}`);
        }
        _.defaults(f, conf);
        if (conf.type) {
            f.type = conf.type; // Force to kendo type
        }
        const when_null = f.when_null;
        if (g.indexOf('.') > 0) {
            model.nested_fields[g] = f;
            delete model.fields[g];
        }
        const { defaultValue, parse } = f;

        if (f.gearsInit == null) {
            f.gearsInit = function autoFieldInit(): void {
                const oldValue = _.get(this, g) as any;
                let value = oldValue;
                if (value == null) {
                    if (when_null) {
                        value = when_null;
                    } else if (defaultValue && this.isNew()) {
                        value = defaultValue;
                    }
                    if (typeof value === 'function') {
                        value = value.call(this);
                    }
                }
                if (parse) {
                    value = parse(value);
                }
                if (oldValue !== value) {
                    try {
                        this.set(g, value);
                    } catch (e) {
                        console.log(`Error setting '${g}' (missing parent object field?)`, value);
                        ensureNestedObservable(this, g);
                        _.set(this, g, value);
                    }
                }
            };
        }
    }
    Object.keys(model.fields)
        .sort()
        .forEach((fieldName: string) => {
            setupFieldDefault(model.fields[fieldName], fieldName);
        });
}

function validatorCollect(result: any, field: { validation?: any }, fieldName: string): any {
    result[fieldName] = field.validation ? parseConstraints(field.validation) : result[fieldName];
    return result;
}

function initCollect(result: any, field: { gearsInit?: any }, fieldName: string): any {
    result[fieldName] = field.gearsInit ? field.gearsInit : result[fieldName];
    return result;
}

const emptyLookup = {
    icon: 'icon-blank',
    id: '&nbsp;&nbsp;&nbsp;&nbsp;',
};

function valueFindFunction(lookupName: string, field: string, lookupId = 'id'): any {
    return function valueFind(): any {
        const id = this.get(field);
        inspect('ValueFindFunction', lookupName, field, lookupId, id);
        return _.find(gearsState.lookups.lists.get(lookupName), (it: any) => id == it[lookupId]);
    };
}

function lookupTemplateFunction(propName: string, options: any): () => any {
    return function lookupTemplate(): any {
        return Gears.formatLookup(this[propName] || emptyLookup, options);
    };
}

function isCustomProperty(view: any): boolean {
    return typeof view === 'object' && (view.set != null || view.get != null || view.value != null);
}

function setupCustomProperties(model: any, options: any): void {
    const customProperties = _.pickBy(options, isCustomProperty);
    model.prototype._customProperties = customProperties;
    Object.defineProperties(model.prototype, customProperties);
}

function setupViews(model: any, views: any): void {
    Object.assign(model.prototype, _.omitBy(views, isCustomProperty));
}

function setupShowProperties(model: any): void {
    const allFields = model.prototype._all_fields;
    _.each(allFields, (config: any, field: string) => {
        const { editableFunction } = config;
        const visible = config.form && config.form.visible;
        // const camelizedField = camelize(field.replace(/\./g,'_'));
        if (editableFunction != null) {
            Object.defineProperty(model.prototype, `enable_${field}`, toBooleanPropertyDescriptor(editableFunction));
        }
        if (visible != null) {
            Object.defineProperty(model.prototype, `show_${field}`, toBooleanPropertyDescriptor(visible));
        }
    });
}

function setupLookupProperties(model: any): void {
    const allFields = model.prototype._all_fields;
    _.each(allFields, (config: any, field: string) => {
        const { lookup } = config;
        if (lookup) {
            const fl = field + '_lookup';
            const options = lookup.options || {};
            switch (lookup.type) {
                case 'inline':
                    const dataPath = lookup.data_path || 'webfront_relations.' + lookup.name;
                    Object.defineProperty(model.prototype, fl, {
                        get: function getPath(): any {
                            return this.get(dataPath);
                        },
                        set: function setPath(val: any): any {
                            return this.set(dataPath, val);
                        },
                    });
                    break;
                case 'values':
                    const getter = valueFindFunction(lookup.source, field, config.lookup.id);
                    Object.defineProperty(model.prototype, fl, {
                        get: getter,
                    });
            }
            const valgetter = lookupTemplateFunction(fl, options);
            Object.defineProperty(model.prototype, field + '_template', {
                get: valgetter,
            });
        }
    });
}

function setupGearsInitializers(model: any): void {
    const inits = model._gears_initializers || (model._gears_initializers = {});
    if (model.views.gearsInit != null) {
        inits.gearsInit = model.views.gearsInit;
    }
    _.reduce(model.fields, initCollect, inits);
    _.reduce(model.nested_fields, initCollect, inits);
    if (_.some(model.fields, 'onChange')) {
        inits._onChange = function initOnChange(): void {
            this.bind('change', this._onFieldChanged);
        };
    }
}

function setupGearsValidators(model) {
    if (!model._validators) {
        model._validators = {};
    }
    const validators = model._validators;
    if (model.modelValidator != null) {
        validators.modelValidator = model.modelValidator;
    }
    _(model.fields).reduce(validatorCollect, validators);
    _(model.nested_fields).reduce(validatorCollect, validators);
}

export class Model extends kendoModel {
    public static modelName: string;
    [key: string]: any;
    public _multiSelect?: any;

    // public [key: string]: any;
    // private _gears_initializers: { [key: string]: () => any };
    // disable because new babel initializes to undefined.

    constructor(data: any) {
        try{
            super(data);
        }catch(e){
            console.log("Error constructing model", e, data, this);
        }finally{
            this.init(data);
        }
        // throw new Error(`DATA: ${JSON.stringify(data)}`);
    }

    public static define(base: typeof kendo.data.Model | IModelSpec, options?: IModelSpec): IModelType<any> {
        if (options == null) {
            options = base as IModelSpec;
            base = Model as any;
        }
        options = _.cloneDeep(options);
        setupGearsDefaults(options);
        setupGearsInitializers(options);
        setupGearsValidators(options);
        const newModel = kendoModel.define.call(this, base, options);
        newModel.prototype.modelType = newModel;
        const { views } = options;
        setupViews(newModel, views);
        setupShowProperties(newModel);
        setupCustomProperties(newModel, options.views);
        setupLookupProperties(newModel || this);
        // kendoDefined &&
        //     decorate(
        //         newModel,
        //         _.mapValues(options.fields, () => observable),
        //     );
        return newModel;
    }

    public init(data: any): void {
        // extendObservable(this, this._customProperties);
        kendoModelInit.call(this, data);
        if (this._gears_initializers) {
            _.each(this._gears_initializers, it => it.call(this));
        }
        this.set('_rollthrough', {});
        this.dirty = false;
        this.dirtyFields = false;
    }

    public enableRecord(): boolean {
        return true;
    }

    public disableRecord() {
        return !this.enableRecord();
    }

    public enableField(fieldName: string, aDefault?: boolean) {
        return this._enableField(fieldName, aDefault);
    }

    public showField(fieldName: string, aDefault?: boolean) {
        return this._showField(fieldName, aDefault);
    }

    public accept(data: any): void {
        const parent = () => this;
        _.forOwn(data, (value, field) => {
            if (field.charAt(0) !== '_') {
                value = this.wrap(value, field, parent);
            }
            this._set(field, this._parse(field, value));
        });
        if (this.idField) {
            this.id = this.get(this.idField);
        }
        this.dirty = false;
        this.dirtyFields = {};
    }

    public setAll(obj: any) {
        let key,
            value,
            own$ = {}.hasOwnProperty,
            results$ = [];
        for (key in obj) {
            if (own$.call(obj, key)) {
                {
                    value = obj[key];
                    results$.push(this.set(key, value));
                }
            }
        }
        return results$;
    }

    public stringify() {
        return JSON.stringify(this);
    }

    public fetchLookup(field: string, lookup_name: string) {
        const id = this.get(field);
        return _.find(gearsState.lookups.lists.get(lookup_name), it => id === it.id);
    }

    public displayName() {
        return '#' + this.get('id');
    }

    public reportId() {
        return this.get('id');
    }

    public reportDisplayName() {
        return this.displayName();
    }

    public get gearsSelected(): boolean {
        return !!(this._multiSelect && this._multiSelect.selectedIds.has(this.id));
    }

    public transitionPermitted(transition: Transition, role: string): boolean {
        return Transitions.isPermitted(transition, role, this);
    }

    public availableTransitions(transitions: Transition[], role: string): Transition[] {
        const at = Transitions.availableTransitions(transitions, role, this);
        // const predicates = _.map(transitions, t => Transitions.toFilterSpec(t));
        // console.log('predicates', JSON.stringify(predicates, null, '  '));
        // console.log('valid role?', _.map(transitions, t => Transitions.validRole(t.roles, role)));
        // return [{model: this, output: at, predicates}];
        return at;
    }

    public get validationErrors(): any {
        return validate(this, this._validators);
    }

    public get isValid(): boolean {
        return !this.validationErrors;
    }

    public gearsSelectedTemplate(): string {
        const selected = this.gearsSelected;
        const checked = selected ? 'checked' : '';
        const rowclass = selected ? 'gears-row-class gears-multi-selected' : '';
        return `<div class='checkbox ${rowclass}"'><label><input class='checkbox multi-select style-0' type='checkbox' ${checked}/><span></span></label></div>`;
    }

    protected _showField(fieldName: string, showDefault?: boolean): boolean {
        this.get('');
        fieldName = fieldName.replace(/%%/g, '.'); // kendo template backwards compatibility
        const show = this[`show_${fieldName}`];
        return show == null ? (showDefault == null ? true : showDefault) : show;
    }

    protected _enableField(fieldName: string, enableDefault?: boolean): boolean {
        this.get('');
        fieldName = fieldName.replace(/%%/g, '.'); // kendo template backwards compatibility
        const show = this[`enable_${fieldName}`];
        return show == null ? (enableDefault == null ? true : enableDefault) : show;
    }

    protected _onFieldChanged(e: { field: string }): void {
        const field = this._all_fields[e.field];
        const onChange = field && field.onChange;
        if (onChange) {
            onChange.call(this, e);
        }
    }
}

export function isModelInstance<T extends Model>(i: T | any): i is T {
    return i instanceof Model;
}
