import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import styles from './ActionTabs.scss';

interface IActionTabsProps extends React.HTMLProps<HTMLElement> {
  defaultTab?: number;
  smartScroll?: boolean;
}

interface IActionTabsState {
  tab: number;
}

function isArrayOrMobXArray(arg: any): arg is any[] {
  return arg.splice !== undefined;
}

export default class ActionTabs extends React.PureComponent<IActionTabsProps, IActionTabsState> {
  private tabControllerRef: HTMLUListElement | null;
  private lastScroll = 0;

  constructor(props: IActionTabsProps) {
    super(props);

    this.state = { tab: props.defaultTab || 0 };
  }

  public componentDidMount(): void {
    if (this.props.smartScroll) {
      document.addEventListener('scroll', this.onScroll);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.smartScroll) {
      document.removeEventListener('scroll', this.onScroll);
    }
  }

  private get topOffset(): number {
    return screen.width >= 600 ? 64 : 56;
  }

  @bind
  private onScroll(): void {
    if (this.tabControllerRef) {
      if (window.scrollY <= this.lastScroll) {
        const posY = this.tabControllerRef.getBoundingClientRect().top;
        if (0 >= posY + this.tabControllerRef.clientHeight) {
          this.tabControllerRef.style.top = `${Math.max(0, window.scrollY - this.topOffset - this.tabControllerRef.clientHeight)}px`;
        }
        if (posY >= this.topOffset) {
          this.tabControllerRef.style.position = 'sticky';
          this.tabControllerRef.style.top = `${this.topOffset}px`;
        }
      } else {
        if (this.tabControllerRef.style.position) {
          this.tabControllerRef.style.position = null;
          this.tabControllerRef.style.top = `${this.lastScroll}px`;
        }
      }
      this.lastScroll = window.scrollY;
    }
  }

  public render(): JSX.Element {
    const { children, smartScroll } = this.props;
    const realChildren = (children ? (isArrayOrMobXArray(children) ? children : [children]) : []) as Array<React.ReactElement<any>>;
    return (
      <div>
        <ul ref={ref => (this.tabControllerRef = ref)} className={Class(styles['tab-controller'], { [styles['smart-scroll']]: smartScroll })}>
          {realChildren.map((item, index) => (
            <li
              key={index}
              className={Class(styles.tab, {
                [styles.active]: index === this.state.tab,
              })}
              onClick={() => {
                this.setState({ tab: index });
              }}
              tabIndex={0}
              role="link"
            >
              {item.props.title}
            </li>
          ))}
        </ul>
        {realChildren[this.state.tab]}
      </div>
    );
  }
}
