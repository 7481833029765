import Class from 'classnames';
import { bind } from 'decko';
import { observer } from 'mobx-react';
import * as React from 'react';
import styles from './Range.scss';

interface IRangeProps {
  observable: any;
  value: string;
  min: number | string;
  max: number | string;
  reversed?: boolean;
  step?: number | string;
  label?: string;
  disabled?: boolean;
  alwaysActive?: boolean;
  showValueTooltip?: boolean;
  showValueLabel?: boolean;
  onChange?: (value: number) => void;
  valueDisplay?: (value: string) => string;
}

interface IRangeState {
  clicking: boolean;
  focused: boolean;
}

@observer
export default class Range extends React.Component<IRangeProps, IRangeState> {
  private inputRef: HTMLInputElement | null;

  constructor(props: IRangeProps) {
    super(props);

    this.state = { clicking: false, focused: false };
  }

  @bind
  private setClick(): void {
    this.setState({ clicking: true, focused: true });
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  @bind
  private unsetClick(): void {
    this.setState({ clicking: false });
  }

  @bind
  private onFocus(): void {
    this.setState({ focused: true });
  }

  @bind
  private onBlur(): void {
    this.setState({ focused: false });
  }

  @bind
  private onChange(e: any): void {
    this.props.observable[this.props.value] = parseInt(e.currentTarget.value, 10);
    if (this.props.onChange) {
      this.props.onChange(parseInt(e.currentTarget.value, 10));
    }
  }

  public render(): JSX.Element {
    const { observable, value, valueDisplay, min, max, step, label, reversed, alwaysActive, showValueLabel, showValueTooltip, disabled, onChange, ...other } = this.props;
    const minAsNumber = typeof min === 'string' ? parseFloat(min) : min;
    const maxAsNumber = typeof max === 'string' ? parseFloat(max) : max;
    const realValue = observable[value];
    const maxOffset = showValueLabel ? 64 : 16;
    const percentage = (realValue - minAsNumber) / (maxAsNumber - minAsNumber);
    const yPos = `calc(${Math.max(0, Math.min(100, percentage * 100))}% - ${Math.max(0, Math.min(maxOffset, percentage * maxOffset))}px)`;
    const width = reversed
      ? {
          left: yPos,
          width: `calc(${Math.max(0, Math.min(100, (1 - percentage) * 100))}% - ${Math.max(0, Math.min(maxOffset, (1 - percentage) * maxOffset))}px)`,
        }
      : { width: yPos };
    return (
      <div className={Class(styles['range-container'], 'range-container')}>
        <span className={Class(styles.label, 'label')}>{label}</span>
        <span
          className={Class(styles['back-plane'], 'back-plane', {
            [styles['has-value-label']]: showValueLabel,
            [styles.disabled]: disabled,
            disabled,
            'has-value-label': showValueLabel,
          })}
        />
        <span
          className={Class(styles['active-plane'], 'active-plane', {
            [styles.disabled]: disabled,
            disabled,
          })}
          style={width}
        />
        <input
          aria-label={label}
          onMouseDown={this.setClick}
          onMouseUp={this.unsetClick}
          onTouchStart={this.setClick}
          onTouchEnd={this.unsetClick}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          ref={ref => (this.inputRef = ref)}
          type="range"
          min={minAsNumber}
          max={maxAsNumber}
          step={step ? step.toString() : undefined}
          disabled={disabled}
          value={realValue}
          className={Class(styles.input, 'input', {
            [styles['has-value-label']]: showValueLabel,
            'has-value-label': showValueLabel,
          })}
          onChange={this.onChange}
        />
        {showValueTooltip && (
          <span
            className={Class(styles.tooltip, 'tooltip', {
              [styles.focus]: this.state.focused,
              [styles['has-value']]: (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
              focus: this.state.focused,
              'has-value': (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
            })}
            style={{ left: yPos }}
          >
            <span>{valueDisplay ? valueDisplay(realValue) : realValue}</span>
          </span>
        )}
        {showValueLabel && (
          <span
            className={Class(styles['value-label'], 'value-label', {
              [styles.focus]: this.state.focused,
              [styles['has-value']]: (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
              [styles.disabled]: disabled,
              disabled,
              focus: this.state.focused,
              'has-value': (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
            })}
          >
            <span>{valueDisplay ? valueDisplay(realValue) : realValue}</span>
          </span>
        )}
        <span
          className={Class(styles.pointer, 'pointer', {
            [styles['has-value']]: (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
            [styles.active]: this.state.clicking,
            [styles.focus]: this.state.focused,
            [styles.disabled]: disabled,
            active: this.state.clicking,
            disabled,
            focus: this.state.focused,
            'has-value': (reversed ? realValue < maxAsNumber : realValue > minAsNumber) || alwaysActive,
          })}
          style={{ left: yPos }}
        />
      </div>
    );
  }
}
