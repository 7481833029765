import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, NUMBER_FORMATS, registerCalculationType } from './Calculator';

export interface IFlatRateOptions extends ICalculationOptions {
    computation_type: 'flat_rate';
    percentage: string | number;
}

export default class FlatRate extends Calculator {
    private percentage: any;
    constructor(options: IFlatRateOptions) {
        super(options);
        this.percentage = numeral(options.percentage + '%');
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> | number {
        const value = inputs.baseValue;
        const rawResult = this.percentage.value() * value;
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = `${value} * ${this.percentage.format(NUMBER_FORMATS.percentFormat)} = ${formattedResult}`;
        return {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            inputValue: numeral(value).format(),
            percentage: this.percentage.format(NUMBER_FORMATS.percentFormat),
            rawResult,
            result: result.value(),
            resultUnit: '$',
            rounding: this.roundFormula('' + rawResult),
        };
    }
}

registerCalculationType('flat_rate', FlatRate);
registerCalculationType('fixed_rate', FlatRate);
