import Class from 'classnames';
import { bind } from 'decko';
import { computed } from 'mobx';
import { inject } from 'mobx-react';
import * as React from 'react';
import styles from './Sidebar.scss';

interface ISidebarProps extends React.HTMLProps<HTMLDivElement> {
  open: boolean;
  rhs?: boolean;
  hasActionBar?: boolean;
  store?: any;
  onBackdropClicked?: () => void;
  /** Let user open by dragging with mobile touch events */
  mobileOpenable?: boolean;
}

const isMobile = window.orientation !== undefined;
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !global.MSStream;
const standAlone = navigator.standalone !== undefined;

/**
 * A mobile friendly navigation pane / sidebar component that doesn't rely on ReactMDL
 */
@inject((props, stores, context) => props)
export default class Sidebar extends React.PureComponent<ISidebarProps, never> {
  private backdropRef: HTMLDivElement | null;
  private sidebarRef: HTMLDivElement | null;
  private previousOpen?: boolean;
  private selfOpened = false;
  private isSelfOpening = false;
  private lastTouchX = 0;
  private lastTouchVelocity = 0;
  private startTouchX = 0;

  constructor(props: ISidebarProps) {
    super(props);

    this.previousOpen = props.open;
  }

  public componentDidMount(): void {
    if (!this.props.rhs && this.canDragOpen) {
      document.body.addEventListener('touchstart', this.onTouchStart, { passive: true });
      document.body.addEventListener('touchmove', this.onTouchMove, { passive: true });
      document.body.addEventListener('touchend', this.onTouchEnd, { passive: true });
    }
    if (this.open) {
      document.body.classList.add('no-scroll');
      document.addEventListener('keypress', this.onKeyboard);
    }
  }

  @bind
  private onTouchStart(e: TouchEvent): void {
    if (!this.sidebarRef || !this.backdropRef) {
      return;
    }
    if (e.touches[0].clientX <= 16 && !this.isSelfOpening) {
      this.isSelfOpening = true;
      this.lastTouchX = e.touches[0].clientX;
      this.sidebarRef.style.visibility = 'visible';
      this.sidebarRef.style.boxShadow = '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.20)';
      this.sidebarRef.style.transition = 'box-shadow 400ms';
      this.backdropRef.style.transition = 'unset';
    }
    if (this.open) {
      this.lastTouchX = e.touches[0].clientX;
      this.startTouchX = e.touches[0].clientX;
      this.lastTouchVelocity = 0;
      this.sidebarRef.style.transition = 'box-shadow 400ms';
      this.backdropRef.style.transition = 'unset';
    }
  }

  @bind
  private onTouchMove(e: TouchEvent): void {
    if (!this.sidebarRef || !this.backdropRef) {
      return;
    }
    if (this.isSelfOpening) {
      // We are attempting to drag open the sidebar
      const percent = Math.min(1, e.touches[0].clientX / this.sidebarRef.clientWidth);
      this.lastTouchVelocity = e.touches[0].clientX - this.lastTouchX;
      this.lastTouchX = e.touches[0].clientX;
      this.backdropRef.style.opacity = (percent * 0.25).toString();
      const left = Math.min(e.touches[0].clientX - this.sidebarRef.clientWidth, 0);
      this.sidebarRef.style.transform = `translateX(${left}px)`;
    } else if (this.open) {
      // We are attempting to drag close the sidebar
      this.lastTouchVelocity = e.touches[0].clientX - this.lastTouchX;
      this.lastTouchX = e.touches[0].clientX;
      const left = Math.min(e.touches[0].clientX - this.startTouchX, 0);
      this.sidebarRef.style.transform = `translateX(${left}px)`;
    }
  }

  @bind
  private onTouchEnd(): void {
    if (!this.sidebarRef || !this.backdropRef) {
      return;
    }
    if (this.isSelfOpening) {
      const percent = Math.min(1, (this.lastTouchX + this.lastTouchVelocity * 4) / this.sidebarRef.clientWidth);
      this.lastTouchX = 0;
      this.isSelfOpening = false;
      if (percent >= 0.5) {
        // Opening
        const remainingTime = 400 * (1 - (percent - 0.5));
        this.selfOpened = true;
        this.backdropRef.style.transition = `opacity ${remainingTime}ms`;
        this.backdropRef.style.pointerEvents = 'unset';
        this.backdropRef.style.opacity = '0.25';
        this.sidebarRef.style.transition = `transform ${remainingTime}ms`;
        this.sidebarRef.style.transform = `translateX(0px)`;
        document.body.classList.add('no-scroll');
        document.addEventListener('keypress', this.onKeyboard);
      } else {
        // Not far enough, snap back closed again
        const remainingTime = 400 * percent;
        this.isSelfOpening = false;
        this.backdropRef.style.transition = `opacity ${remainingTime}ms`;
        this.backdropRef.style.pointerEvents = '';
        this.backdropRef.style.opacity = '0';
        this.sidebarRef.style.transition = `transform ${remainingTime}ms`;
        this.sidebarRef.style.transform = `translateX(-${this.sidebarRef.style.width})`;
      }
    } else if (this.open) {
      this.sidebarRef.style.transition = ``;
      this.backdropRef.style.transition = ``;
      const percent = -Math.max(-1, (this.lastTouchX + this.lastTouchVelocity * 4 - this.startTouchX) / this.sidebarRef.clientWidth);
      // Close
      if (percent >= 0.5 && Math.abs(this.startTouchX - this.lastTouchX) > 16) {
        const remainingTime = 400 * percent;
        this.backdropRef.classList.remove(styles.open);
        this.backdropRef.style.transition = `opacity ${remainingTime}ms`;
        this.backdropRef.style.pointerEvents = '';
        this.backdropRef.style.opacity = '0';
        this.selfOpened = false;
        this.sidebarRef.style.transition = `transform ${remainingTime}ms`;
        this.sidebarRef.style.transform = `translateX(-${this.sidebarRef.style.width})`;
        setTimeout(() => {
          if (!this.sidebarRef || !this.backdropRef) {
            return;
          }
          this.backdropRef.style.opacity = '';
          this.backdropRef.style.transition = ``;
          this.sidebarRef.style.transition = '';
          this.sidebarRef.style.visibility = ``;
          this.sidebarRef.style.boxShadow = '';
        }, remainingTime);
        document.body.classList.remove('no-scroll');
        document.removeEventListener('keypress', this.onKeyboard);
        (this.props.onBackdropClicked || (this.props.store ? this.props.store.onBackdropClicked : undefined))();
      } else if (this.lastTouchX <= this.sidebarRef.clientWidth || Math.abs(this.startTouchX - this.lastTouchX) > 16) {
        // Snap back to fully open
        const remainingTime = 400 * percent;
        this.backdropRef.style.transition = `opacity ${remainingTime}ms`;
        this.backdropRef.style.pointerEvents = 'unset';
        this.backdropRef.style.opacity = '0.25';
        this.sidebarRef.style.transition = `transform ${remainingTime}ms`;
        this.sidebarRef.style.transform = `translateX(0px)`;
        setTimeout(() => {
          if (!this.sidebarRef || !this.backdropRef) {
            return;
          }
          this.backdropRef.style.transition = ``;
          this.sidebarRef.style.transition = '';
        }, remainingTime);
      }
      this.lastTouchX = 0;
    } else {
      this.sidebarRef.style.transition = ``;
      this.backdropRef.style.transition = ``;
    }
  }

  public componentDidUpdate(): void {
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
    }
    if (this.open) {
      document.body.classList.add('no-scroll');
      document.addEventListener('keypress', this.onKeyboard);
    }
    if (!this.open && this.previousOpen) {
      this.sidebarRef.classList.add(styles['scroll-transition']);
      this.backdropRef.style.pointerEvents = '';
      this.backdropRef.style.opacity = '';
      document.removeEventListener('keypress', this.onKeyboard);
      setTimeout(() => {
        this.sidebarRef.classList.remove(styles['scroll-transition']);
      }, 400);
    }
    this.previousOpen = this.open;
  }

  public componentWillUnmount(): void {
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('keypress', this.onKeyboard);
    }
    if (!this.props.rhs && this.canDragOpen) {
      document.body.removeEventListener('touchstart', this.onTouchStart, { passive: true });
      document.body.removeEventListener('touchmove', this.onTouchMove, { passive: true });
      document.body.removeEventListener('touchend', this.onTouchEnd, { passive: true });
    }
  }

  private get canDragOpen(): boolean {
    if (iOS && !standAlone) {
      return false;
    }
    return this.props.mobileOpenable === undefined ? false : this.props.mobileOpenable;
  }

  @computed
  private get open(): boolean {
    return this.selfOpened || this.props.open || (this.props.store ? this.props.store.open : false);
  }

  @bind
  private onKeyboard(e: KeyboardEvent): void {
    // Manaully control tabs to create a keyboard trap
    if (e.keyCode === 9) {
      e.preventDefault();
      const elements = this.sidebarRef.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
      );
      let activeIndex = -1;
      const activeElement: Element = document.activeElement;
      const maxIndex = elements.length;
      for (const index in elements) {
        if (elements[index]) {
          const element = elements[index];
          if (element === document.activeElement) {
            activeIndex = parseInt(index, 0);
          }
        }
      }
      let newIndex = activeIndex;
      // Go forward
      if (!e.shiftKey) {
        newIndex++;
        if (newIndex >= maxIndex) {
          newIndex = -1;
        }
      } else {
        // Go backwards
        newIndex--;
        if (newIndex === -2) {
          newIndex = maxIndex - 1;
        }
      }
      if (newIndex >= 0) {
        elements[newIndex].focus();
      } else if (activeElement) {
        activeElement.blur();
      }
    }
    // Match escape
    if (e.keyCode === 27) {
      const realBackdropClicked = this.props.onBackdropClicked || (this.props.store ? this.props.store.onBackdropClicked : undefined);
      realBackdropClicked();
    }
  }

  @bind
  private onBackdropClicked(): void {
    if (!this.sidebarRef || !this.backdropRef) {
      return;
    }
    if (this.selfOpened) {
      this.selfOpened = false;
      this.backdropRef.classList.remove(styles.open);
      this.backdropRef.style.opacity = '';
      this.backdropRef.style.transition = ``;
      this.backdropRef.style.pointerEvents = '';
      this.sidebarRef.style.transition = ``;
      this.sidebarRef.style.transform = `translateX(-${this.sidebarRef.style.width})`;
      setTimeout(() => {
        if (!this.sidebarRef || !this.backdropRef) {
          return;
        }
        this.sidebarRef.style.visibility = ``;
        this.sidebarRef.style.boxShadow = '';
      }, 400);
      document.body.classList.remove('no-scroll');
      document.removeEventListener('keypress', this.onKeyboard);
    }
    if (this.props.onBackdropClicked || this.props.store) {
      (this.props.onBackdropClicked || (this.props.store ? this.props.store.onBackdropClicked : undefined))();
    }
  }

  public render(): JSX.Element {
    const { hasActionBar, store, rhs, className, children } = this.props;

    const width = Math.min(isMobile ? 320 : 400, window.innerWidth - 56);
    const left = rhs ? (this.open ? `calc(100vw - ${width}px)` : '100vw') : this.open ? `0%` : `-${width}px`;

    return (
      <div>
        <div
          ref={ref => (this.backdropRef = ref)}
          onClick={this.onBackdropClicked}
          className={Class('backdrop', styles.backdrop, {
            open: this.open,
            [styles.open]: this.open,
            [styles['has-action-bar']]: hasActionBar !== undefined ? hasActionBar : store,
          })}
        />
        <div
          aria-disabled={!this.open}
          aria-hidden={!this.open}
          ref={ref => (this.sidebarRef = ref)}
          className={Class(className, 'sidebar', styles.sidebar, {
            open: this.open,
            [styles.open]: this.open,
            [styles['has-action-bar']]: hasActionBar !== undefined ? hasActionBar : store,
          })}
          style={{ transform: `translateX(${left})`, width: width + 'px' }}
        >
          {children}
        </div>
      </div>
    );
  }
}
