import Class from 'classnames';
import RadioContext, { IRadioContext } from 'commonui/RadioContext';
import { bind } from 'decko';
import * as React from 'react';
import styles from './Radio.scss';

const defaultProps = {
  enabled: true,
};

interface IExportedRadioProps {
  className?: string;
  disabled?: boolean;
  enabled?: boolean;
  label: string;
  value: string;
}

type IRadioProps = IRadioContext & IExportedRadioProps;

/**
 * A react style radio that is fully customizable from CSS
 * This component must be somewhere inside a RadioGroup in order to function
 */
class Radio extends React.Component<IRadioProps, never> {
  public static defaultProps = defaultProps;

  @bind
  private onChange(): void {
    this.props.onChange(this.props.value);
  }

  @bind
  private onKeyPress(e: React.KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      this.onChange();
    }
  }

  public render(): JSX.Element {
    const { value, enabled, disabled, label, className, setDisabled, setValue, onChange, ...other } = this.props;
    const realDisabled = setDisabled || disabled || !enabled;
    const classNames = Class(styles.radio, 'radio', className, {
      [styles.active]: setValue === value,
      [styles.disabled]: realDisabled,
      active: setValue === value,
      disabled: realDisabled,
    });
    return (
      <span tabIndex={0} onKeyPress={this.onKeyPress} className={classNames} onClick={realDisabled ? undefined : this.onChange} {...other}>
        <div />
        {label ? <span className={Class(styles.label, 'label')}>{label}</span> : null}
      </span>
    );
  }
}

export default (props: IExportedRadioProps) => (
  <RadioContext.Consumer>{values => <Radio {...props} setValue={values.setValue} onChange={values.onChange} setDisabled={values.setDisabled} />}</RadioContext.Consumer>
);
