import Class from 'classnames';
import * as React from 'react';

import CardContext from 'commonui/Card/CardContext';
import Icon from '../Icon';
import CardTab from './CardTab';

interface ICardTabsProps extends React.HTMLProps<HTMLElement> {
  small?: boolean;
}

interface ICardTabsState {
  tab: number;
}

/**
 * CardTabs controls multiple CardTab children and manages swapping between them
 */
export default class CardTabs extends React.Component<ICardTabsProps, ICardTabsState> {
  constructor(props: ICardTabsProps) {
    super(props);

    this.onChangeTab = this.onChangeTab.bind(this);
    this.changeTab = this.changeTab.bind(this);

    this.state = { tab: 0 };
  }

  private onChangeTab(e: any): void {
    this.changeTab(parseInt(e.currentTarget.attributes['data-tab'].value, 10));
  }

  public changeTab(tab: number): void {
    this.setState({ tab });
  }

  public render(): JSX.Element {
    const { className, style, children, small, ...other } = this.props;

    return (
      <CardContext.Consumer>
        {context => {
          let realStyle = style;
          if (context.colour) {
            if (!realStyle) {
              realStyle = {};
            }
            realStyle.backgroundColor = context.colour;
          }
          const realChildren: any[] = [];
          if (Array.isArray(children)) {
            for (const child of children) {
              if (Array.isArray(child)) {
                for (const grandChild of child) {
                  if (grandChild) {
                    realChildren.push(grandChild);
                  }
                }
              } else {
                if (child) {
                  realChildren.push(child);
                }
              }
            }
          } else if (children) {
            realChildren.push(children);
          }

          return (
            <div className={Class('card-tabs', className, { coloured: context.colour, small })}>
              <ul style={realStyle} {...other}>
                {realChildren.map((item: CardTab, index: number) => (
                  <li key={index} data-tab={index} className={Class({ active: index === this.state.tab })} onClick={this.onChangeTab}>
                    {item.props.icon ? <Icon icon={item.props.icon} colour={item.props.iconColour} small={small} /> : null}
                    <span className={Class('title', { hasSubtitle: item.props.subtitle !== undefined })}>{item.props.title}</span>
                    <span className="subtitle">{item.props.subtitle}</span>
                  </li>
                ))}
              </ul>
              <div className="card-tabs-content">
                {realChildren.map((item, index) => (
                  <div key={index} className={Class({ inactive: this.state.tab !== index })}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </CardContext.Consumer>
    );
  }
}
