// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "ul.tLa0ot8db1j__bsLF-9xq{width:100%;margin:0px;margin-top:-48px;padding:0px;height:48px;list-style:none;display:flex;position:-webkit-sticky;position:sticky;z-index:10;top:64px;color:white;box-sizing:border-box;overflow-x:auto}ul.tLa0ot8db1j__bsLF-9xq::-webkit-scrollbar{display:none}ul.tLa0ot8db1j__bsLF-9xq.nm1Tq-ptLbNAmQtsPD7di{position:relative;top:0px}ul.tLa0ot8db1j__bsLF-9xq li{min-width:100px;cursor:pointer;margin:auto;box-sizing:border-box;flex:1;display:inline-block;max-width:264px;font-size:14px;text-align:center;line-height:48px;border-bottom:4px solid transparent;transition:border-bottom-color 200ms;text-transform:uppercase;font-weight:500;height:48px;outline:none;-moz-user-select:none;touch-action:manipulation;user-select:none}ul.tLa0ot8db1j__bsLF-9xq li:hover,ul.tLa0ot8db1j__bsLF-9xq li:focus{border-bottom-color:rgba(255,255,255,0.5)}ul.tLa0ot8db1j__bsLF-9xq li._396__ZUnIE777b2hCrCEWj{border-bottom-color:#fff}@media screen and (max-width: 600px){ul.tLa0ot8db1j__bsLF-9xq{top:56px}}\n", ""]);
// Exports
exports.locals = {
	"tab-controller": "tLa0ot8db1j__bsLF-9xq",
	"smart-scroll": "nm1Tq-ptLbNAmQtsPD7di",
	"active": "_396__ZUnIE777b2hCrCEWj"
};
module.exports = exports;
