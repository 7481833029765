import Class from 'classnames';
import * as $ from 'jquery';
import * as React from 'react';
import './Perspective.scss';

const defaultProps = {
    defaultX: 0.5,
    defaultY: 0.5,
    maxDegrees: 10,
    maxOverlay: 0.25,
};

interface IPerspectiveProps {
    defaultX?: number;
    defaultY?: number;
    maxDegrees?: number;
    maxOverlay?: number;
    className?: string;
}

export default class Perspective extends React.Component<IPerspectiveProps, void> {
    private x: number;
    private y: number;
    private timeout?: number;
    private perspective: number;

    constructor(props: IPerspectiveProps) {
        super(props);

        this.x = this.props.defaultX === undefined ? defaultProps.defaultX : this.props.defaultX;
        this.y = this.props.defaultY === undefined ? defaultProps.defaultY : this.props.defaultY;
        this.hover = this.hover.bind(this);
        this.leave = this.leave.bind(this);
        this.timeout = undefined;
        this.perspective = 0;
    }

    public componentDidMount() {
        this.perspective = $(this.refs.perspectiveContainer).width();
        $(this.refs.perspectiveContainer).on('mousemove', this.hover);
        $(this.refs.perspectiveContainer).on('mouseout', this.leave);
        this.setPerspective(this.x, this.y);
    }

    private hover(e) {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
            this.timeout = undefined;
        }
        this.perspective = $(this.refs.perspectiveContainer).width();
        this.setNewRotate(e);
    }

    private setNewRotate(e) {
        const pos = $(this.refs.perspectiveContainer).offset();
        const width = $(this.refs.perspectiveContainer).width();
        const height = $(this.refs.perspectiveContainer).height();
        const newx = (e.pageX - pos.left) / width;
        const newy = 1 - (e.pageY - pos.top) / height;
        this.x += (newx - this.x) / 25;
        this.y += (newy - this.y) / 25;
        this.setPerspective(this.x, this.y);
        if (Math.abs(this.x - newx) + Math.abs(this.y - newy) > 0.05) {
            this.timeout = window.setTimeout(() => this.setNewRotate(e), 16);
        } else {
            this.setPerspective(this.x, this.y);
        }
    }

    private leave() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
            this.timeout = undefined;
        }
        this.leaveStep();
    }

    private leaveStep() {
        const newx = this.props.defaultX === undefined ? defaultProps.defaultX : this.props.defaultX;
        const newy = this.props.defaultY === undefined ? defaultProps.defaultY : this.props.defaultY;
        this.x += (newx - this.x) / 25;
        this.y += (newy - this.y) / 25;
        this.setPerspective(this.x, this.y);
        if (Math.abs(this.x - newx) + Math.abs(this.y - newy) > 0.025) {
            this.timeout = window.setTimeout(() => this.leaveStep(), 16);
        } else {
            this.setPerspective(newx, newy);
        }
    }

    private setPerspective(x: number, y: number) {
        if (y !== 0.5) {
            const col = y > 0.5 ? '255, 255, 255' : '0, 0, 0';
            const intensity = Math.abs(y - 0.5) * 2 * (this.props.maxOverlay === undefined ? defaultProps.maxOverlay : this.props.maxOverlay);
            $(this.refs.perspectiveOverlay).css({
                background: 'rgba(' + col + ',' + intensity + ')',
            });
        }
        const maxDegrees = this.props.maxDegrees || defaultProps.maxDegrees;
        $(this.refs.perspective).css({
            transform: 'perspective(' + this.perspective + 'px) rotateX(' + (-maxDegrees + y * maxDegrees * 2) + 'deg) rotateY(' + (-maxDegrees + x * maxDegrees * 2) + 'deg)',
        });
    }

    public render() {
        return (
            <div ref="perspectiveContainer" className={Class('perspective', this.props.className)}>
                <div ref="perspective">
                    {this.props.children}
                    <div ref="perspectiveOverlay" className="perspective-overlay" />
                </div>
            </div>
        );
    }
}
