import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, registerCalculationType } from './Calculator';

export interface IRawValueOptions extends ICalculationOptions {
    computation_type: 'raw_value';
    raw_value: string | number;
}

export default class RawValue extends Calculator {
    raw_value: any;
    constructor(options: IRawValueOptions) {
        super(options);
        this.raw_value = numeral(options.raw_value);
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> {
        const value = inputs.baseValue;
        const rawResult = this.raw_value.value();
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = formattedResult;
        return {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            inputValue: numeral(value).format(),
            percentage: '',
            rawResult,
            result: result.value(),
            resultUnit: '$',
            rounding: this.roundFormula('' + rawResult),
        };
    }
}

registerCalculationType('raw_value', RawValue);
registerCalculationType('raw_input', RawValue);
