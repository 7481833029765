import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import '../Dialog.scss';
import styles from './Modal.scss';

declare const global: any;

if (!'HTMLDialogElement' in global) {
  // global.dialogPolyfill = import(/* webpackChunkName: "dialog-polyfill" */ "dialog-polyfill");
}

export interface IModalProps {
  className?: string;
  dialogStyle?: any;
  show: boolean;
  targetX?: number;
  targetY?: number;
  enablePolyfill?: boolean;
  onBackdropClicked?: () => void;
  style?: object;
  dialogClassName?: string;
}

/**
 * A react based modal
 */
export default class Modal extends React.Component<IModalProps, never> {
  private containerRef: HTMLDialogElement | null;
  private modalRef: HTMLDivElement | null;
  private emergencyBackdropRef: HTMLDivElement | null;
  private mutator: MutationObserver;

  public componentDidMount(): void {
    if ('MutationObserver' in global) {
      this.mutator = new MutationObserver(() => {
        if (!this.containerRef || !this.modalRef) {
          return;
        }
        const height = this.modalRef.clientHeight || 0;
        const width = this.modalRef.clientWidth || 0;
        this.containerRef.style.top = `calc(50% - ${Math.floor(height / 2)}px)`;
        this.containerRef.style.left = this.polyfill ? '0' : `calc(50% - ${Math.floor(width / 2)}px)`;
        this.containerRef.style.height = 'auto';
        this.containerRef.style.position = 'fixed';
      });
    }
    this.popin();
    document.body.style.overflow = 'hidden';
    window.addEventListener('resize', this.onWindowResize);
  }

  public componentWillUnmount(): void {
    if (this.mutator) {
      this.mutator.disconnect();
    }
    document.body.style.overflow = '';
    window.removeEventListener('resize', this.onWindowResize);
  }

  private get polyfill(): boolean {
    return false;
    // return /Chrome/i.test(navigator.userAgent) ? true : isIEorEdge ? false : this.props.enablePolyfill;
  }

  @bind
  private onWindowResize(): void {
    if (!this.containerRef || !this.modalRef) {
      return;
    }
    const height = this.modalRef.clientHeight || 0;
    const width = this.modalRef.clientWidth || 0;
    this.containerRef.style.width = width + 'px';
    this.containerRef.style.top = `calc(50% - ${Math.floor(height / 2)}px)`;
    this.containerRef.style.left = this.polyfill ? '0' : `calc(50% - ${Math.floor(width / 2)}px)`;
    this.containerRef.style.height = 'auto';
  }

  public componentDidUpdate(): void {
    this.popin();
  }

  @bind
  private popin(): void {
    if (this.props.show && this.containerRef && this.modalRef) {
      if (this.polyfill && global.dialogPolyfill) {
        global.dialogPolyfill.registerDialog(this.containerRef);
      }
      let targetX = window.innerWidth / 2;
      let targetY = window.innerHeight / 2;
      if (this.props.targetX !== undefined && this.props.targetY !== undefined) {
        const angle = Math.atan2(this.props.targetY - window.innerHeight / 2, this.props.targetX - window.innerWidth / 2);
        targetX = targetX + Math.cos(angle) * 256;
        targetY = targetY + Math.sin(angle) * 256;
      }
      if (this.polyfill) {
        this.containerRef.showModal();
      } else if (this.emergencyBackdropRef) {
        this.emergencyBackdropRef.classList.add(styles.polyfill);
        this.containerRef.classList.add(styles.polyfill);
      }
      this.containerRef.style.width = 'auto';
      this.containerRef.style.transition = 'none';
      this.containerRef.style.opacity = '0';
      this.containerRef.style.height = 'auto';
      this.modalRef.style.opacity = '0.5';
      this.modalRef.style.maxHeight = '100% !important';
      setTimeout(this.initialDelayLoad, 1);
    }
  }

  @bind
  private initialDelayLoad(): void {
    if (!this.containerRef || !this.modalRef) {
      return;
    }
    if (!this.polyfill && this.emergencyBackdropRef) {
      this.emergencyBackdropRef.classList.add(styles.active);
      this.emergencyBackdropRef.classList.add('active');
    }
    // Split into multiple function called for IE11 bug
    this.containerRef.classList.add(styles.active);
    this.containerRef.classList.add('active');
    this.modalRef.classList.add(styles.active);
    this.modalRef.classList.add('active');
    const height = this.modalRef.clientHeight || 0;
    const width = this.modalRef.clientWidth || 0;
    this.containerRef.style.top = `calc(50% - ${Math.floor(height / 2)}px)`;
    this.containerRef.style.left = this.polyfill ? '0' : `calc(50% - ${Math.floor(width / 2)}px)`;
    this.containerRef.style.width = width + 'px';
    this.containerRef.style.height = height + 'px';
    this.containerRef.style.opacity = '1';
    this.modalRef.style.left = '0';
    this.modalRef.style.top = '0';
    this.modalRef.style.opacity = '1';
    this.modalRef.style.maxHeight = '100%';
    setTimeout(this.popinAnimation, 400);
  }

  @bind
  private popinAnimation(): void {
    if (!this.containerRef || !this.modalRef) {
      return;
    }
    const height = this.modalRef.clientHeight || 0;
    const width = this.modalRef.clientWidth || 0;
    this.mutator.observe(this.modalRef, { attributes: true, characterData: true, childList: true, subtree: true });
    this.containerRef.style.top = `calc(50% - ${Math.floor(height / 2)}px)`;
    this.containerRef.style.left = this.polyfill ? '0' : `calc(50% - ${Math.floor(width / 2)}px)`;
    this.containerRef.style.height = 'auto';
    this.modalRef.style.display = 'block';
    setTimeout(this.clearAnimation, 2000);
  }

  @bind
  private clearAnimation(): void {
    if (!this.containerRef || !this.modalRef) {
      return;
    }
    this.containerRef.style.top = `calc(50% - ${Math.floor(this.modalRef.clientHeight / 2)}px)`;
    this.containerRef.style.left = this.polyfill ? '0' : `calc(50% - ${Math.floor(this.modalRef.clientWidth / 2)}px)`;
    this.containerRef.style.height = 'auto';
  }

  public render(): JSX.Element | null {
    const { show, children, className, dialogClassName, dialogStyle, onBackdropClicked, enablePolyfill, ...other } = this.props;
    const style = { ...dialogStyle };
    if (show) {
      return (
        <div>
          {!this.polyfill && (
            <div className={Class('emergency-backdrop', styles['emergency-backdrop'])} onClick={onBackdropClicked} ref={ref => (this.emergencyBackdropRef = ref)} />
          )}
          <dialog ref={ref => (this.containerRef = ref)} className={Class(dialogClassName, styles['react-modal-container'], 'react-modal-container')}>
            <div {...other} ref={ref => (this.modalRef = ref)} className={Class(styles['react-modal'], 'react-modal', className)} style={style}>
              {children}
            </div>
          </dialog>
        </div>
      );
    } else {
      return null;
    }
  }
}
