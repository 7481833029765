import Class from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import DatePicker from '../DatePicker';
import Icon from '../Icon';
import Input from '../Input';
import format from '../util/formatter';
import { IColumn } from './SmartGrid';

interface ICellProps {
  column: IColumn;
  rowData: any;
  isGroupHeader?: boolean;
  isTotal?: boolean;
  disabled?: boolean;
  onKeyDown?: (e: any) => void;
  updateOnBlur?: boolean;
}

function computeWidth(column: IColumn): string | number | undefined {
  if (column.width === undefined) {
    return;
  }
  // Width was a percentage, so we need children to fill 100%
  if (column.width.toString().indexOf('%') >= 0) {
    return '100%';
  }
  return column.width;
}

function Cell({ column, rowData, isGroupHeader, isTotal, disabled, onKeyDown, updateOnBlur, ...other }: ICellProps): JSX.Element {
  const inputRef = React.useRef<Input>();
  const thisRef = React.useRef();
  React.useImperativeHandle(thisRef, () => ({
    blur: () => {
      if (inputRef.current && inputRef.current.blur) {
        inputRef.blur();
      }
    },
    focus: () => {
      if (inputRef.current && inputRef.current.focus) {
        inputRef.focus();
      }
    },
  }));

  let observable = rowData;
  let value = column.field;
  let isDisabled = isGroupHeader || disabled || column.disabled;
  const realValue = rowData[column.field];
  if (typeof realValue === 'object' && realValue !== null) {
    observable = realValue;
    value = 'value';
    isDisabled = isDisabled || realValue.disabled;
  }
  if (!observable) {
    throw new Error('Expected observable, got null or undefined');
  }

  let render;
  if (column.spacer) {
    return isGroupHeader ? <th className="spacer" /> : <td className="spacer" />;
  } else if (observable.renderer) {
    render = observable.renderer(isGroupHeader ? 'group' : isTotal ? 'total' : 'row', column, observable, value);
  } else if (column.renderer) {
    render = column.renderer(isGroupHeader ? 'group' : isTotal ? 'total' : 'row', column, observable, value);
  } else {
    if (isDisabled) {
      switch (column.type) {
        case 'icon':
          render = <Icon small icon={observable[value]} />;
          break;
        case 'date':
        case 'datetime':
        case 'time':
        case undefined:
        case 'string':
        case 'number':
        case 'currency':
          const val = observable[value];
          if (column.type === 'currency' && val === undefined) {
            render = <span>Error, undefined value</span>;
            break;
          }
          render = (
            <span style={{ width: computeWidth(column) }} {...other}>
              {column.type === 'currency' ? format(val, 'currency', column.typeOptions) : val}
            </span>
          );
          break;
        default:
          render = rowData[column.field];
      }
    } else {
      switch (column.type) {
        case 'icon':
          render = <Icon small icon={observable[value]} />;
          break;
        case 'date':
        case 'datetime':
        case 'time':
          render = <DatePicker ref={inputRef} observable={observable} value={value} disabled={isDisabled} style={{ width: computeWidth(column) }} {...other} />;
          break;
        case undefined:
        case 'string':
        case 'number':
        case 'currency':
          if (column.type === 'currency' && observable[value] === undefined) {
            render = <span>Error, undefined value</span>;
            break;
          }
          render = (
            <Input
              ref={inputRef}
              observable={observable}
              value={value}
              type={column.type || 'number'}
              typeOptions={column.typeOptions}
              selectOnFocus
              disabled={isDisabled}
              onKeyDown={onKeyDown}
              updateOnBlur={updateOnBlur}
              style={{ width: computeWidth(column) }}
              simple
              {...other}
            />
          );
          break;
        default:
          render = rowData[column.field];
      }
    }
  }
  return isGroupHeader ? (
    <th className={Class('cell', column.className, column.alignment, { ['hidden-cell']: column.name === '' || column.name === undefined })} style={{ width: column.width }}>
      {render}
    </th>
  ) : (
    <td className={Class('cell', column.className, column.alignment, { ['hidden-cell']: column.name === '' || column.name === undefined })} style={{ width: column.width }}>
      {render}
    </td>
  );
}

export default observer(Cell, { forwardRef: true });
