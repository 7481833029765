import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Class from 'classnames';
import { bind } from 'decko';
import { action, IObservableValue, isObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Icon from '../Icon';
import formatter from '../util/formatter';
import styles from './Input.scss';

interface IInputProps {
  disabled?: boolean;
  enabled?: boolean;
  error?: string;
  icon?: string | IconDefinition;
  label?: string;
  onChange?: (value: string) => void;
  name?: string;
  password?: boolean;
  search?: boolean;
  placeholder?: string;
  selectOnFocus?: boolean;
  shrinkLabel?: boolean;
  type?: string;
  typeOptions?: object;
  value: string;
  simple?: boolean;
  observable?: object;
  onFocus?: () => void;
  onBlur?: () => void;
  updateOnBlur?: boolean;
}

interface IInputState {
  focus?: boolean;
  error?: string | null;
  value: string;
}

/**
 * A material style input box with a few more extra nice options
 */
@observer
export default class Input extends React.Component<IInputProps, IInputState> {
  public static defaultProps: Partial<IInputProps> = { enabled: true, type: 'text', value: '' };
  private inputRef: HTMLInputElement;

  constructor(props: IInputProps) {
    super(props);

    this.state = { error: null, focus: false, value: props.observable[props.value] };
  }

  public static getDerivedStateFromProps(nextProps: IInputProps, prevState: IInputState): IInputState {
    return {
      error: prevState.error,
      focus: prevState.focus,
      value: prevState.focus ? prevState.value : nextProps.observable[nextProps.value],
    };
  }

  private getRealValue(props: IInputProps): string {
    if (props.updateOnBlur) {
      return this.state.value;
    }
    let realValue = props.value;
    if (props.observable) {
      realValue = props.observable[props.value];
    } else if (isObservable(realValue)) {
      realValue = (realValue as IObservableValue<string | number>).get();
    }
    return realValue as string;
  }

  @bind
  private onChange(event: any): void {
    const value = event.currentTarget.value;
    this.changeValue(value);
  }

  @bind
  @action
  private changeValue(newValue: string, onBlur = false, props = this.props): void {
    if (!props.updateOnBlur || onBlur) {
      if (props.observable) {
        props.observable[props.value] = newValue;
        if (props.onChange) {
          props.onChange(newValue);
        }
        return;
      }
      if (isObservable(props.value)) {
        const value = props.value as IObservableValue<string | number>;
        value.set(newValue);
        if (props.onChange) {
          props.onChange(newValue);
        }
      }
    } else {
      this.setState({ value: newValue });
    }
  }

  @bind
  private onFocus(): void {
    this.setState({ focus: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  @bind
  private onBlur(): void {
    this.setState({ focus: false });
    if (this.props.type === 'currency' || this.props.type === 'number') {
      const realValue = this.getRealValue(this.props);
      if (typeof realValue === 'string') {
        const value = parseFloat(realValue.replace(/[$,]/g, ''));
        if (isNaN(value)) {
          this.changeValue(0, true);
        } else {
          this.changeValue(value, true);
        }
      }
    } else if (this.props.updateOnBlur) {
      this.changeValue(this.state.value, true);
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: IInputState): void {
    if (this.props.selectOnFocus) {
      if (!prevState.focus && this.state.focus) {
        this.inputRef.select();
      }
    }
  }

  /**
   * Focuses all the text inside input and selects it
   */
  @bind
  public focus(): void {
    this.inputRef.focus();
    this.inputRef.select();
  }
  /**
   * Selects all text inside input
   */
  @bind
  public select(): void {
    this.inputRef.select();
  }
  /**
   * Blurs focus on input
   */
  @bind
  public blur(): void {
    this.inputRef.blur();
  }

  public render(): JSX.Element {
    const {
      value,
      observable,
      simple,
      shrinkLabel,
      error,
      placeholder,
      search,
      type,
      typeOptions,
      enabled,
      disabled,
      label,
      icon,
      password,
      onBlur,
      onFocus,
      onChange,
      selectOnFocus,
      updateOnBlur,
      ...other
    } = this.props;
    const realDisabled = disabled || !enabled;

    let realValue = value;
    if (observable) {
      realValue = observable[value];
    }
    if (updateOnBlur) {
      realValue = this.state.value;
    }
    realValue = realValue as string;
    const active = realValue !== '' || placeholder !== undefined || this.state.focus || shrinkLabel !== undefined;

    let formattedValue = realValue;
    if ((!this.state.focus || realDisabled) && type === 'currency') {
      formattedValue = formatter(realValue, type, typeOptions);
    }

    if (simple) {
      return (
        <input
          aria-label={label}
          ref={ref => (this.inputRef = ref as HTMLInputElement)}
          readOnly={realDisabled}
          type={password ? 'password' : search ? 'search' : 'text'}
          value={formattedValue === null ? '' : formattedValue}
          placeholder={placeholder}
          onFocus={realDisabled ? undefined : this.onFocus}
          onBlur={realDisabled ? undefined : this.onBlur}
          onChange={realDisabled ? undefined : this.onChange}
          {...other}
        />
      );
    }

    return (
      <span className={Class(styles.input, 'input', { [styles.invaid]: error, [styles.disabled]: realDisabled, disabled: realDisabled, invalid: error })}>
        {icon ? <Icon icon={icon} /> : undefined}
        <div className={Class({ [styles['has-icon']]: icon !== undefined, 'has-icon': icon !== undefined })}>
          <span className={Class(styles.label, 'label', { [styles.active]: active, [styles.focus]: this.state.focus, active, focus: this.state.focus })}>{label}</span>
          <span className={Class(styles['error-label'], 'error-label', { [styles.active]: active, [styles.focus]: this.state.focus, active, focus: this.state.focus })}>
            {error}
          </span>
          <input
            className={Class({ [styles.active]: this.state.focus, active: this.state.focus })}
            aria-label={label}
            ref={ref => (this.inputRef = ref as HTMLInputElement)}
            readOnly={realDisabled}
            type={password ? 'password' : search ? 'search' : type || 'text'}
            value={formattedValue}
            placeholder={placeholder}
            onFocus={realDisabled ? undefined : this.onFocus}
            onBlur={realDisabled ? undefined : this.onBlur}
            onChange={realDisabled ? undefined : this.onChange}
            {...other}
          />
        </div>
      </span>
    );
  }
}

export { Input as MobXInput };
