import { memoize } from "lodash-decorators";
import { observable, ObservableMap } from "mobx";

type Href = string;

import Bluebird from "bluebird";

export interface ILookup {
    id: number;
    identifier: string;
    text: string;
    search?: string;
    value: number;
    icon?: string;
    short_text?: string;
    initials?: string;
    initials_class?: string;
    image?: Href;
    message?: string;
}

export interface ILookupsMap {
    [key: string]: ILookup[];
}

interface ILookupItemMap {
    [key: string]: ILookup;

    [key: number]: ILookup;
}

declare const global: { Gears: { lookups: any } };

export default class Lookups {
    public lists: ObservableMap<string, ILookup[]> = observable.map<string, ILookup[]>(undefined, { deep: false });

    constructor(raw?: ILookupsMap) {
        if (raw) {
            this.setLookups(raw);
        }
    }

    public resetLookups(raw?: ILookupsMap): void {
        this.lists.clear();
        if (raw) {
            this.setLookups(raw);
        }
    }

    public setLookups(raw: ILookupsMap): void {
        for (const lookup in raw) {
            this.setLookup(lookup, raw[lookup]);
        }
    }

    public setLookup(name: string, values: ILookup[]): void {
        if (!global.Gears) {
            global.Gears = { lookups: {} };
        }
        const lookups = global.Gears.lookups || (global.Gears.lookups = {});
        if (_.isArray(values)) {
            for (const item of values) {
                item.value = item.id;
                // if ((item as any).options) {
                //     (item as any).options = undefined;
                // }
            }
        }
        lookups[name] = values;
        this.lists.set(name, values);
    }

    @memoize()
    public fetchLookup(name: string): Bluebird<ILookup[]> {
        console.log(`Fetching lookup ${name}`);
        return Bluebird.resolve($.get(`/lookup/${name}`))
            .then((data: { results: ILookup[] }) => {
                console.log("Data fl", data);
                this.setLookup(name, data.results);
                console.log(`Lookup set: ${name}`);
                return data.results;
            });
    }

    public getLookup(name: string): ILookup[] {
        const lookup = this.lists.get(name);
        if (!lookup) {
            throw this.fetchLookup(name);
        }
        return lookup;
    }
}
