import {autorun} from "mobx";
import {observer} from "mobx-react-lite";
import React, { useMemo } from "react";
import IFrame from "react-iframe";
import {Registry} from "react-registry";
import { InvalidDataError } from "../../Errors";
import { IEditorProps } from "./Editor";

// var kendoBindingGearsObject, out$ = typeof exports != 'undefined' && exports || this;


function toDataUri(html: string): string {
    return `data:text/html,${encodeURIComponent(html)}`;
}

const HtmlFrame: React.FunctionComponent<IEditorProps<string>> = observer(({value,...passthrough}: IEditorProps<string>) => {
    // const mappedValue = useMemo(() => mapComputed(value, unknownToString, stringToObject), [value]);
    console.log("Rendering html: " + value.get());
    return <IFrame url={toDataUri( value.get() || "")} {...passthrough}/>;
});

HtmlFrame.displayName = "HtmlFrame";

Registry.register(HtmlFrame, {id: "HtmlFrame"});
export default HtmlFrame;
