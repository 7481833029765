import * as _ from "lodash";

export function isPropertyDescriptor(val: any): val is PropertyDescriptor {
    return typeof val === "object" && (val.set || val.get || val.value !== "undefined");
}

export function toPropertyDescriptor(p: any): PropertyDescriptor {
    if(isPropertyDescriptor(p)) {
        return p;
    } else if (_.isFunction(p)) {
        return {get: p};
    } else {
        return {value: p};
    }
}

function toBool(i: any): boolean {
    return !!i;
}

export function toBooleanPropertyDescriptor(p: any): PropertyDescriptor {
    if(isPropertyDescriptor(p)) {
        return p;
    } else if (_.isFunction(p)) {
        return {get: _.flowRight(toBool, p)};
    } else {
        return {value: toBool(p)};
    }
}

function propertyReducer(result: PropertyDescriptorMap, val: any, key: string): PropertyDescriptorMap {
    if (isPropertyDescriptor(val)) {
        console.log("A property detected!", val);
        result[key] = val;
    } else {
        result[key] = {value: val};
    }
    return result;
}

export function detectProperties(propertyObject: object) {
    return _(propertyObject).reduce(propertyReducer, {});
}
