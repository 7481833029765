import * as React from 'react';

interface IPortalImageProps extends React.HTMLProps<HTMLDivElement> {
  maxOffset: number;
  src: string;
  width: string | number;
  height: string | number;
  attach?: string;
  lockX?: boolean;
  lockY?: boolean;
}

/**
 * Creates an image that is not fixed to the page scroll, but instead moves at a percentage of it.
 */
export default class PortalImage extends React.Component<IPortalImageProps, void> {
  private imageRef: HTMLDivElement;
  private defaultOffset: 32;

  constructor(props: IPortalImageProps) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
  }

  public componentDidMount() {
    if (!this.props.attach) {
      $(window).scroll(this.onScroll);
    } else {
      $(this.props.attach).scroll(this.onScroll);
    }
    this.onScroll();
  }

  public componentWillUnmount() {
    if (!this.props.attach) {
      $(window).off('scroll', this.onScroll);
    } else {
      $(this.props.attach).off('scroll', this.onScroll);
    }
  }

  private onScroll() {
    let { maxOffset, lockX, lockY } = this.props;
    if (!maxOffset) {
      maxOffset = this.defaultOffset;
    }
    const $imageRef = $(this.imageRef);
    const scrollTop = $(window).scrollTop();
    const scrollLeft = $(window).scrollLeft();
    const offset = $imageRef.offset();
    const imageX = offset.left + $imageRef.width() / 2 - (this.props.attach ? 0 : scrollLeft);
    const imageY = offset.top + $imageRef.height() / 2 - (this.props.attach ? 0 : scrollTop);
    const windowX = $(window).width() / 2;
    const windowY = $(window).height() / 2;
    const multiY = $imageRef.height() / $(window).height();
    const thingX = Math.max(-1, Math.min(1, (windowX - imageX) / Math.max($imageRef.width(), $(window).width())));
    const thingY = Math.max(-1, Math.min(1, (windowY - imageY) / ($(window).height() * multiY)));
    $imageRef.css({ backgroundPosition: `${lockX ? -maxOffset : -maxOffset + (thingX + 1) * maxOffset}px ${lockY ? -maxOffset : -maxOffset + (thingY + 1) * maxOffset}px` });
  }

  public render() {
    const { maxOffset, src, width, style, height, attach, lockX, lockY, ...other } = this.props;
    return (
      <div
        ref={ref => (this.imageRef = ref)}
        style={{
          backgroundImage: `url(${src})`,
          backgroundRepeat: 'no-repeat',
          width,
          height,
          backgroundPosition: '0px 0px',
          backgroundSize: `calc(100% + ${(maxOffset || this.defaultOffset) * 2}px)`,
          ...style,
        }}
        {...other}
      />
    );
  }
}
