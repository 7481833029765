import * as $ from "jquery";
import * as _ from "lodash";
import * as juration from "../../../vendor/assets/javascripts/juration";
import {notification} from "./Notification";

declare var Gears: any;
declare var kendo: any;

export function durationFormat(seconds: number) {
    if (!(_.isNumber(seconds) && seconds > 0)) {
        return "0h 0m";
    }
    if (seconds < 60) {
        seconds = 60;
    }
    return juration.stringify(seconds, {
        format: "long",
        available_units: ["hours", "minutes"],
    });
}

export function hoursFormat(seconds: number | null) {
    if (seconds === 0) {
        return "";
    }
    if (!_.isNumber(seconds)) {
        return "";
    }
    return `
    <span data-toggle='tooltip' data-placement='top' data-html='true' title='${juration.stringify(seconds, {
            format: "long",
            available_units: ["hours", "minutes"],
        })}'>
        <span class='formatted-duration'>${kendo.toString(seconds / 3600, "n1")}</span>
    </span>
    `;
}

// export function durationFormat(seconds: number | null, options: {}) {
//     if (seconds === 0) {
//         return "";
//     }
//     if (!_.isNumber(seconds)) {
//         return "";
//     }
//     return `
//     <span data-toggle='tooltip' data-placement='top' data-html='true' title='${juration.stringify(seconds, {
//         format: "long",
//         available_units: ["hours", "minutes"],
//     })}'>
//         <span class='formatted-duration'>${kendo.toString(seconds / 3600, "n1")}</span>
//     </span>
//     `;
// }

export function parseDuration(timeString: string) {
    timeString = $.trim(timeString).replace(/\s+/g, " ");
    if (timeString.length === 0) {
        return 0;
    }
    timeString = timeString.replace(/^([\d\.]+)(?:[\:\s]+)([\d\.]+)/, "$1h $2m");
    if (timeString.match(/\d$/)) {
        timeString = timeString + "h";
    }
    console.log(timeString);
    return juration.parse(timeString);
}

type DurationString = string;

export function durationHandler(fieldName: string): (newVal: string) => string {
    return function(newVal: DurationString) {
        if (newVal != null) {
            const oldVal = this.get(fieldName);
            this.set(fieldName, -123);
            try {
                return this.set(fieldName, parseDuration(newVal));
            } catch (e$) {
                const e = e$;
                this.set(fieldName, oldVal);
                return notification({
                    title: "Invalid Duration Format: <strong>" + newVal + "</strong>",
                    content: "Example durations: <strong><br/><div class='row'><div class='col-xs-4'>3h 15m</div><div class='col-xs-4'>3 15</div><div class='col-xs-4'>3.25h</div><div class='col-xs-4'>195m</div><div class='col-xs-4'>3.25</div><div class='col-xs-4'>3.25 hours</div><div class='col-xs-4'>3:15</div></strong>",
                    color: "#296191",
                    icon: "fa-clock",
                    animation: "bounce animated",
                    timeout: 20000,
                    displayType: "bigBox",
                });
            }
        } else {
            return durationFormat(this.get(fieldName));
        }
    };
}
