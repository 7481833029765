import { observable } from "mobx";
import * as React from "react";
import { Model } from "..";
import {InputColumnsContext} from "./Field";
import  FieldSet  from "./FieldSet";
import FormState, {FormContext} from "./FormState";
import {Registry} from "react-registry";

export interface IFormProps<M extends Model = any> {
    formState: FormState<M>;
    formProps?: any;
    fields?: string[] | string;
    inputColumns?: number;
}

const Form: React.FunctionComponent<IFormProps> = ({formState, children, fields, inputColumns = 8, formProps = {}}: IFormProps & { children?: React.ReactNode }): React.ReactElement<any> => {
    if (!children) {
        children = <FieldSet fields={fields}/>;
    }
    return <InputColumnsContext.Provider value={inputColumns}><FormContext.Provider value={formState}><div className="form form-horizontal" {...formProps}>{children}</div></FormContext.Provider></InputColumnsContext.Provider>;
};
Form.displayName = "Form";
Registry.register(Form, {id: "Form"});

export default Form;
