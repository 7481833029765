require('@progress/kendo-ui/js/kendo.core');
require('@progress/kendo-ui/js/kendo.dom');
require('@progress/kendo-ui/js/cultures/kendo.culture.en-AU');
// require("@progress/kendo-ui/js/kendo.drawing");
// require("@progress/kendo-ui/js/kendo.webcomponents");
// require("@progress/kendo-ui/js/kendo.combobox");
// require("@progress/kendo-ui/js/kendo.dropdownlist");
// require("@progress/kendo-ui/js/kendo.multiselect");
// require("@progress/kendo-ui/js/kendo.validator");
require('@progress/kendo-ui/js/kendo.data');
// require("@progress/kendo-ui/js/kendo.list");
// require("@progress/kendo-ui/js/kendo.mobile.scroller");
// require("@progress/kendo-ui/js/kendo.popup");
// require("@progress/kendo-ui/js/kendo.slider");
// require("@progress/kendo-ui/js/kendo.userevents");
// require("@progress/kendo-ui/js/kendo.menu");
// require("@progress/kendo-ui/js/kendo.data.odata");
// require("@progress/kendo-ui/js/kendo.data.xml");
// require("@progress/kendo-ui/js/kendo.fx");
// require("@progress/kendo-ui/js/kendo.router");
require('@progress/kendo-ui/js/kendo.view');
// require("@progress/kendo-ui/js/kendo.data.signalr");
require('@progress/kendo-ui/js/kendo.binder');
// require("@progress/kendo-ui/js/kendo.draganddrop");
// require("@progress/kendo-ui/js/kendo.tooltip");
// require("@progress/kendo-ui/js/kendo.angular");
// require("@progress/kendo-ui/js/kendo.angular2");
// require("@progress/kendo-ui/js/kendo.calendar");
// require("@progress/kendo-ui/js/kendo.datepicker");
// require("@progress/kendo-ui/js/kendo.timepicker");
// require("@progress/kendo-ui/js/kendo.numerictextbox");
// require("@progress/kendo-ui/js/kendo.resizable");
// require("@progress/kendo-ui/js/kendo.window");
// require("@progress/kendo-ui/js/kendo.colorpicker");
// require("@progress/kendo-ui/js/kendo.imagebrowser");
// require("@progress/kendo-ui/js/kendo.ooxml");
// require("@progress/kendo-ui/js/kendo.listview");
require('@progress/kendo-ui/js/kendo.upload');
// require("@progress/kendo-ui/js/kendo.autocomplete");
// require("@progress/kendo-ui/js/kendo.gantt.list");
// require("@progress/kendo-ui/js/kendo.gantt.timeline");
require('@progress/kendo-ui/js/kendo.grid');
// require("@progress/kendo-ui/js/kendo.columnsorter");
// require("@progress/kendo-ui/js/kendo.datetimepicker");
// require("@progress/kendo-ui/js/kendo.editable");
// require("@progress/kendo-ui/js/kendo.sortable");
// require("@progress/kendo-ui/js/kendo.columnmenu");
// require("@progress/kendo-ui/js/kendo.groupable");
// require("@progress/kendo-ui/js/kendo.pager");
// require("@progress/kendo-ui/js/kendo.selectable");
// require("@progress/kendo-ui/js/kendo.reorderable");
// require("@progress/kendo-ui/js/kendo.excel");
// require("@progress/kendo-ui/js/kendo.progressbar");
// require("@progress/kendo-ui/js/kendo.filebrowser");
// require("@progress/kendo-ui/js/kendo.toolbar");
// require("@progress/kendo-ui/js/kendo.pivotgrid");
// require("@progress/kendo-ui/js/kendo.treeview");
// require("@progress/kendo-ui/js/kendo.scheduler.dayview");
// require("@progress/kendo-ui/js/kendo.scheduler.recurrence");
// require("@progress/kendo-ui/js/kendo.scheduler.view");
// require("@progress/kendo-ui/js/kendo.scheduler.agendaview");
// require("@progress/kendo-ui/js/kendo.scheduler.monthview");
// require("@progress/kendo-ui/js/kendo.treeview.draganddrop");
// require("@progress/kendo-ui/js/kendo.button");
// require("@progress/kendo-ui/js/kendo.notification");
// require("@progress/kendo-ui/js/kendo.maskedtextbox");
// require("@progress/kendo-ui/js/kendo.panelbar");
// require("@progress/kendo-ui/js/kendo.responsivepanel");
// require("@progress/kendo-ui/js/kendo.tabstrip");
// require("@progress/kendo-ui/js/kendo.splitter");
// require("@progress/kendo-ui/js/kendo.dialog");
// require("@progress/kendo-ui/js/kendo.virtuallist");
require('@progress/kendo-ui/js/kendo.editor');
// require("@progress/kendo-ui/js/kendo.mediaplayer");
// require("@progress/kendo-ui/js/kendo.pivot.fieldmenu");
// require("@progress/kendo-ui/js/kendo.filtercell");
// require("@progress/kendo-ui/js/kendo.scheduler");
// require("@progress/kendo-ui/js/kendo.gantt");
// require("@progress/kendo-ui/js/kendo.treelist");
// require("@progress/kendo-ui/js/kendo.spreadsheet");
// require("@progress/kendo-ui/js/kendo.pivot.configurator");
export default kendo;
