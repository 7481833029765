import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import styles from './List.scss';
import ListItem, { IListItemSkeleton } from './ListItem';

type HeightFunction = (index: number) => number;

interface IListProps extends React.HTMLProps<HTMLUListElement> {
  itemHeight?: number | number[] | HeightFunction;
  onLoadMore?: () => void;
  attachToWindow?: boolean;
  skeleton?: boolean | IListItemSkeleton;
}

interface IListState {
  offset: number;
}

function childrenAsArray(children: React.ReactNode): React.ReactNode[] {
  if (Array.isArray(children)) {
    return children;
  } else {
    return [children];
  }
}

const defaultRowHeight = 76; // Definitely upgrade this in future to something more dynamic

export default class List extends React.Component<IListProps, IListState> {
  private listRef: HTMLUListElement | null;
  private canLoadMore = true;
  public static Item = ListItem;

  constructor(props: IListProps) {
    super(props);

    this.state = { offset: 0 };
  }

  public componentDidMount(): void {
    const topTarget = this.props.attachToWindow ? window : this.listRef ? (this.listRef.parentElement ? this.listRef.parentElement : window) : window;
    topTarget.addEventListener('scroll', this.onScroll);
  }

  public componentWillUnmount(): void {
    const topTarget = this.props.attachToWindow ? window : this.listRef ? (this.listRef.parentElement ? this.listRef.parentElement : window) : window;
    topTarget.removeEventListener('scroll', this.onScroll);
  }

  @bind
  private onScroll(): void {
    if (this.listRef) {
      const topOffset = this.props.attachToWindow ? window.pageYOffset : this.listRef.parentElement ? this.listRef.parentElement.scrollTop : window.pageYOffset;
      const rect = this.listRef.getBoundingClientRect();
      const rowHeight = this.props.itemHeight || defaultRowHeight;
      if (Math.abs(topOffset - this.state.offset - rect.top) >= rowHeight) {
        this.setState({ offset: topOffset });
      }
      // const childrenArray = childrenAsArray(this.props.children);
      if (topOffset - this.getTotalHeight() + window.innerHeight > -rowHeight) {
        if (this.canLoadMore && this.props.onLoadMore) {
          this.canLoadMore = false;
          this.props.onLoadMore();
        }
      }
    }
  }

  public componentDidUpdate(nextProps: IListProps, nextState: IListState): void {
    const childrenArray = childrenAsArray(this.props.children);
    const nextChildrenArray = childrenAsArray(nextProps.children);
    if (nextChildrenArray.length !== childrenArray.length) {
      this.canLoadMore = true;
    }
  }

  private getTotalHeight(): number {
    const rowHeight = this.props.itemHeight || defaultRowHeight;
    if (typeof rowHeight === 'number') {
      const childrenArray = childrenAsArray(this.props.children);
      return childrenArray.length * rowHeight;
    } else {
      if (typeof rowHeight === 'function') {
        let total = 0;
        const childrenArray = childrenAsArray(this.props.children);
        childrenArray.forEach((val, index) => {
          total += rowHeight(index);
        });
        return total;
      } else {
        return rowHeight.reduce((val, acc) => acc + val, 0);
      }
    }
  }

  public render(): JSX.Element | null {
    const { itemHeight, attachToWindow, skeleton, onLoadMore, children, ...other } = this.props;
    if (skeleton) {
      const closetSize = window.innerHeight / defaultRowHeight;
      const skeletonMap = [];
      for (let i = 0; i < closetSize; i++) {
        skeletonMap.push(<ListItem key={i} title="" skeleton={skeleton} />);
      }
      return (
        <ul ref={ref => (this.listRef = ref)} className={Class(styles.list, 'list')} {...other}>
          {skeletonMap}
        </ul>
      );
    }
    const childrenArray = childrenAsArray(children);
    if (childrenArray) {
      const rowHeight = (itemHeight || defaultRowHeight) as number; // Temporarily just accept a number
      const height = window.innerHeight;
      const canFit = height / rowHeight;
      const first = Math.floor(this.state.offset / rowHeight);
      const startBufferHeight = Math.max(0, Math.min((first - canFit) * rowHeight, this.getTotalHeight()));
      const endBufferHeight = Math.max(0, (childrenArray.length - (first + canFit * 2)) * rowHeight);
      return (
        <ul ref={ref => (this.listRef = ref)} className={Class(styles.list, 'list')} {...other}>
          <li style={{ height: startBufferHeight }} />
          {childrenArray.map((child, index) => {
            if (index >= first - canFit && index < first + canFit * 2) {
              return child;
            }
          })}
          <li style={{ height: endBufferHeight }} />
        </ul>
      );
    } else {
      return null;
    }
  }
}
