import './DataGrid.scss';

import Class from 'classnames';
import * as marked from 'marked';
import * as React from 'react';

import Input, { MobXInput } from '../Input';

import { observer } from 'mobx-react';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import format from '../util/formatter';

const defaultProps = {
  title: 'Name',
  type: 'currency',
};

type Alignment = 'left' | 'center' | 'right';

interface IDataGridProps {
  data: any;
  columnNames: string[];
  columnAlign: Alignment[];
  columnHidden?: boolean[];
  rowNames: string[];
  rowTips?: string[];
  rowClassNames?: string[];
  className?: string;
  columnWidths?: string[];
  columnGroups?: any[];
  fields: string[];
  type?: string;
  typeOptions?: any;
  title?: string;
  disabled?: boolean[] | boolean;
}

interface IDataGridState {
  open: boolean[];
}

/**
 * A highly extensible data grid
 */
@observer
class DataGrid extends React.Component<IDataGridProps, IDataGridState> {
  private tableRefs: any = [];
  /**
   * @param {object} props
   * @param {array} props.data - An array of data with fields to fill in the grid
   * @param {array} props.columnNames - Array of columns
   * @param {array} [props.columnAlign] - Array of alignments [left, center, right] for each column
   * @param {array} props.rowNames - Array of row names, or array of row objects if rendering group
   * @param {array} [props.rowTypes] - Array of onclick tips for rows (null for cell to not display tip)
   * @param {array} props.fields - Fields to fetch from inside props.data
   * @param {string} [props.type] - Format type of data (defaults to currency)
   * @param {Object} [props.typeOptions] - Additional configuration for type
   * @param {string} [props.title] - Title to display in top left most cell
   * @param {boolean} [props.disabled] - True to disable entire grid
   */
  constructor(props: IDataGridProps) {
    super(props);
    this.onInput = this.onInput.bind(this);
    this.onToggleGroup = this.onToggleGroup.bind(this);
    this.customNatbuildFormat = this.customNatbuildFormat.bind(this);
    const open = [];
    for (const rowName of props.rowNames) {
      if (typeof rowName === 'object') {
        open[open.length] = false;
      }
    }
    this.state = { open };
    console.warn('DataGrid is deprecated, please convert to SmartGrid');
  }
  public UNSAFE_componentWillReceiveProps(nextProps: IDataGridProps) {
    const open = [];
    for (const rowName of nextProps.rowNames) {
      if (typeof rowName === 'object') {
        open[open.length] = false;
      }
    }
    this.setState({ open });
  }
  public collapseAll() {
    const open = this.state.open;
    for (let i = 0; i < open.length; i++) {
      open[i] = false;
    }
    this.setState({ open });
  }
  public openAll() {
    const open = this.state.open;
    for (let i = 0; i < open.length; i++) {
      open[i] = true;
    }
    this.setState({ open });
  }
  private onToggleGroup(e: React.SyntheticEvent<Event>) {
    const group = parseInt(e.currentTarget.attributes['data-group'].value, 10);
    const open = this.state.open;
    open[group] = !open[group];
    this.setState({ open });
  }
  private onInput(e: KeyboardEvent, row: number, column: number) {
    if (e.which === 13) {
      const dir = e.shiftKey ? -1 : 1;
      this.refs[row + ':' + column].blur();
      const newref = this.refs[row + dir + ':' + column];
      if (newref) {
        this.refs[row + 1 + ':' + column].focus();
      }
    }
  }
  private renderHeader() {
    const { columnNames, columnTooltips, columnTooltips2, columnGroups, columnAlign, columnHidden, columnWidths, title } = this.props;
    return (
      <thead>
        {columnGroups && (
          <tr>
            {columnGroups.map((item, index) => {
              if (item.spacer) {
                return <th className="spacer" />;
              }
              let width = 0;
              if (columnWidths) {
                for (let i = index; i < index + item.width; i++) {
                  width += parseInt(columnWidths[i] || '', 10);
                }
              }
              return (
                <th colSpan={item.width} style={{ textAlign: item.align, width: width && width + '%' }}>
                  <span>{item.name}</span>
                </th>
              );
            })}
          </tr>
        )}
        <tr>
          <th className={Class('title')} style={columnWidths ? { width: columnWidths[0] } : undefined}>
            <span>{typeof title === 'string' ? title : undefined}</span>
          </th>
          {columnNames.map((item, index) => (
            <th
              className={Class('table-label', columnAlign && columnAlign[index], {
                columnHidden: columnHidden && columnHidden[index],
                spacer: item === null,
              })}
              key={index + ':' + item}
              style={columnWidths ? { width: columnWidths[index + 1] } : undefined}
            >
              <span>
                {item}
                {columnTooltips && columnTooltips[index] && <br />}
                {columnTooltips && columnTooltips[index] && (
                  <Icon data-tooltip={columnTooltips[index]} data-tooltip-mount="below-center" small className="column-tooltip-icon" fixedWidth icon="fa-info-circle" />
                )}
                {columnTooltips2 && columnTooltips2[index] && (
                  <Icon data-tooltip={columnTooltips2[index]} data-tooltip-mount="below-center" small className="column-tooltip-icon2" fixedWidth icon="fa-question-circle" />
                )}
              </span>
            </th>
          ))}
        </tr>
      </thead>
    );
  }
  private renderCell(item, index: number, trueIndex: number, item2, index2: number) {
    const { columnAlign, columnNames, type, columnCustomRenderer, columnHidden, data, disabled } = this.props;
    let realDisabled = disabled;
    if (Array.isArray(realDisabled) || realDisabled.peek) {
      realDisabled = realDisabled[index2];
    }
    item = data[trueIndex];
    // Check if it's a special mobx variable
    if (typeof item[item2] === 'object') {
      item = item[item2];
      item2 = 'value';
      realDisabled = true;
    }
    let render;
    if (columnCustomRenderer && columnCustomRenderer[index2]) {
      render = columnCustomRenderer[index2](item, item2, trueIndex);
    } else {
      render = (
        <Input
          ref={trueIndex + ':' + index2}
          observable={item}
          value={item2}
          disabled={realDisabled}
          selectOnFocus
          type={type}
          onKeyDown={e => this.onInput(e, trueIndex, index2)}
        />
      );
    }
    if (columnNames[index2] != null) {
      return (
        <td
          key={trueIndex + ':' + index2}
          className={Class(columnAlign && columnAlign[index2], {
            columnHidden: columnHidden && columnHidden[index2],
          })}
        >
          {render}
        </td>
      );
    } else {
      return <td key={trueIndex + ':' + index2} className={Class('spacer', columnAlign && columnAlign[index2])} />;
    }
  }
  private renderGroup(item: number, index: number, groupIndex: number, item2: string, index2: number) {
    const { rowNames, columnNames, columnCustomRenderer, columnHidden, columnAlign, data, type, typeOptions } = this.props;
    let total = 0;
    const rows = [];
    for (let i = 0; i < rowNames[groupIndex].children.length; i++) {
      let rowValue = data[groupIndex + index + i][item2];
      rows.push(data[groupIndex + index + i]);
      // Quick and dirty turn currency into number
      if (typeof rowValue === 'string') {
        rowValue = parseFloat(rowValue.replace('$', '').replace(new RegExp(',', 'g'), ''));
      }
      if (typeof rowValue === 'object') {
        rowValue = rowValue.value;
      }
      total += rowValue;
    }
    total = format(total, type, typeOptions);
    let render;
    if (columnCustomRenderer && columnCustomRenderer[index2]) {
      render = columnCustomRenderer[index2](rows, item2, groupIndex + index);
    } else {
      render = <span>{total.toString()}</span>;
    }
    if (columnNames[index2] != null) {
      return (
        <td
          key={'g' + groupIndex + ':' + index2}
          className={Class('group', columnAlign && columnAlign[index2], {
            columnHidden: columnHidden && columnHidden[index2],
          })}
        >
          {render}
        </td>
      );
    } else {
      return <td key={'g' + groupIndex + ':' + index2} className={Class('spacer', 'group', columnAlign && columnAlign[index2])} />;
    }
  }
  private customNatbuildFormat(value: string) {
    try {
      let val = format(value, this.props.type, this.props.typeOptions);
      if (val === '0' || val === '0.00') {
        val = '-';
      }
      return val;
    } catch (e) {
      return '!';
    }
  }
  public render() {
    const { fields, rowNames, className, rowTips, disabled, rowClassNames } = this.props;
    const rows = [];
    let index = 0;
    let hasGroups = false;
    for (let i = 0; i < rowNames.length; i++) {
      let rowName = rowNames[i];
      if (typeof rowName === 'object') {
        hasGroups = true;
        rowName = rowName.name;
        const groupRow = { child: false, name: rowName, open: false, type: 'group' };
        rows.push(groupRow);
        // if (this.state.open[index]) {
        groupRow.open = true;
        for (let a = 0; a < rowNames[i].children.length; a++) {
          rows.push({ child: true, name: rowNames[i].children[a], open: this.state.open[index], type: 'row' });
        }
        // }
      } else {
        rows.push({ child: false, name: rowName, type: 'row' });
      }
      index++;
    }
    let topIndex = 0;
    let childIndex = 0;
    let skipped = 0;
    return (
      <div className={Class('data-grid-container', className)}>
        <table>
          {this.renderHeader()}
          <tbody className={Class({ hasGroups })}>
            {rows.map((item, index: number) => {
              const rowName = item.name;
              const render = (
                <tr
                  key={index}
                  className={Class(
                    {
                      childOpen: item.child && this.state.open && !this.state.open[topIndex - 1],
                      disabled: Array.isArray(disabled) ? false : disabled,
                      group: item.type === 'group',
                      open: this.state.open && this.state.open[topIndex],
                    },
                    !item.child && rowClassNames && rowClassNames[topIndex],
                  )}
                  data-group={topIndex}
                  onClick={item.type === 'group' ? this.onToggleGroup : undefined}
                >
                  <th data-tooltip={rowTips && rowTips[childIndex]} ref={ref => (this.tableRefs[index] = ref)} className={Class('table-label', { child: item.child })}>
                    <span>
                      {rowName}
                      {item.type === 'row' && rowTips && rowTips[childIndex] !== undefined && <Icon icon="fa-question-circle" />}
                    </span>
                  </th>
                  {item.type === 'group'
                    ? fields.map((item2, index2) => this.renderGroup(item, skipped + childIndex - topIndex, topIndex, item2, index2))
                    : fields.map((item2, index2) => this.renderCell(item, skipped + index + (item.child ? childIndex : topIndex), skipped + childIndex, item2, index2))}
                </tr>
              );
              if (item.type === 'group') {
                if (!item.open) {
                  skipped += rowNames[topIndex].children.length;
                }
              }
              if (!item.child) {
                topIndex++;
                if (item.type === 'row') {
                  childIndex++;
                }
              } else {
                childIndex++;
              }
              return render;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
DataGrid.defaultProps = defaultProps;
export default DataGrid;
