import {keys} from "lodash";
import { ConfigurationError } from "../Errors";
import assignPropertyDescriptors from "../helpers/extendPropertyDescriptors";
import { default as FieldConfig, IFieldSpec } from "./FieldConfig";
import {  Model } from "./Model";


interface IModelViewFunc<T extends Model> {
    (this: T, ...params: any[]): any;
}

export interface IModelSpec {
    id: string;
    fields: {
        [key: string]: IFieldSpec<any, any>;
    };
    views: {[key: string]: IModelViewFunc<any> | PropertyDescriptor};
    actions: {};
}

export interface IModelType<T extends Model> extends IModelSpec, ModelTypeMixin<T> {
    new(data?: object): T;
    fields: {
        [key: string]: FieldConfig<any, T>;
    };
    modelName: string;
}
// const wrap = (obj: any) => {
//     return new Proxy(obj, {
//         get(target, propKey): any {
//             console.log(`Reading property "${propKey.toString()}"`);
//             console.trace();
//             return target[propKey];
//         }
//     });
// };


export function defineModel<T extends Model>(spec: IModelSpec, modelName: string): IModelType<T> {
    const model = Model.define(spec) as IModelType<T>;
    assignPropertyDescriptors(model, ModelTypeMixin.prototype);
    model.modelName = modelName;
    model.fields = _.mapValues(spec.fields, (value: IFieldSpec<any, T>, key: string) => new FieldConfig(value, key));
    // model.fields = spec.fields;
    model.views = spec.views;
    model.actions = spec.actions;

    return model;
}

class ModelTypeMixin<T extends Model> implements Partial<IModelType<T>> {
    public modelName: string;
    public fields: {
        [key: string]: FieldConfig<any, T>;
    };
    public getFieldConfig(fieldName: string): FieldConfig<any, T> {
        const config = this.fields[fieldName];
        if(!config) {
            throw new ConfigurationError(`Field "${fieldName} not found on model "${this.modelName}"`);
        }
        return config;
    }

    public get formFields(): string[] {
        return keys(this.fields);
    }
}
