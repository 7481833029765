import each from "lodash/each";
import reduce from "lodash/reduce";
import iconSymbolizer from "./IconSymbolizer";

export const FaSizes = ["lg", "xs", "sm", "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"];
export const FaSizeMatch = /\bfa-(lg|xs|sm|\d\d?x)\b/;
export type FaSize = "lg" | "xs" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
export type FaFlip = "horizontal" | "vertical" | "both";
export type FaPull = "right" | "left";

const FaIcon = /\bfa-((?!fw\b|lg\b|spin\b|border\b|li\b|flip-\w+\b|pull-\w+\b|rotate-\w+\b|xs\b|sm\b|\d\d?x\b)[\w-]+)/;
const FaStyle = /\bfa[lrbs]?(?![\w-])/;
const NonFaClass = /(^|\s)(?!fa(?:[lrbs]?\b|-))[\w-]+/g;

export function addFaExtras(icon: string, fixedWidth?: boolean, large?: boolean): string {
    if (!FaIcon.test(icon)) {
        return icon;
    }
    if (!FaStyle.test(icon)) {
        icon = icon + " fa";
    }
    if (fixedWidth !== false) {
        icon = icon + " fa-fw";
    }
    if (large !== false) {
        icon = icon + " fa-lg";
    }
    return icon;
}


export interface IFAIcon {
    prefix?: string;
    iconName?: string;
}


export function getFaIcon(iconString: string): IFAIcon | null {
    const iconMatch = FaIcon.exec(iconString);
    if (!iconMatch) {
        return null;
    }

    const faStyleMatch = FaStyle.exec(iconString);

    return { prefix: faStyleMatch ? faStyleMatch[0] : "fa", iconName: iconMatch[1] };
}

export function simpleIconHtml(iconTxt: string, quoteChar: string = "'"): string {
    return `<i class=${quoteChar}${iconTxt}${quoteChar}></i>`;
}

export function iconHtml(iconTxt: string, quoteChar?: string): string {
    if (!iconTxt) {return "";}
    const result: string = (iconTxt.indexOf('<') >= 0) ? iconTxt : iconSymbolizer.getSybolizedIcon(addFaExtras(iconTxt));
    return quoteChar ? result.replace(/["']/g, quoteChar) : result;
}

export interface IFAIconProps {
    icon: IFAIcon | string | string[];
    className?: string;
    fixedWidth?: boolean;
    size?: FaSize;
    flip?: FaFlip;
    border?: boolean;
    mask?: object | string[] | string;
    listItem?: boolean;
    pull?: FaPull;
    pulse?: boolean;
    name?: string;
    rotation?: 90 | 180 | 270;
    spin?: boolean;
    symbol?: boolean | string;
    transform?: string | object;
}

const FaClassMap = {
    size: FaSizeMatch,
    flip: /\bfa-flip-(\w+)/,
    fixedWidth: /\bfa-fw\b/,
    border: /\bfa-border\b/,
    listItem: /\bfa-li\b/,
    pulse: /\bfa-pulse\b/,
    pull: /\bfa-pull-(\w+)/,
    rotation: /\bfa-rotate-(\w+)/,
};

export function getFaIconProps(iconString: string): IFAIconProps {
    if (iconString == null) {
        iconString = "";
    }
    const icon = getFaIcon(iconString);
    const nonFaClasses = iconString.match(NonFaClass);
    const className = nonFaClasses && nonFaClasses.join("").trim();
    return reduce(FaClassMap, (result: any, value: RegExp, key: keyof IFAIconProps) => {
        const matchResult = value.exec(iconString);
        if (matchResult) {
            result[key] = matchResult[1] || true;
        }
        return result;
    }, { icon, className });
}

interface IStyles {
    fa: { [key: string]: any };
    fab: { [key: string]: any };
    fal: { [key: string]: any };
    far: { [key: string]: any };
    fas: { [key: string]: any };
}

type IShim = [string, keyof IStyles | null, string];

export function enableFAShimsInNode(): void {
    if (!(global as any).___FONT_AWESOME___) {
        return;
    }
    const styles: IStyles = (global as any).___FONT_AWESOME___.styles;
    const shims: IShim[] = (global as any).___FONT_AWESOME___.shims;
    const fa = styles.fa;
    each(shims, (shim) => {
        const aliasName = shim[0];
        const targetBrand = shim[1] || "fa";
        const targetName = shim[2] || aliasName;

        fa[aliasName] = styles[targetBrand][targetName];
    });
}

enableFAShimsInNode();

