import classnames from "classnames";
import {IComputedValue} from "mobx";
import { useObserver } from "mobx-react-lite";
import * as Monaco from "monaco-editor";
import React, { useCallback, useContext, useRef } from "react";
import MonacoEditor  from "react-monaco-editor/lib/index";
import {Registry} from "react-registry";
import { beautifyHtml, beautifyJs } from "../../helpers";
import { FormContext } from "../FormState";
import { IEditorProps } from "./Editor";

export interface ITextEditorProps extends IEditorProps<string> {
    updateValueOn?: "blur" | "change" | "keyUp" | "keyDown";
    language?: string;
}

function watchBlur(value: IComputedValue<string>): (instance: editor.IStandaloneCodeEditor) => void {
    return (instance: Monaco.editor.IStandaloneCodeEditor, ...aa: any[]) => {
        console.log("EditorDidMount called", instance, aa);
        instance.onDidBlurEditorWidget(() => {
            value.set(instance.getValue());
        });
        instance.layout();
        console.log("EditorInstance", instance);
    };
}

const TextEditor: React.FunctionComponent<ITextEditorProps> = (
    { field, form, className, value, onValueChange, enabled, language, updateValueOn}: ITextEditorProps
) => {
    const blur = updateValueOn === "blur";

    // console.log("TextAreaProps", value, form);
    return useObserver(() => (
        <div className={classnames(className, "text-editor")} style={{padding: 0, height: "200px", width: "99.7%"}}>
            <MonacoEditor
                value={value.get()}
                language={language || "javascript"}
                editorDidMount={blur ? watchBlur(value) : undefined}
                onChange={useCallback((v) => value.set(v),[value])}
                options={{
                    minimap: { enabled: false },
                    automaticLayout: true,
                    autoIndent: true,
                    lineNumbers: "off"
                }}
            />
        </div>
    ));
};

TextEditor.displayName = "TextEditor";

Registry.register(TextEditor, {id: "TextEditor"});
export default TextEditor;

export const JavascriptEditor: React.FunctionComponent<ITextEditorProps> = (props: ITextEditorProps) => {
    // const mappedValue = useMemo(() => mapComputed(value, unknownToString, stringToObject), [value]);
    return TextEditor({ ...props, language: 'javascript'});
};


JavascriptEditor.displayName = "JavascriptEditor";

Registry.register(JavascriptEditor, {id: "JavascriptEditor"});


