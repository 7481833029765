import * as React from 'react';

interface IMenuHeaderProps {
  title: string;
}

/**
 * Extremely simple headers for Menu
 */
export default class MenuHeader extends React.Component<IMenuHeaderProps, never> {
  public render(): JSX.Element {
    const { title, children, ...other } = this.props;
    return <h5 {...other}>{title}</h5>;
  }
}
