import './Dialog.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import Modal from 'commonui/Modal';
import Button from './Button';

export const DialogContext = React.createContext({ resolve: null } as { resolve?: any });

/**
 * Creates a new GearDialog in the page
 * Look at GearsDialog constructor for props
 */
function gearsDialog(options: Partial<IDialogProps>): Promise<IDialogResolve> {
  console.debug('Creating dialog');
  return new Promise((resolve: (result: IDialogResolve) => void) => {
    let container = document.getElementById('modalContainer');
    if (!container) {
      container = document.createElement('div');
      container.id = 'modalContainer';
      document.body.appendChild(container);
    }
    ReactDOM.unmountComponentAtNode(container);
    ReactDOM.render(React.createElement(GearsDialog, { ...options, onClose: resolve }), container);
  });
}

interface IDialogResolve {
  button?: string;
}

const propTypes = {
  align: PropTypes.string,
  buttonJustify: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  custom: PropTypes.bool,
  icon: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.string,
};

export interface IDialogButton {
  name: string;
  value?: string;
  reject?: boolean;
  className?: string;
  onClick?: () => void;
  id?: string;
}

function iIDialogButton(buttonSpec: IDialogButton | string): IDialogButton {
  return typeof buttonSpec === 'string' ? { name: buttonSpec } : buttonSpec;
}

type IconType = 'error' | 'info' | 'check' | 'warning' | 'question';
type Align = 'left' | 'center' | 'right';

export interface IDialogProps {
  buttons: Array<IDialogButton | string>;
  width?: string;
  title: string;
  /** Content to be displayed inside dialog, if content is a function then the result is rendered */
  content?: string | (() => any);
  className?: string;
  onClose?: (result: any) => void;
  /** Controls the large icon that appears above title. Valid values are 'error', 'info', 'check', 'warning', 'question' */
  icon?: IconType;
  align?: Align;
  dialogStyle?: any;
  buttonJustify?: string;
  /** Replaces content, can render HTML directly into the dialog */
  children?: any;
  enablePolyfill?: boolean;
  target?: string | ITarget | HTMLElement;
  custom?: boolean;
}

interface ITarget {
  targetX: number;
  targetY: number;
}

interface IDialogState {
  openDialog: boolean;
}

export function closeDialog(): void {
  ReactDOM.unmountComponentAtNode(document.getElementById('modalContainer'));
}

const userAgent = (global as any).userAgent || 'node';

const isMobile = /Mobi/.test(userAgent);
const isIEorEdge = /rv:11.0/i.test(userAgent) || /Edge\/\d./i.test(userAgent);

/**
 * A dialog that presents several options to the user
 */
export class GearsDialog extends React.Component<IDialogProps, IDialogState> {
  public static defaultProps: IDialogProps = {
    align: 'center',
    buttonJustify: 'center',
    buttons: [{ name: 'Cancel', reject: true, value: 'cancel' }, 'OK'],
    content: '',
    title: '',
    width: 'width-75p width-400',
  };
  public static propTypes = propTypes;

  private result: any = { button: null };

  constructor(props: IDialogProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    window.addEventListener('keydown', this.handleKeyDown);

    this.state = { openDialog: true };
  }

  private handleKeyDown(e) {
    if (e.key === 'Escape') {
      if (this.props.onClose) {
        this.props.onClose(Promise.reject(null));
        this.close();
      }
    }
  }

  public close(): void {
    this.setState({ openDialog: false });
    window.removeEventListener('keydown', this.handleKeyDown);
    ReactDOM.unmountComponentAtNode(document.getElementById('modalContainer')!);
  }

  protected buttonResult(button: IDialogButton, e: any): any {
    return button.onClick ? button.onClick.call(this, e) : { button: button.value || button.name };
  }

  private handleClick(button: IDialogButton, e: any): Promise<any> {
    const res: Promise<any> = Promise.resolve().then(() => this.buttonResult(button, e));
    // button.onClick ? Bluebird.try(() => button.onClick.call(this, e)) : Bluebird.resolve({ button: button.value || button.name });

    // var res = button.onClick ? Bluebird.try(() => button.onClick.call(this, e)) : Bluebird.resolve(button.value || button);
    // res = res.tap(value => {
    // this.result = value;
    res.then(result => {
      if (button.reject) {
        result = Promise.reject(result);
      }
      if (this.props.onClose) {
        this.props.onClose(result);
      }
      this.close();
      console.debug('Called close', this.props.onClose, result);
      return result;
    });
    return res;
  }

  private renderContent(): JSX.Element {
    if (this.props.children) {
      return <span className="dialog-content">{this.props.children}</span>;
    } else {
      const contentString = typeof this.props.content === 'function' ? this.props.content() : this.props.content;
      return <span className="dialog-content" dangerouslySetInnerHTML={{ __html: contentString }} />;
    }
  }

  private renderButtons(): JSX.Element[] {
    const { buttons } = this.props;
    return (
      buttons &&
      buttons.map(item => {
        const button = iIDialogButton(item);
        return (
          <Button
            className={classNames('blue', button.className)}
            coloured={!isMobile}
            flat={isMobile}
            key={button.value || button.name}
            onClick={(e: any) => this.handleClick(button, e)}
            title={button.name}
            id={button.id}
            style={{ flex: 1, ...button.style }}
          />
        );
      })
    );
  }

  private renderIcon(icon?: string): JSX.Element | null {
    switch (icon) {
      case 'error':
        return (
          <div className="dialog-icon dialog-icon__error">
            <div className="circle" />
            <div className="cross" />
          </div>
        );
      case 'info':
        return (
          <div className="dialog-icon dialog-icon__info">
            <div className="circle" />
            <div className="info" />
          </div>
        );
      case 'check':
        return (
          <div className="dialog-icon dialog-icon__check">
            <div className="circle" />
            <div className="check" />
          </div>
        );
      case 'warning':
        return (
          <div className="dialog-icon dialog-icon__warning">
            <div className="circle" />
            <div className="warning" />
          </div>
        );
      case 'question':
        return (
          <div className="dialog-icon dialog-icon__question">
            <div className="circle" />
            <div className="question" />
          </div>
        );
      default:
        return null;
    }
  }

  public render(): JSX.Element {
    const { className, width, children, target, dialogStyle, enablePolyfill, buttonJustify, custom, ...other } = this.props;

    /*
        let targetObj;
        if (target) {
            if (!isTarget(target)) {
                targetObj = { targetX: window.innerWidth / 2, targetY: window.innerHeight / 2 };
                const $target = $(target);
                if ($target) {
                    const pos = $target.offset();
                    targetObj.targetX = pos.left + $target.outerWidth() / 2;
                    targetObj.targetY = pos.top + $target.outerHeight() / 2;
                }
            } else {
                targetObj = target;
            }
        }
        */

    if (custom && children) {
      return (
        <Modal
          className={classNames('modal-dialog2', 'custom', className)}
          style={dialogStyle}
          show={this.state.openDialog}
          dialogStyle={dialogStyle}
          enablePolyfill={enablePolyfill}
        >
          <DialogContext.Provider
            value={{
              resolve: (result: any) => {
                this.props.onClose(result);
                this.close();
              },
            }}
          >
            {children}
          </DialogContext.Provider>
        </Modal>
      );
    }

    return (
      <Modal
        className={classNames('mdl-skin', 'modal-dialog2', className, width)}
        style={dialogStyle}
        show={this.state.openDialog}
        dialogStyle={dialogStyle}
        enablePolyfill={enablePolyfill}
      >
        {this.props.icon && !isMobile && this.renderIcon(this.props.icon)}
        {this.props.title && <div className="title" dangerouslySetInnerHTML={{ __html: this.props.title }} />}
        <div className={classNames('content')} style={{ textAlign: isMobile ? 'left' : this.props.align }}>
          {this.renderContent()}
        </div>
        <div className={classNames('actions', { ieFix: isIEorEdge })} style={{ justifyContent: isMobile ? 'flex-end' : buttonJustify }}>
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }
}

function isTarget(arg: any): arg is ITarget {
  if (typeof arg !== 'object') {
    return false;
  }
  if (arg.targetX !== undefined && arg.targetY !== undefined) {
    return true;
  }
  return false;
}

export default gearsDialog;
