import "./TemplateGlobals";

import * as Promise from "bluebird";
import * as $ from "jquery";
import * as mobx from "mobx";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { ICompiledPageConfig, IComponentsMapped, IPageJS } from "./PageConfig";

import gearsDialog from "commonui/Dialog";
import Loader from "commonui/Loader";
import lite from "../../modules/LiteLoader";
import ErrorDisplayComponent from "../ErrorDisplayComponent";
import PageRenderer from "./PageRenderer";
import toPageJS from "./toPageJS";
import inspect from "inspect.macro";

const { observable, extendObservable } = mobx;
import registry from "../WebfrontRegistry";

declare const global: any;

function getLocationHashVariable(name: string, customWindow: any = window): string {
    const m = customWindow.location.hash.match(new RegExp(`${name}=([^&]+)`));
    return m && m[1];
}

function getLocationTimestamp(customWindow: any = window): string {
    const version = $.deparam(global.location.search.replace(/^\?/, "")).version;
    if (version) {
        return version;
    }
    const timestampMatch = global.location.pathname.match(/^\/t\/(\d+)\//);
    return timestampMatch && timestampMatch[1];
}

function getLocationWithoutTimestamp(customWindow: any = window): string {
    return global.location.pathname.replace(/^\/t\/\d+(?=\/)/, "");
}

const PATH_EXTRAS_R = /^(\/t\/\d+)?(\/v\/[\w-]{36})?(.*)$/;

function splitPathnameExtras(fullPathname: string) {
    const [timestamp, version, path] = fullPathname.match(PATH_EXTRAS_R) as RegExpMatchArray;
    if(!path) {
        return {path: fullPathname || ''};
    }
    return {timestamp, version, path};
}

class StandalonePage {
    @observable private displayPage: ICompiledPageConfig & IComponentsMapped;
    @observable private pageJS: IPageJS;
    @observable private page: any;

    constructor(componentId?: string) {
        global.ErrorDisplayComponent = ErrorDisplayComponent;
        // Load the other stylesheets
        const loadDeferredStyles = () => {
            const addStylesNode = document.getElementById("deferred-styles");
            const replacement = document.createElement("div");
            if (addStylesNode) {
                replacement.innerHTML = addStylesNode.textContent || "";
                document.body.appendChild(replacement);
                addStylesNode.parentElement.removeChild(addStylesNode);
            }
        };
        const raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        if (raf) {
            raf(() => {
                window.setTimeout(loadDeferredStyles, 0);
            });
        } else {
            window.addEventListener("load", loadDeferredStyles);
        }

        global.tp = this;
        this.loadTemplates(componentId);
    }

    private pageUrlFromLocation(customWindow: any = window) {
        return this.pageUrlFromPath(customWindow.location.pathname);
    }

    private pageUrlFromPath(fullPath: string) {
        const {version, path, timestamp} = splitPathnameExtras(fullPath);

        let base: string;

        if (version) {
            base =  `/display_page/${version}`;
        } else {
            const urlMatch = path.match(/\/p\/([^\/]+)\/(([^\/]+)\/)?([^\/]+)/);
            if (!urlMatch) {
                base = global.location.pathname;
                // this.errorMessage(new Error("Cannot determine Template Page url"));
                // throw new Error("Cannot determine Template Page url");
            } else {
                const [mat, controller, idgroup, id, identifier] = urlMatch;
                if (!identifier) {
                    base = `/display_page/${controller}/${id}`;
                } else {

                    base =  `/display_page/${controller}/${identifier}`;
                }

            }
        }
        if (timestamp) {
            return `/t/${timestamp}${base}`;
        } else {
            return base;
        }
    }

    private loadTemplates(componentId?: string) {
        return Promise.resolve(global._code)
            .then((code) => {
                if(code) {
                    console.debug("code was preloaded");
                    return code;
                }
                const url: string = this.pageUrlFromLocation();
                console.debug("Loading templates", url);
                return $.ajax({
                    url,
                    dataType: "json"
                });
            })
            .then(this.checkIfError)
            .then(this.onLoadTemplates)
            .catch(this.errorMessage);
    }

    private checkIfError(data: any) {
        if (typeof data === "string" && data.includes("<!DOCTYPE html>")) {
            throw new Error("PermissionDeniedError");
        }
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    }

    private errorMessage(error: any) {
        console.error(error);
        if (error.responseText.includes("PermissionDeniedError")) {
            gearsDialog({
                buttons: ["Login"],
                children: <div>
                    <p>Please note: You may need to login to access this page</p>
                    <p>If you are already logged in and believe you should be able to see this resource please email
                        support</p>
                </div>,
                className: "width-50p width-600",
                icon: "error",
                title: "Login Required",
                width: ""
            }).then(() => {
                const url = btoa(location.toString());
                window.location.assign(`/login?redirect=${url}`);
            });
        } else {
            let errorMessage = error.responseText || error.toString();
            if (error.title) {
                errorMessage = `${error.title} - ${error.message}`;
            }
            if (error.responseJSON) {
                errorMessage = `${error.responseJSON.title} - ${error.responseJSON.message}`;
            }
            gearsDialog({
                buttons: ["OK"],
                children: (
                    <div>
                        <p>Unable to load page</p>
                        <div>{errorMessage}</div>
                    </div>
                ),
                className: "width-50p width-600",
                icon: "error",
                title: "Error",
                width: ""
            });
        }
    }

    private onLoadTemplates = (displayPage: any) => {
        console.debug("Loaded templates", displayPage);
        this.displayPage = displayPage;
        Promise.try<any>(() => {
                return (this.displayPage.state === "compiled" ? null : lite.templates);
            })
            .catch((e) => {
                console.error(e);
            })
            .then(() => {
                this.pageJS = toPageJS(this.displayPage);
                this.runPreload();
            });
    };

    private runPreload() {
        this.page = observable({ loaded: false, errors: [] });
        const preload: (page: any, getComponent: any) => Promise<any> = this.pageJS.preload as () => Promise<any>;
        console.log("[STARTUP]", "Loading Data");
        const start = performance.now();
        Promise.resolve(preload ? preload.call(this, this.page, this.getComponent) : this.loadData())
            .then((data) => {
                console.log("[STARTUP]", `Finished loading data in ${(performance.now() - start).toFixed(0)}ms`);
                return data;
            })
            .then(this.checkIfError)
            .then(this.onLoadData)
            .catch(this.errorMessage);
    }

    public loadData() {
        let url: string;
        let { dataSource, gearsRoute } = this.pageJS;

        const {id, controller, timestamp} = $('body').data();

        // const urlMatch = global.location.pathname.match(/\/p\/([^\/]+)\/(([^\/]+)\/)?([^\/]+)/);
        // if (!urlMatch) {
        //     throw new Error("Cannot determine Data URL");
        // }
        // const [mat, controller, idgroup, id, versionIdentifier] = urlMatch || [];
        gearsRoute = gearsRoute || controller;
        if (id) {
            url = `/${gearsRoute}/${id}${dataSource ? `/${dataSource}` : ``}`;
        } else {
            url = `/${gearsRoute}/${dataSource}`;
        }

        return $.ajax({
            url,
            dataType: "json"
        });
    }

    private onLoadData = (data: any) => {
        // Add data
        console.log("[STARTUP]", "raw data", data);
        extendObservable(this.page, { data });
        global.page_data = this.page.data || {};
        global.pageData = global.page_data;
        global.page = this.page;
        console.log("[STARTUP]", "observable data", this.page);
        this.render();
        this.page.loaded = true;
    };

    private getComponent(n: string) {
        return global[n] || registry.getComponent(n);
    }

    private render() {
        const $preloader = $("#preloader");
        const div = document.createElement("div");
        div.setAttribute("id", "standalone-root");
        div.style.transition = "opacity 600ms";
        div.style.opacity = "0";
        document.body.appendChild(div);
        setTimeout(() => {
            $preloader.css({ backgroundColor: "unset" });
            div.style.opacity = "1";
        }, 1);
        setTimeout(() => {
            if ($preloader) {
                $preloader.remove();
            }
        }, 600);
        const em = location.search.match(/email=(\w+)/);
        if(em) {
            return lite.email.then((e) => {

                global.EMAIL_COMPONENT =  this.getComponent(em[1]);
                console.log("rendering", this.pageJS, global.EMAIL_COMPONENT);
                const rendered = e.renderComponentEmail(this.page.data, {}, global.EMAIL_COMPONENT);
                console.log("rendered", rendered);
                div.innerText = rendered;
                div.setAttribute('email',em[1]);
            });
        }
        ReactDOM.render(
            <ErrorDisplayComponent>
                <React.Suspense fallback="Loading...">
                    <PageRenderer
                        pageJS={this.pageJS}
                        pageData={this.page.data}
                        renderWindow={window}
                        getComponent={this.getComponent}
                        page={this.page}
                    />
                </React.Suspense>
            </ErrorDisplayComponent>
            , div);
    }
}

export default StandalonePage;
