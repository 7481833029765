import * as Promise from "bluebird";
import {pluralize, singularize} from "inflection";
import * as _ from "lodash";
import vendor from "../../modules/vendor";

declare const Gears: any;
declare const gearsState: any;

export function fetchAutoConcerns() {
    if (Gears._autoConcernPromise == null) {
        Gears._autoConcernPromise = Promise.resolve(Gears.Auto || $.getScript("/gearsjs.js?view=none"))
            .then(function(data) {

                if (Gears.Auto) {
                    const state = Gears.Auto.extras || {};
                    if (Gears.Auto.Nav) {
                        state.nav = Gears.Auto.Nav;
                    }
                    gearsState.setState(state);
                }
                return Gears.Auto;
            })
            .catch(function(error) {
                return console.log("Error loading auto", error);
            });
    }

    return Gears._autoConcernPromise;
}

export function useConcerns(models: any, source: any) {
    return fetchAutoConcerns()
        .then(function() {
            return setConcerns(models, source);
        });
}

export function setConcerns(models: any, source: any) {
    if (!source) {
        source = Gears.Concerns;
    }
    return _.each(models, function(model) {
        let singular, plural;
        singular = singularize(model);
        plural = pluralize(model);
        if (source.Grids[plural]) {
            Gears.Grids[plural] = _.clone(source.Grids[plural], true);
        }
        if (source.Models[singular]) {
            Gears.Models[singular] = _.clone(source.Models[singular], true);
        }
        if (source.Views[plural]) {
            return Gears.Views[plural] = source.Views[plural];
        }
        if (source.Templates && source.Templates[singular]) {
            Gears.Models[singular] = _.clone(source.Templates[singular], true);
        }
    });
}

export function useDolphinConcerns(models: any) {
    // console.log("Prepare dolphin concerns");
    return fetchAutoConcerns()
        .then(() => vendor.dolphin)
        .then((dolphin: any) => {
            const m = models ? models : _.map(_.flatten(_.map(["Views", "Grids", "Models"], (k) => _.keys(dolphin[k]))), (s) => s.pluralize().singularize());
            setConcerns(m, dolphin);
        },
    );
}

export function useAllConcerns(source: any) {
    source = source ? source : Gears.Concerns;
    return fetchAutoConcerns().then(function() {
        // console.log("USING ALL", source);
        return _.each(["Grids", "Models", "Views", "Templates"], function(type) {
            if (!source[type]) {
                return;
            }
            return _.each(source[type], function(val, index: number) {
                let ref$;
                (ref$ = Gears[type])[index] == null && (ref$[index] = {});
                return _.defaults(Gears[type][index], _.cloneDeep(val));
            });
        });
    });
}

export function useAllAutoConcerns() {
    // console.log("Start UAAUCO");
    return fetchAutoConcerns()
        .then(function(it: any) {
            return useAllConcerns(it);
        });
}
