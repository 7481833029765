/// <reference path="../global.d.ts" />
import * as monaco from 'monaco-editor';
// import * as csvtojson from "../../node_modules/@types/csvtojson";
import { Loader, memoizedLoader } from './Loader';
import { ISassModule } from './sass';
import { TemplatesCompilers } from './templates';

// declare var require: {
//     <T>(path: string): T;
//     (paths: string[], callback: (...modules: any[]) => void): void;
//     ensure: (paths: string[], callback: (require: <T>(path: string) => T) => void) => void;
// };
(global as any).Promise = Promise;
(global as any).Bluebird = Promise;
if (Promise.longStackTraces) {
    Promise.longStackTraces();
}

// declare const csvtojson: import('csvtojson');

export class LiteLoader extends Loader {
    modules: { templates: TemplatesCompilers; sass: ISassModule; csvtojson: csvtojson.Converter };

    constructor(loadTarget?: any, preload?: string[]) {
        super(loadTarget, preload);
    }

    // @memoizedLoader
    // get sass(): Promise<ISassModule> {
    //     return Promise.resolve(import(/* webpackChunkName: "sass" */ "./sass"));
    // }

    @memoizedLoader
    get csvtojson(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "csvtojson" */ 'csvtojson'));
    }

    @memoizedLoader
    get templateBuilder(): Promise<TemplatesCompilers> {
        const tc = Promise.resolve(import(/* webpackChunkName: "template_builder" */ '../react-components/template_builder/TemplateBuilder'));
        return Promise.join(tc, this.templates, tb => ((global as any).templateBuilder = tb)).return(tc);
    }

    @memoizedLoader
    get templateHelp(): Promise<TemplatesCompilers> {
        const tc = Promise.resolve(import(/* webpackChunkName: "template_help" */ '../react-components/template_builder/help/TemplateHelp'));
        return tc;
        return Promise.join(this.templates, th => ((global as any).templateHelp = th)).return(tc);
    }

    @memoizedLoader
    get templates(): Promise<TemplatesCompilers> {
        return new Promise(resolve => {
            require.ensure(
                ['./templates'],
                () => {
                    resolve(System.import('./templates'));
                },
                'templates',
            );
        }).tap((templates: TemplatesCompilers) => ({ ...global, ...templates })) as Promise<TemplatesCompilers>;
    }

    @memoizedLoader
    get dropzone(): Promise<any> {
        return new Promise(resolve => {
            require.ensure(
                ['dropzone/dist/min/dropzone.min.css', 'react-dropzone-component/styles/filepicker.css', 'react-dropzone-component'],
                () => {
                    resolve(System.import('react-dropzone-component'));
                },
                'dropzone',
            );
        });
    }

    @memoizedLoader
    get charts(): Promise<any> {
        const charts = import(/* webpackChunkName: "recharts" */ 'recharts');
        return charts.then(recharts => {
            global.Recharts = recharts;
            return recharts;
        });
    }

    @memoizedLoader
    get monaco(): Promise<typeof monaco> {
        return import(/* webpackChunkName: "monaco" */ 'monaco-editor');
    }
}

const liteLoader = new LiteLoader();
export default liteLoader;
