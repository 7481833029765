import { bind } from 'decko';
import { IObservableObject, IObservableValue, isObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import RadioContext, { IRadioContext } from './RadioContext';

const defaultProps = {
  enabled: true,
};

interface IBaseRadioGroupProps {
  name?: string;
  onChange?: (value: string) => void;
  enabled?: boolean;
  disabled?: boolean;
  label?: string;
}

interface IStandardRadioGroupProps extends IBaseRadioGroupProps {
  observable?: never;
  value: string | IObservableValue<string>;
}

interface IObservableRadioGroupProps extends IBaseRadioGroupProps {
  observable: IObservableObject;
  value: string;
}

type RadioGroupProps = IStandardRadioGroupProps | IObservableRadioGroupProps;

/**
 * A RadioGroup holds multiple Radio's and manages their state
 */
@observer
export default class RadioGroup extends React.Component<RadioGroupProps, never> {
  public static defaultProps = defaultProps;

  @bind
  private onChange(newValue: string): void {
    if (this.props.observable) {
      this.props.observable[this.props.value] = newValue;
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
      return;
    }
    if (isObservable(this.props.value)) {
      const value = this.props.value as IObservableValue<string>;
      value.set(newValue);
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  public render(): JSX.Element {
    const { children, name, observable, value, enabled, disabled, onChange, label, ...other } = this.props;

    let realValue = value;
    if (observable) {
      realValue = observable[value];
    } else {
      if (isObservable(value)) {
        realValue = (value as IObservableValue<string>).get();
      }
    }
    realValue = realValue as string;
    const context: IRadioContext = {
      onChange: this.onChange,
      setDisabled: disabled,
      setValue: realValue,
    };

    return (
      <div {...other}>
        <RadioContext.Provider value={context}>
          {children}
          <input aria-label={label} name={name} value={realValue} disabled style={{ display: 'none' }} />
        </RadioContext.Provider>
      </div>
    );
  }
}

export { RadioGroup as MobXRadioGroup };
