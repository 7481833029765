import { underscore } from 'inflection';
import { observable, observe } from 'mobx';

interface IURLOptions<T> {
  /** Values to be tracked and their default values, if no default is required use undefined */
  defaults: T & { [key: string]: any };
  /** Mapping from field name to url parameter, to prettify output */
  mapping?: { [key: string]: string };
  /** If present, maps a variables javascript value to a string value before being put in URL (useful for converting moment objects to their string counterparts) */
  transformToURL?: {
    [P in keyof T]?: (value: any) => string;
  };
  /** If present, maps a URL parameter to a variable using a custom function, (useful for converting a date to a moment object) */
  transformToObject?: {
    [P in keyof T]?: (value: string | boolean | number) => any;
  };
  // pushState?: boolean;
}

export type URLObservable<T> = {
  [P in keyof T]: any;
};

function strMapToObj(strMap: URLSearchParams): any {
  const obj = Object.create(null);
  for (const [k, v] of strMap) {
    obj[k] = v;
  }
  return obj;
}

/**
 * Creates an observable object that updates the URL search parameters when values change
 */
export default function urlObservable<T>(options: IURLOptions<T>): URLObservable<T> {
  const data: URLObservable<T> = observable(options.defaults);
  // Detect if we're running in a jQuery environment (IE11), otherwise use native URLSearchParams
  const urlParams: { [key: string]: string } = global.$ ? $.deparam(location.search.substr(1)) : strMapToObj(new URLSearchParams(location.search));
  if (urlParams) {
    for (const key of Object.keys(options.defaults)) {
      const mappedKey = options.mapping ? (options.mapping[key] ? options.mapping[key] : underscore(key)) : underscore(key);
      if (urlParams[mappedKey] !== undefined) {
        if (Array.isArray(urlParams[mappedKey])) {
          const arr = [];
          for (const item of urlParams[mappedKey]) {
            let convertedValue;
            if (item.includes('-')) {
              convertedValue = item;
            } else {
              convertedValue = isNaN(parseFloat(item)) ? (item === 'true' ? true : item === 'false' ? false : item) : parseFloat(item);
            }
            if (options.transformToObject) {
              const transformer = options.transformToObject[key as keyof T];
              if (transformer) {
                arr.push(transformer(convertedValue));
                continue;
              }
            }
            arr.push(convertedValue);
          }
          data[key as keyof T] = arr;
        } else {
          // Convert from URL strings to number or boolean if needed
          let convertedValue;
          if (urlParams[mappedKey].includes('-')) {
            convertedValue = urlParams[mappedKey];
          } else {
            convertedValue = isNaN(parseFloat(urlParams[mappedKey]))
              ? urlParams[mappedKey] === 'true'
                ? true
                : urlParams[mappedKey] === 'false'
                ? false
                : urlParams[mappedKey]
              : parseFloat(urlParams[mappedKey]);
          }
          if (options.transformToObject) {
            const transformer = options.transformToObject[key as keyof T];
            if (transformer) {
              data[key as keyof T] = transformer(convertedValue);
              continue;
            }
          }
          data[key as keyof T] = convertedValue;
        }
      }
    }
  }
  observe(data, () => {
    const finalURLParams: any = {};
    for (const key in data) {
      if (data[key] !== undefined && data[key] !== options.defaults[key]) {
        const mappedKey = options.mapping ? (options.mapping[key] ? options.mapping[key] : underscore(key)) : underscore(key);
        if (options.transformToURL) {
          const transformer = options.transformToURL[key as keyof T];
          if (transformer) {
            finalURLParams[mappedKey] = transformer(data[key]);
            continue;
          }
        }
        finalURLParams[mappedKey] = data[key];
      }
    }
    const params = global.$ ? $.param(finalURLParams) : new URLSearchParams(finalURLParams).toString();
    history.replaceState(null, '', location.pathname + '?' + params + location.hash);
  });
  return data;
}
