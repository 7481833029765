declare let global: any;
declare const require: Function;

import '../react-components/react-ribbon-bar';

let jQuery, bootstrapContextmenu, velocityAnimate, velocity, inflection, juration, favAwesome, smart_admin, starRating, clipboard, jqueryUi, url, e, kendo;
jQuery = require('jquery');
global.$ = jQuery;
global.jQuery = jQuery;
bootstrapContextmenu = require('../../vendor/assets/javascripts/bootstrap-contextmenu');

// inflection = require("../../vendor/assets/javascripts/inflection");
juration = require('../../vendor/assets/javascripts/juration');
favAwesome = require('./../extensions/FaviconAwesome').default;
smart_admin = require('../../vendor/assets/javascripts/smart_admin');
clipboard = require('clipboard-js');
global.favAwesome = favAwesome;
global.juration = juration;
global.clipboard = clipboard;

global.platform = require('platform');
// global.typeahead = {
//   Bloodhound: require("corejs-typeahead/dist/bloodhound.js"),
//   loadjQueryPlugin() {
//     return require("corejs-typeahead");
//   },
// };
// global.typeahead.loadjQueryPlugin();
jqueryUi = require('../../vendor/assets/jquery-ui-1.12.0.custom/jquery-ui');
url = require('../../vendor/assets/javascripts/url.min.js');
try {
    global.dialogPolyfill = require('../../vendor/assets/dialog-polyfill/dialog-polyfill');
} catch (e$) {
    e = e$;
}
require('hoverintent-jqplugin')($);
require('superfish');
global.screenfull = require('screenfull');
global.webuiPopover = require('../../vendor/assets/webui-popover/jquery.webui-popover.js');
// global.moreKendo = require('kendo').moreKendo;
// require("kendo-ui/js/kendo.web");
// require("kendo-ui/js/kendo.mobile");
// require("kendo-ui/js/kendo.drawing");
