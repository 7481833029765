import Class from 'classnames';
import { bind } from 'decko';
import * as marked from 'marked';
import * as React from 'react';
import Portal from './Portal';
import './Tooltip.scss';

declare const global: any;

type TooltipEvent = 'hover' | 'click';
type Mount =
  | 'right-top'
  | 'right-bottom'
  | 'right-center'
  | 'left-top'
  | 'left-bottom'
  | 'left-center'
  | 'above-left'
  | 'above-right'
  | 'above-center'
  | 'below-left'
  | 'below-right'
  | 'below-center';

interface ITooltipProps {
  className?: string;
  content?: (e: React.SyntheticEvent<HTMLElement>) => string;
  event: TooltipEvent;
  mount: Mount;
  target?: any;
}

interface ITooltipState {
  visible: boolean;
}

const isMobile = window.orientation !== undefined;

export default class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
  public static defaultProps = { event: 'hover', mount: 'right-top' };
  private ignore = false;
  private toolRef: HTMLElement | null;
  private currentTarget: any;
  private mounting: string;
  private longClickTimer?: NodeJS.Timer;

  public static fromAttribute(e) {
    if (e.dataset.tooltip) {
      return e.dataset.tooltip;
    }
    return undefined;
  }

  constructor(props: ITooltipProps) {
    super(props);

    this.state = { visible: false };
    this.mounting = props.mount;
  }

  private computePosition(override: Mount | string | undefined, target: any): void {
    if (!this.toolRef) {
      return;
    }
    let mount: Mount | string = override || this.props.mount || 'right-top';
    const targetBounds = target.getBoundingClientRect();
    const targetWidth = targetBounds.width;
    const targetHeight = targetBounds.height;
    this.toolRef.style.display = 'block';
    const toolWidth = this.toolRef.offsetWidth || 0;
    const toolHeight = this.toolRef.offsetHeight || 0;
    this.toolRef.style.display = '';
    const currentTarget = typeof this.props.target === 'string' ? (document.querySelector(this.props.target) as HTMLDivElement) : (this.props.target as HTMLDivElement);
    const position = currentTarget.getBoundingClientRect();
    // Fix for IE11/Edge not having x/y properties
    if (position.x === undefined) {
      position.x = position.left;
      position.y = position.top;
    }
    let requiresOverride = false;

    let left = 0;
    let top = 0;
    if (mount.includes('right-')) {
      left = position.x + targetWidth + 8;
    }
    if (mount.includes('left-')) {
      left = position.x - toolWidth - 16;
    }
    if (mount.includes('above-')) {
      top = position.y - toolHeight - 24;
    }
    if (mount.includes('below-')) {
      top = position.y + targetHeight + 8;
    }

    if (mount.includes('-top')) {
      top = position.y - 8;
    }
    if (mount.includes('-bottom')) {
      top = position.y + targetHeight - toolHeight;
    }
    if (mount.includes('left-') || mount.includes('right-')) {
      if (mount.includes('-center')) {
        top = position.y + targetHeight / 2 - (toolHeight + 16) / 2;
      }
    }
    if (mount.includes('-right')) {
      left = position.x - 8;
    }
    if (mount.includes('-left')) {
      left = position.x - toolWidth - 8 + targetWidth;
    }
    if (mount.includes('above-') || mount.includes('below-')) {
      if (mount.includes('-center')) {
        left = position.x + targetWidth / 2 - (toolWidth + 16) / 2;
      }
    }

    // Override and reverse mounting if we hit edges of screen
    if (left < 0) {
      mount = mount.replace('left-', 'right-');
      requiresOverride = true;
    }
    if (top < 0) {
      mount = mount.replace('above-', 'below-');
      requiresOverride = true;
    }
    if (left + toolWidth > window.innerWidth) {
      mount = mount.replace('right-', 'left-');
      requiresOverride = true;
    }
    if (top + toolHeight > window.innerHeight) {
      mount = mount.replace('below-', 'above-');
      requiresOverride = true;
    }

    if (!requiresOverride || override) {
      if (left + toolWidth > window.innerWidth) {
        left = window.innerWidth - toolWidth - 16;
      }
      this.toolRef.style.top = top + 'px';
      this.toolRef.style.left = left + 'px';
    } else {
      this.computePosition(mount, target);
    }
  }

  @bind
  private startLongClick(e): void {
    this.longClickTimer = setTimeout(() => {
      e.preventDefault();
      this.toggle(e);
    }, 750);
  }

  @bind
  private endLongClick(): void {
    if (this.longClickTimer) {
      clearTimeout(this.longClickTimer);
      this.longClickTimer = undefined;
    }
  }

  private attach(props: ITooltipProps): void {
    if (props.target) {
      const target = document.querySelector(props.target);
      if (target) {
        if (props.event === 'hover') {
          if (!isMobile) {
            target.addEventListener('mouseenter', this.show);
            target.addEventListener('mouseleave', this.hide);
          } else {
            target.addEventListener('touchstart', this.startLongClick);
            target.addEventListener('touchend', this.endLongClick);
          }
        } else if (props.event === 'click') {
          target.addEventListener('click', this.toggle);
        }
      }
    } else {
      document.querySelectorAll('[data-tooltip]').forEach(item => {
        item.addEventListener('mouseenter', this.show);
        item.addEventListener('mouseleave', this.hide);
      });
    }
  }

  public componentDidMount(): void {
    this.attach(this.props);
  }

  public componentWillUnmount(): void {
    if (this.props.target) {
      const target = document.querySelector(this.props.target);
      if (target) {
        if (this.props.event === 'hover') {
          if (!isMobile) {
            target.removeEventListener('mouseenter', this.show);
            target.removeEventListener('mouseleave', this.hide);
          } else {
            target.removeEventListener('touchstart', this.startLongClick);
            target.removeEventListener('touchend', this.endLongClick);
          }
        } else if (this.props.event === 'click') {
          target.removeEventListener('click', this.toggle);
        }
      }
    } else {
      document.querySelectorAll('[data-tooltip]').forEach(item => {
        item.removeEventListener('mouseenter', this.show);
        item.removeEventListener('mouseleave', this.hide);
      });
    }
    if (global._masterTooltip === this) {
      global._masterTooltip = null;
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: ITooltipProps): void {
    const target = document.querySelector(this.props.target);
    if (target) {
      if (this.props.event === 'hover') {
        if (!isMobile) {
          target.removeEventListener('mouseenter', this.show);
          target.removeEventListener('mouseleave', this.hide);
        } else {
          target.removeEventListener('touchstart', this.startLongClick);
          target.removeEventListener('touchend', this.endLongClick);
        }
      } else if (this.props.event === 'click') {
        target.removeEventListener('click', this.toggle);
      }
    }
    this.mounting = newProps.mount;
    this.attach(newProps);
  }

  @bind
  private show(e): void {
    this.mounting = (e.currentTarget.attributes['data-tooltip-mount'] && e.currentTarget.attributes['data-tooltip-mount'].value) || this.props.mount;
    this.currentTarget = e.currentTarget;
    if (this.props.content && this.toolRef) {
      this.toolRef.innerHTML = marked(this.props.content(this.currentTarget));
    }
    this.computePosition(this.mounting, e.currentTarget);
    this.becomeMasterTooltip();
    this.setState({ visible: true });
    e.stopPropagation();
  }

  @bind
  private hide(): void {
    document.removeEventListener('click', this.toggle);
    this.setState({ visible: false });
  }

  @bind
  private toggle(e): void {
    if (!this.ignore && this.toolRef) {
      this.ignore = true;
      setTimeout(() => (this.ignore = false), 20);
      this.setState({ visible: !this.state.visible });
      if (this.state.visible) {
        this.becomeMasterTooltip();
        window.addEventListener('click', this.hide);
        window.addEventListener('touchstart', this.hide);
        this.toolRef.addEventListener('click', this.onClick);
      } else {
        window.removeEventListener('click', this.hide);
        window.removeEventListener('touchstart', this.hide);
        this.toolRef.removeEventListener('click', this.onClick);
      }
      if (e && this.state.visible) {
        this.computePosition(undefined, e.currentTarget);
        this.currentTarget = e.currentTarget;
        e.stopPropagation();
      }
    }
  }

  @bind
  private onClick(e: Event): void {
    e.stopPropagation();
  }

  private becomeMasterTooltip(): void {
    if (global._masterTooltip) {
      if (global._masterTooltip !== this) {
        global._masterTooltip.hide();
      }
    }
    global._masterTooltip = this;
  }

  public render(): JSX.Element | null {
    const { target, mount, event, className, children, content, ...other } = this.props;
    const mounts = this.mounting.split('-');
    mounts[1] = '-' + mounts[1];
    if (typeof this.props.target === 'function') {
      return null;
    }
    let realContent;
    if (this.currentTarget && content) {
      realContent = content(this.currentTarget);
    } else {
      realContent = children;
    }
    return (
      <Portal>
        <div
          role="tooltip"
          ref={ref => (this.toolRef = ref)}
          className={Class('react-tooltip', this.mounting, ...mounts, className, { visible: this.state.visible && (content || children) !== undefined })}
          {...other}
        >
          {(!this.currentTarget || !this.props.content) && children}
        </div>
      </Portal>
    );
  }
}
