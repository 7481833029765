import Promise from "bluebird";
import React, { Suspense, useMemo } from "react";
import { Model } from "..";
import ErrorDisplayComponent from "../../react-components/ErrorDisplayComponent";
import { AnyLazySource } from "../../react-components/WebfrontRegistry";
import gearsDialog, { DialogContext, GearsDialog } from "../../submodules/commonui/Dialog";
import Form from "../Forms/Form";
import FormState from "../Forms/FormState";
import { useModel } from "../Forms/hooks";
import InteractiveEmail from "./InteractiveEmail";

const PreviewForm = ({ emailData, stateRef, record, source}: { record: Model, emailData: any, stateRef: React.MutableRefObject<FormState<any> | null>, source: string | AnyLazySource}) => {
    const GearsEmail = useModel("GearsEmail");
    const formState = useMemo(() => {
        const p = Promise.resolve(emailData);
        // if(!p.isPending()) {
        //     throw p;
        // }
        if(!p.isFulfilled()) {
            console.log("Failed to load data", p);
            throw p;
        }
        console.log("Raw data loaded: ", p.value());
        const emailRecord = new GearsEmail(p.value());
        return new FormState(emailRecord);
    }, [emailData, GearsEmail]);
    (global as any).formState = formState;
    stateRef.current = formState;
    return <InteractiveEmail record={record} formState={formState} source={source} formProps={{style: {height: "90%", textAlign: "left"}}} />;
};

export function previewEmail(emailData: any, record: Model, source: string | AnyLazySource): Promise<Model> {
    const formStateRef = React.createRef<FormState<any> | null>();
    return gearsDialog({
        width: "width-75p",
        children: (
            <ErrorDisplayComponent><Suspense fallback="Loading..."><PreviewForm emailData={emailData} record={record} stateRef={formStateRef} source={source}/></Suspense></ErrorDisplayComponent>
        )
    }).then(() => {
        console.log("Email submitted", formStateRef.current);
        return formStateRef.current!.record;
    });
}

export default previewEmail;
