import Class from 'classnames';
import * as React from 'react';
import Markdown from '../public/Markdown';
import styles from './Note.scss';
import INoteType from './NoteType';
import relativeTime from './relativeTime';

interface IChildNoteProps {
    note: INoteType;
}

export default class ChildNote extends React.Component<IChildNoteProps, never> {
    public render(): JSX.Element {
        const { note } = this.props;

        const user = note.webfront_relations.user;
        return (
            <div className={Class(styles.note, styles.child, 'note', 'child')}>
                <div className={Class(styles['user-area'], 'user-area')}>
                    {user && <span className={Class(styles.initials, user.initials_class, 'initials')}>{user.initials}</span>}
                    {user && <span className={Class(styles['user-name'], 'user-name')}>{user.text}</span>}
                    {!user && <span className={Class(styles['user-name'], 'user-name')}>Unknown User</span>}
                    <span className={Class(styles.timestamp, 'timestamp')}>posted {relativeTime(note.created_at)} ago</span>
                </div>
                <Markdown className={Class(styles['note-message'], 'note-message')}>{note.note_text}</Markdown>
            </div>
        );
    }
}
