import {cnb as classCat} from 'cnbuilder';
import ActionItemArea from 'commonui/ActionBar/ActionItemArea';
import ActionTab from 'commonui/ActionTab';
import ActionTabs from 'commonui/ActionTabs';
import { bind } from 'decko';
import find from 'lodash/find';
import { action, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { isArray } from 'util';
import ActionItem from '../ActionItem';
import Icon from '../Icon';
import Input from '../Input';
import { Menu, MenuItem } from '../Menu';
import styles from './ActionBar.scss';
import If from '@ts-delight/if-expr.macro';

interface IFilterOptions {
  value: string;
  label: string;
}

interface IActionBarProps {
  activeFilter?: string;
  filterOptions?: IFilterOptions[];
  hasTabBar?: boolean;
  isLoading?: boolean;
  name?: string;
  onBackPressed?: () => void;
  onChangeFilter?: (value: IFilterOptions) => void;
  onSearch?: (search: string) => void;
  onSearchChange?: (val: any) => void;
  onSidebarPressed?: () => void;
  searchMulti?: boolean;
  searchObservable?: { [key: string]: any };
  searchOptions?: any;
  searchValue?: any;
  selectSearch?: boolean;
  sidebar?: boolean;
  /** Scroll dynamically with javascript to auto-hide */
  smartScroll?: boolean;
  store?: any;
  subtitle?: string;
  title?: string;
  titleImage?: string;
  transparent?: boolean;
}

interface IActionBarState {
  showingSearch: boolean;
}

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(global as any).MSStream;

@inject(props => props)
@observer
export default class ActionBar extends React.Component<IActionBarProps, IActionBarState> {
  public static Tabs = ActionTabs;
  public static Tab = ActionTab;
  public static Item = ActionItem;
  private searchInputRef: Input | null;
  private actionbarRef: HTMLDivElement | null;
  private lastScroll = 0;

  constructor(props: IActionBarProps) {
    super(props);

    this.state = { showingSearch: false };
  }

  public componentDidMount(): void {
    if (this.props.smartScroll) {
      document.addEventListener('scroll', this.onScroll);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.smartScroll) {
      document.removeEventListener('scroll', this.onScroll);
    }
  }

  public render(): JSX.Element {
    const {
      name,
      title,
      subtitle,
      sidebar,
      onSidebarPressed,
      hasTabBar,
      smartScroll,
      transparent,
      titleImage,
      store,
      onBackPressed,
      searchObservable,
      activeFilter,
      filterOptions,
      isLoading,
      searchValue,
      searchOptions,
      searchMulti,
      onSearchChange,
      children,
    } = this.props;

    const hasLeftIcon = sidebar || onBackPressed !== undefined;
    const hasFilter = filterOptions !== undefined;
    const activeFilterObject = find(filterOptions || [], { value: activeFilter }) || { label: 'Error' };
    let displayTitle = title;
    if (searchObservable && searchObservable[searchValue]) {
      displayTitle = searchObservable[searchValue];
    }

    const realChildren: any[] = If(isArray(children))
      .then([...(children as any[])])
      .else([children])();

    if (searchObservable && !this.state.showingSearch) {
      realChildren.splice(0, 0, <ActionBar.Item title="Search" icon="fa-search" onClick={this.startSearch} />);
    }

    return (
      <div
        ref={ref => (this.actionbarRef = ref)}
        className={classCat([
          'action-bar',
          styles['action-bar'],
          {
            [styles.ios]: iOS,
            [styles.searching]: this.state.showingSearch,
            [styles.transparent]: transparent,
            [styles['has-tab-bar']]: hasTabBar,
            [styles['smart-scroll']]: smartScroll,
          },
        ])}
      >
        {sidebar && !this.state.showingSearch && (
          <div className={classCat(['sidebar-button', styles['sidebar-button']])} tabIndex={0} onClick={store ? store.onSidebar : onSidebarPressed}>
            <Icon icon="fa-bars" />
          </div>
        )}
        {onBackPressed && !this.state.showingSearch && (
          <div tabIndex={0} onClick={onBackPressed} className={classCat(['back-button', styles['back-button']])}>
            <Icon icon={iOS ? 'fa-angle-left' : 'fa-arrow-left'} />
          </div>
        )}
        {!this.state.showingSearch && (
          <header id={`actionbar-header${name}`} className={classCat({ [styles.hasLeftIcon]: hasLeftIcon, [styles.hasFilter]: hasFilter, [styles.hasSubtitle]: subtitle })}>
            {titleImage && <img src={titleImage} />}
            {!titleImage && <span className={classCat(['title', styles.title, { [styles.center]: iOS && !subtitle }])}>{displayTitle}</span>}
            {!titleImage && <span className={classCat(['filter', styles.filter])}>{subtitle || (hasFilter && activeFilterObject.label)}</span>}

            {filterOptions && (
              <Menu target={`#actionbar-header${name}`} mount="below-right">
                {filterOptions.map(filter => (
                  <MenuItem key={filter.value} title={filter.label || 'Error'} data-value={filter.value} onClick={this.changeFilter} />
                ))}
              </Menu>
            )}
          </header>
        )}
        {/*searchOptions &&
                    <Select
                        value={searchValue}
                        onChange={onSearchChange}
                        options={searchOptions}
                        multi={searchMulti}
                    />*/}
        {searchObservable && this.state.showingSearch && (
          <span>
            <Icon icon="fa-search" className="search-button" onClick={this.onSearch} />
            <form action="." onSubmit={this.onSearch}>
              <Input search placeholder="Search..." ref={ref => (this.searchInputRef = ref)} name="search" observable={searchObservable} value={searchValue} />
            </form>
            <Icon icon="fa-times" className="close-button" onClick={this.onClearSearch} />
          </span>
        )}
        {!this.state.showingSearch && <ActionItemArea children={realChildren} hasSearch={searchOptions} onSearch={this.startSearch} />}
        {isLoading && <div className={classCat('loading-bar')} />}
      </div>
    );
  }

  @bind
  private onScroll(): void {
    if (this.actionbarRef) {
      if (window.scrollY <= this.lastScroll) {
        const posY = this.actionbarRef.getBoundingClientRect().top;
        if (0 >= posY + this.actionbarRef.clientHeight) {
          this.actionbarRef.style.top = `${Math.max(0, window.scrollY - this.actionbarRef.clientHeight)}px`;
        }
        if (posY >= 0) {
          this.actionbarRef.style.position = 'sticky';
          this.actionbarRef.style.top = `0px`;
        }
      } else {
        if (this.actionbarRef.style.position) {
          this.actionbarRef.style.position = null;
          this.actionbarRef.style.top = `${this.lastScroll}px`;
        }
      }
      this.lastScroll = window.scrollY;
    }
  }

  @bind
  @action
  private changeFilter(e: React.MouseEvent<HTMLElement>): void {
    const { onChangeFilter, filterOptions } = this.props;
    const newValue = e.currentTarget.dataset.value;
    const newFilter = find(filterOptions || [], { value: newValue }) as IFilterOptions;
    if (onChangeFilter) {
      onChangeFilter(newFilter);
    }
  }

  @bind
  private startSearch(): void {
    this.setState({ showingSearch: true });
    setTimeout(() => {
      if (this.searchInputRef) {
        this.searchInputRef.focus();
      }
    }, 10);
  }

  @bind
  private onSearch(e?: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLElement>): false {
    if (this.props.onSearch) {
      this.props.onSearch(this.props.searchObservable[this.props.searchValue]);
      this.setState({ showingSearch: false });
    }
    if (e) {
      e.preventDefault();
    }
    return false;
  }

  @bind
  private onClearSearch(): void {
    if (this.props.searchObservable) {
      runInAction(() => {
        this.props.searchObservable[this.props.searchValue] = '';
      });
      this.onSearch();
    }
  }
}
