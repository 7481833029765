/**
 *  Turns an integer into a comma seperate string (eg, 1234 becomes 1,234)
 *  @param number - number to convert
 *  @param splitter - string to place inbetween numbers
 */
export default function numberWithCommas(value: number, splitter = ',') {
  if (typeof value !== 'number') {
    throw new Error('Number must be a number');
  }
  if (typeof splitter !== 'string') {
    throw new Error('Splitter must be a string');
  }
  if (!splitter || splitter === '') {
    throw new Error('Splitter must be set');
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, splitter);
}
