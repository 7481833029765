export { default } from './customValidate';
import If from '@ts-delight/if-expr.macro';
import { format as formatDate } from 'date-fns';
import inspect from 'inspect.macro';
import { compact, each, flatten, transform } from 'lodash';
import validate from './customValidate';

function jsValidator(value: any, options: any, key: string, attributes: any, globalOptions: any) {
    const actualOptions: { [key: string]: any } = If(typeof options === 'object')
        .then(options)
        .elseIf(typeof options === 'function')
        .then({ code: options })
        .else({})();
    const errors: string[] = compact(
        flatten(
            transform(
                actualOptions,
                (e: Array<string | string[]>, v, k: string) => {
                    const codeMatch = k.match(/^code(\w*)$/);
                    inspect('jsValidator', options, actualOptions, e, v, k, codeMatch);
                    if (codeMatch && v) {
                        const result = jsSingleValidator.call(
                            jsSingleValidator,
                            value,
                            { code: v, message: actualOptions[`message${codeMatch[1]}`] },
                            key,
                            attributes,
                            globalOptions,
                        );
                        if (result) {
                            e.push(result);
                        }
                    }
                },
                [],
            ),
        ),
    );
    if (errors.length > 0) {
        return errors;
    }
}

function jsSingleValidator(value: any, options: any, key: string, attributes: any, globalOptions: any): string | string[] | undefined {
    if (!options) {
        throw new Error('Code not defined for jsSingleValidator');
    }
    const message = options.message || this.message || 'does not pass custom validation';
    if (globalOptions.forceErrors) {
        return message;
    }
    const code: (this: any, value: any, options: any, key: any, attributes: any) => any = If(typeof options === 'object')
        .then(options.code)
        .elseIf(typeof options === 'function')
        .then(options)();
    if (typeof code === 'function') {
        const result = code.call(attributes, value, options, key, attributes);
        if (result === undefined) {
            return;
        }
        if (!result) {
            return options.message || this.message || 'does not pass custom validation';
        }
        return result;
    }
    return options;
}

validate.validators.js = jsValidator;

validate.extend(validate.validators.datetime, {
    // Input is a unix timestamp
    format(value: any, options: any) {
        const format = options.dateOnly ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm';
        return formatDate(value, format);
    },

    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse(value: any, options: any) {
        return typeof value === 'string' ? new Date(value) : value;
    },
});

const numericalityMessage = 'must be %{type} %{count}';
const defaultMessages = {
    length: {
        message: null,
        notValid: 'has an incorrect length',
        tooLong: 'is too long (maximum is %{count} characters)',
        tooShort: 'is too short (minimum is %{count} characters)',
        wrongLength: 'is the wrong length (should be %{count} characters)',
    },
    numericality: {
        message: null,
        notDivisibleBy: numericalityMessage,
        notEqualTo: numericalityMessage,
        notEven: 'must be even',
        notGreaterThan: numericalityMessage,
        notGreaterThanOrEqualTo: numericalityMessage,
        notInteger: 'must be an integer',
        notLessThan: numericalityMessage,
        notLessThanOrEqualTo: numericalityMessage,
        notOdd: 'must be odd',
        notValid: 'must be a valid number',
    },
    presence: { message: "can't be blank" },
};

each(defaultMessages, (o: any, key: string) => {
    validate.validators[key].options = o;
});

validate.getConstraintMessages = function(constraints: any, options?: any): string[] {
    options = validate.extend({}, { forceErrors: true, format: 'flat' }, options);
    return this({}, constraints, options);
};

const constraintToMessage = {};
