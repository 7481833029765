// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2c0qgViGu8oFvHgtvoDT41{margin:4px;display:inline-block;border-radius:2px;box-shadow:0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.2),0 1px 5px 0 rgba(0,0,0,0.12)}._2c0qgViGu8oFvHgtvoDT41 button{border:1px solid rgba(0,0,0,0.075);outline:none;padding:4px;padding-left:8px;padding-right:8px;margin:0px;color:white;transition:background-color 200ms;user-select:none;cursor:pointer;font-size:1rem;color:var(--text-colour, white);background:#1976d2;background:var(--primary-colour, #1976d2)}._2c0qgViGu8oFvHgtvoDT41 button:hover,._2c0qgViGu8oFvHgtvoDT41 button:focus{background:#1565c0;background:var(--primary-hover-colour, #1565c0)}._2c0qgViGu8oFvHgtvoDT41 button:active{background:#0d47a1;background:var(--primary-active-colour, #0d47a1)}._2c0qgViGu8oFvHgtvoDT41 button *{user-select:none;cursor:pointer}._2c0qgViGu8oFvHgtvoDT41 button:first-child{border-top-left-radius:2px;border-bottom-left-radius:2px}._2c0qgViGu8oFvHgtvoDT41 button:last-child{border-top-right-radius:2px;border-bottom-right-radius:2px}._2c0qgViGu8oFvHgtvoDT41 button svg{margin:0px;padding:0px;margin-left:4px;margin-right:4px}._2c0qgViGu8oFvHgtvoDT41 button svg._38HZtNIKIzqFVdfWs-PB04:not(._1v8GUswUW9JtBOln9iSz0Y){margin-right:8px}._2c0qgViGu8oFvHgtvoDT41 button svg.b-2blRqmzwy5BfixuXdjP:not(._1v8GUswUW9JtBOln9iSz0Y){margin-left:8px}._2c0qgViGu8oFvHgtvoDT41 button svg._1v8GUswUW9JtBOln9iSz0Y{width:12px !important}\n", ""]);
// Exports
exports.locals = {
	"button-group": "_2c0qgViGu8oFvHgtvoDT41",
	"left": "_38HZtNIKIzqFVdfWs-PB04",
	"iconOnly": "_1v8GUswUW9JtBOln9iSz0Y",
	"right": "b-2blRqmzwy5BfixuXdjP"
};
module.exports = exports;
