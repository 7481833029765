import React, { createElement, useContext } from 'react';
import { Registry } from 'react-registry';
import Field from './Field';
import FormState, { FormContext } from './FormState';

import classNameType from 'classnames';

const dedupeClassNames: typeof classNameType = require('cndedupe');

interface IFormGroupProps {
    fields?: string[] | string;
    form?: FormState<any>;
    tag?: boolean | string;
    className?: any;
}

const FieldSet: React.FunctionComponent<IFormGroupProps> = (props: IFormGroupProps) => {
    const form = props.form || (useContext(FormContext) as FormState<any>);
    const fields = typeof props.fields === 'string' ? props.fields.split(/\s*,\s*/g) : props.fields || form.allFields;
    const tag = props.tag === true || props.tag == null ? 'div' : props.tag;
    const { className } = props;

    // console.log("Rendering FieldSet:", form, fields);
    const fieldArray = fields.map(field => <Field key={field} field={field} form={form} />);

    return tag ? createElement(tag, { className: className ? dedupeClassNames('fieldset', className) : 'fieldset' }, fieldArray) : <>{fieldArray}</>;
};

FieldSet.displayName = 'FieldSet';
Registry.register(FieldSet, { id: 'FieldSet' });
export default FieldSet;
