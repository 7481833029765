import * as _ from 'lodash';
import { ILookup } from '../GearsState/Lookups';
import { addFaExtras, iconHtml } from './fa';
import { IFormatLookupOptions } from './Helpers';
import initials from './initials';

const HexReg = new RegExp('\\b(background|text||bg|txt)-hex-([0-9a-f]+)\\b', 'ig');

interface IHexMap {
    bg: 'background-color';
    text: 'color';
    background: 'background-color';
    txt: 'color';
}

const hex_map: IHexMap = {
    background: 'background-color',
    bg: 'background-color',
    text: 'color',
    txt: 'color',
};

function formatLookup(value: ILookup, options: Readonly<IFormatLookupOptions> = {}) {
    let icon, text, style, full_text, basic_display, hover_title;
    let include_text = options.include_text == null ? true : options.include_text;
    const append_id = !!options.append_id;
    const replace_newlines = !!options.replace_newlines;
    const tooltip = options.tooltip == null ? false : options.tooltip;
    const short_text = options.short_text == null ? false : options.short_text;

    if (value == null) {
        return '';
    }
    icon = value.icon;
    text = (short_text && value.short_text) || value.text;
    switch (include_text) {
        case null:
        case 'empty':
            include_text = !(icon || value.initials);
            break;
        case 'never':
            include_text = false;
            break;
        case 'always':
            include_text = true;
    }
    if (icon != null) {
        style = '';
        icon = icon.replace(HexReg, (i: string, attrStr: keyof IHexMap, value: string) => {
            let style;
            style = style + hex_map[attrStr] + ': #' + value + ';';
            return '';
        });
        if (style) {
            style = 'style="' + style + '"';
        }
        if (icon.match(/badge/)) {
            if (!value.initials) {
                value.initials = initials(text);
            }
            if (!value.initials_class) {
                value.initials_class = icon;
            }
            include_text = false;
            icon = null;
        }
    }
    if (replace_newlines) {
        text = text.replace(/\n/g, '<br/>');
    }
    if (include_text) {
        if (include_text === 'fullscreen') {
            full_text = "<span class='icon-fulltext fullscreen-only'>  " + text + '</span>';
        } else {
            full_text = '  ' + text;
        }
    } else {
        full_text = '';
    }
    if (append_id) {
        text += ' (' + value.id + ')';
    }
    basic_display = (function() {
        switch (false) {
            case !value.image:
                return `<img src='${value.image}' class='lookup-image'/> ${full_text}`;
            case !icon:
                return `${iconHtml(addFaExtras(icon))}${full_text}`;
            case !value.initials:
                return `<span class='badge initials ${value.initials_class}' ${style}>${value.initials}</span>${full_text}`;
            default:
                return full_text;
        }
    })();
    if (!tooltip) {
        return basic_display;
    }
    switch (false) {
        case !icon:
            hover_title = '';
            if (value.message) {
                hover_title = text + '<br/><i class=&#39;' + icon + '&#39;></i> ' + value.message;
            } else {
                hover_title = text;
            }
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title="${hover_title}" >${basic_display}</span>`;
        case !value.initials:
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title="${text}">${basic_display}</span>`;
        case !!full_text.match(/tooltip/):
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title='${text}'>${full_text}</span>`;
        default:
            return full_text;
    }
}

function inlineLookup(dataPath, options, fieldName?: string) {
    options == null && (options = {});
    return function inline(): string {
        let value;
        value = this.get(dataPath);
        if (!value && fieldName) {
            const text = this.get(fieldName);
            if (text) {
                value = { id: fieldName, identifier: fieldName, text: `<span class='txt-color-red'>${text}</span>` };
            }
        }
        if (value && value.text) {
            return formatLookup(value, options);
        } else {
            return '';
        }
    };
}

export { inlineLookup };

function iconLookup(fieldName: string, lookupName: string, options: any, state?: any) {
    options == null && (options = {});
    if (state == null) {
        state = (global as any).gearsState;
    }
    return function iconLookupGenerated(): string {
        let value;
        const id = _.get(this, fieldName) as string | number;
        options.id == null && (options.id = 'id');
        value = _.find(state.lookups.lists.get(lookupName), function flexibleCompare(it): boolean {
            // tslint:disable-next-line:triple-equals
            return id == it.id || (it.identifier !== undefined && id == it.identifier);
        });
        if (!value) {
            if (id) {
                value = { id: fieldName, identifier: fieldName, text: `<span class='txt-color-red'>${id}</span>` };
            } else {
                value = {
                    icon: 'icon-blank',
                    id: null,
                };
            }
        }
        value.text == null && (value.text = '&nbsp;&nbsp;&nbsp;&nbsp;');
        return formatLookup(value, options);
    };
}

export { iconLookup, formatLookup, initials };
