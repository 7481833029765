/// <reference path="../../global.d.ts" />

import Class from 'classnames';
import * as FileSaver from 'filesaver.js-npm/FileSaver';
import * as $ from 'jquery';
import 'jquery-deparam';
import * as _ from 'lodash';
import * as md5 from 'md5';
import * as MobX from 'mobx';
import * as moment from 'moment-timezone';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as mobxLite from 'mobx-react-lite';

import ActionTab from 'commonui/ActionTab';
import ActionTabs from 'commonui/ActionTabs';
import { ButtonGroup, ButtonItem } from 'commonui/ButtonGroup';
import { Card, CardAction, CardBottom, CardContent, CardHeader, CardTab, CardTabs } from 'commonui/Card';
import Checkbox, { MobXCheckbox } from 'commonui/Checkbox';
import createConfigDialog, { ConfigDialogBuilder } from 'commonui/ConfigDialog';
import Input from 'commonui/Input';
import { Menu, MenuHeader, MenuItem } from 'commonui/Menu';
import { PageController, PageRoute } from 'commonui/Page';
import Portal from 'commonui/Portal';
import PortalImage from 'commonui/PortalImage';
import PullToRefresh from 'commonui/PullToRefresh';
import Range from 'commonui/Range';
import Switch, { MobXSwitch } from 'commonui/Switch';
import TextArea, { MobXTextArea } from 'commonui/TextArea';
import Theme from 'commonui/Theme';
import {
    arraysToDataGrid,
    dataGridToArrays,
    dataGridToCSV,
    dataGridToObjects,
    dataGroupToArrays,
    dataGroupToObjects,
    mappedObjectsToDataGrid,
    objectsToDataGrid,
    objectToDataGroup,
} from 'commonui/util/grid-converter';
import { errorDisplay, rtError } from '../errorDisplay';

import urlObservable from '@util/urlObservable';
import ActionBar from 'commonui/ActionBar';
import Button from 'commonui/Button';
import CircleImage from 'commonui/CircleImage';
import DataGrid from 'commonui/DataGrid';
import DataGridBuilder from 'commonui/DataGrid/DataGridBuilder';
import DatePicker from 'commonui/DatePicker';
import Device from 'commonui/Device';
import gearsDialog from 'commonui/Dialog';
import FloatingActionButton from 'commonui/FloatingActionButton';
import Icon from 'commonui/Icon';
import List, { ListItem } from 'commonui/List';
import Loader from 'commonui/Loader';
import Radio from 'commonui/Radio';
import RadioGroup from 'commonui/RadioGroup';
import Select from 'commonui/Select';
import Sidebar from 'commonui/Sidebar';
import SmartGrid from 'commonui/SmartGrid';
import SmartGridBuilder from 'commonui/SmartGrid/SmartGridBuilder';
import SnackBar from 'commonui/SnackBar';
import StarRating from 'commonui/StarRating';
import SubmitLoader from 'commonui/SubmitLoader';
import Toast from 'commonui/Toast';
import Tooltip from 'commonui/Tooltip';
import format from 'commonui/util/formatter';
import styled from '@emotion/styled';
import * as emotion from '@emotion/core';
import {
    camelize,
    capitalize,
    classify,
    dasherize,
    demodulize,
    foreign_key,
    humanize,
    indexOf,
    inflect,
    ordinalize,
    pluralize,
    singularize,
    tableize,
    titleize,
    transform,
    underscore,
} from 'inflection';
import { observer } from 'mobx-react';
import * as numeral from 'numeral';
import tryCatch from '../../gears/helpers/tryCatch';
import lite from '../../modules/LiteLoader';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import Format from '../Format';
import { Note, NoteManager } from '../Note';
import FormEntry from '../public/FormEntry';
import FormPage from '../public/FormPage';
import FormSubmitted from '../public/FormSubmitted';
import Markdown from '../public/Markdown';
import MultiPage from '../public/MultiPage';
import Page from '../public/Page';
import Perspective from '../public/Perspective';
import GearsActionSubscriber from '../util/GearsActionSubscriber';
import validate from "../../gears/helpers/validate";
import { parseConstraints } from '../../gears/GearsModel/Constraints';
import * as Transitions from '../../gears/transitions';

import Form from '../../gears/Forms/Form';
import FormState from '../../gears/Forms/FormState';
import * as hooks from '../../gears/Forms/hooks';
import gearsState from '../../gears/GearsState';
import "../WebfrontRegistry";
import {PDF} from "../../to-pdf/PDF";

declare const global: any;

type HomeLookup = InvalidHomeLookup | IValidHomeLookup;

interface InvalidHomeLookup {
    title: string;
    message: string;
    error: string;
    query_log_id: number;
}

// TODO: Replace with proper homelookups User interface
interface IValidHomeLookup {
    user: IUser;
}

interface IUser {
    id: number;
    name: string;
}

function isValidHomeLookup(arg: any): arg is IValidHomeLookup {
    return arg.user !== undefined;
}

async function requireLogin(redirect: boolean = false): Promise<boolean> {
    const res = await $.ajax({
        url: '/homelookups.json',
    })
        .then((result: HomeLookup) => isValidHomeLookup(result))
        .catch(() => false)
        .then((loggedIn: boolean) => {
            if (redirect && !loggedIn) {
                const redirectURL = btoa(window.location.pathname + window.location.search + window.location.hash);
                window.location.assign('/login#redirect=' + redirectURL);
            }
            return loggedIn;
        })
        .then((loggedIn: boolean) => {
            if (!loggedIn) {
                throw new Error('Not logged in');
            }
            return loggedIn;
        });
    return res;
}
type IDataSourceFilterItem = kendo.data.DataSourceFilterItem;
interface IDataSourceFilters {
    logic: 'and' | 'or';
    filters: Array<IDataSourceFilterItem | IDataSourceFilters>;
}

interface IFilterOptions {
    field: string;
    value: string | number | boolean | string[] | number[] | boolean[];
    operator?: 'eq' | 'neq';
}

/**
 * Creates a kendo compatible filter by iterating over an array of input values
 */
function createFilter(options: IFilterOptions[], fullText?: string): IDataSourceFilters {
    const filter: IDataSourceFilters = {
        filters: [],
        logic: 'and',
    };
    for (const option of options) {
        if (Array.isArray(option.value)) {
            const subFilter: IDataSourceFilters = {
                filters: [],
                logic: (option.operator || 'eq') === 'eq' ? 'or' : 'and',
            };
            for (const value of option.value) {
                subFilter.filters.push({
                    field: option.field,
                    operator: option.operator || 'eq',
                    value,
                });
            }
            filter.filters.push(subFilter);
        } else {
            if (option.value !== undefined) {
                filter.filters.push({
                    field: option.field,
                    operator: option.operator || 'eq',
                    value: option.value,
                });
            }
        }
    }
    if (fullText) {
        filter.filters.push({
            field: 'fulltext',
            operator: 'contains',
            value: fullText,
        });
    }
    return filter;
}

function webWorker(target: string, key: (params: any[]) => any): (params: any[]) => Promise<any> {
    /**
     *  Write the function here as a precompiled string to avoid babel
     *  optimization using global polyfills (that wouldn't be available
     *  in worker thread)
     */
    const blob = new Blob(
        [
            `const func = ` +
            key.toString() +
            `;
        self.onmessage = function(event) {
            try{
                const result = func.apply(undefined, event.data);
                self.postMessage({success: true, result: result});
            } catch(e){
                self.postMessage({error: e.toString()})
            }
        }`,
        ],
        { type: 'text/javascript' },
    );

    const blobURL = window.URL.createObjectURL(blob);
    const worker = new Worker(blobURL);

    const workerRunner = (...params: any[]) => new Promise((resolve, reject) => {
        worker.onmessage = message => {
            if (message.data.success) {
                resolve(message.data.result);
            } else {
                reject(new Error(message.data.error));
            }
        };
        worker.postMessage(params);
    });
    return workerRunner;
}

class GearsHelper {
    public async getModelRecord(model: string, id: string | number): Promise<any> {
        return $.ajax({
            dataType: 'json',
            method: 'GET',
            url: `/${model}/${id}`,
        });
    }
    public async getModelRecords(model: string, config?: any): Promise<any> {
        return $.ajax({
            data: config,
            dataType: 'json',
            method: 'POST',
            url: `/${model}/gears_index`,
        });
    }
    public async getModelLookups(model: string): Promise<any> {
        return $.ajax({
            dataType: 'json',
            url: `/${model}/lookups`,
        });
    }
    public async getModelAsLookup(model: string): Promise<any> {
        return $.ajax({
            dataType: 'json',
            url: `/${model}/lookup`,
        });
    }
}

const Gears = new GearsHelper();

export const modules: any = {
    $,
    DataGridBuilder,
    FileSaver,
    MobX,
    _,
    Promise,
    Gears,
    React,
    ReactDOM,
    SmartGridBuilder,
    SnackBar,
    webWorker,
    Toast,
    arraysToDataGrid,
    dataGridToArrays,
    dataGridToCSV,
    mobx: MobX,
    Moment: moment,
    dataGridToObjects,
    dataGroupToArrays,
    dataGroupToObjects,
    errorDisplay,
    extendObservable: MobX.extendObservable,
    gearsDialog,
    Loader,
    mappedObjectsToDataGrid,
    Class,
    md5,
    GearsActionSubscriber,
    moment,
    Portal,
    numeral,
    ConfigDialogBuilder,
    objectToDataGroup,
    camelize,
    objectsToDataGrid,
    capitalize,
    observable: MobX.observable,
    classify,
    rtError,
    createConfigDialog,
    tryCatch,
    createFilter,
    dasherize,
    demodulize,
    format,
    foreign_key,
    humanize,
    requireLogin,
    createURLObservable: urlObservable,
    indexOf,
    inflect,
    observer,
    ordinalize,
    pluralize,
    singularize,
    tableize,
    titleize,
    mobxLite,
    transform,
    underscore,
    urlObservable,
    useCallback: React.useCallback,
    useContext: React.useContext,
    useEffect: React.useEffect,
    useMemo: React.useMemo,
    useRef: React.useRef,
    useState: React.useState,
    parseConstraints,
    validate,
    Transitions,
    styled,
    emotion,
    css: emotion.css,
    jsx: emotion.jsx,
    PDF,
};

interface ILazyLoaderState {
    loaded?: React.ComponentClass<any>;
}
/**
 *  Loads a component dynamically by calling liteloader on the appropiate module
 */
type Module = 'dropzone';
function lazyLoadFactory(module: Module, component: string = 'default') {
    return class LazyLoader extends React.Component<any, ILazyLoaderState> {
        constructor(props: any) {
            super(props);

            lite[module].then((loadedModule: any) => {
                if (defineGlobal) {
                    global[defineGlobal] = component ? loadedModule[component] : loadedModule;
                }
                this.setState({ loaded: loadedModule[component || displayComponent] });
                if (component && !loadedModule[component]) {
                    gearsDialog({
                        buttons: ['OK'],
                        content: `Unable to load component (${component}) from module (${module})`,
                        title: 'Error',
                    });
                }
            });

            this.state = { loaded: undefined };
        }

        public render(): JSX.Element | null {
            if (!this.state.loaded) {
                return null;
            } else {
                return React.createElement(this.state.loaded, { ...this.props, children: undefined }, this.props.children);
            }
        }
    };
}

function rechartsWarning() {
    throw new Error('Tried to use a recharts component without loading recharts first, please use lite.charts before page loads');
}

const Dropzone = lazyLoadFactory('dropzone', 'default');

const AreaChart = rechartsWarning;
const BarChart = rechartsWarning;
const LineChart = rechartsWarning;
const ComposedChart = rechartsWarning;
const PieChart = rechartsWarning;
const RadialChart = rechartsWarning;
const RadialBarChart = rechartsWarning;
const ScatterChart = rechartsWarning;
const Treemap = rechartsWarning;

const ResponsiveContainer = () => null;
const Legend = () => null;
const ChartTooltip = () => null;
const Cell = () => null;
const ChartText = () => null;
const ChartLabel = () => null;
const ChartLabelList = () => null;

const Area = () => null;
const Bar = () => null;
const Line = () => null;
const Scatter = () => null;
const XAxis = () => null;
const YAxis = () => null;
const ZAxis = () => null;

const Brush = () => null;
const CartesianAxis = () => null;
const CartesianGrid = () => null;
const ReferenceLine = () => null;
const ReferenceDot = () => null;
const ReferenceArea = () => null;
const ErrorBar = () => null;

const Pie = () => null;
const Radar = () => null;
const RadialBar = () => null;
const PolarAngleAxis = () => null;
const PolarGrid = () => null;
const PolarRadiusAxis = () => null;

const Cross = () => null;
const Curve = () => null;
const Dot = () => null;
const Polygon = () => null;
const Rectangle = () => null;
const Sector = () => null;

const rechartAPI = 'http://recharts.org/#/en-US/api/';

export interface IReactComponentDocumentation {
    name: string;
    object: any;
    parent?: string;
    children: boolean;
    documentation?: string;
    documentationURL?: string;
    props: Array<string | IReactComponentProp>;
    optionalProps?: Array<string | IReactComponentProp>;
}

interface IReactComponentProp {
    name: string;
    documentation?: string;
    /** Doesn't actually mean boolean, only that we shouldn't add ="" to the result. Do not use on properties that are likely to be dynamic */
    boolean?: boolean;
}

const observableDoc = 'Observable object to listen change values of';
const valueDoc = 'String value to get from observable';

export const reactComponents: IReactComponentDocumentation[] = [
    {
        children: true,
        documentation: 'A navigation pane that appears at the top of the page',
        documentationURL: '/template_help/#/actionbar',
        name: 'ActionBar',
        object: ActionBar,
        optionalProps: [
            {
                name: 'title',
            },
            {
                name: 'subtitle',
            },
            {
                boolean: true,
                documentation: 'Transforms the back arrow into a hamburger open-sidebar button',
                name: 'sidebar',
            },
            {
                boolean: true,
                documentation: 'Tells the ActionBar to resize to fit a tab bar, this does not add the ActionTabs!',
                name: 'hasTabBar',
            },
            {
                documentation: 'Callback when user clicks on the back arrow or open sidebar menu (if sidebar property is true)',
                name: 'onBackPressed',
            },
            {
                documentation: 'Creates an indeterminate loading bar right underneath the ActionBar',
                name: 'isLoading',
            },
            {
                boolean: true,
                documentation: 'Makes actionbar hide when scrolling down, but appear as soon as user scrolls up',
                name: 'smartScroll',
            },
        ],
        props: [],
    },
    {
        children: false,
        documentation: 'Creates a button icon inside ActionBar that users can click (or creates a dropdown menu on small screens)',
        documentationURL: '/template_help/#/actionbar',
        name: 'Item',
        object: ActionBar.Item,
        optionalProps: [
            {
                boolean: true,
                documentation: "Controls when the item should be displayed as icon or in menu. Accepted values are 'ifRoom', 'never', 'always'",
                name: 'showAsIcon',
            },
            {
                documentation: "Callback when user clicks this button or menu item, does not work if 'menu' prop is present",
                name: 'onClick',
            },
            {
                documentation: 'JSON structure matching <Menu> component to create a dropdown menu, disables the onClick button',
                name: 'menu',
            },
        ],
        parent: 'ActionBar',
        props: [
            {
                documentation: 'Label of the icon when it appears in a menu, or as a tooltip when it appears as an item',
                name: 'title',
            },
            {
                name: 'icon',
            },
        ],
    },
    { children: false, documentationURL: '/template_help/#/actionbar', name: 'Tab', object: ActionTab, parent: 'ActionBar', props: ['title', 'icon'] },
    { children: true, documentationURL: '/template_help/#/actionbar', name: 'Tabs', object: ActionTabs, parent: 'ActionBar', props: [] },
    {
        children: false,
        documentationURL: '/template_help/#/button',
        name: 'Button',
        object: Button,
        optionalProps: [
            {
                boolean: true,
                documentation: 'Fill the width of the container button is in',
                name: 'fill',
            },
            {
                boolean: true,
                documentation: 'Remove shadows and background colour',
                name: 'flat',
            },
            {
                boolean: true,
                documentation: 'Changes the button colour, colour can be chosen changed by adding a className or using a <Theme> component',
                name: 'coloured',
            },
            {
                documentation: 'Callback when user presses button',
                name: 'onClick',
            },
        ],
        props: ['title', 'icon'],
    },
    { children: true, name: 'ButtonGroup', object: ButtonGroup, props: [] },
    { children: false, name: 'ButtonItem', object: ButtonItem, props: ['title'] },
    { children: true, name: 'Card', object: Card, props: [] },
    { children: false, name: 'CardAction', object: CardAction, props: ['icon', 'onClick'] },
    { children: true, name: 'CardBottom', object: CardBottom, props: [] },
    { children: true, name: 'CardContent', object: CardContent, props: [] },
    { children: true, name: 'CardHeader', object: CardHeader, props: ['title'] },
    { children: true, name: 'CardTab', object: CardTab, props: ['title'] },
    { children: true, name: 'CardTabs', object: CardTabs, props: [] },
    { children: false, name: 'Checkbox', object: Checkbox, props: ['onChange', 'value', 'label'] },
    { children: false, help: 'circleimage', name: 'CircleImage', object: CircleImage, props: ['size', 'src'] },
    { children: false, name: 'DatePicker', object: DatePicker, props: ['displayFormat', 'editFormat'] },
    { children: true, name: 'Device', object: Device, props: ['device'] },
    { children: false, name: 'Dropzone', object: Dropzone, props: ['config', 'eventHandlers', 'djsConfig'] },
    { children: false, name: 'FloatingActionButton', object: FloatingActionButton, props: ['onClick'] },
    { children: false, name: 'Format', object: Format, props: ['value', 'format', 'className', 'zeroFormat', 'nullFormat'] },
    { children: false, name: 'Icon', object: Icon, props: ['icon'] },
    { children: false, name: 'Input', object: Input, props: ['onChange', 'value', 'label'] },
    { children: true, name: 'List', object: List, props: ['onLoadMore'] },
    { children: true, name: 'Item', object: ListItem, parent: 'List', props: ['title', 'subtitle'] },
    { children: true, documentationURL: '/template_help/#/markdown', name: 'Markdown', object: Markdown, props: [] },
    { children: true, name: 'Menu', object: Menu, props: ['target'] },
    { children: false, name: 'MenuHeader', object: MenuHeader, props: ['title'] },
    { children: true, name: 'MenuItem', object: MenuItem, props: ['title'] },
    { children: true, name: 'MultiPage', object: MultiPage, props: ['page'] },
    { children: true, name: 'Page', object: Page, props: ['title', 'type'] },
    { children: true, name: 'PageController', object: PageController, props: [] },
    { children: true, name: 'PageRoute', object: PageRoute, props: ['route', 'component'] },
    { children: true, name: 'Perspective', object: Perspective, props: [] },
    { children: false, name: 'PortalImage', object: PortalImage, props: ['width', 'height', 'maxOffset', 'image'] },
    { children: false, name: 'PullToRefresh', object: PullToRefresh, props: ['onRefresh'] },
    { children: false, name: 'Radio', object: Radio, props: ['label', 'value'] },
    {
        children: true,
        documentation: 'Group Manager for a series of <Radio/> components',
        name: 'RadioGroup',
        object: RadioGroup,
        optionalProps: [
            {
                documentation: 'Name used if this RadioGroup is used in a HTML form',
                name: 'name',
            },
        ],
        props: [
            {
                documentation: observableDoc,
                name: 'observable',
            },
            {
                documentation: valueDoc,
                name: 'value',
            },
        ],
    },
    { children: false, name: 'Range', object: Range, props: ['observable', 'value', 'min', 'max'] },
    { children: false, name: 'Select', object: Select, props: ['value', 'options'] },
    { children: true, name: 'Sidebar', object: Sidebar, props: ['open', 'onBackPressed'] },
    { children: false, name: 'SmartGrid', object: SmartGrid, props: ['rt-props'] },
    { children: false, name: 'StarRating', object: StarRating, props: ['rating'] },
    { children: false, name: 'SubmitLoader', object: SubmitLoader, props: ['onSubmit'] },
    { children: false, name: 'Switch', object: Switch, props: ['onChange', 'value', 'label'] },
    { children: false, name: 'TextArea', object: TextArea, props: ['value', 'onChange'] },
    {
        children: true,
        documentation: 'Sets the theme colour for certain child components',
        name: 'Theme',
        object: Theme,
        props: [
            {
                documentation: 'The base theme colour, can be one of Material Design colour presets or a custom CSS class name',
                name: 'theme',
            },
        ],
    },
    { children: true, name: 'Tooltip', object: Tooltip, props: ['target'] },
    {
        children: true,
        documentation: 'Manages notes for display by Note components',
        name: 'NoteManager',
        object: NoteManager,
        optionalProps: [
            {
                documentation: 'Custom route to load notes from',
                name: 'route',
            },
            {
                documentation: 'Custom route to post notes too',
                name: 'submitRoute',
            },
            {
                documentation: 'Submits notes as an email instead of automatically based on user ID',
                name: 'email',
            },
            {
                documentation: 'URL to fetch to get user ID, if function it will instead use the return value as user id',
                name: 'userLookup',
            },
            {
                documentation: 'Custom filtering mechanism when channel is not provided',
                name: 'data',
            },
        ],
        props: ['channel'],
    },
    {
        children: false,
        documentation: 'Shows notes that are managed by a NoteManager',
        name: 'Note',
        object: Note,
        optionalProps: [
            {
                documentation: 'Filter data to match on, notes submitted here will also have that filter data attached to them',
                name: 'filter',
            },
            {
                documentation: 'Name for this note area, if <Note> does not have a name and a note message does, it will be displayed',
                name: 'name',
            },
            {
                documentation: 'Limit number of notes that can be created while previous notes are still unresolved',
                name: 'limit',
            },
            {
                documentation: 'Hide notes that have already been resolved',
                name: 'hideResolve',
            },
            {
                documentation: 'Completely disables creating notes or replies if false',
                name: 'canCreate',
            },
            {
                documentation: 'Disables showing the header of this notes name if false',
                name: 'showHeader',
            },
            {
                documentation: 'Disables showing names on notes if false',
                name: 'showName',
            },
            {
                documentation: 'Removes controls (including resolving) if true',
                name: 'removeControls',
            },
        ],
        props: [],
    },
    /**
     * Lots of charting stuff, should move this into a seperately loaded module so it doesn't bloat gears_lite
     */
    { children: true, help: rechartAPI + 'AreaChart', name: 'AreaChart', object: AreaChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'BarChart', name: 'BarChart', object: BarChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'LineChart', name: 'LineChart', object: LineChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'ComposedChart', name: 'ComposedChart', object: ComposedChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'PieChart', name: 'PieChart', object: PieChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'RadialChart', name: 'RadialChart', object: RadialChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'RadialBarChart', name: 'RadialBarChart', object: RadialBarChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'ScatterChart', name: 'ScatterChart', object: ScatterChart, parent: 'Recharts', props: ['width', 'height', 'data'] },
    { children: true, help: rechartAPI + 'Treemap', name: 'Treemap', object: Treemap, parent: 'Recharts', props: ['width', 'height', 'data'] },

    { children: true, help: rechartAPI + 'ResponsiveContainer', name: 'ResponsiveContainer', object: ResponsiveContainer, parent: 'Recharts', props: ['width', 'height'] },
    { children: false, help: rechartAPI + 'Legend', name: 'Legend', object: Legend, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Tooltip', name: 'Tooltip', object: ChartTooltip, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Cell', name: 'Cell', object: Cell, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Text', name: 'ChartText', object: ChartText, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Label', name: 'ChartLabel', object: ChartLabel, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'LabelList', name: 'ChartLabelList', object: ChartLabelList, parent: 'Recharts', props: [] },

    { children: false, help: rechartAPI + 'Area', name: 'Area', object: Area, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Bar', name: 'Bar', object: Bar, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Line', name: 'Line', object: Line, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Scatter', name: 'Scatter', object: Scatter, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'XAxis', name: 'XAxis', object: XAxis, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'YAxis', name: 'YAxis', object: YAxis, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'ZAxis', name: 'ZAxis', object: ZAxis, parent: 'Recharts', props: [] },

    { children: false, help: rechartAPI + 'Brush', name: 'Brush', object: Brush, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'CartesianAxis', name: 'CartesianAxis', object: CartesianAxis, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'CartesianGrid', name: 'CartesianGrid', object: CartesianGrid, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'ReferenceLine', name: 'ReferenceLine', object: ReferenceLine, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'ReferenceDot', name: 'ReferenceDot', object: ReferenceDot, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'ReferenceArea', name: 'ReferenceArea', object: ReferenceArea, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'ErrorBar', name: 'ErrorBar', object: ErrorBar, parent: 'Recharts', props: [] },

    { children: false, help: rechartAPI + 'Pie', name: 'Pie', object: Pie, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Radar', name: 'Radar', object: Radar, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'RadialBar', name: 'RadialBar', object: RadialBar, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'PolarAngleAxis', name: 'PolarAngleAxis', object: PolarAngleAxis, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'PolarGrid', name: 'PolarGrid', object: PolarGrid, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'PolarRadiusAxis', name: 'PolarRadiusAxis', object: PolarRadiusAxis, parent: 'Recharts', props: [] },

    { children: false, help: rechartAPI + 'Cross', name: 'Cross', object: Cross, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Curve', name: 'Curve', object: Curve, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Dot', name: 'Dot', object: Dot, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Polygon', name: 'Polygon', object: Polygon, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Rectangle', name: 'Rectangle', object: Rectangle, parent: 'Recharts', props: [] },
    { children: false, help: rechartAPI + 'Sector', name: 'Sector', object: Sector, parent: 'Recharts', props: [] },

    {
        children: true,
        documentation: 'Wraps child components so that errors do not crash the entire page.',
        name: 'ErrorDisplayComponent',
        object: ErrorDisplayComponent,
        props: [],
    },
];

// Components that still need to be in global scope, but should not appear in template builder
export const legacyComponents = {
    ActionItem: ActionBar.Item,
    DataGrid,
    ActionTab: ActionBar.Tab,
    FormEntry,
    ActionTabs: ActionBar.Tabs,
    FormPage,
    Form,
    FormSubmitted,
    FormState,
    MobXCheckbox,
    ListItem: List.Item,
    MobXSwitch,
    MobXTextArea,
    MobXTextarea: MobXTextArea,
    Textarea: TextArea,
};

global.reactComponents = reactComponents;

Object.assign(global, modules, legacyComponents, hooks);
reactComponents.map((item: any) => {
    if (item.parent) {
        if (global[item.parent] === undefined) {
            global[item.parent] = {};
        }
        if (global[item.parent][item.name] === undefined) {
            global[item.parent][item.name] = item.object;
        }
    } else {
        global[item.name] = item.object;
    }
});
