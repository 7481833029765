import { extendObservable, observable } from 'mobx';

type Alignment = 'left' | 'center' | 'right';
type Row = string | any;

class GroupedRow {
  public tips: Array<string | undefined> = [];
  private rows: string[] = [];
  private name: string;

  constructor(name: string) {
    this.name = name;
  }

  /**
   * Adds a new row
   * @param name - Name of row
   * @param tooltip - Tooltip of this row
   */
  public addRow(name: string, tooltip?: string) {
    this.rows.push(name);
    this.tips.push(tooltip);
    return this;
  }

  /**
   * Adds multiple new rows
   * @param names - Name of row
   * @param tooltips - Tooltip of this row
   */
  public addRows(names: string[], tooltips?: string[]) {
    for (let i = 0; i < names.length; i++) {
      this.rows.push(names[i]);
      this.tips.push(tooltips ? tooltips[i] : undefined);
    }
    return this;
  }

  /**
   * Gets num of rows in this group
   */
  public getNumRows() {
    return this.rows.length;
  }

  public build() {
    return {
      children: this.rows,
      name: this.name,
    };
  }
}

/**
 * DataGridBuilder helps construct all the properties needed for DataGrid
 */
class DataGridBuilder {
  private defaultAlignment: Alignment = 'left';
  private defaultDisabled = false;
  private rowTotal?: string;
  private columnTotal: any;
  private built = false;
  private gridData: any[] = [];
  private rows: Row[] = [];
  private rowClassNames: Array<string | undefined> = [];
  private tips: Array<string | undefined> = [];
  private fields: Array<string | null> = [];
  private columns: Array<string | null> = [];
  private columnsAlign: Alignment[] = [];
  private columnDisabled: boolean[] = [];
  private columnHidden: boolean[] = [];
  private columnWidths: Array<string | number | undefined> = [];
  private rowTitleWidth: string | number | undefined;

  /**
   * Sets the default alignment when no other is provided
   * @param alignment - accepted values are left, center and right
   */
  public setDefaultAlignment(alignment: Alignment) {
    if (alignment === 'left' || alignment === 'center' || alignment === 'right') {
      this.defaultAlignment = alignment;
    } else {
      throw new Error("Alignment is not a valid value (must be 'left', 'center' or 'right')");
    }
    return this;
  }

  /**
   * Sets default disabled when no other is provided
   * @param disabled
   */
  public setDefaultDisabled(disabled: boolean) {
    if (typeof disabled === 'boolean') {
      this.defaultDisabled = disabled;
    } else {
      throw new Error('Disabled value must be boolean');
    }
    return this;
  }

  public setRowTitle(width: string | number) {
    this.rowTitleWidth = width;
  }

  /**
   * Creates an additional row at bottom of grid for totals
   * This function can be called at anytime before build
   * @param name - Name of total row
   */
  public createRowTotal(name: string) {
    if (this.rowTotal) {
      throw new Error('Row Total already set');
    }
    this.rowTotal = name;
    return this;
  }

  /**
   * Creates an additional column on end of grid that shows totals for that row
   * This function can be called at anytime before build
   * @param  name - Name of column
   * @param field - Field to use in datagrid
   * @param alignment - Alignment to use
   */
  public createColumnTotal(name: string, field = 'column_total', alignment = this.defaultAlignment) {
    if (this.columnTotal) {
      throw new Error('Column Total already set');
    }
    this.columnTotal = {
      alignment,
      field,
      name,
    };
    return this;
  }

  /**
   * Adds a new groups row
   * @param name - Name of group
   */
  public addGroupedRow(name: string, groupClassName?: string) {
    const group = new GroupedRow(name);
    this.rows.push(group);
    this.rowClassNames.push(groupClassName);
    return group;
  }

  /**
   * Adds a new row
   * @param  name - Name of row
   * @param tooltip - Tooltip of this row
   */
  public addRow(name: string, tooltip?: string, rowClassName?: string) {
    this.rows.push(name);
    this.tips.push(tooltip);
    this.rowClassNames.push(rowClassName);
    return this;
  }

  /**
   * Adds multiple new rows
   * @param names - Name of row
   * @param tooltips - Tooltip of this row
   */
  public addRows(names: string[], tooltips?: Array<string | undefined>, rowClassNames?: Array<string | undefined>) {
    for (let i = 0; i < names.length; i++) {
      this.rows.push(names[i]);
      this.tips.push(tooltips ? tooltips[i] : undefined);
      this.rowClassNames.push(rowClassNames ? rowClassNames[i] : undefined);
    }
    return this;
  }

  /**
   * Adds a new column
   * @param name - Name of column
   * @param field - Name of field to get from datagrid
   * @param alignment - Alignment of column
   * @param disabled - Whether to disable input on entire column
   */
  public addColumn(name: string, field?: string, alignment?: Alignment, disabled?: boolean, hidden?: boolean, width?: number) {
    this.columns.push(name);
    this.fields.push(field || 'field' + (this.getNumColumns() - 1));
    this.columnsAlign.push(alignment || this.defaultAlignment);
    this.columnDisabled.push(disabled != null ? disabled : this.defaultDisabled);
    this.columnHidden.push(hidden ? true : false);
    this.columnWidths.push(width);
    return this;
  }

  /**
   * Adds multiple new columns
   * @param names - Name of column
   * @param fields - Name of field to get from datagrid
   * @param alignments - Alignment of column
   * @param disabled - Whether to disable input on entire column
   */
  public addColumns(names: string[], fields?: string[], alignments?: Alignment[], disabled?: boolean[], hidden?: boolean[], widths?: Array<number | undefined>) {
    for (let i = 0; i < names.length; i++) {
      this.columns.push(names[i]);
      this.fields.push(fields && fields[i] ? fields[i] : 'field' + (this.getNumColumns() - 1));
      this.columnsAlign.push(alignments && alignments[i] ? alignments[i] : this.defaultAlignment);
      this.columnDisabled.push(disabled && disabled[i] != null ? disabled[i] : this.defaultDisabled);
      this.columnHidden.push(hidden ? (hidden[i] ? true : false) : false);
      this.columnWidths.push(widths && widths[i] != null ? widths[i] : undefined);
    }
    return this;
  }

  /**
   * Adds an empty column spacer
   */
  public addColumnSpacer() {
    this.columns.push(null);
    this.fields.push(null);
    this.columnsAlign.push('left');
    this.columnDisabled.push(true);
    this.columnHidden.push(false);
    this.columnWidths.push(undefined);
    return this;
  }

  /**
   * Gets the current number of columns (not counting spacers)
   */
  public getNumColumns() {
    let columns = 0;
    for (const column of this.columns) {
      if (column) {
        columns++;
      }
    }
    return columns;
  }

  /**
   * Gets the current number of rows (counting group rows too)
   */
  public getNumRows() {
    let rows = 0;
    for (const row of this.rows) {
      if (typeof row === 'string') {
        rows++;
      } else if (row instanceof GroupedRow) {
        rows += row.getNumRows();
      }
    }
    return rows;
  }

  /**
   * Builds everything and returns DataGrid props
   * @param fillBlank - True to fill in the datagrid with empty zero data
   */
  public build(fillBlank?: boolean) {
    this.gridData = observable(this.gridData);
    if (!this.built) {
      // Fill in datagrid with empty data
      if (fillBlank) {
        const gridData = [];
        for (let y = 0; y < this.getNumRows(); y++) {
          let row = observable({});
          for (let x = 0; x < this.columns.length; x++) {
            const field = this.fields[x];
            if (typeof field === 'string') {
              row = extendObservable(row, {
                [field]: 0,
              });
            }
          }
          gridData.push(row);
        }
        this.gridData = observable(gridData);
      }
      if (this.columnTotal) {
        const { name, field, alignment } = this.columnTotal;
        this.addColumn(name, field, alignment, true);
        for (let i = 0; i < this.gridData.length; i++) {
          this.gridData[i][field] = observable({
            fields: this.fields,
            gridData: this.gridData,
            name: field,
            row: i,
            get value() {
              let total = 0;
              for (const fieldIter of this.fields) {
                if (fieldIter !== this.name && fieldIter) {
                  const num = parseFloat(this.gridData[this.row][fieldIter]);
                  total += isNaN(num) ? 0 : num;
                }
              }
              return total;
            },
          });
        }
      }
      if (this.rowTotal) {
        this.rows.push(this.rowTotal);
        this.rowClassNames.push('total');
        const row: any = {};
        for (let i = 0; i < this.columns.length; i++) {
          const field = this.fields[i];
          if (typeof field === 'string') {
            row[field] = observable({
              field: this.fields[i],
              gridData: this.gridData,
              get value() {
                let total = 0;
                for (let i = 0; i < this.gridData.length - 1; i++) {
                  let value = this.gridData[i][this.field];
                  if (typeof value === 'object') {
                    value = value.value;
                  }
                  const num = parseFloat(value);
                  total += isNaN(num) ? 0 : num;
                }
                return '$' + total.toFixed(2);
              },
            });
          }
        }
        this.gridData.push(row);
      }
      this.built = true;
    }
    const finalRows = [];
    const finalTips = [];
    for (let i = 0; i < this.rows.length; i++) {
      if (typeof this.rows[i] === 'string') {
        finalRows.push(this.rows[i]);
        finalTips.push(this.tips[i]);
      } else {
        finalRows.push(this.rows[i].build());
        for (const tip of this.rows[i].tips) {
          finalTips.push(tip);
        }
      }
    }
    return {
      columnAlign: this.columnsAlign,
      columnHidden: this.columnHidden,
      columnNames: this.columns,
      columnWidths: [this.rowTitleWidth, ...this.columnWidths],
      data: this.gridData,
      disabled: this.columnDisabled,
      fields: this.fields,
      rowClassNames: this.rowClassNames,
      rowNames: finalRows,
      rowTips: finalTips,
    };
  }
}

export default DataGridBuilder;
