import { useEffect } from 'react';

export function PDF(options: any) {
    useEffect(() => {
        window.page.pdf = {
            options,
            ready: true,
        };
        console.log('PDF version ready to print', options);
    });
    return null;
}
