import * as React from 'react';
import styles from './ButtonGroup.scss';

/**
 * ButtonGroup holds multiple button items to display in a row
 */
export default class ButtonGroup extends React.Component<React.HTMLProps<HTMLSpanElement>, never> {
  public render(): JSX.Element {
    return <span className={styles['button-group']}>{this.props.children}</span>;
  }
}
