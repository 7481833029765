import * as React from 'react';

export interface IComponentErrorDisplayProps {
    className?: string;
    error?: Error;
    name?: string;
}

export function errorDisplay(props: IComponentErrorDisplayProps) {
    const cn = props.className || 'error-display';
    const errorText = props.error && props.error.error;
    const name = props.name;
    return (
        <div className={cn}>
            Error in "{name || 'unknown'}" component ({errorText})
        </div>
    );
}

export function rtError(error: Error, name: string) {
    console.error('rtError', arguments);
    return errorDisplay({ className: 'template-component-error', error, name });
}
