import * as numeral from 'numeral';
import { ICalculationInputs, NUMBER_FORMATS, registerCalculationType } from './Calculator';
import { ITier, ITiersOptions, Tiers } from './Tiers';

export default class PercentageTiers extends Tiers {
    readonly tiers: ITier[];

    calculationFunction(inputs: ICalculationInputs): any {
        return this.percentage_tiers_calculate(inputs);
    }

    percentage_tiers_calculate(inputs: any): any {
        const base = inputs.baseValue;
        let tierLookupValue = inputs.tierLookupValue;
        if (tierLookupValue == null) {
            tierLookupValue = base;
        }
        const baseValue = numeral(base);
        const tier = this.findTier(tierLookupValue);
        const percentage = numeral(tier.percentage + '%');
        const rawResult = percentage.value() * baseValue.value();
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const resultUnit = '$';
        const rounding = this.roundFormula('' + rawResult);
        const formula = 'inputValue * calculatedTierResult';
        const calculation = `${numeral(baseValue).format(NUMBER_FORMATS.numberFormat)} * ${percentage.format(NUMBER_FORMATS.percentFormat)} = ${formattedResult}`;
        // const groupTotalValue = (groupTotal != baseValue) ? groupTotal : undefined;
        const res = {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            formula,
            inputValue: baseValue.value(),
            percentage: percentage.format(NUMBER_FORMATS.percentFormat),
            rawResult,
            result: result.value(),
            resultUnit,
            rounding,
            tier,
            tierLookupValue,
        };
        return res;
    }
}

// registerCalculationType("group_percentage_tiers", PercentageTiers);
registerCalculationType('percentage_tiers', PercentageTiers);
