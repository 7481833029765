import numberWithCommas from './number-with-commas';

const formatMap = {
  bytes,
  // kilogram: kilogram,
  currency,
};

function format(value: string | number, type, options = {}) {
  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('Value can only be a string or number, received ' + typeof value);
  } else {
    if (formatMap[type]) {
      return formatMap[type](value, options);
    }
    throw new Error('No valid format type provided');
  }
}

/**
 * Converts a string or number to a nicely formatted currency value
 * @param {string|number} currency - String or number value you want to convert
 * @param {object} [options]
 * @param {boolean} [options.noSign] - Whether or not to place a sign infront of number
 * @param {string} [options.sign=$] - Sign to place infront of currency
 * @param {boolean} [options.noCents] - True to remove numbers after decimal place
 * @param {boolean} [options.strict] - Throw errors if value cannot be converted
 * @param {boolean} [options.noDelimiter] - Do not seperate the number with delimiters
 */
function currency(currency, options) {
  const sign = options.sign || '$';
  if (typeof currency === 'string') {
    currency = currency.replace(sign, '').replace(new RegExp(',', 'g'), '');
  }
  let newprice;
  if (options.noCents) {
    newprice = parseFloat(currency).toString();
  } else {
    newprice = parseFloat(currency).toFixed(2);
  }
  if (isNaN(newprice) || !newprice) {
    if (options.strict) {
      throw new Error('Could not convert ' + currency + ' to currency');
    } else {
      return (options.noSign ? '' : sign) + '0' + (options.noCents ? '' : '.00');
    }
  } else {
    let oldprice = newprice;
    const olderprice = parseFloat(newprice);
    newprice = parseInt(newprice, 10);
    oldprice = Math.abs(parseFloat(oldprice) - newprice)
      .toFixed(2)
      .replace('0.', '.');
    if (!options.noDelimiter) {
      newprice = numberWithCommas(newprice, ',');
    } else {
      newprice = newprice.toString();
    }
    newprice = newprice.replace('-', '');
    newprice = (olderprice >= 0 ? '' : '-') + (options.noSign ? '' : sign) + newprice + (options.noCents ? '' : oldprice);
  }
  return newprice;
}

function bytes(bytes, options) {
  const round = options.round || Math.round;
  let val = bytes + 'B';
  if (bytes >= 1024) {
    val = round(bytes / 1024) + 'KB';
    if (bytes >= 1024 * 1024) {
      val = round(bytes / 1024 / 1024) + 'MB';
      if (bytes >= 1024 * 1024 * 1024) {
        val = round(bytes / 1024 / 1024 / 1024) + 'GB';
        if (bytes >= 1024 * 1024 * 1024 * 1024) {
          val = round(bytes / 1024 / 1024 / 1024 / 1024) + 'TB';
        }
      }
    }
  }
  return val;
}

/*
function kilogram(weight, options) {
    if (weight) {
        if (typeof (weight) == "string") {
            weight = weight.replace("\kg", "");
        }
        var newweight = parseFloat(weight).toFixed(0);
        if (isNaN(newweight) || newweight == "") {
            newweight = weight;
        }
        return newweight + "kg";
    } else {
        return "0kg";
    }
}
*/

export default format;
export { currency, bytes };
