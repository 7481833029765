// Consider ie11 support;

import { getFaIcon, simpleIconHtml } from './fa';
// import fontawesome from "@fortawesome/fontawesome";
const platform = require('platform');

const FaStyle = /\bfa[lrbs]?(?![\w-])/g;
const FaIcon = /\bfa-((?!fw\b|lg\b|spin\b|border\b|li\b|flip-\w+\b|pull-\w+\b|rotate-\w+\b|xs\b|sm\b|\d\d?x\b)[\w-]+)/g;

export class IconSymbolizer {
    private readonly cached: { [icon: string]: string };
    private readonly rendered: { [symbolId: string]: boolean };
    private readonly symbols: HTMLElement;

    constructor(symbolId: string = 'fa-symbols') {
        this.cached = {};
        this.rendered = {};
        if (!global.document || !document.getElementById || !document.createElement) {
            return;
        }
        const existingSymbols = document.getElementById(symbolId);
        if (existingSymbols) {
            this.symbols = existingSymbols;
        } else {
            const newSymbols = document.createElement('span');
            newSymbols.style.display = 'none';
            newSymbols.id = symbolId;
            document.body.appendChild(newSymbols);
            this.symbols = newSymbols;
        }
    }

    private createSymbolizedIcon(iconTxt: string): string {
        if (!iconTxt) {
            return '';
        }
        const id = iconTxt.replace(/\s+/g, '_');
        const icon = getFaIcon(iconTxt);
        if (!icon) {
            return '';
        }
        const symbolId = `${icon.prefix}-fa-${icon.iconName}`;
        if (!this.rendered[symbolId]) {
            // console.log("Fontawesome", (global as any).FontAwesome);
            const faIcon = (global as any).FontAwesome.icon(icon, { symbol: true });
            if (!faIcon) {
                return '';
            }
            this.symbols.appendChild(faIcon.node[0]);
            this.rendered[symbolId] = true;
        }
        const withoutIcon = iconTxt.replace(FaStyle, '').replace(FaIcon, '');
        // console.log("Without icon::", withoutIcon);
        const referenceCode = `<svg class="svg-inline--fa ${withoutIcon}"><use xlink:href="#${symbolId}"></use></svg>`;
        this.cached[iconTxt] = referenceCode;
        return referenceCode;
    }

    public getSybolizedIcon(iconTxt: string): string {
        const referenceCode = this.cached[iconTxt];
        if (referenceCode) {
            return referenceCode;
        } else {
            return this.createSymbolizedIcon(iconTxt);
        }
    }
}

const iconSymbolizer = new IconSymbolizer();

if (platform && platform.name === 'IE') {
    iconSymbolizer.getSybolizedIcon = simpleIconHtml;
}

export { iconSymbolizer as default };
//
// const symbolizedIcon(iconTxt: string) {
//
// }
// <i class="fas fa-edit" data-fa-symbol="edit"></i>
//     <i class="fas fa-trash" data-fa-symbol="delete"></i>
//
// <div>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#edit"></use></svg>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#edit"></use></svg>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#edit"></use></svg>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#delete"></use></svg>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#delete"></use></svg>
// <svg class="svg-inline--fa fa-w-20"><use xlink:href="#delete"></use></svg>
// </div>
