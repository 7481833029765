import * as React from 'react';
import styles from './FloatingActionButton.scss';

import Icon from './Icon';

interface IFloatingActionButtonProps {
  icon: string;
}

export default class FloatingActionButton extends React.PureComponent<IFloatingActionButtonProps, never> {
  public render(): JSX.Element {
    const { icon, ...other } = this.props;
    return (
      <button className={styles.fab} {...other}>
        <Icon icon={icon} />
      </button>
    );
  }
}
