import { extendObservable, reaction, toJS } from "mobx";
import { useDisposable } from "mobx-react-lite";
import React, {} from "react";
import ReactDOMServer from "react-dom/server";
import MediumEditor from "react-medium-editor";
import registry, { AnyLazySource } from "../../react-components/WebfrontRegistry";
import HtmlEditor from "../Forms/Editors/HtmlEditor";
import HtmlFrame from "../Forms/Editors/HtmlFrame";
import Placeholder, { PlaceholderContext } from "../Forms/Editors/Placeholder";
import Field from "../Forms/Field";
import FieldEditor from "../Forms/FieldEditor";
import FieldSet from "../Forms/FieldSet";
import Form, { IFormProps } from "../Forms/Form";
import FormState from "../Forms/FormState";

export interface IInteractiveEmailProps extends IFormProps {
    formState: FormState<any>;
    formProps: any;
    record: any;
    source: string | AnyLazySource;
}

const emailFields = ["email_to", "email_from", "cc", "bcc", "subject"];
const InteractiveEmail: React.FunctionComponent<IInteractiveEmailProps> = (
    props: IInteractiveEmailProps
) => {

    const inputColumns = props.inputColumns || 10;

    const email = props.formState.record;
    const placeholderStore = email.webfront_metadata;
    extendObservable(placeholderStore, {});

    const EmailComponent = registry.getComponent(props.source) as React.FunctionComponent<{ record: any }>;

    useDisposable(() => reaction(() => JSON.stringify(toJS(placeholderStore)), () => {
        email.body = ReactDOMServer.renderToStaticMarkup((
            <PlaceholderContext.Provider value={{ enabled: false, placeholders: placeholderStore }}>
                <EmailComponent record={props.record}/>
            </PlaceholderContext.Provider>
        ));
    }));

    return (
        <PlaceholderContext.Provider value={{ enabled: true, placeholders: placeholderStore }}>
            <Form {...props} inputColumns={inputColumns}>
                <div className="row">
                    <div className="col-xs-12 col-md-8 col-lg-6">
                        <FieldSet fields={emailFields}/>
                    </div>
                </div>
                {/*<MediumEditor text={"sample <b>text</b> here"}/>But not here.*/}
                {/*<FieldEditor field="body" editor={HtmlEditor} editorProps={{height: "400px"}} />*/}

                <div className="well" style={{marginTop: "7.5px"}}>
                    <EmailComponent record={props.record}/>
                </div>
            </Form>
        </PlaceholderContext.Provider>
    );
};


export default InteractiveEmail;
