function dataGridToArrays(data, rows, columns, transform) {
  const grid = [];
  const width = columns.length;
  const height = rows.length;
  for (let y = 0; y < height; y++) {
    const row = [];
    for (let x = 0; x < width; x++) {
      row[x] = data[y]['field' + x];
      if (transform) {
        row[x] = transform(row[x]);
      }
    }
    grid.push(row);
  }
  return grid;
}

function dataGridToObjects(data, rows, columns, transform) {
  const grid = {};
  const width = columns.length;
  const height = rows.length;
  for (let y = 0; y < height; y++) {
    const row = {};
    for (let x = 0; x < width; x++) {
      const id = columns[x];
      row[id] = data[y]['field' + x];
      if (transform) {
        row[id] = transform(row[id]);
      }
    }
    grid[rows[y]] = row;
  }
  return grid;
}

function dataGridToCSV(data, rows, columns, transform, options = {}) {
  let grid = '';
  const width = columns.length;
  const height = rows.length;
  for (let x = 0; x < width; x++) {
    grid += ',' + columns[x];
  }
  if (options.showRowCounter) {
    grid += ',Total';
  }
  grid += '\n';

  for (let y = 0; y < height; y++) {
    let row = rows[y];
    let total = 0;
    for (let x = 0; x < width; x++) {
      let value = data[y]['field' + x];
      if (typeof value === 'number') {
        total += value;
      }
      if (transform) {
        value = transform(value);
      }
      row += ',' + value;
    }
    if (options.showRowCounter) {
      if (transform) {
        total = transform(total);
      }
      row += ',' + total;
    }
    grid += row + '\n';
  }
  return grid;
}

function arraysToDataGrid(data) {
  const returnData = [];
  for (let y = 0; y < data.length; y++) {
    const row = {};
    for (let x = 0; x < data[y].length; x++) {
      row['field' + x] = data[y][x];
    }
    returnData.push(row);
  }
  return returnData;
}

function objectsToDataGrid(data) {
  const returnData = [];
  for (const key in data) {
    const row = {};
    let i = 0;
    for (const key2 in data[key]) {
      row['field' + i] = data[key][key2];
      i++;
    }
    returnData.push(row);
  }
  return returnData;
}

function mappedObjectsToDataGrid(data, rowIds, columnIds) {
  const returnData = [];
  for (let y = 0; y < rowIds.length; y++) {
    const row = {};
    for (let x = 0; x < columnIds.length; x++) {
      row['field' + x] = data[rowIds[y]][columnIds[x]];
    }
    returnData.push(row);
  }
  return returnData;
}

function dataGroupToArrays(data, groups, columns, transform) {
  const grid = [];
  const groupHeight = groups.length;
  let index = 0;
  for (let y = 0; y < groupHeight; y++) {
    const group = groups[y];
    const groupItem = [];
    for (let yy = 0; yy < group.children.length; yy++) {
      const row = [];
      for (let x = 0; x < columns.length; x++) {
        row[x] = data[index]['field' + x];
        if (transform) {
          row[x] = transform(row[x]);
        }
      }
      groupItem.push(row);
      index++;
    }
    grid.push(groupItem);
  }
  return grid;
}

function dataGroupToObjects(data, groups, columns, transform) {
  const grid = {};
  const groupHeight = groups.length;
  let index = 0;
  for (let y = 0; y < groupHeight; y++) {
    const group = groups[y];
    const groupItem = {};
    for (let yy = 0; yy < group.children.length; yy++) {
      const row = {};
      for (let x = 0; x < columns.length; x++) {
        let value = data[index]['field' + x];
        if (transform) {
          value = transform(value);
        }
        row[columns[x]] = value;
      }
      groupItem[group.children[yy]] = row;
      index++;
    }
    grid[group.name] = groupItem;
  }
  return grid;
}

function objectToDataGroup(data, groupScope, rowScope, groupId, rowId) {
  const groupIds = {};
  for (let i = 0; i < groupScope.length; i++) {
    groupIds[groupScope[i][groupId]] = i;
  }
  const rowIds = {};
  let rowCount = 0;
  for (let i = 0; i < rowScope.length; i++) {
    rowIds[rowScope[i][rowId]] = i;
    rowCount++;
  }

  const outputData = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const id = rowIds[item[rowId]] + rowCount * groupIds[item[groupId]];
    outputData[id] = {};
    for (const field in item) {
      if (field !== groupId && field !== rowId) {
        outputData[id][field] = item[field];
      }
    }
  }

  return outputData;
}

export {
  dataGridToArrays,
  dataGridToObjects,
  dataGridToCSV,
  arraysToDataGrid,
  objectsToDataGrid,
  mappedObjectsToDataGrid,
  dataGroupToArrays,
  dataGroupToObjects,
  objectToDataGroup,
};
