// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "span._2m8yraL4mFr_tofiODh9g4{display:inline-block;margin:2px;padding:8px 8px;position:relative}span._2m8yraL4mFr_tofiODh9g4:not(._3vXq8Ml32UU-EL3DlyxY2U){cursor:pointer}span._2m8yraL4mFr_tofiODh9g4:not(._3vXq8Ml32UU-EL3DlyxY2U) *{cursor:pointer}span._2m8yraL4mFr_tofiODh9g4._3vXq8Ml32UU-EL3DlyxY2U>div{border-color:rgba(0,0,0,0.25)}span._2m8yraL4mFr_tofiODh9g4._3vXq8Ml32UU-EL3DlyxY2U ._2PsFU7_Wr65PjOCWHQpX0h{color:rgba(0,0,0,0.25)}span._2m8yraL4mFr_tofiODh9g4._2IXsa4AMzyRXUZpcgr9Tzz._3vXq8Ml32UU-EL3DlyxY2U>div{background:rgba(0,0,0,0.25);border-color:transparent}span._2m8yraL4mFr_tofiODh9g4._2IXsa4AMzyRXUZpcgr9Tzz:not(._3vXq8Ml32UU-EL3DlyxY2U)>div{background-color:#1976d2;border-color:#1976d2;background-color:var(--primary-colour, #1976d2);border-color:var(--primary-colour, #1976d2)}span._2m8yraL4mFr_tofiODh9g4._2IXsa4AMzyRXUZpcgr9Tzz svg{opacity:1}span._2m8yraL4mFr_tofiODh9g4._1dnn8NyUtDG8xTkSjqrnrn>div,span._2m8yraL4mFr_tofiODh9g4:hover:not(._3vXq8Ml32UU-EL3DlyxY2U)>div{border-radius:6px;border:6px solid rgba(0,0,0,0.25) !important;margin:-4px}span._2m8yraL4mFr_tofiODh9g4>div{border-radius:2px;border:2px solid rgba(0,0,0,0.5);box-sizing:content-box;display:inline-block;height:20px;transition:background-color 200ms, border-color 200ms;vertical-align:middle;width:20px}span._2m8yraL4mFr_tofiODh9g4>div svg{color:white;margin-left:2px;margin-top:2px;opacity:0;transition:opacity 200ms;position:relative;top:-2px}span._2m8yraL4mFr_tofiODh9g4 input{bottom:0;display:block;height:28px;left:7px;opacity:0;position:absolute;right:0;top:7px;width:28px;margin:0px}span._2m8yraL4mFr_tofiODh9g4 ._2PsFU7_Wr65PjOCWHQpX0h{color:black;display:inline;font-family:'Roboto';font-size:14px;font-weight:normal;line-height:1;padding:0.2em 0.6em 0.3em}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "_2m8yraL4mFr_tofiODh9g4",
	"disabled": "_3vXq8Ml32UU-EL3DlyxY2U",
	"label": "_2PsFU7_Wr65PjOCWHQpX0h",
	"active": "_2IXsa4AMzyRXUZpcgr9Tzz",
	"focused": "_1dnn8NyUtDG8xTkSjqrnrn"
};
module.exports = exports;
