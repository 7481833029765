import { pickBy, transform, reduce } from 'lodash';
import { isEmpty, isObject } from 'validate.js';
import inspect from 'inspect.macro';

export interface FieldConstraintsSpec {
    datetime: FieldConstraintSpec;
    js: FieldConstraintSpec;
    presence: FieldConstraintSpec;
}

export interface FieldConstraintSpec {
    enabled?: boolean;
    [key: string]: any;
}

export function parseFieldConstraint(s?: FieldConstraintSpec): { [key: string]: any } | undefined {
    if (!(s && s.enabled)) {
        return undefined;
    }

    return transform(
        s,
        (a: any, v: any, k: string) => {
            if (k !== 'enabled' && !isEmpty(v)) {
                const asFloat = parseFloat(v);
                a[k] = asFloat == v ? asFloat : v;
            }
        },
        {},
    );
}

export function parseConstraints(s?: FieldConstraintsSpec): FieldConstraints | undefined {
    const fcs = transform(
        s,
        (a: { [key: string]: any }, v: any, k: string) => {
            const fc = parseFieldConstraint(v);
            if (k === 'js' && (!fc || (typeof fc.code !== 'function' && isEmpty(fc.code)))) {
                inspect('js skip', fc, typeof fc.code, isEmpty(fc.code));
                return a;
            }

            if (fc) {
                a[k] = isEmpty(fc) ? true : fc;
            }
        },
        {},
    );
    if (isEmpty(fcs)) {
        return;
    }
    if (fcs.raw) {
        const { raw, rest } = fcs;

        if (raw === true) {
            return rest;
        }
        if (typeof raw.validation === 'function') {
            return raw.validation.bind(rest);
        } else {
            return Object.assign(rest, raw.validation);
        }
    }
    return fcs;
}

export interface FieldConstraints {
    datetime: { [key: string]: any };
    js: { [key: string]: any };
    presence: { [key: string]: any };
}
