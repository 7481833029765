import Class from 'classnames';
import { bind } from 'decko';
import * as React from 'react';
import Icon from './Icon';
import styles from './PullToRefresh.scss';

interface IPullToRefreshProps {
  onRefresh: () => Promise<any>;
  className?: string;
  style?: any;
}

interface IPullToRefreshState {
  cancelled: boolean;
  finishing: boolean;
  refreshing: boolean;
  dragging: boolean;
  startRefreshY: number;
  currentRefreshY: number;
}

export default class PullToRefresh extends React.Component<IPullToRefreshProps, IPullToRefreshState> {
  private requiredDragDistance: number = window.innerHeight / 4;

  constructor(props: IPullToRefreshProps) {
    super(props);

    this.state = { cancelled: false, currentRefreshY: 0, dragging: false, finishing: false, refreshing: false, startRefreshY: 0 };
  }

  @bind
  public handleTouchStart(e: TouchEvent): undefined | false {
    if (!this.state.refreshing && e.touches.length === 1 && window.scrollY === 0 && e.touches[0].clientY < window.innerHeight / 2) {
      this.setState({
        cancelled: false,
        currentRefreshY: e.touches[0].clientY,
        dragging: true,
        startRefreshY: e.touches[0].clientY,
      });
      return false;
    }
  }

  @bind
  public handleTouchMove(e: TouchEvent): undefined | false {
    if (this.state.dragging) {
      if (e.touches[0].clientY < this.state.startRefreshY) {
        this.setState({ dragging: false });
      } else {
        e.preventDefault();
        this.setState({ currentRefreshY: e.touches[0].clientY });
        return false;
      }
    }
  }

  @bind
  public handleTouchEnd(): void {
    this.setState({ dragging: false });
    const distance = this.state.currentRefreshY - this.state.startRefreshY;
    if (distance > this.requiredDragDistance) {
      this.setState({ refreshing: true });
      this.props.onRefresh().then(() => {
        this.setState({ finishing: true, refreshing: false });
        setTimeout(() => {
          this.setState({ currentRefreshY: 0, finishing: false, startRefreshY: 0 });
        }, 600);
      });
    } else {
      this.setState({ cancelled: true });
    }
  }

  public componentDidMount(): void {
    window.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    window.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    window.addEventListener('touchend', this.handleTouchEnd);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('touchstart', this.handleTouchStart, { passive: false });
    window.removeEventListener('touchmove', this.handleTouchMove, { passive: false });
    window.removeEventListener('touchend', this.handleTouchEnd);
  }

  public render(): JSX.Element | null {
    const { className, style } = this.props;
    const distance = this.state.currentRefreshY - this.state.startRefreshY;
    if (!distance) {
      return null;
    }
    let adjustedDistance = Math.min(distance / this.requiredDragDistance, 1);
    adjustedDistance = adjustedDistance * (2 - adjustedDistance);
    const rotation = Math.min(distance / this.requiredDragDistance, 1);
    const adjustedRotation = rotation * (2 - rotation) * 270;
    return (
      <div
        className={Class(styles.container, 'pull-to-refresh', className)}
        style={{
          transition: this.state.cancelled ? 'top 400ms' : undefined,
          top: this.state.cancelled ? 0 : adjustedDistance * 128,
          animation: this.state.finishing ? 'pull-refresh-fade 400ms' : undefined,
          opacity: this.state.finishing || this.state.cancelled ? '0' : undefined,
          ...style,
        }}
      >
        <Icon
          style={{
            animation: (this.state.refreshing || this.state.finishing) && 'pull-refresh-rotate 400ms infinite linear',
            color: distance > this.requiredDragDistance ? '#1E88E5' : '#BDBDBD',
            transform: `rotate(${adjustedRotation}deg)`,
          }}
          icon="fa-sync-alt"
        />
      </div>
    );
  }
}
