import './PageController.scss';

import * as Promise from 'bluebird';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { observer, Provider } from 'mobx-react';

import { observable } from 'mobx';
import PageRoute from './PageRoute';

interface IPages {
  [index: string]: PageRoute;
}

interface IPagePromises {
  [index: string]: (data?: any) => void;
}

const transitions = {
  popDown: ' pop-down',
  popLeft: ' pop-left',
  popRight: ' pop-right',
  popUp: ' pop-up',
  slideDown: 'slide-down',
  slideLeft: 'slide-left',
  slideRight: 'slide-right',
  slideUp: 'slide-up',
};

interface IPageControllerProps extends React.HTMLProps<HTMLElement> {
  customHistory?: boolean;
}

@observer
export default class PageController extends React.Component<IPageControllerProps, never> {
  @observable private route = '';
  private pageData: any = {};
  private pages: IPages = {};
  private promises: IPagePromises = {};
  private currentTransition = '';
  private goingBack = false;
  private routes: string[] = [];
  private visibleRoutes: string[] = [];

  constructor(props: IPageControllerProps) {
    super(props);

    this.pop = this.pop.bind(this);
    this.back = this.back.bind(this);
    this.finish = this.finish.bind(this);
    this.send = this.send.bind(this);
    this.swap = this.swap.bind(this);
    this.handlePopState = this.handlePopState.bind(this);
  }

  public UNSAFE_componentWillMount() {
    const div = document.createElement('div');
    if (!this.props.children) {
      throw new Error('PageController requires exactly one child component, none given');
    }
    if (Array.isArray(this.props.children)) {
      throw new Error('PageController requires exactly one child component, ' + this.props.children.length + ' given');
    }
    ReactDOM.render(
      <Provider baseRoute="" controller={this}>
        {this.props.children}
      </Provider>,
      div,
    );

    window.addEventListener('popstate', this.handlePopState);
  }

  public componentDidMount() {
    if (!this.props.customHistory) {
      history.pushState(
        {
          trueRoute: '',
        },
        '',
      );
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  private handlePopState(e) {
    if (this.props.customHistory) {
      return;
    }
    if (e.state) {
      if (this.routes.indexOf(e.state.trueRoute) >= 0) {
        this.pop();
      } else {
        /*
                alert("Going forward is not supported on this page");
                this.routes.push(this.route);
                this.route = e.state.trueRoute;
                history.back();
                */
      }
    } else if (this.routes.length) {
      this.pop();
    }
  }

  private pop() {
    const newRoute = this.routes.pop();
    if (newRoute !== undefined) {
      this.goingBack = true;
      const promise = this.promises[this.route];
      if (promise) {
        promise();
      }
      this.route = newRoute;
    }
  }

  public back() {
    if (this.props.customHistory) {
      setTimeout(() => this.pop(), 1);
    } else {
      history.back();
    }
  }

  public finish(data: any) {
    const prom = this.promises[this.route];
    if (prom) {
      prom(data);
    }
    this.back();
  }

  public send(route: string, data: {}) {
    const trueRoute = route.charAt(0) !== '/' ? (this.route ? this.route + '/' : '') + route : route.substr(1);
    this.pageData[trueRoute] = data;
    this.goingBack = false;
    if (this.visibleRoutes.indexOf(trueRoute) >= 0) {
      this.forceUpdate();
    } else {
      if (!this.props.customHistory) {
        history.pushState(
          {
            data,
            trueRoute,
          },
          '',
        );
      }
      this.routes.push(this.route);
      this.route = trueRoute;
    }
    return new Promise(resolve => {
      this.promises[trueRoute] = resolve;
    });
  }

  // Changes the route without adding history that it's changed
  // Because of this, you cannot actually transfer data as there is no history of this
  public swap(route: string) {
    const trueRoute = (this.route && route.charAt(0) !== '/' ? this.route + '/' : '') + route;
    this.goingBack = false;
    if (this.visibleRoutes.indexOf(trueRoute) >= 0) {
      this.forceUpdate();
    } else {
      if (!this.props.customHistory) {
        history.replaceState(
          {
            trueRoute,
          },
          '',
        );
      }
      this.routes[this.routes.length] = trueRoute;
      this.route = trueRoute;
    }
  }

  public registerRoute(route: string, page: PageRoute) {
    this.pages[route || '_index'] = page;
    this.forceUpdate();
  }

  public render() {
    const page = this.pages[this.route || '_index'];
    if (page) {
      let childElement;
      this.visibleRoutes = [];
      this.visibleRoutes.push(this.route);
      if (page.props.inline && window.innerWidth > 600) {
        const child = Array.isArray(page.props.children) ? page.props.children[0] : page.props.children;
        const childRoute = (this.route ? this.route + '/' : '') + child.props.route;
        childElement = React.createElement(child.props.component, { controller: this, data: this.pageData[childRoute], inline: true, key: childRoute });
        this.visibleRoutes.push(childRoute);
      }
      let render;
      if (childElement) {
        render = (
          <div key={`key${this.route}`}>
            <div className={page.props.inlineClassName}>
              {React.createElement(page.props.component, { controller: this, data: this.pageData[this.route] })}
              {childElement}
            </div>
          </div>
        );
      } else {
        render = <div key={`key${this.route}`}>{React.createElement(page.props.component, { controller: this, data: this.pageData[this.route] })}</div>;
      }

      let transition = '';
      if (this.goingBack) {
        transition = this.currentTransition + '-back';
      } else if (page.props.transition) {
        transition = transitions[page.props.transition];
        this.currentTransition = transition;
      }
      return (
        <TransitionGroup>
          <CSSTransition classNames={'transition-holder ' + transition} timeout={400}>
            {render}
          </CSSTransition>
        </TransitionGroup>
      );
    } else {
      return null;
    }
  }
}
