import GearsState from "./GearsState";
import GearsUser from "./GearsUser";
declare const global: any;

const state = new GearsState();
// global._handleLookups = (data: any) => state.setState(data);
global._handleLookups = (data: any) => {
    state.setState(data);
    global.Gears = global.Gears || {};
    global.Gears.lookups = data;
};
global.gearsState = state;

export {GearsUser, GearsState};
export default state;
