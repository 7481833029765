/// <reference path="../global.d.ts" />
import * as Promise from 'bluebird';
import { memoize } from 'lodash-decorators';

type IPageJS = any;

function getDefault(all: any): any {
    return all.default;
}

export function memoizedLoader(target: Loader, propertyKey: string, descriptor: PropertyDescriptor) {
    const { get } = descriptor;
    function memoizedGet() {
        const promise = (get as () => any).call(this);
        this.modules = this.modules || {};
        Object.defineProperty(this, propertyKey, { value: promise });
        promise.then((mod: any) => {
            this.modules[propertyKey] = mod;
        });
        return promise;
    }
    descriptor.get = memoizedGet;
}

function pageTimestamp() {
        const { timestamp} = $('body').data();
        return timestamp || new Date().getTime();
}

export class Loader {
    modules: any;
    pages: Map<string, IPageJS> = new Map<string, IPageJS>();
    constructor(loadTarget?: any, preload?: string[]) {
        this.modules = loadTarget || {};
        if (preload) {
            this.loadModules(preload);
        }
    }
    loadModules(toLoad: string[]): Promise<any> {
        return Promise.map(toLoad, item => (this as any)[item]);
    }

    @memoizedLoader
    get i18next(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "i18next" */ './i18next'));
    }

    @memoizedLoader
    get registry(): PromiseLike<any> {
        return import(/* webpackChunkName: "browser" ,  webpackPrefetch: true */ '../react-components/WebfrontRegistry').then(getDefault);
    }

    @memoize()
    loadPage(pageName: string, forModel?: string): Promise<IPageJS> {
        const url = `/t/${pageTimestamp()}/p/${forModel || 'null'}/${pageName}.json`;

        const pageLoader = $.get({
            dataType: 'json',
            url,
        });
        return Promise.all([
                pageLoader,
                this.registry,
                import(/* webpackChunkName "toPageJs", webpackPrefetch: true*/ '../react-components/template_builder/toPageJS').then(getDefault),
            ])
            .then(([pageConfig, registry, toPageJs]) => {
                const pageJs = toPageJs(pageConfig);
                this.pages.set(pageName, pageJs);
                registry.registerPageComponents(pageJs);
                return pageJs;
            })
            .catch((e: Error) => {
                console.error('Error loading page', e);
                throw new Error('Error loading page');
            });
    }

    @memoize()
    loadModel(model: string, forModel?: string): Promise<IPageJS> {
        const url = `/t/${pageTimestamp()}/p/${forModel || 'null'}/${pageName}.json`;

        const pageLoader = $.get({
            dataType: 'json',
            url,
        });
        return Promise.all([
                pageLoader,
                this.registry,
                import(/* webpackChunkName "toPageJs", webpackPrefetch: true*/ '../react-components/template_builder/toPageJS').then(getDefault),
            ])
            .then(([pageConfig, registry, toPageJs]) => {
                const pageJs = toPageJs(pageConfig);
                this.pages.set(pageName, pageJs);
                registry.registerPageComponents(pageJs);
                return pageJs;
            })
            .catch((e: Error) => {
                console.error('Error loading page', e);
                throw new Error('Error loading page');
            });
    }
    @memoizedLoader
    get email(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "email" */ '../compiler/renderPageEmail'));
    }
}
