import * as _ from "lodash";
import * as pluralize from "pluralize";
import {notification} from "./Notification";

declare const Gears: any;

const spacer = "&nbsp;&nbsp;&nbsp;";

export const AttachmentModel = {
    getAttachableText() {
        const type = this.get("attachable_type");
        const id = this.get("attachable_id");
        if (!type || !id) {
            return "";
        }
        return `${type}(${id})`;
    },
    thumbUrl() {
        const thumb_url = this.get("details.urls.thumb");
        if (!thumb_url) {
            return "";
        }
        return `<img src='${thumb_url}' height='50' width='50'/>`;
    },
    urlImg(version = "file") {
        const url = this.get(`details.urls.${version}`);
        if (!url) {
           return "";
        }
        return `<img src='${url}'/>`;
    },
    fileUrlLink() {
        const url = this.get("details.urls.file");
        const filename = this.get("details.file.filename");
        if (!filename) {return ""; }
        return `<a target='_blank' href='${url}'>${filename}</a> ${spacer}`;
    },
    links() {
        const urls = this.get("details.urls");
        if (!urls) {
            return "";
        }
        const {thumb, backdrop, file, thumb_fit, logo, preview} = urls;
        let links = "";
        if (file) {
            links += "<a target='_blank' href='" + file + "'>File</a>" + spacer;
        }
        if (backdrop) {
            links += "<a target='_blank' href='" + backdrop + "'>320x180 (User Backdrop)</a>" + spacer;
        }
        if (preview) {
            links += "<a target='_blank' href='" + preview + "'>150x150</a>" + spacer;
        }
        if (logo) {
            links += "<a target='_blank' href='" + logo + "'>64 height (Header Logo)</a>" + spacer;
        }
        if (thumb) {
            links += "<a target='_blank' href='" + thumb + "'>50x50 (fill)</a>" + spacer;
        }
        if (thumb_fit) {
            links += "<a target='_blank' href='" + thumb_fit + "'>50x50 (fit)</a>" + spacer;
        }
        return links;
    },
};

export const AttachmentsGridController = {
    customInitialize() {
        Gears.interceptDragAndDrop();
        this.initializeAttachments();
    },
    addRecordModal() {
        const item = new (this.config.gearsModel)();
        this.saveRecord(item).then(() => {
            return this.editRecord();
        });
    },
    gridUploadClick() {
        return $(this.gridFileUploadInputSelector()).last().click();
    },
    initializeAttachments() {
        _.defer(() => {
            this.initializeGridAttachments();
            this.initializeModalAttachments();
        });
    },
    modalFileUploadInputSelector() {
        return `${this.config.modalDomId()} input.file-upload`;
    },
    modalFileUploadDropZoneSelector() {
        return `${this.config.modalDomId()}`;
    },
    gridFileUploadDropZoneSelector() {
        return `${this.config.gridContainer()}`;
    },
    gridFileUploadInputSelector() {
        return `${this.config.gridContainer()} input.file-upload`;
    },
    initializeModalAttachments(): void {
        const $input = $(this.modalFileUploadInputSelector());
        console.log("initializeModalAttachments", $input, this.modalFileUploadInputSelector());
        if ($input.data("kendoUpload") != null) {
            console.log("Upload Already initialised");
            return;
        }
        $(this.modalFileUploadInputSelector()).kendoUpload({
            multiple: false,
            showFileList: false,
            async: {
                saveUrl: this.config.updateUrl(this.get("selectedRecord.id") + "/update"),
                saveField: "file",
                batch: false,
                autoUpload: true,
            },
            dropZone: this.modalFileUploadDropZoneSelector(),
            upload: (e: any) => {
                this.set("uploadError", false);
                console.log("Upload Attempt");
                return e.sender.options.async.saveUrl = this.config.updateUrl(this.get("selectedRecord.id")) + "/file.json";
            },
            error: (it) => {
                console.log("Error");
                console.log(it);
                this.set("uploadError", true);
                return this.showDataSourceError("Error Uploading File");
            },
            success: (it) => {
                console.log("Success");
                console.log(it);
                this.saveRecord().then(() => {
                    return this.refreshWithLookups();
                });
                console.log("Complete");
                console.log(it);
                if (this.get("uploadError")) {
                    this.set("uploadError", null);
                } else {
                    notification({
                        title: "Upload Complete",
                        content: "File sucessfully uploaded",
                        messageType: "success",
                        displayType: "bigBox",
                        timeout: 10000,
                    });
                }
                return true;
            },
        });
    },
    attachmentCreateUrl() {
        if (this.parentGrid) {
            return this.config.createUrl(this.parentGrid.get("selectedRecord.id"));
        } else {
            return this.config.createUrl();
        }
    },
    initializeGridAttachments() {
        const $input = $(this.gridFileUploadInputSelector());
        console.log("Initialising grid attachment", $input);
        if ($input.data("kendoUpload") != null) {
            console.log("Upload Already initialised");
            return;
        }
        // const $dropcontainer = $input.closest('.gears-drop-zone');
        // if (!$dropcontainer.length) {
        //    $input.closest('.gears-grid-container').addClass('gears-drop-zone');
        // }
        const kendoUpload = $(this.gridFileUploadInputSelector()).kendoUpload({
            async: {
                saveUrl: this.attachmentCreateUrl(),
                saveField: "file",
                batch: false,
                autoUpload: true,
            },
            dropZone: this.gridFileUploadDropZoneSelector(),
            upload: (e: any) => {
                this.set("uploadError", false);
                console.log("Upload Attempt", e);
                this.resizeGrid();
                e.sender.options.async.saveUrl = this.attachmentCreateUrl();
            },
            complete: (it) => {
                console.log("Complete");
                console.log(it);
                this.dataSource.read();
                if (this.get("uploadError")) {
                    this.set("uploadError", null);
                } else {
                    notification({
                        content: pluralize("files", kendoUpload.getFiles().length, true) + " sucessfully uploaded",
                        displayType: "bigBox",
                        messageType: "success",
                        timeout: 10000,
                        title: "Upload Complete",
                    });
                }
                kendoUpload.clearAllFiles();
                this.resizeGrid();
            },
            error: (it) => {
                this.set("uploadError", true);
                this.showDataSourceError(it.XMLHttpRequest);
            },
            success: (it) => {
                this.refreshWithLookups();
                if (it.response.error) {
                    this.set("uploadError", true);
                    $(".k-upload-files .k-cancel").each((id: any, brokenfile: any) => {
                        const fi = $(brokenfile).closest("li").first();
                        console.log(fi);
                        fi.removeClass("k-file-success");
                        fi.addClass("k-file-error");
                        return fi.find(".k-upload-status").html("<br/>error");
                    });
                    this.showDataSourceError(it.response);
                }
                if (this.parentGrid && !this.parentGrid.get("selectedRecord.id")) {
                    this.parentGrid.set("selectedRecord.id", it.response.attachable_id);
                }
            },
            files: [],
        }).data("kendoUpload");
        window.kendoUpload = kendoUpload;
    },
};

interface IAttachmentConfig {
    inputSelector: string;
    dropZoneSelector: string;
    url: string | Function;
    grid?: any;
}

export class AttachmentUploader {
    private options: IAttachmentConfig;
    private uploadError: boolean = false;
    private kendoUpload: kendo.ui.Upload;

    constructor(options: IAttachmentConfig) {
        this.options = options;
        Gears.interceptDragAndDrop();
        this.initializeAttachments();
    }

    public uploadClick(): void {
        $(this.inputSelector).last().click();
    }

    public get inputSelector(): string {
        return this.options.inputSelector || `${this.options.grid.config.gridContainer()} input.file-upload`;
    }

    public get dropZoneSelector(): string {
        return this.options.dropZoneSelector || `${this.options.grid.config.gridContainer()}`;
    }

    public get url(): string {
        const {url} = this.options;
        if (!url) {
            return this.options.grid.config.webFunctionUrl({methodType: "table", method: "create_attachment"});
        } else if (_.isFunction(url)) {
            return url();
        } else {
            return url as string;
        }
    }

    public initializeAttachments(): void {
        _.defer(() => {
            this._initializeAttachments();
        });
    }

    public _initializeAttachments(): void {
        const $input = $(this.inputSelector);
        console.log("initializeModalAttachments", $input, this.inputSelector);
        if ($input.data("kendoUpload") != null) {
            console.log("Upload Already initialised");
            return;
        }
        this.kendoUpload = $input.kendoUpload({
            multiple: false,
            showFileList: false,
            async: {
                saveUrl: this.url,
                saveField: "file",
                batch: false,
                autoUpload: true,
            },
            dropZone: this.dropZoneSelector,
            upload: (e: any) => {
                this.uploadError = false;
                // console.log("Upload Attempt");
                // $(this.inputSelector).remove();
                return e.sender.options.async.saveUrl = this.url;
            },
            error: (it) => {
                console.log("Error");
                console.log(it);
                this.uploadError = true;
                return Gears.showDataSourceError("Error Uploading File");
            },
            success: (it) => {
                console.log("Success");
                console.log(it);
                const {grid} = this.options;
                if (grid) {
                    Promise.resolve(grid.selectedRecord && grid.saveRecord()).then(() => grid.refreshWithLookups());
                }
                console.log("Complete");
                console.log(it);
                if (this.uploadError) {
                    this.uploadError = false;
                } else {
                    notification({
                        title: "Upload Complete",
                        content: "File sucessfully uploaded",
                        messageType: "success",
                        displayType: "bigBox",
                        timeout: 10000,
                    });
                }
                return true;
            },
        }).data("kendoUpload");
    }
}
