import Moment from 'moment-timezone';
import { IEditor } from './Editors/Editor';
import { Registry } from 'react-registry';
// import JsonEditor from "./Editors/JsonEditor";

type IStringTypeName = 'string' | 'tags_string' | 'interval' | 'tags';
type IArrayTypeName = 'tags';
type IDateTypeName = 'date' | 'date_month' | 'date_recent' | 'date_year' | 'datetime';
export type IFieldTypeName = IDateTypeName | IStringTypeName | IArrayTypeName | 'number' | 'rating' | 'boolean' | 'object';

export interface IFieldType<D = any> {
    type: IFieldTypeName;
    parse: (raw: any) => D;
    defaultValue?: (() => D) | D | null;
    validation?: (it: any) => boolean;
    editor: string;
    asJson?: (value: D) => string | null | object | number;
}

export function parseGearsBoolean(it: string | boolean | null): boolean | null {
    switch (it) {
        case 'false':
            return false;
        case 'true':
            return true;
        case '':
            return null;
        default:
            return !!it;
    }
}

// kendoModel.parseGearsBoolean = parseGearsBoolean;

export const DateType: IFieldType<Moment.Moment> = {
    asJson(value: Moment.Moment): string | null {
        return Moment(value).format('YYYY-MM-DD HH:mm Z');
    },
    defaultValue: null,
    editor: 'InputEditor',
    parse(it: string | Moment.Moment | Date): Moment.Moment {
        return Moment(it);
    },
    type: 'date',
};

export const NumberType: IFieldType<number> = {
    asJson(value: any): number | null {
        const asFloat = parseFloat(value);
        return asFloat == value ? asFloat : value;
    },
    defaultValue: null,
    editor: 'JsonEditor',
    parse: (global as any).kendo != null ? kendo.parseFloat : parseFloat,
    type: 'number',
};

export const StringType: IFieldType<string> = {
    defaultValue: '',
    editor: 'InputEditor',
    parse(value: string | number | null | undefined): string | null {
        if (value != null) {
            return value + '';
        } else {
            return null;
        }
    },
    type: 'string',
};

export const ArrayType: IFieldType<any[]> = {
    defaultValue(): any[] {
        return [];
    },
    parse(it: any): any[] {
        if (typeof it === 'object') {
            return it;
        } else {
            return [];
        }
    },
};
export const ObjectType: IFieldType<any> = {
    defaultValue(): object {
        return {};
    },
    editor: 'JsonEditor',
    parse(it: any): object {
        if (typeof it === 'object') {
            return it;
        } else {
            return {};
        }
    },
};

export const BooleanType: IFieldType<boolean> = {
    defaultValue: null,
    editor: 'SwitchEditor',
    parse: parseGearsBoolean,
};

export const UnknownType: IFieldType<any> = {
    defaultValue: null,
    parse(it: any): any {
        return it;
    },
};

export const FieldTypes = {
    boolean: BooleanType,
    date: DateType,
    date_month: DateType,
    date_recent: DateType,
    date_year: DateType,
    datetime: DateType,
    default: UnknownType,
    interval: StringType,
    number: NumberType,
    object: ObjectType,
    rating: NumberType,
    string: StringType,
    tags: ArrayType,
    tags_string: StringType,
};

export function toFieldType(t: keyof typeof FieldTypes | IFieldType): IFieldType {
    return typeof t === 'string' ? FieldTypes[t] || FieldTypes.default : (t as IFieldType);
}
