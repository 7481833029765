// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2am6QHOeaKWHh9QX5GHYhJ{border-radius:50%;width:56px;height:56px;background-color:var(--primary-colour, #1e88e5);border:none;color:var(--text-colour, white);position:fixed;right:24px;bottom:24px;outline:none;z-index:11;box-shadow:0 6px 10px rgba(0,0,0,0.14),0 1px 18px rgba(0,0,0,0.12),0 3px 5px rgba(0,0,0,0.2);transition:color 200ms, background-color 200ms, box-shadow 200ms}._2am6QHOeaKWHh9QX5GHYhJ:hover,._2am6QHOeaKWHh9QX5GHYhJ:focus{background-color:var(--primary-hover-colour, #1565c0)}._2am6QHOeaKWHh9QX5GHYhJ:active{background-color:var(--primary-active-colour, #0d47a1);box-shadow:0 12px 17px 2px rgba(0,0,0,0.14),0 5px 22px 4px rgba(0,0,0,0.12),0 7px 8px rgba(0,0,0,0.2)}._2am6QHOeaKWHh9QX5GHYhJ svg{font-size:24px !important}\n", ""]);
// Exports
exports.locals = {
	"fab": "_2am6QHOeaKWHh9QX5GHYhJ"
};
module.exports = exports;
