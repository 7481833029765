import classnames from 'classnames';
import * as React from 'react';

const jQuery = require('jquery');
global.$ = jQuery;
global.jQuery = jQuery;
global.webuiPopover = require('../../vendor/assets/webui-popover/jquery.webui-popover');

// import {observer} from "mobx-react";

interface IPopoverProps {
    className: string;
    placement:
        | 'auto'
        | 'top'
        | 'right'
        | 'bottom'
        | 'left'
        | 'top-right'
        | 'top-left'
        | 'bottom-right'
        | 'bottom-left'
        | 'auto-top'
        | 'auto-right'
        | 'auto-bottom'
        | 'auto-left'
        | 'horizontal'
        | 'vertical'; // Default: 'auto'
    container: any; // Default document.body. The container in which the popover will be added (i.e. The parent scrolling area). May be a jquery object, a selector string or a HTML element. See https://jsfiddle.net/1x21rj9e/1/
    width: 'auto' | number; // Default 'auto'.
    height: 'auto' | number; // Default "auto".
    trigger: 'click' | 'hover' | 'manual' | 'sticky'; // Default "click".
    selector: string;
    style: string; // Not to be confused with inline `style=""`, adds a classname to the container for styling, prefixed by `webui-popover-`. Default '' (light theme), 'inverse' for dark theme
    animation: 'pop' | 'fade'; // pop with animation,values: pop,fade (only take effect in the browser which support css3 transition)
    delay: {
        // show and hide delay time of the popover, works only when trigger is 'hover',the value can be number or object
        show?: number;
        hide?: number;
    };
    async: {
        type: 'GET' | 'POST' | 'PUT'; // ajax request method type, default is GET
        before: (that: any, xhr: any, settings: any) => any; // executed before ajax request
        success: (that: any, data: any) => any; // executed after successful ajax request
        error: (that: any, xhr: any, data: any) => any; // executed after error ajax request
    };
    cache: boolean; // if cache is set to false,popover will destroy and recreate
    multi: boolean; // allow other popovers in page at same time
    arrow: boolean; // show arrow or not
    title: string; // the popover title, if title is set to empty string,title bar will auto hide
    content: string; // content of the popover,content can be function
    closeable: boolean; // display close button or not
    direction: 'ltr' | 'rtl'; // direction of the popover content default is ltr ,values:'rtl';
    padding: boolean; // content padding
    type: 'html' | 'iframe' | 'async'; // content type, values:'html','iframe','async'
    url: any; // if type equals 'html', value should be jQuery selecor.  if type equels 'async' the plugin will load content by url.
    backdrop: boolean; // if backdrop is set to true, popover will use backdrop on open
    dismissible: boolean; // if popover can be dismissed by  outside click or escape key
    autoHide: boolean; // automatic hide the popover by a specified timeout, the value must be false,or a number(1000 = 1s).
    offsetTop: number; // offset the top of the popover
    offsetLeft: number; // offset the left of the popover
    onShow: ($element: any) => any;
    onHide: ($element: any) => any;
}

export default class Popover extends React.PureComponent<Partial<IPopoverProps>, never> {
    private popoverRef: React.RefObject<any> = React.createRef();

    componentDidMount(): void {
        if (this.popoverRef.current) {
            ($(this.popoverRef.current) as any).webuiPopover(this.props);
        }
    }

    render(): React.ReactNode {
        return (
            <span ref={this.popoverRef} className={this.props.className}>
                {this.props.children}
            </span>
        );
    }
}
