import * as _ from 'lodash';
import * as numeral from 'numeral';
import { Calculator, ICalculationInputs, ICalculationOptions, ICalculationResult, registerCalculationType } from './Calculator';

export interface IAddOptions extends ICalculationOptions {
    calculation_type: 'add';
    values: Array<string | number>;
}

export default class Add extends Calculator {
    private values: Array<string | number>;
    constructor(options: IAddOptions) {
        super(options);
        this.values = options.values;
    }

    protected calculationFunction(inputs: ICalculationInputs): Partial<ICalculationResult> {
        const parts = _.map(this.values, (v: string | number) => (typeof v === 'string' ? (inputs as any)[v] : v) || 0);

        const rawResult = _.sum(parts);
        const result = numeral(this.roundValue(rawResult));
        const formattedResult = result.format(this.roundFormat);
        const calculation = _.join(parts, ' + ') + ' = ' + formattedResult;
        return {
            calculation,
            calculation_type: this.calculation_type,
            formattedResult,
            rawResult,
            result: result.value(),
            rounding: this.roundFormula('' + rawResult),
        };
    }
}

registerCalculationType('add', Add);
registerCalculationType('sum', Add);
