import {cnb as classnames} from 'cnbuilder';
import React from 'react';

import styles from './ActionItem.scss';
import Icon from './Icon';
import { Menu, MenuItem } from './Menu';
import { IExportedMenuItemProps } from './Menu/MenuItem';
import Tooltip from './Tooltip';

interface IBaseActionItemProps {
  id?: string;
  title: string;
  showAsIcon?: 'ifRoom' | 'never' | 'always';
  disabled?: boolean;
  className?: string;
  index?: number;
  icon: string | object;
}

interface IIconActionItemProps extends IBaseActionItemProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}

interface IMenuActionItemProps extends IBaseActionItemProps {
  menu: IExportedMenuItemProps[];
}

type IActionItemProps = IIconActionItemProps | IMenuActionItemProps;
type IACtionItemMergedProps = IIconActionItemProps & IMenuActionItemProps;

interface IActionItemRenderProps extends IACtionItemMergedProps {
  type: 'icon' | 'menu';
}

export default class ActionItem extends React.PureComponent<IActionItemProps, never> {
  public render(): JSX.Element {
    const { type } = this.props as IActionItemRenderProps;
    return type === 'icon' ? this.renderIcon() : this.renderMenu();
  }

  private generateMenu(menu: IExportedMenuItemProps[], submenu?: boolean): JSX.Element {
    return (
      <Menu target={submenu ? `#actionbar-item-overflow-${this.props.index}` : `#actionbar-item-${this.props.index}`} mount="left-top">
        {menu.map(item => (
          <MenuItem key={item.title + item.icon} {...item} />
        ))}
      </Menu>
    );
  }

  public renderIcon(): JSX.Element {
    const { id, index, icon, onClick, disabled, title, className, menu, showAsIcon, ...other } = this.props as IIconActionItemProps & IMenuActionItemProps;
    return (
      <span
        id={id || `actionbar-item-${index}`}
        tabIndex={0}
        onTouchStart={() => {}}
        onClick={!disabled ? onClick : undefined}
        className={classnames(className, styles.item, { [styles['has-dropdown']]: menu !== undefined, [styles.disabled]: disabled })}
        {...other}
      >
        <Icon icon={icon} />
        {menu && <Icon className={styles.dropdown} small icon={'fa-caret-down'} />}
        {!menu && !disabled && (
          <Tooltip target={id || `#actionbar-item-${index}`} mount="below-center" event="hover">
            {title}
          </Tooltip>
        )}
        {menu && this.generateMenu(menu)}
      </span>
    );
  }

  public renderMenu(): JSX.Element {
    const { id, index, icon, onClick, disabled, title, className, menu, showAsIcon, ...other } = this.props as IIconActionItemProps & IMenuActionItemProps;
    return (
      <MenuItem id={`actionbar-item-overflow-${index}`} title={title} icon={icon} disabled={disabled} onClick={onClick}>
        {menu && this.generateMenu(menu, true)}
      </MenuItem>
    );
  }
}
