// import { equal as shallowEqual } from "fast-shallow-equal";
import Switch from '@commonui/Switch';
import React, { useCallback } from 'react';
import { Registry } from 'react-registry';
import { IEditorProps } from './Editor';

import classNameType from 'classnames';
const dedupeClassNames: typeof classNameType = require('cndedupe');

export interface ISwitchEditorProps extends IEditorProps<boolean> {}

const SwitchEditor: React.FunctionComponent<ISwitchEditorProps> = ({ field, form, onValueChange, className, ...props }: ISwitchEditorProps) => {
    const onChange = useCallback(
        (v: boolean) => {
            console.log('Clicked', v);
        },
        [props.value],
    );
    console.log('Rendering switch', props.value, props.value.get());
    return <Switch {...props} onChange={onChange} className={dedupeClassNames(className, { 'form-control': false })} />;
};

SwitchEditor.displayName = 'SwitchEditor';
Registry.register(SwitchEditor, { id: 'SwitchEditor' });

export default SwitchEditor;

// const editors =
