import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './setup';
import * as $ from 'jquery';
import * as calculation from './calculation';
import { ActionCable, default as cable } from './gears/actioncable';
import disableBackspace from './gears/helpers/disableBackspace';
import tryCatch from './gears/helpers/tryCatch';
import lite from './modules/LiteLoader';
import StandalonePage from './react-components/template_builder/StandalonePage';
require('./react-components/mock/MBIEInitiliazer');

require('../vendor/assets/javascripts/bootstrap.min');
require('../vendor/assets/javascripts/url.min');

declare const global: any;

if (!console.table) {
    console.table = console.log;
}

disableBackspace();

global.$ = $;
global.jQuery = global.$;
require('js-url');
// Requires global jquery to install to
// import "../vendor/assets/javascripts/url.min";
global.tryCatch = tryCatch;
global.lite = lite;
global.Promise = global.Bluebird;
global.cable = cable;
global.ActionCable = ActionCable;
global.calculation = calculation;
global.StandalonePage = StandalonePage;
