// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".no-scroll{overflow:hidden}._16uo0RODySB945kIaZqlgR{background:black;opacity:0;z-index:9000;transition:opacity 400ms;position:fixed;top:0;left:0;right:0;bottom:0;pointer-events:none}._16uo0RODySB945kIaZqlgR._38twMrs_K_h7RBqIG2_TtF{pointer-events:unset;opacity:0.25}@media (min-width: 1024px){._16uo0RODySB945kIaZqlgR._5ZycCIfK0zpwF_VnhKahT{z-index:7}}.ApVCXXJKFISWCpPQzyGhn{position:fixed;top:0px;left:0px;height:100vh;bottom:0px;z-index:9001;background:white;transition:transform 400ms, box-shadow 400ms;box-shadow:-3px 0px 5px 2px rgba(0,0,0,0);will-change:transform;visibility:hidden}@media (min-width: 1024px){.ApVCXXJKFISWCpPQzyGhn._5ZycCIfK0zpwF_VnhKahT{z-index:8;margin-top:64px;height:calc(100vh - 64px)}}.ApVCXXJKFISWCpPQzyGhn._38twMrs_K_h7RBqIG2_TtF{visibility:visible;box-shadow:0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12),0 8px 10px rgba(0,0,0,0.2)}.ApVCXXJKFISWCpPQzyGhn._38TomfRGPIeytcikJxiF72{visibility:visible}\n", ""]);
// Exports
exports.locals = {
	"backdrop": "_16uo0RODySB945kIaZqlgR",
	"open": "_38twMrs_K_h7RBqIG2_TtF",
	"has-action-bar": "_5ZycCIfK0zpwF_VnhKahT",
	"sidebar": "ApVCXXJKFISWCpPQzyGhn",
	"scroll-transition": "_38TomfRGPIeytcikJxiF72"
};
module.exports = exports;
