// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._9U_Vk_ddRibDROl3huXK2{position:relative;margin:0px;padding-bottom:16px;padding-top:24px;padding:32px}\n", ""]);
// Exports
exports.locals = {
	"card-content": "_9U_Vk_ddRibDROl3huXK2"
};
module.exports = exports;
