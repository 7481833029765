// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3CcBkzbyyrTQeSAVOKNUeb:not(._1_OgFO1SZGqk2x7t-il6tU),._3CcBkzbyyrTQeSAVOKNUeb:not(._1_OgFO1SZGqk2x7t-il6tU) *{cursor:pointer}._3CcBkzbyyrTQeSAVOKNUeb:disabled{opacity:0.5}\n", ""]);
// Exports
exports.locals = {
	"button-item": "_3CcBkzbyyrTQeSAVOKNUeb",
	"disabled": "_1_OgFO1SZGqk2x7t-il6tU"
};
module.exports = exports;
