// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "ul._1CmYDPIq_GKXTCSmcMzHPZ{color:gold;display:inline-block;list-style:none;margin:0px;padding:0px;vertical-align:middle}ul._1CmYDPIq_GKXTCSmcMzHPZ li{display:inline}ul._1CmYDPIq_GKXTCSmcMzHPZ li svg{display:inline;text-shadow:1px 1px 1px #666;margin:0px;cursor:pointer}ul._1CmYDPIq_GKXTCSmcMzHPZ li svg._24iqgbzCmMGsEVh8M06AQ_{color:#cccccc}ul._1CmYDPIq_GKXTCSmcMzHPZ._3fpFywGXjAUIsVyHBMheA3 li{font-size:2em}\n", ""]);
// Exports
exports.locals = {
	"rating": "_1CmYDPIq_GKXTCSmcMzHPZ",
	"rating-empty": "_24iqgbzCmMGsEVh8M06AQ_",
	"form": "_3fpFywGXjAUIsVyHBMheA3"
};
module.exports = exports;
