import Class from 'classnames';
import Button from 'commonui/Button';
import Dialog from 'commonui/Dialog';
import TextArea from 'commonui/TextArea';
import { bind } from 'decko';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NoteManager } from '.';
import styles from './Note.scss';
import INoteType from './NoteType';
import ParentNote from './ParentNote';

interface INoteProps {
    filter?: object;
    name?: string | NoteNameFunction;
    limit?: number;
    hideResolved?: boolean;
    canCreate?: boolean;
    showHeader?: boolean;
    showName?: boolean;
    removeControls?: boolean;
    createButtonText?: string;
    createButtonIcon?: string;
    createButtonClassName?: string;
    postButtonText?: string;
    postButtonIcon?: string;
    postButtonClassName?: string;
}

type NoteNameFunction = (metadata?: object) => string;

@inject('noteStore')
@observer
export default class Note extends React.Component<INoteProps, never> {
    @observable private creatingNote: boolean = false;
    @observable private noteText: string = '';

    @bind
    @action
    private startCreateNote(): void {
        this.creatingNote = true;
    }

    @bind
    @action
    private onCancelNote(): void {
        this.noteText = '';
        this.creatingNote = false;
    }

    @bind
    @action
    private onAddReply(reply: INoteType): void {
        this.creatingNote = false;
        this.noteText = '';
        if (_.findIndex(this.props.noteStore.notes, { id: reply.id }) < 0) {
            this.props.noteStore.notes.push(reply);
        }
    }

    @bind
    private onFailedCreate(): void {
        Dialog({
            buttons: ['OK'],
            content: 'An unexpected error occured or your connection to the server timed out, please try again',
            title: 'Failed to create note',
        });
    }

    @bind
    private onFailedReply(): void {
        Dialog({
            buttons: ['OK'],
            content: 'An unexpected error occured or your connection to the server timed out, please try again',
            title: 'Failed to reply',
        });
    }

    @bind
    @action
    private onCreateNote(): void {
        const noteStore = this.props.noteStore as NoteManager;
        if (this.noteText) {
            $.ajax({
                data: {
                    note: {
                        channel: noteStore.channel,
                        created_by_email: noteStore.email,
                        created_by_id: noteStore.user,
                        note_text: this.noteText,
                        webfront_metadata: {
                            ...this.props.filter,
                            _name: this.props.name,
                        },
                    },
                    ...noteStore.data,
                },
                dataType: 'json',
                method: 'POST',
                url: noteStore.submitRoute || '/notes/',
            })
                .then(this.onAddReply)
                .catch(this.onFailedCreate);
        } else {
            this.creatingNote = false;
        }
    }

    public render(): JSX.Element {
        const {
            name,
            filter,
            limit,
            hideResolved,
            canCreate,
            showHeader,
            showName,
            removeControls,
            createButtonClassName,
            createButtonIcon,
            createButtonText,
            postButtonClassName,
            postButtonIcon,
            postButtonText,
        } = this.props;

        const noteStore = this.props.noteStore as NoteManager;

        if (noteStore.loading) {
            return (
                <div className={Class(styles['no-notes'], 'no-notes')}>
                    <span className={Class(styles.message, 'message')}>Loading Notes...</span>
                </div>
            );
        }

        const realCreate = canCreate === undefined ? true : canCreate;
        const notes = noteStore.notes as INoteType[];
        const displayName = name ? (typeof name === 'string' ? name : name(filter)) : undefined;
        const filteredNotes = notes
            .filter(item => !item.parent_note_id)
            .filter(item => (hideResolved ? item.resolved_at === null : true))
            .filter(item => (filter ? _.find(item, filter) !== undefined : true));

        return (
            <div className={Class(styles['note-area'], 'note-area')}>
                {displayName && filteredNotes.length > 0 && showName && <div className={Class(styles['note-header'], 'note-header')}>{displayName}</div>}
                {filteredNotes.map(note => (
                        <ParentNote
                            key={note.id}
                            note={note}
                            user={noteStore.user}
                            onAddReply={this.onAddReply}
                            onFailedReply={this.onFailedReply}
                            filter={filter}
                            name={displayName}
                            canResolve={noteStore.canResolve}
                            channel={noteStore.channel}
                            showHeader={showHeader}
                            removeControls={removeControls}
                            childNotes={_.filter(notes, { parent_note_id: note.id })}
                        />
                    ))}
                {!filteredNotes.length && !this.creatingNote && (
                    <div className={Class(styles['no-notes'], 'no-notes')}>
                        <span className={Class(styles.message, 'message')}>{displayName ? `No Active Notes for ${displayName}` : 'No Active Notes'}</span>
                        {realCreate && (
                            <Button
                                onClick={this.startCreateNote}
                                className={Class(styles.create, 'create', createButtonClassName)}
                                title={createButtonText || 'Create Note'}
                                icon={createButtonIcon || 'fa-plus'}
                                coloured
                            />
                        )}
                    </div>
                )}
                {realCreate && filteredNotes.length > 0 && !this.creatingNote && (limit ? filteredNotes.length < limit : true) && (
                    <div className={styles['add-note']}>
                        <Button onClick={this.startCreateNote} className={Class(styles.create, 'create')} title="Create Note" icon="fa-plus" coloured />
                    </div>
                )}
                {this.creatingNote && (
                    <div className={Class(styles['create-note'], 'create-note')}>
                        <TextArea hideLabel placeholder={displayName || 'Note'} observable={this} value="noteText" label={displayName || 'Note'} />
                        <Button className={postButtonClassName} onClick={this.onCreateNote} title={postButtonText || 'Create'} icon={postButtonIcon || 'fa-check'} coloured />
                        <Button className={'red'} onClick={this.onCancelNote} title="Cancel" icon="fa-times" coloured />
                    </div>
                )}
            </div>
        );
    }
}
