import useFetch from "fetch-suspense";
import _, { property,  toPath, reduce } from "lodash";
import { computed, IComputedValue, IObservableValue, set as mobxSet, action } from "mobx";
import { useCallback, useMemo, useState } from "react";
import { ConfigurationError } from "../Errors";
import { defineModel } from "../GearsModel/ModelType";
import gearsState from "../GearsState";
import { ILookup } from "../GearsState/Lookups";
import { ensureNestedObservable, setNestedObservable } from "../helpers/setNestedObservable";
import useEachCallback from "./useEachCallback";

export function usePathComputed<T extends object, VT = any>(object: T, path: string): IComputedValue<VT> {
    return useMemo(() => {
        const p = toPath(path);
        const getP = property<T, VT>(path);
        return computed<VT>(() => {
            ensureNestedObservable(object, p);
            return getP(object);
        }, action((v: VT) => {
            setNestedObservable(object, p, v);
        }));
    }, [object, path]);
}

export function useFocusWithProps(props: Partial<{ onFocus?: React.FocusEventHandler, onBlur?: React.FocusEventHandler }>, initialState: boolean = false): [boolean, { onFocus: React.FocusEventHandler<HTMLInputElement>, onBlur: React.FocusEventHandler<HTMLInputElement> }] {
    const { onFocus, onBlur } = props;
    const [focus, setFocus] = useState(initialState);
    const setFocusTrue = useCallback(() => setFocus(true), []);
    const setFocusFalse = useCallback(() => setFocus(false), []);
    return [focus, {
        onFocus: useEachCallback([onFocus, setFocusTrue]),
        onBlur: useEachCallback([onBlur, setFocusFalse])
    }];
}

export function useModel(modelName: string): any {
    return useMemo(() => {
        const Models = (global as any).Gears?.Auto?.Models;
        if (!(Models && Models[modelName])) {
            const data = useFetch("/gearsjs.js", {
                credentials: "include"
            });
            (global as any).gearsFunction = (global as any).gearsFunction || ((...args: any[]) => console.log("gearsFunction called", args));
            eval(data);
        }
        const modelSpec = (global as any).Gears.Auto.Models[modelName];
        if (!modelSpec) {
            throw new ConfigurationError(`Could not load spec for Model '${modelName}'`);
        }
        const usingModel = defineModel(modelSpec, modelName);
        console.log(`Using Model: ${modelName}`, usingModel);
        return usingModel;
    }, [modelName]);
}

export function useLookup(name: string): ILookup[] {
    return gearsState.lookups.getLookup(name);
}

const handlerMap = {
    blur: "onBlur",
    change: "onChange",
    keyUp: "onKeyUp",
    keyDown: "onKeyDown",
    keyPress: "onKeyPress"
};

interface IChangeEventHandlers<T = Element> {
    onBlur?: React.FormEventHandler<T>;
    onChange?: React.FormEventHandler<T>;
    onKeyUp?: React.FormEventHandler<T>;
    onKeyDown?: React.FormEventHandler<T>;
    onKeyPress?: React.FormEventHandler<T>;
}

export interface IValueChangeHandlerProps<T = string> extends IChangeEventHandlers {
    updateValueOn: "blur" | "change" | "keyUp" | "keyDown";
    value: IObservableValue<T>;
    onValueChange?: (newValue: T) => void;
}

export function useValueChangeHandlerWithProps<T extends IValueChangeHandlerProps,
    E extends Element & { value: any } = HTMLInputElement>
(props: T, eventValue: (event: React.FocusEvent<E>) => any = (event: React.FocusEvent<E>): void => event.target.value): IChangeEventHandlers<E> {
    const { updateValueOn, onValueChange, value } = props;
    const handlerName = handlerMap[updateValueOn] as keyof IChangeEventHandlers<E>;
    const passthrough = props[handlerName] as any as React.FormEventHandler<E> | null;
    return useMemo(() => {
        const valueChangeHandler = (event: React.FocusEvent<E>): void => {
            const newValue = eventValue(event);
            passthrough && passthrough(event);
            value && (value as any).setter && value.set(newValue);
            onValueChange && onValueChange(newValue);
        };
        return { [handlerName]: valueChangeHandler };
    }, [updateValueOn, onValueChange, handlerName, passthrough]);
}
