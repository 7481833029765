import * as Promise from 'bluebird';
import * as $ from 'jquery';
import gearsDialog, { closeDialog } from '../../react-components/kendo-dialog';
import { ConfigurationError } from '../Errors';
import { beautify, isBeautifyFormat } from '../helpers/beautify';
import { highlight } from './highlight';

declare const Gears: any;

type DisplayType = 'smallBox' | 'extraSmallBox' | 'bigBox' | 'dialog' | 'iframe' | 'browser';

type MessageType = 'info' | 'warning' | 'question' | 'success' | 'default' | null;

type ContentFormat = 'code' | 'sql' | 'sqlError' | 'js' | 'javascript' | 'json' | 'grid' | null;

export interface INotificationOptions {
    animation?: string;
    buttons?: any[];
    kendo?: string;
    color?: string;
    content?: string | any[] | null;
    children?: any;
    html?: string;
    icon?: string;
    iconSmall?: string;
    timeout?: number;
    grid?: string;
    title?: string | null | undefined;
    displayType?: DisplayType;
    messageType?: MessageType;
    type?: MessageType;
    dialogStyle?: any;
    contentFormat?: ContentFormat;
    tag?: string;
    width?: string;
}

interface IFrameINotificationOptions extends INotificationOptions {
    displayType: 'iframe';
    url: string;
}

export function notification(options: INotificationOptions): Promise<{}> {
    console.log('Options: ', options);
    switch (options.displayType) {
        case 'smallBox':
            return smartNotification(options);
        case 'extraSmallBox':
            return smartNotification(options);
        case 'bigBox':
            return smartNotification(options);
        case 'browser':
            return browserNotification(options);
        default:
            options.buttons = options.buttons || ['Ok'];
            return dialog(options);
    }
}

function formatContent(content?: string | any[] | null, options?: INotificationOptions): string | null {
    if (!options) {
        options = {};
    }
    if (!content && options.displayType !== 'browser') {
        return '&nbsp;';
    }
    const longContent = typeof content !== 'string' || content.length > 200;
    if (longContent) {
        options.width = options.width || 'width-75p width-800px';
    }

    let format = (options && options.contentFormat) || 'text';
    if (format === 'raw') {
        return content as string;
    }

    // if (format === "grid") {
    //     const grid = options.grid || {};
    //     options.children = <QuickGrid content={content as any[]} {...grid} />;
    //     options.width = "grid-dialog";
    //     return null;
    // }

    let isCode = format === 'code';
    if (isBeautifyFormat(format)) {
        content = beautify(content as string, format);
        isCode = true;
    }
    if (format === 'sqlError') {
        isCode = true;
        format = 'sql';
    }

    if (isCode) {
        return `<pre class="gears-code pre-wrap">${highlight(content as string, format)}</pre>`;
    } else {
        if (longContent) {
            return `<pre class="gears-code pre-wrap">${content}</pre>`;
        } else {
            return Gears.breakNewlines(content || '&nbsp;');
        }
    }
}

function formatTitle(title: string | null | undefined, options?: INotificationOptions): string | undefined | null {
    if (title && title.length > 200) {
        return _.truncate(title, 200);
    } else {
        return title;
    }
}

function notificationOptions(options: INotificationOptions | string): INotificationOptions {
    const actualOptions: INotificationOptions = typeof options === 'string' ? { content: options as string } : options;
    if (!actualOptions.title && actualOptions !== null && actualOptions.displayType !== 'browser') {
        actualOptions.title = '&nbsp;';
    }
    actualOptions.content = formatContent(actualOptions.content || (actualOptions as any).message, actualOptions);
    actualOptions.title = formatTitle(actualOptions.title, actualOptions);

    return actualOptions;
}

interface INotificationDefaults {
    [key: string]: INotificationOptions;
}

const notificationDefaults: INotificationDefaults = {
    default: {
        animation: 'fadeInRight animated',
        color: '#C79121',
        icon: 'fa-shield',
    },
    info: {
        animation: 'fadeInRight animated',
        color: 'bg-color-blue bg-lighten-1',
        icon: 'fa-thumbs-up',
    },
    question: {
        animation: 'bounce animated',
        color: 'bg-color-blue bg-lighten-1',
        icon: 'fa-question',
    },
    success: {
        animation: 'bounce animated',
        color: '#296191',
        icon: 'fa-check',
    },
    warning: {
        animation: 'fadeInLeft animated',
        color: '#C79121',
        icon: 'fa-shield',
    },
};

export function browserNotification(options: INotificationOptions): Promise<any> {
    return Promise.try(() => Notification.requestPermission())
        .then(permission => {
            if (permission !== 'granted') {
                throw new Error('Notification not supported');
            }
        })
        .catch(() => {
            options.displayType = 'smallBox';
            return smartNotification(options);
        })
        .then(() => showBrowserNotification(options));
}

function showBrowserNotification(options: INotificationOptions): Promise<any> {
    const processedOptions = notificationOptions(options);
    const { title, content, timeout, tag } = processedOptions;
    return new Promise((resolve, reject) => {
        const n = new Notification(title as string, { body: content as string, tag });
        n.addEventListener('close', resolve, false);
    });
}

export function smartNotification(options: INotificationOptions): Promise<any> {
    const processedOptions = notificationOptions(options);
    const { title, content, animation, timeout, messageType } = processedOptions;
    let { icon, displayType, color } = processedOptions;

    const defaults = (messageType && notificationDefaults[messageType]) || notificationDefaults.default;
    if (icon == null) {
        icon = defaults.icon;
    }
    if (animation == null) {
        animation || defaults.animation;
    }
    if (color == null) {
        color = defaults.color;
    }

    const iconString = `fa ${icon} ${animation}`;

    if (color && color[0] !== '#') {
        const colRgb = Gears.bgFromClass(color);
        if (colRgb) {
            color = colRgb;
        }
    }

    const smartOptions: INotificationOptions = { title, content, color, timeout };

    /*
    global.notifications.next({
        id: Math.random(),
        title: title,
        content: content ? content.replace(/(<([^>]+)>)/ig, "") : "",
        icon: {name: icon, colour: color},
        extra: global.moment().format("h:mm:ss a MMM Do"),
    });
    */

    if (displayType === 'extraSmallBox') {
        smartOptions.iconSmall = iconString;
        displayType = 'smallBox';
    } else {
        smartOptions.icon = iconString;
        if (displayType !== 'smallBox') {
            displayType = 'bigBox';
        }
    }

    return new Promise((resolve, reject) => {
        ($ as any)[displayType as string](smartOptions, resolve);
    });
}

export function dialog(options: INotificationOptions): Promise<any> {
    console.log('dialog called');
    if (!options) {
        return Promise.resolve('No Confirmation Required');
    }
    options = notificationOptions(options);
    options.messageType = options.messageType || 'info';
    options.icon = options.icon || options.messageType;
    return gearsDialog(options);
}

export function iFrameDialog(optionsUrl: IFrameINotificationOptions | string): Promise<any> {
    let options: INotificationOptions;
    let url: string;
    console.log('Iframe not', optionsUrl);
    if (typeof optionsUrl === 'string') {
        options = {};
        url = optionsUrl;
    } else {
        options = optionsUrl;
        url = optionsUrl && optionsUrl.url;
    }
    if (!(options && url)) {
        console.log('Url missing for iframe notification', options, optionsUrl);
        throw new ConfigurationError('Url missing for iframe notification');
    }
    options.content = `<iframe src="${url}" width="99.6%" height="600" frameborder="0"></iframe>`;
    options.title = options.title || null;

    return gearsDialog(options);
}

export function getConfirmation(options: INotificationOptions): Promise<any> {
    if (!options) {
        return Promise.resolve('No Confirmation Required');
    }
    options = notificationOptions(options);
    options.icon = options.icon || options.messageType || 'warning';
    options.messageType = options.messageType || 'info';
    return dialog(options);
}

export { closeDialog };
