import {titleize} from "inflection";
import {observable, get, set} from "mobx";
import {createContext, ReactNode} from "react";
import FieldConfig from "../GearsModel/FieldConfig";
import { isModelInstance, Model } from "../GearsModel/Model";
import { defineModel, IModelType } from "../GearsModel/ModelType";

export default class FormState<M extends Model> {
    public readonly model: IModelType<M>;
    @observable public record: M;
    @observable public errors: {} = {};

    constructor(recordOrModel?: M | IModelType<M>) {
        if (recordOrModel == null) {
            recordOrModel = defineModel({id: "id", fields: {}, views: {}, actions: {}}, 'Undefined Model');
        }
        // console.log("Treat record as record?", recordOrModel, isModelInstance(recordOrModel));
        if (isModelInstance(recordOrModel)) {
            // console.log("Record");
            this.model = recordOrModel.constructor as IModelType<M>;
            this.record = recordOrModel;
        } else {
            // console.log("Model");
            this.model = recordOrModel;
            this.record = new recordOrModel({});
        }
    }

    public getFieldConfig(field: string): FieldConfig<M, any>  {
        return this.model.getFieldConfig(field);
    }

    public showField(fieldName: string): boolean {
        const field  = this.getFieldConfig(fieldName);
        return field.enabled && this.record.showField(fieldName);
    }

    public get allFields(): string[] {
        return this.model.formFields;
    }
}

export const FormContext = createContext<FormState<any>>(null);
