// import { equal as shallowEqual } from "fast-shallow-equal";
import { compact, noop, over } from "lodash";
import {  reaction} from "mobx";
import {useDisposable} from "mobx-react-lite";
import React, {  useRef } from "react";
import {Registry} from "react-registry";
import { IValueChangeHandlerProps, useFocusWithProps, useValueChangeHandlerWithProps } from "../hooks";
import { IEditorProps } from "./Editor";


export interface IInputEditorProps extends IEditorProps<string>, IValueChangeHandlerProps<string>{
    updateValueOn: "blur" | "change" | "keyUp" | "keyDown";
}

const InputEditor: React.FunctionComponent<IInputEditorProps> = (props: IInputEditorProps) => {
    const { onValueChange, enabled,  updateValueOn, form, value, ...extra } = props;
    if (!enabled) {
        return (<input {...extra} readOnly={true} value={value.get()}/>);
    }
    const [focus, focusHandlers] = useFocusWithProps(props, false);

    const inputRef = useRef<HTMLInputElement>(null);

    useDisposable(() =>
        reaction(() => value.get(), (newValue: string) => {
            if (!focus && inputRef.current && inputRef.current.value !== newValue ) {
                inputRef.current.value = newValue;
            }
        })
    );

    const updateHandler = useValueChangeHandlerWithProps(props);

    // const inputProps = {...extra, ...focusHandlers, ...updateHandler};

    return (<input  {...extra} {...focusHandlers} {...updateHandler} defaultValue={value.get()} />);
};

InputEditor.defaultProps = {
    updateValueOn: "change",
    enabled: true
};

InputEditor.displayName = "InputEditor";

Registry.register(InputEditor, {id: "InputEditor"});


export default InputEditor;

// const editors =

