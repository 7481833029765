import classnames from "classnames";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext, useContext } from "react";
import {Registry} from "react-registry";
import {IEditor} from "./Editors";
// import { RenderParam } from "../../react-components/RenderProps";
import  FieldEditor  from "./FieldEditor";
import FieldLabel from "./FieldLabel";
import FormState, { FormContext } from "./FormState";

interface IFieldProps {
    field: string;
    form?: FormState<any>;
    inputColumns?: number;
    render?: {
        label?: boolean,
    };
    editor?: IEditor;
}

export const InputColumnsContext = createContext<number>(8);

const Field: React.FunctionComponent<IFieldProps> = observer((
    { field, form, inputColumns, editor, render = { label: true } }: IFieldProps
) => {
    form = form || useContext(FormContext);
    const isVisible = form.showField(field);
    // console.log(`Rendering Field: ${field}, visible: ${isVisible}`, form);
    if (!isVisible) {
        return null;
    }

    const fieldConfig = form.getFieldConfig(field);
    const effectiveInputColumns: number = (render.label === false) ? 12 : inputColumns || useContext(InputColumnsContext) as number;
    return (
        <div className="form-group">
            <FieldLabel form={form} field={field} className={`col-xs-${12 - effectiveInputColumns}`}/>
            <div className={`col-xs-${effectiveInputColumns} input-group`}><FieldEditor field={field} editor={editor} /></div>
        </div>
    );
});

Field.displayName = "Field";
Registry.register(Field, {id: "Field"});

export default Field;
