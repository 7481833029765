import { compact, difference, filter, includes, map, sortBy, sortedUniq, some,  transform, isEmpty } from 'lodash';
import { compileFilter } from '@progress/kendo-data-query';

import inspect from 'inspect.macro';

interface Named {
    name: string;
}

interface Simple {
    to: string;
    field: string;
}

export type Transition = {
    name?: string;
    to?: string;
    field?: string;
    from?: string[];
    roles?: string[] | null;
    filters?: any;
} & (Named | Simple);

export interface TransitionsSpec {
    transitions: any[];
    field?: string;
    roles?: string[];
    source?: string;
    states?: string[];
}

interface Model {
    [key: string]: any;
}

export function isPermitted(transition: Transition, role: string, instance: Model): boolean {
    return toPredicate(transition, role)(instance);
}

// const arraytoFilter = <T>(a: T[], logic: 'or') => any {
//     if(!a || a.length === 0) {
//         return null;
//     }
//     return {
//         logic: logic,
//     filters: a.map((a) => {operator: 'eq'a.})
//     }
//
//
// }

const TRUE = (m: Model) => true;
const FALSE = (m: Model) => false;

function any(r: any, options: any[]): boolean {
    return includes(options, r);
}

export function toFilterSpec(transition: Transition): any {
    const { filters, from, field } = transition;
    const allFilters = [];

    if (from || from === '') {
        const normalizedFrom = typeof from === 'string' ? [from as string] : (from as string[]);
        if (includes(normalizedFrom, '')) {
            normalizedFrom.push(null as any);
        }
        if (normalizedFrom.length === 1) {
            allFilters.push({
                field,
                operator: 'eq',
                value: normalizedFrom[0],
            });
        } else if (normalizedFrom.length > 1) {
            allFilters.push({
                filters: normalizedFrom.map(f => ({
                    field,
                    operator: 'eq',
                    value: f,
                })),
                logic: 'or',
            });
        }
    }

    if (!isEmpty(filters)) {
        allFilters.push(filters);
    }
    return { filters: allFilters, logic: 'and' };
}
export function validRole(required: string[] | null | undefined, role: string | { [key: string]: boolean }): boolean {
    if (!required) {
        return true;
    }
    if (!role) {
        return false;
    }
    const ownedRoles: { [key: string]: boolean } = typeof role === 'string' ? transform((role || '').split(/\s+/), (a, i) => (a[i] = true), {}) : role;
    // inspect(required, role, ownedRoles, typeof role === 'string', (role || '').split(/\s+/), transform((role || '').split(/s+/), (a, i) => (a[i] = true), {}));
    return some(required, r => ownedRoles[r]);
}

export function toPredicate(transition: Transition, role: string | { [key: string]: boolean }): (m: Model) => boolean {
    if (!validRole(transition.roles, role)) {
        return FALSE;
    }
    if (!transition.from && isEmpty(transition.filters)) {
        return TRUE;
    }
    return compileFilter(toFilterSpec(transition));
}

export function availableTransitions(transitions: Transition[], role: string, instance: Model): Transition[] {
    return filter(transitions, t => isPermitted(t, role, instance));
}

export class Transitions implements TransitionsSpec {
    transitions: any[];
    field?: string;
    roles: string[];
    source?: string;
    states: string[];

    constructor(spec: TransitionsSpec) {
        this.field = spec.field;
        this.roles = spec.roles || [];
        this.source = spec.source;
        this.states = spec.states || [];
        this.transitions = spec.transitions || this.missingTransitions;
    }

    get missingTransitions(): Transition[] {
        if (this.states) {
            return [];
        }
        return map(difference(this.states, this.toStates), (s: string) => ({
            field: this.field as string,
            to: s,
        }));
    }

    get toStates(): string[] {
        return compact(sortedUniq(sortBy(map(this.transitions, 'to'))));
    }
}
