// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1TUkU8-LLJr__YvT8vajF7{position:fixed;top:100%;font-family:'Roboto', 'Open-Sans';width:240px;left:calc(50% - 120px);color:rgba(255,255,255,0.87);background-color:#212121;padding:14px 24px;font-size:1rem;font-weight:500;transition:top 400ms}._1TUkU8-LLJr__YvT8vajF7._1bApoVfV0bxpmIpxDsGKSi{top:calc(100% - 48px)}@media (max-width: 600px){._1TUkU8-LLJr__YvT8vajF7{left:0;right:0;width:auto}._1TUkU8-LLJr__YvT8vajF7._1bApoVfV0bxpmIpxDsGKSi{top:calc(100% - 48px)}}\n", ""]);
// Exports
exports.locals = {
	"snackbar-container": "_1TUkU8-LLJr__YvT8vajF7",
	"open": "_1bApoVfV0bxpmIpxDsGKSi"
};
module.exports = exports;
