import classnames from "classnames";
import * as marked from "marked";
import React, { FunctionComponent, ReactNode } from "react";
import Popover from "../../react-components/Popover";
import { ConfigurationError } from "../Errors";
import FieldConfig, { IFieldHelpSpec } from "../GearsModel/FieldConfig";
import FormState from "./FormState";
import { titleize } from "inflection";
import {Registry} from "react-registry";

// import {observer} from "mobx-react";

interface ILabelProps {
    field: string;
    form: FormState<any>;
    label?: ReactNode;
    help?: ReactNode | IFieldHelpSpec;
    className: string;
}

function helpPopoverAttributes(help: IFieldHelpSpec): object {
    if (help == null) {
        throw new ConfigurationError("Attempting to generate help popover from null help specification.");
    }
    return {
        title: help.title,
        content: help.title ? marked(help.content) : "",
        trigger: "focus click",
        html: true
    };
}

const FieldLabel: FunctionComponent<ILabelProps> = ({field, form, help, label, className}) => {
    const fieldConfig =  form.getFieldConfig(field);

    if(help == null) {
        help = fieldConfig.help;
    }

    if(label == null) {
        label = fieldConfig.label;
    }

    // console.log("Label for spec",  fieldConfig);

    if (help) {
        const helpSpec: IFieldHelpSpec | null = typeof help === "object" ? help as IFieldHelpSpec :  {content: help as string};
        const helpIcon = helpSpec.icon || "fa-info-circle";

        return (
            <label style={{ cursor: "pointer" }} className={classnames("control-label", className)}>
                <Popover {...helpPopoverAttributes(helpSpec)}>
                    <i className={classnames(helpIcon, "fa fa-fw fa-lg txt-color-grey")}/>
                    {label}
                </Popover>
            </label>
        );
    } else {
        return (<label className={classnames("control-label", className)}> {label} </label>);
    }
};
FieldLabel.displayName = "FieldLabel";
Registry.register(FieldLabel, {id: "FieldLabel"});

export default FieldLabel;
