import Class from 'classnames';
import { bind } from 'decko';
import { find, findIndex, flatMap, orderBy, sortBy, uniqBy } from 'lodash';
import * as React from 'react';
import { ButtonGroup, ButtonItem } from '../ButtonGroup';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Cell from './Cell';
import Header from './Header';
import './SmartGrid.scss';

export type Alignment = 'left' | 'center' | 'right';
export type CellType = 'number' | 'string' | 'currency' | 'icon' | 'date' | 'datetime' | 'time' | 'custom';
export type Renderer = (type: 'group' | 'row' | 'total', column: IColumn, data: object, value: string) => JSX.Element;

export interface IMergedRow {
  data: ICell[];
  originalIndex?: number;
  [key: string]: any;
}

export interface IComplexCell {
  value: string | number;
  renderer?: Renderer;
  export?: (data: any) => string | number;
  disabled?: boolean;
}

type ICell = IComplexCell | string | number;

export interface IColumnTooltip {
  icon: string;
  value: string;
  className?: string;
}

export interface IColumn {
  name: string;
  icon?: string;
  iconOnly?: boolean;
  alignment?: Alignment;
  width?: string | number;
  field: string;
  type?: CellType;
  typeOptions?: any;
  className?: string;
  disabled?: boolean;
  renderer?: Renderer;
  totalable?: boolean;
  export?: (data: object) => string | number;
  sortable?: boolean;
  spacer?: false;
  groupable?: boolean;
  tooltip?: IColumnTooltip | IColumnTooltip[];
  customSum?: (type: 'group' | 'total', column: IColumn, rows: IMergedRow[], field: string) => string | number;
  customHeader?: string | JSX.Element;
  updateOnBlur?: boolean;
}

export interface ISpacer {
  spacer: true;
}

interface ICustomHeader {
  title: string;
  columns: string[];
}

interface ICustomHeaderArray {
  [key: number]: ICustomHeader[];
}

export interface ISmartGridProps {
  data: ICell[];
  className?: string;
  columns: IColumn[];
  buttonGroup?: string;
  disabled?: boolean;
  theme?: string;
  sort?: string;
  sortOrder?: 'asc' | 'desc';
  group?: string;
  grid: any;
  rowTotal?: boolean;
  /** Inlines group with first column, if this column has a total it will be hidden */
  inlineGroups?: boolean;
  showGroupTitle?: boolean;
  downloadCSV?: (name: string) => void;
  /** Prevent grouping controls from appearing on grid */
  lockGrouping?: boolean;
  /** Prevent sorting controls from appearing on grid */
  lockSorting?: boolean;
  /** Start the grid closed if grouped */
  startClosed?: boolean;
  /** True to add pagination to grid, breaking up display of rows over multiple pages */
  paginate?: boolean;
  /** Pagesize if paginated */
  pageSize?: number;
  customHeaders?: ICustomHeaderArray[];
  customSum?: (type: 'group' | 'total', column: IColumn, rows: IMergedRow[], field: string) => string | number;
}

interface ISmartGridState {
  sortedRows: IMergedRow[];
  sortedField?: string;
  sortOrder?: 'asc' | 'desc';
  groupBy?: string;
  closedGroups: string[];
  page: number;
  pageTabScroll: number;
}

interface ICellRef {
  [key: string]: ICell;
}

function idString(str: string): string {
  return (str || '')
    .toString()
    .replace(new RegExp(/[ .+-/!/@/#/$/%/^/&/*/;/:/?/|<>/\\(\)\'\"]/, 'g'), '_')
    .toLowerCase();
}

const firefoxFix = navigator.userAgent.toLowerCase().indexOf('firefox') >= 0;

/**
 * A highly extensible smart grid
 */
export default class SmartGrid extends React.Component<ISmartGridProps, ISmartGridState> {
  private cellRefs: ICellRef = {};
  private defaultPaginationLimit = 32;
  private defaultPageTabSize = 5;
  private cachedGroupCount: number[] = [];
  private cachedFullGroupCount: number[] = [];

  constructor(props: ISmartGridProps) {
    super(props);

    this.state = {
      closedGroups: [],
      groupBy: props.group,
      page: 0,
      pageTabScroll: 0,
      sortOrder: props.sortOrder,
      sortedField: props.sort,
      sortedRows: this.generateSortedRows(props, props.sort, props.sortOrder),
    };

    const closedGroups: string[] = [];
    if (props.startClosed && props.group) {
      this.props = props;
      this.generateGroupList(props.group).map((item: string) => {
        closedGroups.push(item);
      });
    }

    this.state = {
      closedGroups,
      groupBy: props.group,
      page: 0,
      pageTabScroll: 0,
      sortOrder: props.sortOrder,
      sortedField: props.sort,
      sortedRows: this.generateSortedRows(props, props.sort, props.sortOrder),
    };
  }

  public static average(type: 'group' | 'total', column: IColumn, rows: IMergedRow[], field: string): number {
    let total = 0;
    for (const row of rows) {
      total += row[field];
    }
    return total / rows.length;
  }

  public shouldComponentUpdate(nextProps: ISmartGridProps, nextState: ISmartGridState) {
    if (this.props.grid.smartgrid !== nextProps.grid.smartgrid) {
      return false;
    }
    if (
      JSON.stringify({
        closedGroups: nextState.closedGroups,
        groupBy: nextState.groupBy,
        page: nextState.page,
        pageTabScroll: nextState.pageTabScroll,
        sortOrder: nextState.sortedRows,
        sortedField: nextState.sortedField,
      }) !==
      JSON.stringify({
        closedGroups: this.state.closedGroups,
        groupBy: this.state.groupBy,
        page: this.state.page,
        pageTabScroll: this.state.pageTabScroll,
        sortOrder: this.state.sortedRows,
        sortedField: this.state.sortedField,
      })
    ) {
      return true;
    }
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      return true;
    }
    if (
      JSON.stringify({
        buttonGroup: nextProps.buttonGroup,
        className: nextProps.className,
        columns: nextProps.columns.map((e) => ({ ...e, customHeader: null })),
        disabled: nextProps.disabled,
        group: nextProps.group,
        inlineGroups: nextProps.inlineGroups,
        lockGrouping: nextProps.lockGrouping,
        lockSorting: nextProps.lockSorting,
        rowTotal: nextProps.rowTotal,
        showGroupTitle: nextProps.showGroupTitle,
        sort: nextProps.sort,
        sortOrder: nextProps.sortOrder,
        startClosed: nextProps.startClosed,
        theme: nextProps.theme,
      }) !==
      JSON.stringify({
        buttonGroup: this.props.buttonGroup,
        className: this.props.className,
        columns: this.props.columns.map((e) => ({ ...e, customHeader: null })),
        disabled: this.props.disabled,
        group: this.props.group,
        inlineGroups: this.props.inlineGroups,
        lockGrouping: this.props.lockGrouping,
        lockSorting: this.props.lockSorting,
        rowTotal: this.props.rowTotal,
        showGroupTitle: this.props.showGroupTitle,
        sort: this.props.sort,
        sortOrder: this.props.sortOrder,
        startClosed: this.props.startClosed,
        theme: this.props.theme,
      })
    ) {
      return true;
    }
    return false;
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ISmartGridProps) {
    nextProps.grid.smartgrid = this;
    if (this.props.group !== nextProps.group) {
      this.resetGroupCache();
      const closedGroups: string[] = [];
      if (nextProps.startClosed && nextProps.group) {
        this.props = nextProps;
        this.generateGroupList(nextProps.group).map((item: string) => {
          closedGroups.push(item);
        });
      }
      this.setState({ closedGroups, groupBy: nextProps.group });
    }
    this.setState({ sortOrder: nextProps.sortOrder, sortedField: nextProps.sort, sortedRows: this.generateSortedRows(nextProps, nextProps.sort, nextProps.sortOrder) });
  }

  public isGrouped() {
    return this.state.groupBy !== undefined && this.state.groupBy !== '';
  }

  @bind
  public collapseAll() {
    this.resetGroupCache();
    if (this.state.groupBy) {
      const closedGroups: string[] = [];
      this.generateGroupList(this.state.groupBy).map((item: string) => {
        closedGroups.push(item);
      });
      this.setState({ closedGroups });
    }
  }

  @bind
  public openAll() {
    this.resetGroupCache();
    this.setState({ closedGroups: [] });
  }

  private resetGroupCache() {
    // Reset the cached groups
    this.cachedGroupCount.splice(0);
    this.cachedFullGroupCount.splice(0);
  }

  @bind
  private toggleGroupOpen(group: string) {
    const closedGroups = [...this.state.closedGroups];
    if (closedGroups.indexOf(group) >= 0) {
      const t = _.filter(this.state.sortedRows, { [this.state.groupBy]: group });
      this.cachedGroupCount[closedGroups.indexOf(group)] = t.length;
      closedGroups.splice(closedGroups.indexOf(group), 1);
    } else {
      this.cachedGroupCount[closedGroups.indexOf(group)] = 0;
      closedGroups.push(group);
    }
    this.setState({ closedGroups });
  }

  @bind
  private onInput(e: KeyboardEvent, row: number, column: number) {
    // User pressed enter
    if (e.which === 13) {
      const dir = e.shiftKey ? -1 : 1;
      this.cellRefs[row + '_' + column].blur();
      const newRef = this.cellRefs[row + dir + '_' + column];
      if (newRef) {
        newRef.focus();
      }
    }
  }

  @bind
  private onPaste(e: ClipboardEvent, rowNumber: number, columnNumber: number) {
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
      let rows = pastedText.split(new RegExp(/(?!\B"[^"]*)\n(?![^"]*"\B)/, 'g'));
      rows = rows.splice(0, rows.length - 1);
      if (rows.length) {
        let rowIndex = 0;
        for (const row of rows) {
          const columns = row.split(new RegExp(/(?!\B"[^"]*)\t(?![^"]*"\B)/, 'g'));
          let columnIndex = 0;
          for (let column of columns) {
            let newRef = this.cellRefs[rowNumber + rowIndex + '_' + (columnNumber + columnIndex)];
            // Skip hidden columns
            if (newRef.props.column.name === '') {
              while (newRef !== undefined) {
                newRef = this.cellRefs[rowNumber + rowIndex + '_' + (columnNumber + columnIndex + 1)];
                columnIndex++;
                if (newRef.props.column.name) {
                  break;
                }
              }
            }
            if (newRef) {
              let observable = newRef.props.rowData;
              let value = newRef.props.column.field;
              const realValue = newRef.props.rowData[newRef.props.column.field];
              let isDisabled = newRef.props.disabled || newRef.props.column.disabled;
              if (typeof realValue === 'object' && realValue !== null) {
                observable = realValue;
                value = 'value';
                isDisabled = isDisabled || realValue.disabled;
              }
              if (!isDisabled) {
                if (column.charAt(0) === `"`) {
                  column = column.substring(1, column.length - 1);
                }
                observable[value] = column;
              }
            }
            columnIndex++;
          }
          rowIndex++;
        }
        e.preventDefault();
      }
    }
  }

  @bind
  private setSort(e: React.MouseEvent<HTMLElement>) {
    const columnIndex = parseInt(e.currentTarget.attributes['data-column'].value, 10);
    const column = this.props.columns[columnIndex];
    if (this.state.sortedField !== column.field) {
      this.setState({ sortOrder: undefined, sortedField: column.field, sortedRows: this.generateSortedRows(this.props, column.field) });
    } else {
      const sortOrder = this.state.sortOrder === undefined || this.state.sortOrder === 'asc' ? 'desc' : 'asc';
      this.setState({ sortOrder, sortedRows: this.generateSortedRows(this.props, column.field, sortOrder) });
    }
  }

  @bind
  private setGroup(e: React.MouseEvent<HTMLElement>) {
    const group = e.currentTarget.attributes['data-group'].value;
    this.resetGroupCache();
    if (this.state.groupBy !== group) {
      const closedGroups: string[] = [];
      if (this.props.startClosed) {
        this.generateGroupList(group).map((item: string) => {
          closedGroups.push(item);
        });
      }
      this.setState({ closedGroups, groupBy: group });
    } else {
      this.setState({ groupBy: '' });
    }
    e.preventDefault();
    e.stopPropagation();
  }

  private renderHeader() {
    const { columns, inlineGroups, lockGrouping, lockSorting } = this.props;
    return (
      <Header
        columns={columns}
        group={this.setGroup}
        sort={this.setSort}
        sortedField={this.state.sortedField}
        sortOrder={this.state.sortOrder}
        groupBy={this.state.groupBy}
        inlineGroups={inlineGroups}
        lockGrouping={lockGrouping}
        lockSorting={lockSorting}
      />
    );
  }

  private generateGroupList(field: string): string[] {
    let unique = uniqBy(
      flatMap(this.props.data, e => {
        const val = e[field];
        if (typeof val === 'object' && val !== null) {
          return {
            [field]: val.value,
          };
        }
        return {
          [field]: val,
        };
      }),
      field,
    );
    if (this.state.sortedField) {
      if (this.state.sortedField !== field) {
        unique.map((group, columnIndex) => {
          this.state.sortedRows.map((childData: IMergedRow) => {
            const groupValue = childData[field];
            if (groupValue === group[field]) {
              let value = childData[this.state.sortedField];
              if (typeof value === 'string') {
                value = parseFloat(value.replace(/[$,]/g, ''));
              }
              group[this.state.sortedField] = (group[this.state.sortedField] || 0) + value;
            }
          });
        });
      }
      unique = orderBy(unique, this.state.sortedField, this.state.sortOrder || 'asc');
    }
    return flatMap(unique, field);
  }

  private renderRowCells(row: IMergedRow, rowIndex: number, groupedBy?: string, groupValue?: string) {
    const { columns, inlineGroups } = this.props;
    const render = [];
    render.push(
      columns.map((column, columnIndex) => {
        if (column.field === groupedBy && !column.spacer) {
          return;
        } else {
          return this.renderCell(column, row.data, rowIndex, columnIndex);
        }
      }),
    );
    return render;
  }

  private renderCell(column: IColumn, rowData: any, rowIndex: number, columnIndex: number, isGroupHeader?: boolean) {
    const { disabled, updateOnBlur } = this.props;
    return (
      <Cell
        key={idString(column.field + '_' + columnIndex + '_' + column.name + '_' + rowIndex)}
        ref={ref => (this.cellRefs[rowIndex + '_' + columnIndex] = ref)}
        rowData={rowData}
        isGroupHeader={isGroupHeader}
        column={column}
        disabled={disabled || isGroupHeader}
        onKeyDown={!isGroupHeader ? (e: any) => this.onInput(e, rowIndex, columnIndex) : undefined}
        onPaste={!isGroupHeader ? (e: any) => this.onPaste(e, rowIndex, columnIndex) : undefined}
        updateOnBlur={updateOnBlur}
      />
    );
  }

  private generateSortedRows(props = this.props, inSortedField?: string, inSortOrder?: string) {
    const { data } = props;
    const mergedRows = data.map((item: any, index: number) => ({ ...item, data: props.data[index], originalIndex: index }));
    let sortedRows = mergedRows;
    const field = inSortedField || (this.state ? this.state.sortedField : undefined);
    const sortOrder = inSortOrder || (this.state ? this.state.sortOrder : undefined);
    if (field) {
      sortedRows = orderBy(
        sortedRows,
        (row: IMergedRow) => {
          if (typeof row.data[field] === 'string') {
            if (!isNaN(row.data[field].replace(/[\$,]/g, ''))) {
              return parseFloat(row.data[field].replace(/[\$,]/g, ''));
            }
          }
          return row.data[field];
        },
        sortOrder,
      );
    }
    return sortedRows;
  }

  // We re-sort as a user clicks off the table
  @bind
  private onBlur() {
    this.setState({ sortedRows: this.generateSortedRows() });
  }

  private renderCustomHeaders() {
    if (this.props.customHeaders) {
      const rows = this.props.customHeaders.map((row, rowIndex) => {
        const columns: JSX.Element[] = [];
        const sortedRow: ICustomHeader[] = sortBy(row, (header: ICustomHeader) => {
          let start = this.props.columns.length + 1;
          for (const child of header.columns) {
            start = Math.min(start, findIndex(this.props.columns, { field: child }));
          }
          return start;
        });
        let currentIndex = 0;
        sortedRow.forEach((header, headerIndex) => {
          let start = this.props.columns.length + 1;
          let end = 0;
          for (const child of header.columns) {
            const index = findIndex(this.props.columns, (e: IColumn) => {
              if (e.field === child) {
                if (this.state.groupBy && this.state.groupBy.indexOf(e.field) >= 0) {
                  return false;
                }
                return true;
              }
              return false;
            });
            if (index >= 0) {
              start = Math.min(start, index);
              end = Math.max(end, index);
            }
          }
          if (this.state.groupBy && findIndex(this.props.columns, { field: this.state.groupBy }) < start) {
            start--;
            end--;
          }
          if (start > currentIndex) {
            columns.push(<th key={`-${currentIndex}`} colSpan={start - currentIndex} className="column-title spacer" />);
          }
          const size = end - start + 1;
          columns.push(
            <th key={headerIndex} colSpan={size}>
              {header.title}
            </th>,
          );
          currentIndex = start + size;
        });
        return <tr key={rowIndex}>{columns}</tr>;
      });
      return rows;
    } else {
      return null;
    }
  }

  private renderGroupTotals(columns: IColumn[], field: string, group: string) {
    const columnTotals: any = {};

    // Generate totals
    columns.map((column, columnIndex) => {
      if (column.field !== field) {
        if ((column.type === 'currency' || column.type === 'number' || !column.type) && (column.totalable === undefined ? true : column.totalable)) {
          const customSum = this.props.customSum || column.customSum;
          if (customSum) {
            columnTotals[column.field] = customSum(
              'group',
              column,
              this.state.sortedRows.filter((row: IMergedRow) => {
                let groupValue = row[field];
                if (typeof groupValue === 'object' && groupValue !== null) {
                  groupValue = groupValue.value;
                }
                return groupValue === group;
              }),
              column.field,
            );
          } else {
            this.state.sortedRows.map((childData: IMergedRow) => {
              let groupValue = childData[field];
              if (typeof groupValue === 'object' && groupValue !== null) {
                groupValue = groupValue.value;
              }
              if (groupValue === group) {
                let value = childData[column.field];
                if (typeof value === 'string') {
                  value = parseFloat(value.replace(/[$,]/g, ''));
                }
                columnTotals[column.field] = (columnTotals[column.field] || 0) + value;
              }
            });
          }
        }
      }
    });

    // Add the grouped column field as well
    columnTotals[field] = group;

    // Display them
    return columns.map((column, columnIndex) => {
      if (column.field !== field) {
        if ((column.type === 'currency' || column.type === 'number' || !column.type) && (column.totalable === undefined ? true : column.totalable)) {
          return this.renderCell(column, columnTotals, 0, columnIndex, true);
        } else {
          return <th />;
        }
      }
    });
  }

  private renderGroup(field: string, group: string): JSX.Element {
    const { columns, inlineGroups, showGroupTitle, lockGrouping } = this.props;
    const groupColumn = find(columns, { field }) as IColumn;
    return (
      <>
        <tr className="group" data-group={group} onClick={e => this.toggleGroupOpen(e.currentTarget.attributes['data-group'].value)}>
          <th colSpan={!inlineGroups ? columns.length : -1}>
            <span style={{ width: groupColumn.width }}>
              {showGroupTitle && `${groupColumn.name}: `}
              {group}
            </span>
            <Icon icon={this.state.closedGroups.indexOf(group) < 0 ? 'fa-chevron-down' : 'fa-chevron-right'} />
            {!lockGrouping && <Icon id={idString('smartgrid_g_' + idString(group))} className="close-group" icon="fa-times" onClick={this.setGroup} data-group={field} />}
            {!lockGrouping && (
              <Tooltip target={idString('#smartgrid_g_' + idString(group))} event="hover" mount={inlineGroups ? 'right-top' : 'left-top'}>
                Cancel Grouping
              </Tooltip>
            )}
          </th>
          {inlineGroups && this.renderGroupTotals(columns[0].field === this.state.groupBy ? columns.slice(2) : columns.slice(1), field, group)}
        </tr>
        {!inlineGroups && <tr className="group-totals">{this.renderGroupTotals(columns, field, group)}</tr>}
      </>
    );
  }

  private renderRows() {
    const groupBy = this.state.groupBy;
    if (groupBy) {
      // Generate what groups to display
      const groups = this.generateGroupList(groupBy);
      let groupCounts: number[] = [];
      let fullGroupCounts: number[] = [];
      if (!this.cachedGroupCount.length) {
        groups.map(group => {
          let total = 0;
          let trueTotal = 0;
          this.state.sortedRows.map((row, index) => {
            let realValue = row.data[groupBy];
            if (typeof realValue === 'object' && realValue !== null) {
              realValue = realValue.value;
            }
            if (realValue === group && this.state.closedGroups.indexOf(group) < 0) {
              total++;
            }
            if (realValue === group) {
              trueTotal++;
            }
          });
          groupCounts.push(total);
          fullGroupCounts.push(trueTotal);
        });
        this.cachedGroupCount = groupCounts;
        this.cachedFullGroupCount = fullGroupCounts;
      } else {
        groupCounts = this.cachedGroupCount;
        fullGroupCounts = this.cachedFullGroupCount;
      }
      let trueIndex = 0;
      let count = 0;
      let previousCount = 0;
      const maxResults = this.props.paginate ? this.props.pageSize || this.defaultPaginationLimit : this.state.sortedRows.length;
      const pageSize = this.props.pageSize || this.defaultPaginationLimit;
      let startGroup = 0;
      if (this.props.paginate) {
        let offset = 0;
        groups.map((group, groupIndex) => {
          if (groupCounts[groupIndex] + offset < this.state.page * pageSize && groupCounts[groupIndex]) {
            offset += groupCounts[groupIndex];
            startGroup++;
          }
        });
      }
      return groups.map((group, groupIndex) => {
        if (this.state.closedGroups.indexOf(group) >= 0) {
          previousCount += fullGroupCounts[groupIndex];
        }
        if (count >= maxResults || groupIndex < startGroup) {
          return;
        }
        return [
          this.renderGroup(groupBy as string, group),
          this.state.sortedRows.map((row, index) => {
            if (this.props.paginate && index < this.state.page * pageSize + previousCount) {
              return;
            }
            let realValue = row.data[groupBy];
            if (typeof realValue === 'object' && realValue !== null) {
              realValue = realValue.value;
            }
            if (realValue === group) {
              trueIndex++;
              if (this.state.closedGroups.indexOf(group) < 0) {
                count++;
                if (count > maxResults) {
                  return;
                }
                return (
                  <tr className={Class('grouped', { open: this.state.closedGroups.indexOf(group) < 0 })} key={index}>
                    {this.renderRowCells(row, trueIndex, groupBy, group)}
                  </tr>
                );
              }
            }
          }),
        ];
      });
    } else {
      let pageRows = this.state.sortedRows;
      const pageSize = this.props.pageSize || this.defaultPaginationLimit;
      if (this.props.paginate) {
        pageRows = pageRows.slice(this.state.page * pageSize, this.state.page * pageSize + pageSize);
      }
      return pageRows.map((row, index) => <tr key={index}>{this.renderRowCells(row, index)}</tr>);
    }
  }

  private renderTotalRow() {
    const { columns } = this.props;
    const { sortedRows } = this.state;
    const newColumns: any = {};

    columns.map((column, columnIndex) => {
      if (column.field === this.state.groupBy && !column.spacer) {
        return;
      }
      if ((column.type === 'currency' || column.type === 'number' || !column.type) && (column.totalable === undefined ? true : column.totalable)) {
        const customSum = this.props.customSum || column.customSum;
        if (customSum) {
          newColumns[column.field] = customSum('total', column, this.state.sortedRows, column.field);
        } else {
          let total = 0;
          for (const row of sortedRows) {
            let value = row[column.field];
            if (typeof value === 'object' && value !== null) {
              value = value.value;
            }
            if (typeof value === 'string') {
              value = parseFloat(value.replace(/[$,]/g, ''));
            }
            total += value;
          }
          newColumns[column.field] = total;
        }
      }
    });

    const render = [];

    render.push(
      columns.map((column, columnIndex) => {
        if (column.field === this.state.groupBy && !column.spacer) {
          return;
        }
        if ((column.type === 'currency' || column.type === 'number' || !column.type) && (column.totalable === undefined ? true : column.totalable)) {
          return <Cell key={'total_' + columnIndex} column={column} rowData={newColumns} disabled isTotal />;
        } else {
          return <td key={'total_' + columnIndex} className={Class('empty', { ['hidden-cell']: column.name === '' || column.name === undefined })} />;
        }
      }),
    );

    return [
      <tr key="total-spacer" className="total-spacer">
        <td colSpan={columns.length} />
      </tr>,
      <tr key="total-row" className="total-row">
        {render}
      </tr>,
    ];
  }

  @bind
  private setPage(e: React.MouseEvent<HTMLElement>) {
    const page = parseInt(e.currentTarget.attributes['data-page'].value, 10);
    let newTabScroll = this.state.pageTabScroll;
    if (page < newTabScroll) {
      newTabScroll = page;
    }
    if (page >= newTabScroll + this.defaultPageTabSize) {
      newTabScroll = page;
    }
    this.setState({ page, pageTabScroll: newTabScroll });
  }

  @bind
  private setPageTab(e: React.MouseEvent<HTMLElement>) {
    const pageTabScroll = parseInt(e.currentTarget.attributes['data-page'].value, 10);
    this.setState({ pageTabScroll });
  }

  public componentDidUpdate(): void {
    console.timeEnd('Render Insert');
  }

  public componentDidMount(): void {
    if (!this.props.grid.smartgrid) {
      this.props.grid.smartgrid = this;
    }
    console.timeEnd('Render Insert');
  }

  public render() {
    console.time('SmartGrid Render');
    const { className, theme, rowTotal, buttonGroup, downloadCSV, paginate } = this.props;
    const paginateRender = [];
    let totalPages = 0;
    if (paginate) {
      const pageSize = this.props.pageSize || this.defaultPaginationLimit;
      totalPages = Math.ceil(this.state.sortedRows.length / pageSize);
      if (this.state.groupBy) {
        let totalRows = 0;
        for (const row of this.props.data) {
          if (this.state.closedGroups.indexOf(row[this.state.groupBy]) < 0) {
            totalRows++;
          }
        }
        totalPages = Math.ceil(totalRows / pageSize);
      }
      if (this.state.pageTabScroll) {
        const newScroll = Math.max(0, this.state.pageTabScroll - this.defaultPageTabSize);
        paginateRender.push(
          <li key="jump-backwards" data-page={newScroll} onClick={this.setPageTab}>
            ...
          </li>,
        );
      }
      for (let i = Math.max(Math.min(this.state.pageTabScroll, totalPages - 5), 0); i < this.state.pageTabScroll + Math.min(this.defaultPageTabSize, totalPages); i++) {
        if (i < totalPages) {
          paginateRender.push(
            <li key={i} data-page={i} onClick={this.setPage} className={Class({ active: this.state.page === i })}>
              {i + 1}
            </li>,
          );
        }
      }
      if (this.state.pageTabScroll + this.defaultPageTabSize < totalPages) {
        let newScroll = this.state.pageTabScroll + Math.min(this.defaultPageTabSize, totalPages);
        if (newScroll + this.defaultPageTabSize >= totalPages) {
          newScroll = totalPages - this.defaultPageTabSize;
        }
        paginateRender.push(
          <li key="jump-forward" data-page={newScroll} onClick={this.setPageTab}>
            ...
          </li>,
        );
      }
    }
    const ret = (
      <span>
        {buttonGroup && (
          <ButtonGroup>
            {buttonGroup.indexOf('open-all') >= 0 && <ButtonItem title="Open All" className="blue" disabled={!this.state.groupBy} onClick={this.openAll} />}
            {buttonGroup.indexOf('close-all') >= 0 && <ButtonItem title="Close All" className="blue" disabled={!this.state.groupBy} onClick={this.collapseAll} />}
            {buttonGroup.indexOf('download-csv') >= 0 && <ButtonItem title="Download CSV" className="blue" onClick={downloadCSV} />}
          </ButtonGroup>
        )}
        <table ref={ref => (this.tableRef = ref)} className={Class('data-grid', className, theme || 'theme-default', { 'firefox-fix': firefoxFix })} onBlur={this.onBlur}>
          <thead>
            {this.renderCustomHeaders()}
            {this.renderHeader()}
          </thead>
          <tbody>
            {this.renderRows()}
            {rowTotal && this.renderTotalRow()}
          </tbody>
        </table>
        {paginate && paginateRender.length > 1 && (
          <ul className="paginator">
            <li data-page={'0'} onClick={this.state.page ? this.setPage : undefined} className={Class({ disabled: this.state.page === 0 })}>
              <Icon icon="fa-step-backward" small />
            </li>
            <li data-page={(this.state.page - 1).toString()} onClick={this.state.page ? this.setPage : undefined} className={Class({ disabled: this.state.page === 0 })}>
              <Icon icon="fa-play" flip="horizontal" small />
            </li>
            {paginateRender}
            <li
              data-page={(this.state.page + 1).toString()}
              onClick={this.state.page !== totalPages - 1 ? this.setPage : undefined}
              className={Class({ disabled: this.state.page === totalPages - 1 })}
            >
              <Icon icon="fa-play" small />
            </li>
            <li
              data-page={(totalPages - 1).toString()}
              onClick={this.state.page !== totalPages - 1 ? this.setPage : undefined}
              className={Class({ disabled: this.state.page === totalPages - 1 })}
            >
              <Icon icon="fa-step-forward" small />
            </li>
          </ul>
        )}
      </span>
    );
    console.timeEnd('SmartGrid Render');
    console.time('Render Insert');
    return ret;
  }
}
