import _, {  reduce, toPath} from "lodash";
import {  extendObservable, isObservable, isObservableObject as isObservableObjectOriginal, isObservableProp, observable, set} from "mobx";

function compatibleIsObservableObject(value: any): boolean {
    return value !== null && typeof value === "object" && !!value.$mobx;
}

export function setNestedObservable<VT>(o: any, path: string | string[], value: VT): void {
    const p = toPath(path);
    const firstSteps = p.slice(0,-1);
    const finalStep = p[p.length - 1];
    if (!isObservable(o)) {
        extendObservable(o, {});
    }
    const finalTarget = reduce(firstSteps, (target: any, step: string): any => {
        if (compatibleIsObservableObject(target) && !isObservableProp(target, step)) {
            set(target, step, target[step]);
        }
        const nextTarget = target[step];
        if (nextTarget) {
            if (!isObservable(nextTarget)) {
                extendObservable(nextTarget, {});
            }
            return nextTarget;
        } else {
            set(target, step, observable(isNaN(step as any) ? {} : []));
            return target[step];
        }
    }, o);

    set(finalTarget, finalStep, value);
}

export function ensureNestedObservable<VT>(o: any, path: string | string[]): void {
    const p = toPath(path);
    const firstSteps = p.slice(0,-1);
    const finalStep = p[p.length - 1];
    if (!isObservable(o)) {
        extendObservable(o, {});
    }
    // console.debug("ensureNestedObservable", o, path);
    const finalTarget = reduce(firstSteps, (target: any, step: string): any => {
        // console.debug("ensureNestedObservable::reducer", target, step);
        if (compatibleIsObservableObject(target) && !isObservableProp(target, step)) {
            set(target, step, target[step]);
        }
        const nextTarget = target[step];
        if (nextTarget) {
            if (!isObservable(nextTarget)) {
                extendObservable(nextTarget, {});
            }
            return nextTarget;
        } else {
            set(target, step, observable(isNaN(step as any) ? {} : []));
            return target[step];
        }
    }, o);

    if (compatibleIsObservableObject(finalTarget) && !isObservableProp(finalTarget, finalStep)) {
        set(finalTarget, finalStep, finalTarget[finalStep]);
    }
}

(global as any).setNestedObservable = setNestedObservable;
