const kendo = (global as any).kendo;

const Binder: typeof kendo.data.Binder = kendo.data.Binder;

interface ISimpleWidget extends kendo.ui.Widget {
    get(field: string): any;
    set(field: string, newValue: any): void;
}

class WidgetBinder extends Binder {
    private bindingName: string;
    private widget: ISimpleWidget;
    constructor(bindingName: string, widget: ISimpleWidget, bindings: kendo.data.Bindings, options?: kendo.data.BinderOptions) {
        super(widget,bindings,options);
        this.bindingName = bindingName;
        this.widget = widget;
        // console.log("setup " + this.bindingName + " binding: ", this, widget, bindings, options);
        Binder.fn.init.call(this, widget.element[0], bindings, options);
        this.widget = widget;
    }

    // public init(widget: any, bindings: kendo.data.Bindings, options?: kendo.data.BinderOptions) {
    //     console.log("setup " + this.bindingName + " binding: ", this, widget, bindings, options);
    //     return this;
    // }

    public refresh() {
        const value = this.bindings[this.bindingName].get();
        this.widget.set(this.bindingName, this.bindings[this.bindingName].get());
        // return console.log("input binding: " + this.bindingName, value);
    }

    public change() {
        return this.bindings[this.bindingName].set(this.widget.get(this.bindingName));
    }
}

export function simpleWidgetBinder(bindingName: string) {
    if ((kendo.data.binders.widget)[bindingName] != null) {
        return (kendo.data.binders.widget)[bindingName];
    }

    class NamedWidgetBinder extends WidgetBinder {
        constructor(widget: ISimpleWidget, bindings: kendo.data.Bindings, options?: kendo.data.BinderOptions) {
            super(bindingName, widget, bindings, options);
        }
    }

    Object.defineProperty(NamedWidgetBinder, "name", {value: `${bindingName}WidgetBinder`});
    kendo.data.binders.widget[bindingName] = NamedWidgetBinder;
    return NamedWidgetBinder;
}
