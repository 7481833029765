// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MdvzdZ-SoCbXjSehqhIcA{height:52px;width:100%}.eBgcISgYf3BlxtMKQzzqH{background:rgba(0,0,0,0.05);padding:16px;padding-left:32px;padding-right:32px;position:absolute;bottom:0px;left:0px;right:0px;box-sizing:content-box}\n", ""]);
// Exports
exports.locals = {
	"card-bottom-placeholder": "_1MdvzdZ-SoCbXjSehqhIcA",
	"card-bottom": "eBgcISgYf3BlxtMKQzzqH"
};
module.exports = exports;
