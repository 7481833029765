import { bind, debounce } from 'decko';
import * as React from 'react';

type ScreenTypes = 'desktop' | 'tablet' | 'mobile';

interface IDeviceProps {
  device: ScreenTypes;
}

interface IDeviceState {
  device?: ScreenTypes;
}

export default class Device extends React.Component<IDeviceProps, IDeviceState> {
  constructor(props: IDeviceProps) {
    super(props);

    this.onResize = this.onResize.bind(this);
    this.state = { device: undefined };
  }

  public componentDidMount(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  @bind
  @debounce(400)
  private onResize(): void {
    let device: ScreenTypes = 'mobile';
    if (window.innerWidth > 600) {
      device = 'tablet';
      if (window.innerWidth > 1280) {
        device = 'desktop';
      }
    }
    if (device !== this.state.device) {
      this.setState({ device });
    }
  }

  public render(): JSX.Element | null {
    const { device, children } = this.props;
    if (device !== this.state.device) {
      return null;
    }
    return <div>{children}</div>;
  }
}
