import { inject, observer, Provider } from 'mobx-react';
import * as React from 'react';
import PageController from './PageController';

type Transition = 'slideRight' | 'slideLeft' | 'slideUp' | 'slideDown' | 'popRight' | 'popLeft' | 'popUp' | 'popDown';

interface IPageRouteProps extends React.HTMLProps<HTMLElement> {
  component: JSX.Element;
  controller: PageController;
  baseRoute: string;
  route?: string;
  inline?: boolean;
  inlineClassName?: string;
  transition?: Transition;
}

@inject('baseRoute')
@inject('controller')
@observer
export default class PageRoute extends React.Component<IPageRouteProps, never> {
  constructor(props: IPageRouteProps) {
    super(props);

    if (!this.props.component) {
      throw new Error('PageRoute requires a component, none given');
    }
  }

  public componentDidMount() {
    this.props.controller.registerRoute(this.fullRoute(), this);
  }

  private fullRoute() {
    return (this.props.baseRoute ? this.props.baseRoute + '/' : '') + (this.props.route || '');
  }

  public render() {
    const { component, baseRoute, route, controller, children, ...other } = this.props;
    return (
      <Provider baseRoute={this.fullRoute()}>
        <div>{children}</div>
      </Provider>
    );
  }
}
