const transparentColors = ["rgba(0, 0, 0, 0)", "transparent"];

const colorCache: any = {};

function colorTester(): JQuery<HTMLElement> {
    let $colorTester = $("#color-tester");
    if (!$colorTester.length) {
        $colorTester = $("<span id='color-tester' style='display: none'/>");
        $("body").append($colorTester);
    }
    return $colorTester;
}

function selectionCss(): JQuery<HTMLElement> {
    let $colorSpan = $("#color-selection");
    if (!$colorSpan.length) {
        $colorSpan = $("<style id='color-selection'/>");
        $("head").append($colorSpan);
    }
    return $colorSpan;
}


const SELECTION_SELECTOR = `.k-draghandle.k-state-selected:hover, .k-ghost-splitbar-horizontal, .k-ghost-splitbar-vertical, .k-list > .k-state-highlight, .k-list > .k-state-selected, .k-marquee-color, .k-panel > .k-state-selected, .k-scheduler .k-scheduler-toolbar .k-state-selected, .k-scheduler .k-today.k-state-selected, .k-state-selected, .k-state-selected:link, .k-state-selected:visited, .k-tool.k-state-selected`;
const SELECTION_FOREGROUND = `body.gears:not(.gears-ignore-selected) .k-grid .k-grid-content .k-state-selected:not(.k-grouping-row) td, body.gears:not(.gears-ignore-selected) .k-grid .k-grid-content .k-state-selected:not(.k-grouping-row) td a,
                              body.gears:not(.gears-ignore-selected) .gears-grid tr.k-state-selected > td.gears-table-icon:not(.gears-ignore-selected) i.fa, body.gears:not(.gears-ignore-selected) .gears-grid tr.k-state-selected > td.gears-table-icon:not(.gears-ignore-selected) .svg-inline--fa`;

export function setSelectionColor(css: string): void {
    const styleElement = selectionCss();
    const bgColor = bgFromClass(css || "bg-color-webfront bg-selection-background");
    const fgColor = colorFromClass(css || "txt-color-webfront txt-selection-foreground");

    let newCSS = `${SELECTION_SELECTOR} {
        background-color: ${bgColor} !important;
        border-color: ${bgColor} !important;
    }
    `;
    if(fgColor) {
        newCSS = `${newCSS}
        ${SELECTION_FOREGROUND} {
            color: ${fgColor} !important;
        }`;
    } else {
        $('body').addClass('gears-ignore-selected');
    }
    styleElement.html(newCSS);
}

export function bgFromClass(css: string): string | null {
    let $colorTester, bgCol;
    if (colorCache[css]) {
        return colorCache[css];
    }
    $colorTester = colorTester();
    $colorTester.attr("class", css);
    bgCol = $colorTester.css("background-color");
    if (isIn(bgCol, transparentColors)) {
        return null;
    }
    colorCache[css] = bgCol;
    return bgCol;
}

export function colorFromClass(css: string) {
    let $colorTester, col;
    if (colorCache[css]) {
        return colorCache[css];
    }
    $colorTester = colorTester();
    $colorTester.attr("class", css);
    col = $colorTester.css("color");
    if (isIn(col, transparentColors)) {
        return;
    }
    colorCache[css] = col;
    return col;
}

function isIn(x: string, xs: string[]): boolean {
    let i = -1, l = xs.length >>> 0;
    while (++i < l) { if (x === xs[i]) { return true; } }
    return false;
}
