import * as React from 'react';

export interface IRadioContext {
  setValue: string;
  onChange: (value: string) => void;
  setDisabled: boolean | undefined;
}

const RadioContext: React.Context<IRadioContext> = React.createContext({
  onChange: () => 0,
  setDisabled: false,
  setValue: '',
});

export default RadioContext;
