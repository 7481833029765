require("medium-editor/dist/css/medium-editor.css");
require("medium-editor/dist/css/themes/default.css");
import  { get, IComputedValue, IObservableObject, observable, reaction, set } from "mobx";
import { useDisposable, useObserver } from "mobx-react-lite";
import React, {
    createContext,
    memo,
    ReactNode,
    useCallback,
    useContext,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import Medium from "react-medium-editor";
import { Registry } from "react-registry";

interface IPlaceholderContext {
    placeholders: IObservableObject;
    enabled: boolean;
}
export const PlaceholderContext = createContext<IPlaceholderContext>({
    placeholders: observable({}),
    enabled: true,
});


export interface IPlaceholderProps {
    name: string;
    enabled?: boolean;
    children?: ReactNode;
}


const Placeholder: React.FunctionComponent<IPlaceholderProps> = ({ enabled, children, name }: IPlaceholderProps): any => {
    const [placeholder, setPlaceholder] = useState<string | null>(null);

    const placeholderRef = useRef<HTMLSpanElement>(null);
    const editorRef = useRef<Medium>(null);

    const context = useContext(PlaceholderContext);


    // const value = usePathComputed(context, name);
    const { getValue, setValue } = useMemo(() => {
        set(context.placeholders, name, get(context.placeholders, name));
        return {
            getValue: (() => get(context.placeholders, name)),
            setValue: ((n: string) => {
                set(context.placeholders, name, n);
            })
        };
    }, [context, name]);

    useLayoutEffect(() => {
        if (!placeholderRef.current) {
            return;
        }
        const inner: string = placeholderRef.current.innerHTML;
        setPlaceholder(inner);
        if (!getValue()) {
            setValue(inner);
        }
    }, []);


    return useObserver(() => {
        if (enabled === false || !context.enabled) {
            return getValue() || placeholder || children;
        }
        if (placeholder == null) {
            return <span ref={placeholderRef} className="placeholder" children={children}/>;
        }
        return <Medium ref={editorRef} text={getValue() || placeholder} onChange={setValue} tag="span"/>;
    });

};

Placeholder.displayName = "MediumEditor";

Registry.register(Placeholder, { id: "Placeholder" });
export default Placeholder;
