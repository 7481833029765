import * as React from 'react';

interface IActionTabProps {
  title: string;
}

export default class ActionTab extends React.PureComponent<IActionTabProps, never> {
  public render(): JSX.Element {
    return <div>{this.props.children}</div>;
  }
}
