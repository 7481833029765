/*
* This method is like `_.assign` except that it works with property descriptors
*
* **Note:** This method mutates `object`.
*/

function assignPropertyDescriptors<TObject, TSource>(
    object: TObject,
    source: TSource
): TObject & TSource;

function assignPropertyDescriptors<TObject, TSource1, TSource2>(
    object: TObject,
    source1: TSource1,
    source2: TSource2
): TObject & TSource1 & TSource2;

function assignPropertyDescriptors<TObject, TSource1, TSource2, TSource3>(
    object: TObject,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3
): TObject & TSource1 & TSource2 & TSource3;

function assignPropertyDescriptors<TObject, TSource1, TSource2, TSource3, TSource4>(
    object: TObject,
    source1: TSource1,
    source2: TSource2,
    source3: TSource3,
    source4: TSource4
): TObject & TSource1 & TSource2 & TSource3 & TSource4;

function assignPropertyDescriptors<TObject>(object: TObject): TObject;

function assignPropertyDescriptors<TResult>(target: any, ...sources: any[]): TResult {
    sources.forEach((source) => {
        Object.getOwnPropertyNames(source).forEach((propName) => {
            Object.defineProperty(target, propName,
                Object.getOwnPropertyDescriptor(source, propName) as PropertyDescriptor);
        });
    });
    return target;
}

export default assignPropertyDescriptors;
