import * as React from 'react';

interface ICircleImageProps {
  size: number;
  style?: any;
  margin?: number;
  src: string;
}

/**
 * A simple circle-masked image
 */
export default class CircleImage extends React.PureComponent<ICircleImageProps, never> {
  public render(): JSX.Element {
    const { size, margin, src, style, ...other } = this.props;
    const finalStyle = {
      width: size + 'px',
      height: size + 'px',
      borderRadius: size / 2 + 'px',
      margin: margin + 'px',
      boxShadow: '2px 2px 2px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.2)',
      ...style,
    };
    return <img src={src} style={finalStyle} {...other} />;
  }
}
