// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2oY6gKJ0UXA2z9taRsC_M0{background-color:#2b2b2b;padding:16px;color:rgba(255,255,255,0.87) !important}._2oY6gKJ0UXA2z9taRsC_M0 pre{font-family:monospace;font-size:1rem;line-height:1rem;background:rgba(0,0,0,0.25);padding:16px;color:rgba(255,255,255,0.87) !important}\n", ""]);
// Exports
exports.locals = {
	"error": "_2oY6gKJ0UXA2z9taRsC_M0"
};
module.exports = exports;
