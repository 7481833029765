import { IObservableObject } from "mobx";
import { observer, Provider } from "mobx-react";
import * as React from "react";
import { IPageJS } from "./PageConfig";
import "./TemplateGlobals";
import TemplateRenderer from "./TemplateRenderer";

declare const global: any;

interface ITemplateProps {
    pageJS: IPageJS;
    renderWindow?: any;
}

interface IPageProps {
    getComponent: (name: string) => React.ReactType;
    page: IObservableObject;
    pageData: any;
    pageJS: IPageJS;
    renderWindow: any;
}

// @observer
export class StyleRenderer extends React.Component<ITemplateProps, never> {
    public render() {
        const stylesheet = this.props.pageJS.style;
        const renderWindow = this.props.renderWindow || window;
        if (stylesheet) {
            renderWindow.document.getElementById("styleContainer").innerHTML = `<style>${stylesheet}</style>`;
        }
        return null;
    }
}

// @observer
// @inject('page', 'pageData')
export class JavascriptRenderer extends React.Component<IPageProps, never> {
    public render() {
        const { page, pageData, getComponent, pageJS } = this.props;
        const { javascript } = pageJS;
        if (javascript) {
            try {
                javascript(page, getComponent, pageData, pageData);
            } catch (e) {
                console.error("Error running page javascript", e);
                global.gearsDialog({
                    title: "Error",
                    icon: "error",
                    buttons: [{ name: "OK", resolve: true }],
                    content: e.toString(),
                });
                return global.errorDisplay({ name: "javascript", error: e });
            }
        }
        return null;
    }
}

@observer
export class PageRenderer extends React.Component<IPageProps, never> {
    public render() {
        let { page, pageData, pageJS, renderWindow, getComponent } = this.props;

        pageData = pageData || window.page_data;
        window.page = window.page || page;
        return (
            <Provider pageData={pageData} renderWindow={renderWindow} pageJS={pageJS} page={page} getComponent={getComponent}>
                <div>
                    <StyleRenderer pageJS={pageJS} renderWindow={renderWindow} />
                    <JavascriptRenderer pageJS={pageJS} page={page} renderWindow={renderWindow} pageData={pageData} getComponent={getComponent} />
                    <TemplateRenderer pageJS={pageJS} page={page} pageData={pageData} />
                </div>
            </Provider>
        );
    }
}

export default PageRenderer;
