import {cnb as Class} from 'cnbuilder';
import ActionItem from 'commonui/ActionItem';
import Icon from 'commonui/Icon';
import { Menu } from 'commonui/Menu';
import React from 'react';

import styles from './ActionBar.scss';

interface IActionItemAreaProps {
  children: JSX.Element[];
  hasSearch?: boolean;
  onSearch?: () => void;
}

const iOS = /iPad|iPhone|iPod/.test((global as any).navigator && (global as any).navigator.userAgent) && !(global as any).MSStream;

export default class ActionItemArea extends React.Component<IActionItemAreaProps> {
  public shouldComponentUpdate(nextProps: IActionItemAreaProps): boolean {
    if (this.props.onSearch !== nextProps.onSearch) {
      return true;
    }
    if (this.props.hasSearch !== nextProps.hasSearch) {
      return true;
    }
    const realChildren = (Array.isArray(this.props.children) ? this.props.children.filter(child => child !== undefined) : [this.props.children]) as Array<React.ReactElement<any>>;
    const realNextChildren = (Array.isArray(nextProps.children) ? nextProps.children.filter(child => child !== undefined) : [nextProps.children]) as Array<React.ReactElement<any>>;
    if (realChildren.length !== realNextChildren.length) {
      return true;
    }
    for (let i = 0; i < realChildren.length; i++) {
      const oldChild = realChildren[i] as ActionItem;
      const newChild = realNextChildren[i] as ActionItem;
      if (!oldChild) {
        return true;
      }
      if (oldChild.props.title !== newChild.props.title) {
        return true;
      }
      if (oldChild.props.disabled !== newChild.props.disabled) {
        return true;
      }
      if (oldChild.props.icon !== newChild.props.icon) {
        return true;
      }
      if (oldChild.props.showAsIcon !== newChild.props.showAsIcon) {
        return true;
      }
      if (oldChild.props.onClick !== newChild.props.onClick) {
        return true;
      }
    }
    return false;
  }

  public render(): JSX.Element {
    const { children, hasSearch, onSearch } = this.props;
    const realChildren = (Array.isArray(children) ? children : [children]) as Array<React.ReactElement<any>>;

    let maxIcons = 6;
    const width = global.innerWidth / global.devicePixelRatio;
    if (width <= 1280) {
      maxIcons--;
      if (width <= 960) {
        maxIcons--;
        if (width <= 840) {
          maxIcons--;
          if (width <= 600) {
            maxIcons--;
          }
        }
      }
    }

    // Remove one icon slot for search icon
    if (hasSearch) {
      if (width <= 600) {
        maxIcons--;
      } else {
        maxIcons = 4;
      }
    }

    // Generate the lists of shown icons and overflow options
    const iconList: React.ReactFragment[] = [];
    const excludedList: any[] = [];
    const menuList: React.ReactFragment[] = [];
    // Loop first to get all the show always icons
    realChildren.forEach((item, index: number) => {
      if (item && (item.type === ActionItem || item.type.name === 'ActionItem') && item.props.showAsIcon === 'always') {
        iconList.push(<ActionItem key={index} type="icon" index={index} {...item.props} />);
      }
    });
    // Fit all the ones taht can fit
    realChildren.forEach((item, index: number) => {
      if (item && (item.type === ActionItem || item.type.name === 'ActionItem')) {
        if ((item.props.showAsIcon === 'ifRoom' || !item.props.showAsIcon) && iconList.length < maxIcons) {
          iconList.push(<ActionItem key={index} type="icon" index={index} {...item.props} />);
        } else if (item.props.showAsIcon !== 'always') {
          excludedList.push(item);
        }
      }
    });
    // Push everything else into menu
    if (excludedList.length > 1) {
      excludedList.forEach((item, index: number) => {
        menuList.push(<ActionItem index={index} key={index} type="menu" {...item.props} />);
      });
    } else if (excludedList.length) {
      const item = excludedList[0];
      iconList.push(<ActionItem key={iconList.length + 1} type="icon" index={iconList.length + 1} {...item.props} />);
    }

    return (
      <div className={Class('action-container', styles['action-container'])}>
        {hasSearch && (
          <span>
            <Icon icon="fa-search" onClick={onSearch} />
          </span>
        )}
        {iconList}
        {menuList.length > 0 && (
          <span>
            <Icon id={`actionbar-menu${name}`} icon={iOS ? 'fa-ellipsis-h' : 'fa-ellipsis-v'} />
          </span>
        )}
        {menuList.length > 0 && (
          <Menu target={`#actionbar-menu${name}`} mount="below-left">
            {menuList}
          </Menu>
        )}
      </div>
    );
  }
}
