import Class from 'classnames';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import CardContext from 'commonui/Card/CardContext';
import Icon from '../Icon';

interface ICardHeaderProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  subtitle?: string;
  fabIcon?: string;
  small?: boolean;
  onFabPress?: () => void;
  sticky?: boolean;
}

/**
 * Card header controls the top of a card and is where you can place CardActions
 */
@observer
export default class CardHeader extends React.Component<ICardHeaderProps> {
  public render(): JSX.Element {
    const { className, style, title, subtitle, children, fabIcon, small, onFabPress, sticky, ...other } = this.props;
    return (
      <CardContext.Consumer>
        {context => {
          let newStyle = style;
          if (context.colour) {
            if (!newStyle) {
              newStyle = {};
            }
            newStyle.backgroundColor = context.colour;
          }

          return (
            <div className={Class('card-header', className, { coloured: context.colour, small, sticky })} style={newStyle} {...other}>
              <div className="title">{title}</div>
              <div className="subtitle">{subtitle}</div>
              <div className="card-actions">{children}</div>
              {fabIcon && (
                <div className="fab">
                  <Icon icon={fabIcon} onClick={onFabPress} />
                </div>
              )}
            </div>
          );
        }}
      </CardContext.Consumer>
    );
  }
}
