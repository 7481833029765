import Class from 'classnames';
import { bind } from 'decko';
import * as $ from 'jquery';
import * as React from 'react';
import Icon from '../Icon';
import { monthColours, monthColoursDark } from './Constants';
import styles from './DatePicker.scss';

const ArrowKey = {
  DOWN: 40,
  UP: 38,
};
interface ITimeViewProps {
  date: any;
  onChange: Function;
  minuteStep?: number;
}

interface ITimeViewState {
  editing?: boolean;
  hour?: boolean;
  minute?: boolean;
}

export default class TimeView extends React.PureComponent<ITimeViewProps, ITimeViewState> {
  public static defaultProps: Partial<ITimeViewProps> = { minuteStep: 5 };

  private currentDate: any;
  private hour: any;
  private minute: any;
  private focus = false;
  private inputRef: HTMLElement;

  constructor(props: ITimeViewProps) {
    super(props);
    this.currentDate = props.date.clone();

    this.state = { editing: false, hour: false, minute: false };
  }

  public UNSAFE_componentWillReceiveProps(props: ITimeViewProps): void {
    this.currentDate = props.date.clone();
  }

  @bind
  private onFocusHour(): void {
    this.hour = this.currentDate.format('h');
    this.focus = true;
    this.setState({ editing: true, hour: true });
  }

  @bind
  private onChangeHour(e: any): void {
    this.hour = e.target.value;
    this.forceUpdate();
  }

  @bind
  private onBlurHour(): void {
    const newDate = this.currentDate.clone();
    // Do some final validation on hour, and parse 24-12am/pm formats
    if (!isNaN(this.hour)) {
      this.hour = parseInt(this.hour, 10);
      if (this.hour >= 0 && this.hour <= 24) {
        if (this.hour >= 12) {
          newDate.hour(this.hour);
        } else {
          if (this.currentDate.hour() >= 12) {
            newDate.hour(this.hour + 12);
          } else {
            newDate.hour(this.hour);
          }
        }
        if (newDate.isValid()) {
          this.currentDate = newDate;
          if (this.props.onChange) {
            this.props.onChange(this.currentDate);
          }
        }
      }
    }
    this.setState({ editing: false, hour: false });
  }

  @bind
  private onKeyHour(event: any): void {
    if (event.keyCode === ArrowKey.UP) {
      this.incrementHour();
    }
    if (event.keyCode === ArrowKey.DOWN) {
      this.decrementHour();
    }
  }

  @bind
  private onFocusMinute(): void {
    this.minute = this.currentDate.format('mm');
    this.focus = true;
    this.setState({ editing: true, minute: true });
  }

  @bind
  private onChangeMinute(e: any): void {
    this.minute = e.target.value;
    this.forceUpdate();
  }

  @bind
  private onBlurMinute(): void {
    const newDate = this.currentDate.clone();
    if (!isNaN(this.minute)) {
      this.minute = parseInt(this.minute);
      if (this.minute >= 0 && this.minute <= 60) {
        newDate.minute(this.minute);
        if (newDate.isValid()) {
          this.currentDate = newDate;
          if (this.props.onChange) {
            this.props.onChange(this.currentDate);
          }
        }
      }
    }
    this.setState({ editing: false, minute: false });
  }

  @bind
  private onKeyMinute(event: any): void {
    if (event.keyCode === ArrowKey.UP) {
      this.incrementMinute();
    }
    if (event.keyCode === ArrowKey.DOWN) {
      this.decrementMinute();
    }
  }

  @bind
  private incrementHour(): void {
    let hour = this.currentDate.hour();
    hour += 1;
    if (hour >= 24) {
      hour -= 24;
    }
    this.hour = hour;
    this.currentDate.hour(hour);
    // this.currentDate.add(1, "hour")
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  @bind
  private decrementHour(): void {
    let hour = this.currentDate.hour();
    hour -= 1;
    if (hour < 0) {
      hour += 24;
    }
    this.hour = hour;
    this.currentDate.hour(hour);
    // this.currentDate.subtract(1, "hour")
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  @bind
  private incrementMinute(): void {
    let minute = this.currentDate.minute();
    const roundedMinute = Math.ceil(minute / this.props.minuteStep) * this.props.minuteStep;
    if (roundedMinute !== minute) {
      minute = roundedMinute;
    } else {
      minute += this.props.minuteStep;
    }
    if (minute >= 60) {
      minute -= 60;
    }
    this.minute = minute;
    this.currentDate.minute(minute);
    // this.currentDate.add(this.props.minuteStep, "minute")
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  @bind
  private decrementMinute(): void {
    let minute = this.currentDate.minute();
    const roundedMinute = Math.floor(minute / this.props.minuteStep) * this.props.minuteStep;
    if (roundedMinute !== minute) {
      minute = roundedMinute;
    } else {
      minute -= this.props.minuteStep;
    }
    if (minute < 0) {
      minute += 60;
    }
    this.minute = minute;
    this.currentDate.minute(minute);
    // this.currentDate.subtract(this.props.minuteStep, "minute")
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  @bind
  private toggleAMPM(): void {
    if (this.currentDate.hour() >= 12) {
      this.currentDate.subtract(12, 'hour');
    } else {
      this.currentDate.add(12, 'hour');
    }
    if (this.props.onChange) {
      this.props.onChange(this.currentDate);
    }
  }

  @bind
  private onKeyAMPM(event: any): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.toggleAMPM();
    }
    if (event.keyCode === ArrowKey.UP || event.keyCode === ArrowKey.DOWN) {
      this.toggleAMPM();
    }
  }

  public componentDidUpdate(): void {
    if (this.inputRef && this.focus) {
      $(this.inputRef)
        .focus()
        .select();
      this.focus = false;
    }
  }

  public render(): JSX.Element {
    const pm = this.currentDate.hour() >= 12;
    return (
      <div className={Class(styles.component, styles['time-view'])}>
        <div className={styles.title}>Time</div>
        <div className={styles['time-container']}>
          <div className={styles['hour-display']}>
            <Icon icon="fa-chevron-up" className={styles.up} onClick={this.incrementHour} />
            <Icon icon="fa-chevron-down" className={styles.down} onClick={this.decrementHour} />
            {this.state.editing && this.state.hour ? (
              <input ref={ref => (this.inputRef = ref)} value={this.hour} onChange={this.onChangeHour} onBlur={this.onBlurHour} onKeyDown={this.onKeyHour} maxLength={2} />
            ) : (
              <span tabIndex={0} onFocus={this.onFocusHour}>
                {this.currentDate.format('h')}
              </span>
            )}
          </div>
          <div className={styles['minute-display']}>
            <Icon icon="fa-chevron-up" className={styles.up} onClick={this.incrementMinute} />
            <Icon icon="fa-chevron-down" className={styles.down} onClick={this.decrementMinute} />
            {this.state.editing && this.state.minute ? (
              <input ref={ref => (this.inputRef = ref)} value={this.minute} onChange={this.onChangeMinute} onBlur={this.onBlurMinute} onKeyDown={this.onKeyMinute} maxLength={2} />
            ) : (
              <span tabIndex={0} onFocus={this.onFocusMinute}>
                {this.currentDate.format('mm')}
              </span>
            )}
          </div>
          <div className={Class(styles['ampm-display'], { [styles.am]: !pm, [styles.pm]: pm })} onClick={this.toggleAMPM}>
            <Icon icon="fa-chevron-up" className={styles.up} />
            <Icon icon="fa-chevron-down" className={styles.down} />
            <span tabIndex={0} style={{ color: pm ? monthColoursDark[this.currentDate.month()] : monthColours[this.currentDate.month()] }} onKeyDown={this.onKeyAMPM}>
              {this.currentDate.format('A')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
