import Class from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from './SnackBar.scss';

export interface ISnackBarOptions {
  /** URL of the sound to play */
  sound?: string;
}

/**
 * A toast singleton to display short notifications on the screen
 */
export class SnackBar {
  private divContainer: HTMLDivElement;
  private closeTimer: any;
  private textQueue: any[] = [];

  private init(): void {
    if (!this.divContainer) {
      this.divContainer = document.createElement('div');
      document.body.appendChild(this.divContainer);
    }
  }

  public show(content: string, options: ISnackBarOptions = {}): void {
    this.init();
    this.render(content, options);
    setTimeout(() => {
      const snack = document.getElementById('snackbar-container');
      if (snack) {
        snack.classList.add(styles.open);
      }
    }, 50);
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
    }
    if (options.sound) {
      new Audio(options.sound).play();
    }
    this.closeTimer = setTimeout(this.close, 2400);
  }

  private close(): void {
    const snack = document.getElementById('snackbar-container');
    if (snack) {
      snack.classList.remove(styles.open);
    }
  }

  private render(content: string, options: ISnackBarOptions): void {
    ReactDOM.render(
      <div id="snackbar-container" className={Class(styles['snackbar-container'])} aria-live="polite" aria-atomic="true" aria-relevant="additions">
        <div className={Class(styles.snackbar)}>
          <span>{content}</span>
        </div>
      </div>,
      this.divContainer,
    );
  }
}

export default new SnackBar();
