import * as React from 'react';
import * as ReactDOM from 'react-dom';

// TODO: Replace this with React 16's new portal implementation

/**
 * Portal is a way to insert React components directly into the body of the page
 * ignoring hierachy
 */
export default class Portal extends React.Component<React.HTMLProps<HTMLElement>, never> {
  private targetElement: HTMLDivElement;

  public componentDidMount(): void {
    const targetElement = document.createElement('div');
    document.body.appendChild(targetElement);
    this.targetElement = targetElement;
    this.componentDidUpdate();
  }

  public componentDidUpdate(): void {
    ReactDOM.unstable_renderSubtreeIntoContainer(this, <div>{this.props.children}</div>, this.targetElement);
  }

  public componentWillUnmount(): void {
    ReactDOM.unmountComponentAtNode(this.targetElement);
    if (this.targetElement.remove) {
      this.targetElement.remove();
    } else if (this.targetElement.parentNode) {
      this.targetElement.parentNode.removeChild(this.targetElement);
    }
  }

  public render(): null {
    return null;
  }
}
